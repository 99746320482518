/**
 * Since the Google Maps API loads async, we can not extend the OverlayView class like we normally do.
 */

export default class MapMarkersOverlayFactory {

    constructor(googleMap, map, layerGroupName) {

        if (!googleMap) {
            throw new Error('MapMarkersOverlayFactory requires \'googleMap\' argument to be provided');
        }
        if (!layerGroupName) {
            throw new Error('MapMarkersOverlayFactory requires \'layerGroupName\' argument to be provided');
        }

        class MapMarkersOverlay extends googleMap.OverlayView {
            constructor(mapInstance, layerGroup) {
                super(mapInstance);

                this.map = mapInstance;
                this.layerGroupName = layerGroup || MapMarkersOverlay.generateLayerGroupName();
                this.layer = null;
                this.setMap(mapInstance);

                this.markerData = [];
            }

            onAdd() {
                if (this.getPanes === undefined || this.markerData.length === 0) {
                    return;
                }

                this.createLayerGroup();
            }

            createLayerGroup() {
                if (this.layer === null && this.getPanes()) {
                    const groupLayer = document.getElementById(this.layerGroupName);
                    if (groupLayer) {
                        return this.layer = groupLayer;
                    }
                    this.layer = document.createElement('div');
                    this.layer.id = this.layerGroupName;
                    this.getPanes().overlayMouseTarget.appendChild(this.layer);
                }
            }

            draw() {
                if (this.markerData.length === 0 || this.getProjection === undefined) return;
                const projection = this.getProjection();
                if (projection === undefined) return;

                if (this.layer === null) {
                    this.createLayerGroup();
                }

                this.addItemsToLayer();

                this.markerData.forEach(item => {
                    const pixelCoordinate = projection.fromLatLngToDivPixel(item.pos);
                    const elementWidth = item.html.offsetWidth;
                    const elementHeight = item.html.offsetHeight;
                    // Position the item centered above the coordinate, taking into account the dimensions of the item
                    item.html.style.left = (pixelCoordinate.x - elementWidth / 2) + 'px';
                    item.html.style.top = (pixelCoordinate.y - elementHeight) + 'px';
                });
            }

            addItemsToLayer() {
                this.markerData.forEach(item => {
                    this.layer.appendChild(item.html);
                });
            }

            // The onRemove method will be called automatically from the API if
            // we set the overlay's map property to 'null'.
            onRemove() {
                this.clear();
            }

            addMarker(pos, html, label) {
                this.markerData.push({pos, html, label});
                this.draw();
            }

            clear() {
                this.markerData.forEach(item => {
                    if (item.html.parentNode) {
                        item.html.parentNode.removeChild(item.html);
                    }
                });
                this.markerData = [];
            }

            static generateLayerGroupName() {
                return `layer-group-${new Date().getTime()}`;
            }
        }

        return new MapMarkersOverlay(map, layerGroupName);
    }
}

/* global google */

import $ from 'jquery';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import MapMarkersOverlay from '../map-markers-overlay/map-markers-overlay';
import { hashToList } from '../store/utils/data-structures';
import { default as gaLog, EVENT_TYPE } from '../media-viewer-map/media-viewer-gtm-events';

const noop = () => {};

export default class PersonalPlaceMarkers {
    constructor(googleMaps, map, config, store, markerOnClickHandlerCallback) {
        this.googleMaps = googleMaps;
        this.map = map;
        this.config = config;
        this.markerOnClickHandlerCallback = markerOnClickHandlerCallback || noop;
        this.store = store || reduxStore;

        this.connect = new Connect(this.store, [
            'entities.personalPlaces',
            'ui.activeLocationId'
        ]);

        this.markerOverlay = new MapMarkersOverlay(googleMaps, map, 'media-viewer-map');

        this.bindEvents();

        const state = this.store.getState();
        const locations = hashToList(state.entities.locations);
        this.updateLocationMarkers(locations);
    }

    bindEvents() {
        this
            .connect
            .subscribe(() => {
                const state = this.store.getState();
                const locations = hashToList(state.entities.locations);
                this.updateLocationMarkers(locations);
            });

        google.maps.event.addListener(this.map, 'zoom_changed', () => {
            const state = this.store.getState();
            const locations = hashToList(state.entities.locations);
            this.updateLocationMarkers(locations);
        });

        google.maps.event.addListener(this.map, 'maptypeid_changed', () => {
            const state = this.store.getState();
            const locations = hashToList(state.entities.locations);
            this.updateLocationMarkers(locations);
        });
    }

    getMapType() {
        return this.map.getMapTypeId();
    }

    updateLocationMarkers(locations) {
        const state = this.store.getState();
        const activeLocationId = state.ui.activeLocationId;
        const mapTypeRoadmap = this.getMapType() === 'roadmap';

        this.clearMarkers();

        if (locations.length < 1) return;
        if (!mapTypeRoadmap) return;

        locations
            .filter(location => this.isPersonalPlace(location))
            .forEach(location => {
                const isActiveLocation = activeLocationId === location.id;
                const htmlMarker = this.createHtmlMarker(location, isActiveLocation);
                const latLng = new this.googleMaps.LatLng(location.lat, location.lng);
                this.markerOverlay.addMarker(latLng, htmlMarker, location.label);
            });
    }

    isPersonalPlace(location) {
        return location.personalPlace !== null;
    }

    createHtmlMarker(location, isActive) {
        const wrapperDiv = document.createElement('div');
        wrapperDiv.className = 'markers-overlay personal-place-marker';

        if (isActive) {
            wrapperDiv.className += ' active';
        }

        const markerDiv = document.createElement('div');
        markerDiv.title = location.label || location.location;
        markerDiv.className = 'marker';

        if (markerDiv.dataset) {
            markerDiv.dataset.active = isActive;
            markerDiv.dataset.id = location.id;
        }

        const markerLabelSpan = document.createElement('span');

        markerLabelSpan.className = 'marker__title';
        markerLabelSpan.innerText = location.label || location.location;

        markerDiv.appendChild(markerLabelSpan);
        wrapperDiv.appendChild(markerDiv);

        markerDiv.addEventListener('click', this.htmlMarkerOnClickHandler.bind(this));

        return wrapperDiv;
    }

    htmlMarkerOnClickHandler(event) {
        event.stopPropagation();

        const $marker = $(event.currentTarget).closest('.marker');
        const locationId = $marker.data('id');

        const state = this.store.getState();
        const location = state.entities.locations[locationId];

        if (location) {
            gaLog.travelTime(EVENT_TYPE.MARKER, location.location);
        }

        this.markerOnClickHandlerCallback(locationId);
    }

    clearMarkers() {
        this.markerOverlay.clear();
    }
}

import $ from 'jquery';

export default UserReportForm;

// component configuration
const COMPONENT_SELECTOR = '[data-user-report-form]';
const ERROR_MESSAGE_SELECTOR = '[data-user-report-form-error-message]';
const INPUT_SELECTOR = '[data-user-report-form-input]';
const INPUT_ERROR_CLASS = 'input-validation-error';
const TEXTAREA_SELECTOR = '[data-user-report-form-input-message]';
const FORM_SELECTOR = '[data-user-report-form-form]';
const CHECKBOX_OTHER_ATTR = 'data-user-report-form-other';
const HIDDEN_CLASS = 'is-hidden';
const INPUT_TYPE = '[type="checkbox"]';

/**
 * Constructor method, links child elements to variables for internal use
 * @param {HTMLElement} element     The HTML element to bind to.
 */
function UserReportForm(element) {
    const component = this;
    component.$element = $(element);
    component.$errorMessage = $(ERROR_MESSAGE_SELECTOR);
    component.$inputs = component.$element.find(INPUT_SELECTOR);
    component.$form = component.$element.find(FORM_SELECTOR);
    component.$textArea = component.$element.find(TEXTAREA_SELECTOR);
    component.$checkboxes = [].slice.call(document.querySelectorAll(INPUT_TYPE));
    component.checkboxOther = component.$element.attr(CHECKBOX_OTHER_ATTR);

    component.$inputs.on('change', () => component.onChangeHandler());
    component.$textArea.on('keypress', () => component.onChangeHandler());
    component.$form.on('submit', (event) => component.onSubmitHandler(event));
}

/**
 * Handle changes in the form.
 */
UserReportForm.prototype.onChangeHandler = function() {
    const component = this;

    component.$errorMessage.addClass(HIDDEN_CLASS);
    component.$textArea.removeClass(INPUT_ERROR_CLASS);

    component.setFormState();
};

/**
 * Check form validation on submit.
 * @param {event} event     Submit click event.
 */
UserReportForm.prototype.onSubmitHandler = function(event) {
    const component = this;

    if (!component.validateForm()) {
        event.preventDefault();
    }
};

/**
 * Set the form state depending on the option selected.
 */
UserReportForm.prototype.setFormState = function() {
    const component = this;
    const formState = component.getFormState();

    (formState.ReportOther) ? component.$textArea.removeClass(HIDDEN_CLASS) : component.$textArea.addClass(HIDDEN_CLASS);
};

/**
 * Get the serialized form state.
 */
UserReportForm.prototype.getFormState = function() {
    const component = this;
    const serializedForm = component.serializeCheckboxes(component.$checkboxes);

    return serializedForm;
};

/**
 * Validate the form on submit.
 *
 * We only check:
 *  - if at least 1 checkbox was checked.
 *  - if the last checkbox is check AND a message was written in the textarea.
 *
 *  Other, more complex, validation is done by BE.
 *
 * @returns {boolean}       Does the form validate?
 */
UserReportForm.prototype.validateForm = function() {
    const component = this;
    const formState = component.getFormState();
    const checkboxes = component.$checkboxes;
    const textAreaMessage = component.$textArea.val();
    const atLeastOneChecked = checkboxes.some(checkbox => checkbox.checked);

    // None are checked.
    if (!atLeastOneChecked) {
        component.$errorMessage.removeClass(HIDDEN_CLASS);
        return false;
    }

    // Last option is checked but there's no text.
    if (formState.ReportOther && textAreaMessage.length === 0) {
        component.$errorMessage.removeClass(HIDDEN_CLASS);
        component.$textArea.addClass(INPUT_ERROR_CLASS);
        return false;
    }

    component.$errorMessage.addClass(HIDDEN_CLASS);
    return true;
};

/**
 * Serializes the checked state and name of each checkbox element into an object.
 *
 * Object example:
 * {
 *     'TaalgebruikMelding': true
 * }
 *
 * @param  {object} checkboxes      All checkboxes within the form.
 * @return {Object}                 The serialized object
 */
UserReportForm.prototype.serializeCheckboxes = function(checkboxes) {
    return checkboxes.reduce(
        (mapping, checkbox) => {
            mapping[checkbox.name] = checkbox.checked;
            return mapping;
        }, {});
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new UserReportForm(element));

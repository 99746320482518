import $ from 'jquery';

export default HeightSync;

const COMPONENT_SELECTOR = '[data-height-sync]';
const ELEMENT_DATA = 'height-sync-id';
const ELEMENT_SELECTOR = `[data-${ELEMENT_DATA}]`;
const EVENTS_TO_LISTEN = 'resize';

function HeightSync(element) {
    const component = this;
    component.$element = $(element);
    component.$syncElmenents = component.$element.find(ELEMENT_SELECTOR);
    component.bindEvents();
    component.heightChangeHandler();
}

HeightSync.prototype.bindEvents = function () {
    const component = this;

    $(window).on(EVENTS_TO_LISTEN, () => component.heightChangeHandler());
};

HeightSync.prototype.heightChangeHandler = function () {
    const component = this;

    const heightDictionary = component.getHighestHeightPerIdDictionary();

    component.updateHeightsById(heightDictionary);
};

HeightSync.prototype.getHighestHeightPerIdDictionary = function () {
    const component = this;
    const heightDictionary = {};

    component.$syncElmenents.each((i, e) => {
        const $syncElement = $(e);
        const id = $syncElement.data(ELEMENT_DATA);
        $syncElement.height(''); //Restore initial height
        const elementHeight = $syncElement.height();

        if (typeof heightDictionary[id] === 'undefined' || heightDictionary[id] < elementHeight) {
            heightDictionary[id] = elementHeight;
        }
    });

    return heightDictionary;
};

HeightSync.prototype.updateHeightsById = function (heightDictionary) {
    const component = this;

    component.$syncElmenents.each((i, e) => {
        const $syncElement = $(e);
        const id = $syncElement.data(ELEMENT_DATA);
        $syncElement.height(heightDictionary[id]);
    });
};

$(COMPONENT_SELECTOR).each((index, element) => new HeightSync(element));

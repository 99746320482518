import $ from 'jquery';

import reduxStore from '../store/store';
import {getTravelTimes, setModality} from '../store/actions';
import Connect from '../store/connect';
import {default as gaLog, EVENT_TYPE} from '../media-viewer-map/media-viewer-gtm-events';
import { DRAWER_MINIMISED, DRAWER_SEMI } from '../store/constants/drawer-states';
import { POI_DETAIL_DRAWER } from '../store/constants/drawer-names';

const COMPONENT_SELECTOR = '[data-travel-time-modality-toggle]';
const TRAVEL_TIME_MODALITY_SELECTOR = '[data-travel-time-modality-toggle__type-control]';
const HIDDEN_CLASS = 'hidden';
const ENDPOINT_GET_ROUTE_ATTR='data-travel-time-modality-toggle-route-endpoint';
const SHOW_ROUTE_ERROR_EVENT = 'map-route-error-event';
const HIDE_ALL_ROUTE_ERROR_TYPE = 'map-route-hide-error';

const initialModality = 'car';
const LOCATION_URL_REGEX = /locatie\/(.*)/;

const modalitySelector = state => state.ui.modality;

export default class ObjectMapModalityToggle {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'ui.mediaViewerRoute',
            'ui.modality',
            `ui[${POI_DETAIL_DRAWER}]`
        ]);
        this.$element = $(element);
        this.$modalitySelectors = this.$element.find(TRAVEL_TIME_MODALITY_SELECTOR);

        this.personalPlacesRouteEndpoint = this.$element.attr(ENDPOINT_GET_ROUTE_ATTR);
        this.selectModality(initialModality);
        this.bindEvents();
    }

    bindEvents() {
        this.$modalitySelectors.on('click', event => {
            const clickedModality = event.currentTarget.value;
            const state = this.store.getState();
            const cacheKey = Object.keys(state.entities.personalPlaces).join(',');

            gaLog.travelTime(EVENT_TYPE.MODALITY, clickedModality);
            $(document).trigger(SHOW_ROUTE_ERROR_EVENT, {type: HIDE_ALL_ROUTE_ERROR_TYPE});

            this.store.dispatch(setModality(clickedModality));
            this.store.dispatch(getTravelTimes(state.ui.globalObjectId, clickedModality, cacheKey, this.personalPlacesRouteEndpoint));
        });

        this
            .connect
            .subscribe((state, prevState) => {
                const newModality = modalitySelector(state);

                if (newModality !== modalitySelector(prevState)) {
                    this.selectModality(newModality);
                }
                if (state.ui.mediaViewerRoute === '') {
                    this.toggleControlVisibility(true);
                    return;
                }
                if ((LOCATION_URL_REGEX.test(state.ui.mediaViewerRoute)) && (state.ui[POI_DETAIL_DRAWER] === DRAWER_MINIMISED || state.ui[POI_DETAIL_DRAWER] === DRAWER_SEMI)) {
                    this.toggleControlVisibility(true);
                } else {
                    this.toggleControlVisibility(false);
                }
            });
    }

    selectModality(label) {
        this.$modalitySelectors.removeClass('checked');
        this.$modalitySelectors.filter(`[value="${label}"]`).addClass('checked');
    }

    toggleControlVisibility(hidden) {
        this.$element.addClass(HIDDEN_CLASS);
        if (hidden) {
            this.$element.removeClass(HIDDEN_CLASS);
        }
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new ObjectMapModalityToggle(element));

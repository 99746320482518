import $ from 'jquery';
import InstantSearch from '../instant-search/instant-search';
import Expandible from '../expandible/expandible';

import AsyncObjectRating from '../async-object-rating/async-object-rating';
import Rating from '../rating/rating';
export default UserSavedObjectsSorting;

var COMPONENT_SELECTOR = '[data-user-saved-objects-sorting]';
var PAGINATION_SELECTOR = '[data-pagination]';
var PAGINATION_VALUE_SELECTOR = 'input[data-pagination-input]';

/**
 * @param {HTMLFormElement} form
 * @constructor
 */
function UserSavedObjectsSorting(form) {
    InstantSearch.call(this, form);
    this.getOutputs($(form).parent());
}

UserSavedObjectsSorting.prototype = Object.create(InstantSearch.prototype);

UserSavedObjectsSorting.prototype.sendResultsUpdatedEvent = function() {
    //saved-items page shouldn't send result update event
};

UserSavedObjectsSorting.prototype.updateResults = function(data) {
    var component = this;
    component.renderOutputResults(data);
    component.soldFilterOption.attr('href', data.historischAanbodUrl);
    component.updateHistory(data.url);

    //reinitialize the AsyncObjectRating and Expandible modules
    AsyncObjectRating.initialize();
    Expandible.initialize();
    Rating.initialize();
};

UserSavedObjectsSorting.prototype.bindToEvents = function() {
    //call base first
    InstantSearch.prototype.bindToEvents.call(this);

    var component = this;

    //and do some own wiring
    component.$form.parent().on('pageadjusted', PAGINATION_SELECTOR, function() {

        //get the page from the pagination module
        var page = $(this).find(PAGINATION_VALUE_SELECTOR).val();

        //and set it's value in the form that handles the instant search module
        component.$form.find(PAGINATION_VALUE_SELECTOR).val(page);

        if (!component.isRestoring) {
            component.doRequest();
        }
    });
};

$(COMPONENT_SELECTOR).each(function(index, element) {
    return new UserSavedObjectsSorting(element);
});

// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';
import MapTileServer from './map-tile-server';
import ajax from '../ajax/ajax';
import MapEventProxy from '../map/map-event-proxy';
import MapTileCache from '../map/map-tile-cache';

// what does this module expose?
export default MapMarkerOverlay;

const MAP_SELECTOR = '[data-map]';
const TILE_ID = 'tile-';
const TIMEOUT_SEARCH_REQUEST = 30000; // 30 sec
const MAP_CLICK_HANDLER_TIMEOUT = 200; // msec

const SHOW_OBJECT_INFO_EVENT = 'show_object_info';
const HIDE_OBJECT_INFO_EVENT = 'hide_object_info';
const ZOOM_CHANGED_EVENT = 'zoom_changed';
const CENTER_CHANGED_EVENT = 'center_changed';
const NO_OBJECT_EVENT = 'no_object';
const SEARCH_QUERY_UPDATED_EVENT = 'zo_updated';
const FORCE_REFRESH_MAPTILES_EVENT = 'force_maptile_refresh';
const DEFAULT_SEARCH_QUERY = 'koop/heel-nederland';
const MAP_VIEW_SELECTOR_CLOSE_EVENT = 'close_view_type_box';

const MIN_ZOOM = 8;
const MAX_ZOOM = 19;

/**
 * @param {Object} mapData; required {map: *, config: *, tileSize: *}
 * @param {Component} marker component; to create a marker on the map
 * @constructor
 */
function MapMarkerOverlay(mapData, markerComponent) {
    const component = this;
    component.map = mapData.map;
    component.$map = $(MAP_SELECTOR);
    component.tileSize = mapData.tileSize;
    component.cluster = mapData.cluster;
    component.marker = markerComponent;
    component.searchQuery = window.location.pathname.indexOf('/kaart') != -1 ? window.location.pathname.replace('/kaart', '') : DEFAULT_SEARCH_QUERY;
    // event proxy
    component.mapEventProxy = MapEventProxy;
    // caching
    component.mapTileCache = MapTileCache;
    //config settings for creating the tile data url
    const config = mapData.config;
    component.tileServer = new MapTileServer(config.tileServers, config.tilePath, {min: MIN_ZOOM, max: MAX_ZOOM});
    component.singleClicked = null;
    component.clickedMarker = null;

    if (component.map !== null && component.marker !== null) {
        component.bindEvents();
    }
}
// Default GoogleMap API overlay settings
MapMarkerOverlay.prototype.minZoom = MIN_ZOOM;
MapMarkerOverlay.prototype.maxZoom = MAX_ZOOM;
MapMarkerOverlay.prototype.name = 'Tile #s';
MapMarkerOverlay.prototype.alt = 'Tile Coordinate Map Type';

/**
 * Get the tile from current x, y position; Default Google API call
 * @param {Number} zoom
 * @param {Object} coord {x: *, y, *}
 * @param {Document} ownerDocument
 * @returns {Html} The tile on the map
 */
MapMarkerOverlay.prototype.getTile = function(coord, zoom, ownerDocument) {
    const component = this;
    const tileId = TILE_ID + zoom + '-' + coord.x + '-' + coord.y;

    let tileContainer = ownerDocument.createElement('div');
    tileContainer.setAttribute('id', tileId);
    tileContainer.setAttribute('class', 'map-tile');

    if (component.tileServer.isValidTile(zoom, coord)) {
        component.loadMarker(zoom, coord, tileId, tileContainer);
    }
    return tileContainer;
};

/**
 * Refresh the tiles by updating the searchQuery; clean the cache and change the zoom level.
 * For the user it doesn't like that the zoom level is changed
 * @param {String} searchQuery
 */
MapMarkerOverlay.prototype.refreshTiles = function(searchQuery) {
    const component = this;
    component.searchQuery = searchQuery;
    // Remove stored data to get new map tiles
    component.mapTileCache.clear();

    // temporarily disable zoom limiting
    component.$map.trigger(FORCE_REFRESH_MAPTILES_EVENT, {isFinished: false});
    // zoom in and out to forec refresh of tiles
    const currentZoom = component.map.getZoom();
    component.map.setZoom(currentZoom + 1);
    // Change the zoom again and load fresh tiles
    component.map.setZoom(currentZoom);
    // re-enable zoom limiting
    component.$map.trigger(FORCE_REFRESH_MAPTILES_EVENT, {isFinished: true});
};

/**
 *eventbinding
 */
MapMarkerOverlay.prototype.bindEvents = function() {
    const component = this;
    component.mapEventProxy.addListener('click', (event) => component.mapClickHandler(event));
    component.mapEventProxy.addListener('dblclick', () => component.clearMapClick());
    component.mapEventProxy.addListener(CENTER_CHANGED_EVENT, () => component.hideObjectInfo(CENTER_CHANGED_EVENT));
    component.mapEventProxy.addListener(ZOOM_CHANGED_EVENT, () => {
        // Empty stored markers
        component.mapTileCache.clear();
        // hide marker info
        component.hideObjectInfo(ZOOM_CHANGED_EVENT);
    });
    // filters in the sidebar are changed
    component.$map.on(SEARCH_QUERY_UPDATED_EVENT, (event, eventArgs) => component.refreshTiles(eventArgs.zo));
};

/**
 * Load the markers. Determine if current marker is stored, otherwise load from server
 * @param {Number} zoom
 * @param {Object} coord {x: *, y, *}
 * @param {String} tileId
 * @param {Html} tileHtml
 */
MapMarkerOverlay.prototype.loadMarker = function (zoom, coord, tileId, tileHtml) {
    const component = this;
    const storedMarker = component.mapTileCache.getStoredTiles(tileId);

    if (Object.keys(storedMarker).length > 0) {
        // set stored marker on map
        return component.setMarker(storedMarker, tileHtml);
    } else {
        // get the marker from the server
        component.doRequest(zoom, coord, tileId, tileHtml);
    }
};

/**
 * Load the markers from server
 * @param {Number} zoom
 * @param {Object} coord {x: *, y, *}
 * @param {String} tileId
 * @param {Html} tileHtml
 */
MapMarkerOverlay.prototype.doRequest = function (zoom, coord, tileId, tileHtml) {
    const component = this;
    const url = component.tileServer.createUrl(zoom, coord, component.searchQuery, component.cluster);
    let zoomLevel = component.map.getZoom();

    component.request = ajax({
        url: url,
        method: 'GET',
        dataType: 'jsonp',
        cache: true,
        timeout: TIMEOUT_SEARCH_REQUEST
    });
    component.request.done((resultData) => {
        if (resultData !== undefined && resultData !== null) {
            // store locally
            component.mapTileCache.storeMarker(tileId, resultData, zoomLevel);
            // set marker on map
            return component.setMarker(resultData, tileHtml);
        }
    });
};

/**
 * Set the marker in the tile
 * @param {Object} tileData
 * @param {Html} mapTile
 */
MapMarkerOverlay.prototype.setMarker = function(tileData, tileHtml) {
    const component = this;
    const objects = tileData.points;

    if (objects !== undefined && objects.length > 0) {
        for (let i = 0, n = objects.length; i < n; i++) {
            // This is the object we've found.
            let object = objects[i];
            // Create and position an HTML element.
            if (tileHtml !== undefined && object !== undefined) {
                tileHtml.appendChild(component.marker.getElement(object));
            }
        }
    }
    return tileHtml;
};

/**
 * used for getting a 'clean' click event on map
 */
MapMarkerOverlay.prototype.clearMapClick = function() {
    const component = this;
    component.singleClicked = false;
};

/**
 * handler for map click event
 */
MapMarkerOverlay.prototype.mapClickHandler = function(event) {
    const component = this;
    component.singleClicked = true;

    setTimeout(() => {
        if (component.singleClicked) {
            component.clickedOnMap(event);
        }
    }, MAP_CLICK_HANDLER_TIMEOUT);
};

/**
 * user clicked on the map, check for a close marker
 * @param {Object} event {latlng: *}
 */
MapMarkerOverlay.prototype.clickedOnMap = function(event) {
    const component = this;
    let latLng = component.fixEventOffset(event.latLng);
    let zoomLevel = component.map.getZoom();

    let nearestObjectId = MapTileCache.getNearestMarker(latLng, zoomLevel);
    if (!nearestObjectId) {
        component.hideObjectInfo(NO_OBJECT_EVENT);
        return;
    }
    let nearestObject = MapTileCache.getMarkerById(nearestObjectId);
    if (component.isPixelDistanceClose(latLng, nearestObject)) {
        component.showObjectInfo(nearestObjectId);
    } else {
        component.hideObjectInfo(NO_OBJECT_EVENT);
    }

    component.$map.trigger(MAP_VIEW_SELECTOR_CLOSE_EVENT);
};

/**
 * remember selected id indefinitely / trigger event
 */
MapMarkerOverlay.prototype.showObjectInfo = function(objectId) {
    const component = this;
    // add object(s) to part of cache that is not cleared on zoom_changed
    component.mapTileCache.rememberSelectObjects(objectId);
    component.$map.trigger(SHOW_OBJECT_INFO_EVENT, {objectId: objectId, initial: false});
};

/**
 * trigger events
 */
MapMarkerOverlay.prototype.hideObjectInfo = function(eventType) {
    const component = this;
    component.$map.trigger(HIDE_OBJECT_INFO_EVENT, {type: eventType});
};

MapMarkerOverlay.prototype.fixEventOffset = function(latLng) {
    const component = this;
    const scale = Math.pow(2, component.map.getZoom());
    let point = this.map.getProjection().fromLatLngToPoint(latLng);
    point.y += (component.marker.pixelDistance / scale);
    return this.map.getProjection().fromPointToLatLng(point);
};

/**
 * is the object on pixel distance close
 * @param {Object} latLng {lat: *, lng: *}
 * @param {Object} objectData {x: *, y: *, id: *}
 * @returns {boolean}
 */
MapMarkerOverlay.prototype.isPixelDistanceClose = function(latLng, objectData) {
    const component = this;
    const google = window.google;
    const scale = Math.pow(2, component.map.getZoom());
    const mapProjection = component.map.getProjection();
    const objPoint = mapProjection.fromLatLngToPoint(new google.maps.LatLng(objectData.lat, objectData.lng));
    const eventPoint = mapProjection.fromLatLngToPoint(latLng);
    const objPixel = new google.maps.Point(objPoint.x * scale, objPoint.y * scale);
    const eventPixel = new google.maps.Point(eventPoint.x * scale, eventPoint.y * scale);
    const xPixelDistance = Math.abs(objPixel.x - eventPixel.x);
    const yPixelDistance = Math.abs(objPixel.y - eventPixel.y);

    // http://www.smashingmagazine.com/2012/02/21/finger-friendly-design-ideal-mobile-touchscreen-target-sizes/
    return (xPixelDistance <= component.marker.pixelDistance && yPixelDistance <= component.marker.pixelDistance);
};
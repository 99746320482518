import $ from 'jquery';
import 'funda-slick-carousel';

import reduxStore from '../store/store';
import Connect from '../store/connect';

const EVENT_NAMESPACE = '.media-viewer-fotos-view';
const ITEMS_SELECTOR = '[data-media-viewer-items]';
const PREVIOUS_SELECTOR = '[data-media-viewer-previous]';
const NEXT_SELECTOR = '[data-media-viewer-next]';
const CURRENT_SELECTOR = '[data-media-viewer-current]';
const TOTAL_SELECTOR = '[data-media-viewer-total]';
const SLICK_SLIDE_SPEED = 250;
const SLICK_SWIPE_SENSITIVITY = 12; // 1/12th of the image width
const FOTOSTOPPER = 'data-media-viewer-fotostopper';
const REWIND_SELECTOR = '[data-media-viewer-fotostopper-rewind]';
const EVENT_MEDIA_VIEWER_FOTOS_OPENED = 'media-viewer-fotos-opened-event';
const $window = $(window);

const KEY_CODE = {
    LEFT: 37,
    RIGHT: 39
};


export default class FotosView {
    constructor($elements, mediaIndex, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, ['ui.mediaViewerRoute']);

        this.$items = $elements.find(ITEMS_SELECTOR);
        this.$prevButton = $elements.find(PREVIOUS_SELECTOR);
        this.$nextButton = $elements.find(NEXT_SELECTOR);
        this.$currentItemCount = $elements.find(CURRENT_SELECTOR);
        this.$totalItemCount = $elements.find(TOTAL_SELECTOR);
        this.$rewindButton = $elements.find(REWIND_SELECTOR);

        this.bindEvents();
        $window.trigger(EVENT_MEDIA_VIEWER_FOTOS_OPENED);

        this.initializeSlideshow(mediaIndex);
        this.bindGlobalEvents();
        this.setItem(mediaIndex);
    }

    dispose() {
        this.unbindEvents();
        this.unbindGlobalEvents();
        this.disableSlideshow();
    }

    bindEvents() {
        this.$nextButton.on('click', this.nextItem.bind(this));
        this.$prevButton.on('click', this.prevItem.bind(this));
        this.$rewindButton.on('click', this.setItem.bind(this, 0));
        this.$items.on('init reInit', this.slickInitHandler.bind(this));
        this.$items.on('beforeChange', this.slickBeforeChangeHandler.bind(this));
    }

    unbindEvents() {
        this.$nextButton.off('click');
        this.$prevButton.off('click');
        this.$items.off('init reInit');
        this.$items.off('beforeChange');
    }

    bindGlobalEvents() {
        if (this.globalEventsBound) {
            return;
        }

        const component = this;
        $(document).on('keydown' + EVENT_NAMESPACE, function keyHandler(event) {
            if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) {
                return;
            }
            switch (event.keyCode) {
                case KEY_CODE.LEFT:
                    component.prevItem();
                    break;
                case KEY_CODE.RIGHT:
                    component.nextItem();
                    break;
            }
        });

        this.globalEventsBound = true;
    }

    unbindGlobalEvents() {
        if (this.globalEventsBound) {
            $(document).off('keydown' + EVENT_NAMESPACE);
            this.globalEventsBound = false;
        }
    }

    initializeSlideshow(index) {
        if (this.slideshowIsInitialized) {
            return;
        }

        this.$items.slick({
            arrows: false,
            speed: SLICK_SLIDE_SPEED,
            cssEase: 'ease',
            accessibility: true,
            infinite: false,
            mobileFirst: true,
            touchThreshold: SLICK_SWIPE_SENSITIVITY,
            initialSlide: index - 1 || 0,
            useTransform: true,
            lazyLoad: 'anticipated',
            lazyLoadAhead: 3,
            lazyLoadBack: 1
        });

        this.slideshowIsInitialized = true;
    }

    disableSlideshow() {
        this.$items.slick('unslick');
        this.slideshowIsInitialized = false;
    }

    setItem(index) {
        this.$items.slick('slickGoTo', index - 1, true);
    }

    prevItem() {
        this.$items.slick('slickPrev', true);
    }

    nextItem() {
        this.$items.slick('slickNext', true);
    }

    slickInitHandler(event, slick) {
        const hasFotoStopper = slick.$slides[slick.$slides.length - 1].hasAttribute(FOTOSTOPPER);
        this.$totalItemCount.text(hasFotoStopper ? slick.slideCount - 1 : slick.slideCount);
    }

    slickBeforeChangeHandler(event, slick, currentSlide, nextSlide) {
        const isGoingToBeFotoStopper = slick.$slides[nextSlide].hasAttribute(FOTOSTOPPER);
        let nextSlideNumber = parseInt(nextSlide, 10) + 1;

        if (isGoingToBeFotoStopper) {
            nextSlideNumber--;
        }

        this.$currentItemCount.text(nextSlideNumber);
        window.history.replaceState({}, '', '#foto-' + nextSlideNumber);
        this.$nextButton.attr('disabled', slick.slideCount === (nextSlide + 1));
        this.$prevButton.attr('disabled', nextSlide === 0);
    }
}

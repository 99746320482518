import $ from 'jquery';

export default AsyncUrlAddParam;

const Component = AsyncUrlAddParam;
const COMPONENT_DATA = 'async-url-add-param';
const COMPONENT_SELECTOR = `[data-${COMPONENT_DATA}]`;

function AsyncUrlAddParam(element) {
    const component = this;
    component.$element = $(element);
    component.parameter = component.$element.data(COMPONENT_DATA);

    component.bindEvents();
}

AsyncUrlAddParam.prototype.bindEvents = function () {
    const component = this;

    component.$element.on('click', (e) => Component.addParam(component.parameter, e));
};

AsyncUrlAddParam.addParam = function (param, event) {
    if (typeof event === 'object' && typeof event.preventDefault === 'function') {
        event.preventDefault();
    }
    const url = Component.getUrl();
    const newUrl = url + (url.indexOf('?') < 0 ? '?' : '&') + param;
    Component.setUrl(newUrl);
};

AsyncUrlAddParam.getUrl = function () {
    return window.location.href;
};

AsyncUrlAddParam.setUrl = function (url) {
    window.location.href = url;
};

AsyncUrlAddParam.initialize = function () {
    $(COMPONENT_SELECTOR).each((index, element) => new AsyncUrlAddParam(element));
};

AsyncUrlAddParam.initialize();

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

import '../../components/log-request/log-request';

//todo: use expandable component for clicked class if safari allows it

// what does this module expose?
export default ObjectContact;

// Component configuration
var COMPONENT_ATTR = 'data-object-contact';
var COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';
var TELEPHONE_HANDLE_ATTR = 'data-object-contact-phone-handle';
var TELEPHONE_HANDLE_SELECTOR = '[' + TELEPHONE_HANDLE_ATTR + ']';
var CLICKED_CLASS = 'is-clicked';
var ENHANCED_CLASS = 'is-enhanced';

function ObjectContact(element) {
    var component = this;
    component.$element = $(element);
    component.$element.addClass(ENHANCED_CLASS);

    component.$element.find(TELEPHONE_HANDLE_SELECTOR).each(function(index, phoneElement) {
        var $handle = $(phoneElement);
        var $button = $handle.find('button');
        var $link = $handle.find('a');

        $button.on('click', function() {
            $handle.addClass(CLICKED_CLASS);

            // ADA-53: Create delay + separate targeting as workaround for Safari drawing bug
            $link.addClass(CLICKED_CLASS);
            setTimeout(function() {
                $button.addClass(CLICKED_CLASS);
            }, 0);
        });
    });
}

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new ObjectContact(element);
});
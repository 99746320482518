import $ from 'jquery';

const COMPONENT_SELECTOR = '[data-hero-fib]';
const HERO_SRC_DESKTOP_SELECTOR = '[data-hero-fib-image-desktop]';
const HERO_SRC_MOBILE_SELECTOR = '[data-hero-fib-image-mobile]';
const HERO_SRCSET_DESKTOP_SELECTOR = '[data-hero-fib-srcset-desktop]';
const HERO_SRCSET_MOBILE_SELECTOR = '[data-hero-fib-srcset-mobile]';
const HERO_SRC_FALLBACK_SELECTOR = '[data-hero-fib-image-preloaded]';

export default class HeroFib {
    constructor() {
        this.fetchImgSources();
        this.setSrcsetSources();
    }

    fetchImgSources() {
        return {
            desktop: $(COMPONENT_SELECTOR).find(HERO_SRC_DESKTOP_SELECTOR),
            mobile: $(COMPONENT_SELECTOR).find(HERO_SRC_MOBILE_SELECTOR),
            fallback: $(COMPONENT_SELECTOR).find(HERO_SRC_FALLBACK_SELECTOR),
            desktopHidden: $(COMPONENT_SELECTOR).find(HERO_SRCSET_DESKTOP_SELECTOR),
            mobileHidden: $(COMPONENT_SELECTOR).find(HERO_SRCSET_MOBILE_SELECTOR)
        };
    }
    setSrcsetSources() {
        const imgSources = this.fetchImgSources();
        imgSources.desktop[0].srcset = imgSources.desktopHidden[0].value;
        imgSources.mobile[0].srcset = imgSources.mobileHidden[0].value;
        imgSources.fallback[0].src = imgSources.desktopHidden[0].value.split(' ')[0];
    }
}

$(COMPONENT_SELECTOR).each((element) => new HeroFib(element));

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import SearchElementInViewport from '../search-element-in-viewport/search-element-in-viewport';
import ajax from '../ajax/ajax';
import AppSpinner from '../app-spinner/app-spinner';

// what does this module expose?
export default SearchResultsLoading;

// component configuration
const COMPONENT_SELECTOR = '[data-search-results-loading]';
const COMPONENT_SPINNER_SELECTOR = '[data-search-results-spinner]';
const COMPONENT_POSITION_TOP = '48px';
const IS_ACTIVE_CLASS = 'is-active';

function SearchResultsLoading(element) {
    const component = this;
    component.element = element;
    component.$element = $(element);

    // if appSpinner isn't called the loader won't be shown
    component.spinner = new AppSpinner(COMPONENT_SPINNER_SELECTOR);
    component.resultsLoader = new SearchElementInViewport(component.element, COMPONENT_POSITION_TOP);

    $(document).on('resultsUpdating', function() {
        component.show();
    });

    ajax.onError(() => component.hide());
    ajax.onSuccess(() => component.hide());
}

/**
 * Show loading indicator
 */
SearchResultsLoading.prototype.show = function () {
    var component = this;

    component.$element.addClass(IS_ACTIVE_CLASS);
    component.resultsLoader.updateVerticalPosition();

    if (!component.spinner.isVisible()) {
        component.spinner.show();
    }
};

/**
 * Hide loading indicator
 */
SearchResultsLoading.prototype.hide = function () {
    var component = this;

    component.spinner.hide();
    component.$element.removeClass(IS_ACTIVE_CLASS);
};


// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function (index, element) {
    return new SearchResultsLoading(element);
});
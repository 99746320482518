export default class LocalStorageService {
    constructor(localStorage, gtmDataLayer) {
        this.localStorage = localStorage || window.localStorage;
        this.gtmDataLayer = gtmDataLayer || window.gtmDataLayer;
    }

    isSupported() {
        try {
            let test = '__storage_test__';
            this.localStorage.setItem(test, test);
            this.localStorage.removeItem(test);
            return true;
        } catch (error) {
            this.logErrorToGoogleAnalytics(error.message);
            return false;
        }
    }

    logErrorToGoogleAnalytics(errorType) {
        if (this.gtmDataLayer !== undefined) {
            this.gtmDataLayer.push({
                'event': 'localStorageError',
                'errorType': 'localstorage-' + errorType
            });
        }
    }

    saveAsStringified(key, jsObject) {
        this.localStorage.setItem(key, JSON.stringify(jsObject));
    }

    getAsParsedJson(key) {
        try {
            let data = JSON.parse(this.localStorage.getItem(key));
            return data ? data : undefined;
        } catch (err) {
            return undefined;
        }
    }
}

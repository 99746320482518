export default Observable;

/**
 * Observer pattern with optional topics.
 * See: https://en.wikipedia.org/wiki/Observer_pattern
 */
function Observable () {
    const publics = {};

    const globalObservers = [];
    const topicObservers = {};

    /**
     * Register an observer. It can
     *
     * @param p1 topic (optional)
     * @param p2 observer callback
     */
    publics.listen = listen;
    function listen(p1, p2) {
        var observer;
        var topic;
        //Only 1 parameter = Global listener
        if (typeof p2 === 'undefined') {
            observer = p1;
            globalObservers.push(observer);
        } else {
            topic = p1;
            observer = p2;
            if (typeof topicObservers[topic] === 'undefined') {
                topicObservers[topic] = [];
            }
            topicObservers[topic].push(observer);
        }
    }

    /**
     * Sends a message to the observers
     *
     * @param topic (optional)
     * @param message
     */
    publics.notify = notify;
    function notify(topic, message) {
        for (let i = 0; i < globalObservers.length; i++) {
            globalObservers[i](topic, message);
        }
        if (typeof topic !== 'undefined' && typeof topicObservers[topic] !== 'undefined') {
            for (let i = 0; i < topicObservers[topic].length; i++) {
                topicObservers[topic][i](message);
            }
        }
    }

    return publics;
}

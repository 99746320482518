// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

import ajax from '../ajax/ajax';

// what does this module expose?
export default ErrorBar;

// component configuration
const COMPONENT_SELECTOR = '[data-error-bar]';
const NOTIFICATION_SELECTOR = '[data-notification]';

const IS_ACTIVE_CLASS = 'is-active';
const FLASH_ANIMATION_CLASS = 'error-bar-flash';

function ErrorBar(element) {
    const component = this;
    component.element = element;
    component.$element = $(element);
    component.$notifications = this.$element.find(NOTIFICATION_SELECTOR);

    ajax.onError(() => component.show());
    ajax.onAbort(() => component.hide());
    ajax.onSuccess(() => component.hide());
    component.$notifications.on('close', () => component.hide());
}

ErrorBar.prototype.hide = function() {
    const component = this;
    component.$element.removeClass(IS_ACTIVE_CLASS);
};

ErrorBar.prototype.show = function() {
    const component = this;
    if (component.$element.hasClass(IS_ACTIVE_CLASS)) {
        component.$element.removeClass(FLASH_ANIMATION_CLASS);
        // trigger element reflow
        // this enables us to restart the animation
        // source: https://css-tricks.com/restart-css-animation/
        component.element.offsetWidth;
        component.$element.addClass(FLASH_ANIMATION_CLASS);
    } else {
        component.$notifications.trigger('show');
        component.$element.addClass(IS_ACTIVE_CLASS);
    }
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).get().map(element => new ErrorBar(element));
import $ from 'jquery';
import Expandible from '../expandible/expandible';
import AppliedFilters from '../applied-filters/applied-filters';

import '../filter-flyout/filter-flyout';
import Separator from '../thousand-separator/thousand-separator';

export default RadioGroup;

var COMPONENT_GROUP_NAME_ATTR = 'data-radio-group';
var COMPONENT_SELECTOR = '[' + COMPONENT_GROUP_NAME_ATTR + ']';
var GROUP_NAME_LABEL_ATTR = 'data-radio-group-label';
var ITEMS_SELECTOR = '[data-radio-group-items]';
var ITEM_SELECTOR = '[data-radio-group-item]';
var INPUT_SELECTOR = '[data-radio-group-input]';
var DEFAULT_SELECTOR = '[data-radio-group-default]';
var IS_EXPANDIBLE = '[data-is-expandible]';
var HAS_CHECKED_CLASS = 'has-checked';
var IS_CHECKED_CLASS = 'is-checked';
var DESELECT_EVENT = 'deselect-filter-item';
var NESTED_SELECTOR = '[data-nested-filter]';
var FILTER_ADD_GROUP_NAME_SELECTOR = '[data-radio-group-add-groupname-to-filter]';
var NUMBER_VISIBLE_FILTERS = 2; // amount of visible filters, in this case: Woonhuis + Appartment
var RADIO_GROUP_NAME_ATTR= 'data-radio-group-option-name';
// for not applying the filter in the applied filter list
var NOT_IN_APPLIED_FILTERS_SELECTOR = '[data-not-in-applied-filters]';

var RANGE_SELECTOR = '[data-filter-flyout-range]';
var RANGE_ITEM_SELECTOR = '[data-radio-group-range]';
var RANGE_TEMPLATE = '[data-range-template]';
var RANGE_UNIT_TYPE = 'data-range-unit-type';
var RANGE_SELECT_EVENT = 'rangeselected';
var RANGE_DESELECT_EVENT = 'rangedeselected';
var RANGE_INITIALIZED_EVENT = 'rangeinitialized';
var RANGE_RESET = 'radiogroupreset';
var FORM_SELECTOR = '[data-instant-search]';

var THOUSAND_SEPARATOR = 'data-thousand-separator';

function RadioGroup(element) {
    var component = this;

    component.groupName = null;
    component.bindToElements(element);

    component.groupSettings = {
        filterGroupId: component.$element.attr(COMPONENT_GROUP_NAME_ATTR),
        filterGroupName: component.$element.attr(GROUP_NAME_LABEL_ATTR)
    };
    component.initialCheckState();

    component.bindEvents();

    $(window).on('sidebar-refresh', function() {
        if (component.groupName == null) return;
        var $myNewElement = $(COMPONENT_SELECTOR + '[' + COMPONENT_GROUP_NAME_ATTR + '=' + component.groupName + ']');
        component.bindToElements($myNewElement);
        component.initialCheckState();
        component.bindEvents();
    });
}

RadioGroup.prototype.bindToElements = function(element) {
    var component = this;

    component.$element = $(element);
    component.$item = component.$element.find(ITEM_SELECTOR);
    component.$items = component.$element.find(ITEMS_SELECTOR);
    component.$inputs = component.$element.find(INPUT_SELECTOR);
    component.isNestedFilter = component.$element.is(NESTED_SELECTOR);
    component.$rangeFlyout = component.$element.find(RANGE_SELECTOR);
    component.rangeTemplate = component.$element.find(RANGE_TEMPLATE);
    component.isExpandible = component.$element.is(IS_EXPANDIBLE);
    component.$rangeItem = component.$element.find(RANGE_ITEM_SELECTOR);
    component.separator = component.rangeTemplate.attr(THOUSAND_SEPARATOR);
    component.unitType = component.rangeTemplate.attr(RANGE_UNIT_TYPE);
    component.notInAppliedFilters = component.$element.is(NOT_IN_APPLIED_FILTERS_SELECTOR);
    // remember group name (first time)
    if (component.groupName == null) {
        component.groupName = component.$element.attr(COMPONENT_GROUP_NAME_ATTR);
    }
};

RadioGroup.prototype.bindEvents = function() {
    var component = this;

    component.$element.on('change', INPUT_SELECTOR, function() {
        var $input = $(this);
        component.toggleCheckedState($input);
    });
    component.$element.on('reset', function() {
        component.$items.find(RANGE_ITEM_SELECTOR).remove();
        component.$element.removeClass(HAS_CHECKED_CLASS);
        component.$item.removeClass(IS_CHECKED_CLASS);
    });
    component.$element.on('resetfilter', function() {
        component.resetCheckState();
    });
    component.$rangeFlyout.on(RANGE_SELECT_EVENT, function(event, data) {
        component.addActiveRangeLabel(data);
    });
    component.$rangeFlyout.on(RANGE_INITIALIZED_EVENT, function(event, data) {
        component.addActiveRangeLabel(data);
    });
};

RadioGroup.prototype.addActiveRangeLabel = function(data) {
    var component = this;
    var html = component.rangeTemplate.html();
    var appliedFilter = component.groupSettings;

    component.$items.find(RANGE_ITEM_SELECTOR).remove();

    if (!data.min && data.max) {
        data.min = '0';
    }

    var filterName = '';
    if (data.min && !data.max) {
        data.min = data.min + '+';
        html = html.replace('- ', '');
        filterName = Separator.format(data.min, component.separator);
    } else {
        filterName = Separator.format(data.min, component.separator) + ' - ' + Separator.format(data.max, component.separator);
    }

    html = html
        .replace('{min}', Separator.format(data.min, component.separator))
        .replace('{max}', Separator.format(data.max, component.separator));

    appliedFilter.labelText = filterName + ' ' + component.unitType;
    appliedFilter.filterName = appliedFilter.labelText.replace('(' + appliedFilter.filterGroupName + ')', '');
    component.applyOption(appliedFilter);

    component.$items.append(html);
    component.$element.addClass(HAS_CHECKED_CLASS);
    component.onRemoveActiveRange();
};

RadioGroup.prototype.onRemoveActiveRange = function() {
    var component = this;
    component.$rangeItem = component.$element.find(RANGE_ITEM_SELECTOR);
    component.$rangeItem.on('click', function() {
        component.removeCheckedState();
        component.$rangeItem.remove();
        component.$rangeFlyout.trigger(RANGE_DESELECT_EVENT);
        // Due to the lack of communication between components, we have to interfere the dom item directly
        $(FORM_SELECTOR).trigger('range_filter_removed');
    });
};

RadioGroup.prototype.initialCheckState = function() {
    var component = this;
    var $checkedInput = component.$inputs.filter(':checked');
    var checkedInputIndex = component.$inputs.index($checkedInput);
    component.onRemoveActiveRange();

    if (component.isExpandible) {
        component.$element.attr('data-expandible', '');
        component.expandible = new Expandible(component.$element[0]);
    }

    if (component.isExpandible && checkedInputIndex > NUMBER_VISIBLE_FILTERS) {
        component.expandible.toggleExpand(true);
    }

    if ($checkedInput.length > 0) {
        component.toggleCheckedState($checkedInput.first());
    }
};

RadioGroup.prototype.toggleCheckedState = function($input) {
    var component = this;
    var $item = $input.closest(ITEM_SELECTOR);
    var isDefault = $input.is(DEFAULT_SELECTOR);

    if (isDefault) {
        component.removeCheckedState();
    } else {
        component.addCheckedState($item, $input);
    }
};

RadioGroup.prototype.resetCheckState = function(radioGroupComponent) {
    var component = radioGroupComponent || this;
    var $checkedInput = component.$inputs.filter(':checked');
    // SRCH-3139: we need to trigger the default option so other filters are properly shown/hidden
    var isDefault = $checkedInput.is(DEFAULT_SELECTOR);
    var groupFilterIsSet = $checkedInput.val() === '' ? false : true;

    if (groupFilterIsSet && $checkedInput.length > 0) {
        if (isDefault) {
            $checkedInput.prop('checked', false);
            $checkedInput.trigger('change');
        } else {
            var $defaultInput = component.$inputs.filter(DEFAULT_SELECTOR);
            $defaultInput.prop('checked', true);
            $defaultInput.trigger('change');
        }
        component.$items.find(RANGE_ITEM_SELECTOR).remove();
        component.removeCheckedState();
        component.$element.trigger(RANGE_RESET);
    }
    if (component.$rangeItem.length > 0) {
        component.$rangeItem.remove();
        component.$rangeFlyout.trigger(RANGE_DESELECT_EVENT);
        component.removeCheckedState();
        component.$element.trigger(RANGE_RESET);
    }
};

RadioGroup.prototype.removeCheckedState = function() {
    var component = this;
    component.$element.removeClass(HAS_CHECKED_CLASS);
    component.$item.removeClass(IS_CHECKED_CLASS);
    AppliedFilters.remove(component.groupSettings.filterGroupId);
    if (component.isNestedFilter) {
        component.$element.trigger(DESELECT_EVENT);
    }
};

RadioGroup.prototype.addCheckedState = function($item, $input) {
    var component = this;
    var appliedFilter = component.groupSettings;

    component.$element.addClass(HAS_CHECKED_CLASS);
    component.$item.not($item).removeClass(IS_CHECKED_CLASS);
    $item.addClass(IS_CHECKED_CLASS);

    appliedFilter.filterName = $input.attr(RADIO_GROUP_NAME_ATTR);
    appliedFilter.labelText = appliedFilter.filterName;

    if ($input.is(FILTER_ADD_GROUP_NAME_SELECTOR)) {
        appliedFilter.labelText = appliedFilter.filterName + ' (' + appliedFilter.filterGroupName + ')';
    }
    component.applyOption(appliedFilter);
};

RadioGroup.prototype.applyOption = function(data) {
    var component = this;
    if (component.notInAppliedFilters) {
        return;
    }
    AppliedFilters.add(
        data,
        function() {
            // reset radio group
            component.resetCheckState(component);
        }
    );
};

$(COMPONENT_SELECTOR).each(function(index, element) {
    return new RadioGroup(element);
});

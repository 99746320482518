// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import '../../components/partial-page-update/partial-page-update';
import '../../components/radius-filter/radius-filter';
import '../../components/custom-select-box/custom-select-box';

// what does this module expose?
export default SearchBlock;

// component configuration
const COMPONENT_SELECTOR = '[data-search-block]';
const AUTOCOMPLETE_SELECTOR = '[data-autocomplete]';

const FORM_SELECTOR = '[data-search-block-form]';
const SOORT_AANBOD_SELECTOR = '[data-search-block-soort-aanbod-selector]';
const SOORT_AANBOD_SELECTOR_PLACEHOLDER = '[data-search-block-select-placeholder]';
const LOCATION_FILTERS_SELECTOR = '[data-search-block-location-filters]';
const HORECA_REGIONS_SELECTOR = '[data-search-block-horeca-regions]';
const HORECA_REGIONS_SELECT = '[data-search-block-horeca-regions-handle]';
const SOORT_AANBOD_HORECA = 'horeca';

const SEARCH_QUERY_UPDATED_EVENT = 'searchqueryupdated';
const IS_HIDDEN_CLASS = 'is-hidden';

function SearchBlock(element) {
    const component = this;
    component.$element = $(element);
    component.$form = component.$element.find(FORM_SELECTOR);
    component.$autocomplete = component.$element.find(AUTOCOMPLETE_SELECTOR);
    component.$soortAanbodSelect = component.$form.find(SOORT_AANBOD_SELECTOR);

    component.bindEvents();
    component.initializeSoortAanbodHandle();
    component.toggleHorecaRegions();
}

SearchBlock.prototype.bindEvents = function() {
    const component = this;

    component.$autocomplete.on(SEARCH_QUERY_UPDATED_EVENT, (e, data) => {
        if (data === undefined || !data.isOpen) {
            component.$form.submit();
        }
    });

    component.$soortAanbodSelect.on('mousedown.removeSelectPlaceholder', component.removeSelectPlaceholder);
    component.$soortAanbodSelect.on('keydown.removeSelectPlaceholder', component.removeSelectPlaceholder);
    component.$soortAanbodSelect.on('change.removeSelectPlaceholder', component.removeSelectPlaceholder);
};

SearchBlock.prototype.initializeSoortAanbodHandle = function() {
    const component = this;

    if (component.$soortAanbodSelect.length > 0) {
        component.$locationFilters = component.$element.find(LOCATION_FILTERS_SELECTOR);
        component.$horecaRegions = component.$element.find(HORECA_REGIONS_SELECTOR);
        component.$horecaRegionsSelect = component.$element.find(HORECA_REGIONS_SELECT);

        component.$soortAanbodSelect.on('change', () => component.toggleHorecaRegions());
    }
};

/**
 * Find the “listing type” <select> placeholder and remove it if it exists.
 */
SearchBlock.prototype.removeSelectPlaceholder = function() {
    const component = $(this);
    const placeholder = document.querySelector(SOORT_AANBOD_SELECTOR_PLACEHOLDER);
    if (placeholder) {
        placeholder.parentNode.removeChild(placeholder);
    }

    // Remove all event listeners in the `.removeSelectPlaceholder` namespace.
    component.off('.removeSelectPlaceholder');
};

/**
 * Show or hide horeca select
 */
SearchBlock.prototype.toggleHorecaRegions = function() {
    const component = this;
    const soortAanbod = component.$soortAanbodSelect.val();

    if (!soortAanbod) {
        return;
    }

    // soortAanbod does not necessarily represent SoortAanbod.Horeca; it may also be beleggingtype horeca with SoortAanbod.Belegging.
    // Both have the same underlying value (horeca) and both need this same show/hide functionality.
    if (soortAanbod.toLowerCase() === SOORT_AANBOD_HORECA) {
        component.showHorecaRegions();
    } else {
        component.hideHorecaRegions();
    }
};

/**
 * show the horeca regions; hide the auto complete
 */
SearchBlock.prototype.showHorecaRegions = function() {
    const component = this;
    component.$locationFilters.addClass(IS_HIDDEN_CLASS);
    component.$horecaRegions.removeClass(IS_HIDDEN_CLASS);
    // clear the location in the auto complete and radius
    component.$autocomplete.trigger('reset');
};

/**
 * show the auto complete; hide the horeca regions
 */
SearchBlock.prototype.hideHorecaRegions = function() {
    const component = this;
    component.$horecaRegions.addClass(IS_HIDDEN_CLASS);
    component.$locationFilters.removeClass(IS_HIDDEN_CLASS);
    // set the horeca regions to the default (empty) value
    component.$horecaRegionsSelect.val('');
};

$(COMPONENT_SELECTOR).each((index, element) => new SearchBlock(element));

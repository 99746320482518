import $ from 'jquery';

const API_PREFIX = '';
const sentRequests = [];
const PENDING_REQUEST = 'pending';
const TRAVEL_TIME_URL_MATCH = /personalplaces\/traveltimes\/(.*)/;
const TRAVEL_POLYGON_URL_MATCH = /search\/traveltimepolygons\/getpolygons\/(.*)/;

let ajaxReq;

class Api {
    beforeSend(currentRequest, requestObject) {
        if (ajaxReq && ajaxReq.state() === PENDING_REQUEST) {
            if (TRAVEL_TIME_URL_MATCH.test(requestObject.url)
                || TRAVEL_POLYGON_URL_MATCH.test(requestObject.url)) {
                sentRequests.forEach(request => request.abort());
            }
        }
        sentRequests.push(currentRequest);
    }
    get(url, data) {
        ajaxReq = $
            .ajax({
                beforeSend: this.beforeSend,
                url: `${API_PREFIX}${url}`,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                data: data
            })
            .catch(error => {
                throw new Error(error.statusText);
            });
        return ajaxReq;
    }

    post(url, data) {
        return $
            .ajax({
                method: 'POST',
                url: `${API_PREFIX}${url}`,
                xhrFields: {
                    withCredentials: true
                },
                data: data
            })
            .catch(error => {
                throw new Error(error.responseText);
            });
    }
}

export default new Api();

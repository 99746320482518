import gtmEventLogger from '../gtm-event-logger/gtm-event-logger';

const EVENT_CATEGORY = {
    TRAVEL_TIME: 1,
    SERVICES: 2
};
export const EVENT_TYPE = {
    SUGGESTION: 1,
    COORDINATE: 2,
    ENTRY_POINT_MAP: 3,
    ENTRY_POINT_GO_TO: 4,
    ENTRY_POINT_ADD: 5,
    MARKER: 6,
    SAVE_POI: 7,
    MODALITY: 8,
    DELETE_POI: 9,
    LOGIN_FACEBOOK: 10,
    LOGIN_EMAIL: 11,
    REGISTER_FACEBOOK: 12,
    REGISTER_EMAIL: 13,
    CATEGORY: 14,
    ENTRY_POINT_SERVICES: 15,
    DESKTOP_OVERVIEW_DRAWER_EXPAND: 16,
    DESKTOP_OVERVIEW_DRAWER_COLLAPSE: 17,
    DESKTOP_DETAIL_DRAWER_DISMISS: 18,
    HEART_CLICK: 19,

    MOBILE_DETAIL_DRAWER_EXPAND: 19,
    MOBILE_DETAIL_DRAWER_COLLAPSE: 20,
    MOBILE_DETAIL_DRAWER_DISMISS: 21,
    MOBILE_DETAIL_DRAWER_TAP_GRIP: 22
};
const GA_ACTION = {
    [EVENT_CATEGORY.TRAVEL_TIME]: {
        [EVENT_TYPE.SUGGESTION]: 'location-suggestion',
        [EVENT_TYPE.COORDINATE]: 'location-coordinates',
        [EVENT_TYPE.ENTRY_POINT_MAP]: 'entry-point-map',
        [EVENT_TYPE.ENTRY_POINT_GO_TO]: 'entry-point-go-to',
        [EVENT_TYPE.ENTRY_POINT_ADD]: 'entry-point-add',
        [EVENT_TYPE.MARKER]: 'click-marker',
        [EVENT_TYPE.SAVE_POI]: 'save-poi',
        [EVENT_TYPE.MODALITY]: 'switch-modality',
        [EVENT_TYPE.DELETE_POI]: 'delete-poi',
        [EVENT_TYPE.LOGIN_FACEBOOK]: 'login-facebook',
        [EVENT_TYPE.LOGIN_EMAIL]: 'login-email',
        [EVENT_TYPE.REGISTER_FACEBOOK]: 'register-facebook',
        [EVENT_TYPE.REGISTER_EMAIL]: 'register-email',
        [EVENT_TYPE.DESKTOP_OVERVIEW_DRAWER_EXPAND]: 'desktop-overview-drawer-expand',
        [EVENT_TYPE.DESKTOP_OVERVIEW_DRAWER_COLLAPSE]: 'desktop-overview-drawer-collapse',
        [EVENT_TYPE.DESKTOP_DETAIL_DRAWER_DISMISS]: 'desktop-detail-drawer-dismiss',

        [EVENT_TYPE.MOBILE_DETAIL_DRAWER_EXPAND]: 'mobile-detail-drawer-expand',
        [EVENT_TYPE.MOBILE_DETAIL_DRAWER_COLLAPSE]: 'mobile-detail-drawer-collapse',
        [EVENT_TYPE.MOBILE_DETAIL_DRAWER_DISMISS]: 'mobile-detail-drawer-dismiss',
        [EVENT_TYPE.MOBILE_OVERVIEW_DRAWER_TAP_GRIP]: 'mobile-overview-drawer-tap-grip',
        [EVENT_TYPE.MOBILE_DETAIL_DRAWER_TAP_GRIP]: 'mobile-detail-drawer-tap-grip',

    },
    [EVENT_CATEGORY.SERVICES]: {
        [EVENT_TYPE.CATEGORY]: 'select-services-category',
        [EVENT_TYPE.MARKER]: 'click-services-marker',
        [EVENT_TYPE.ENTRY_POINT_SERVICES]: 'entry-point-services'
    }
};

class MediaViewerGtmEvents {
    static travelTime(eventAction, label) {
        MediaViewerGtmEvents._log(EVENT_CATEGORY.TRAVEL_TIME, eventAction, label);
    }

    static services(eventAction, label) {
        MediaViewerGtmEvents._log(EVENT_CATEGORY.SERVICES, eventAction, label);
    }

    static _log(category, eventAction, label) {
        if (!GA_ACTION[category].hasOwnProperty(eventAction)) {
            return;
        }

        gtmEventLogger
            .log({
                eventCategory: MediaViewerGtmEvents.gtmCategory(category),
                eventAction: GA_ACTION[category][eventAction],
                eventLabel: label
            });
    }

    static gtmCategory(category) {
        switch (category) {
            case EVENT_CATEGORY.TRAVEL_TIME:
                return 'interactions-traveltime';

            case EVENT_CATEGORY.SERVICES:
                return 'interactions-traveltime';

            default:
                return null;
        }
    }
}

export default MediaViewerGtmEvents;

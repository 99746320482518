import {
    ADD_LOADED_TILE,
    CHANGE_DRAWER,
    COLLAPSE_ACCORDION_PANEL,
    DISABLE_CATEGORY,
    ENABLE_CATEGORY,
    SET_CATEGORIES,
    EXPAND_ACCORDION_PANEL,
    SET_ACTIVE_LOCATION,
    SET_LOGIN_STATE,
    SET_MEDIA_VIEWER_HASH,
    SET_MEDIA_VIEWER_ROUTE,
    SET_MODALITY,
    SET_GLOBAL_OBJECT_ID,
    SET_POI_TRAVEL_DISTANCE,
    GET_HEADER_AS_PARTIAL_VIEW,
    SET_TRAVEL_TIME_POLYGON_DATA,
    UPDATE_POLYGON_MODALITY_AND_TRAVEL_TIME,
    SHOW_LOADING_SPINNER
} from './../constants/action-types';

import {
    POI_OVERVIEW_DRAWER,
    POI_DETAIL_DRAWER
} from './../constants/drawer-names';

import {
    DRAWER_HIDDEN
} from './../constants/drawer-states';

const initialState = {
    globalObjectId: document.querySelector('[data-global-id]') ? document.querySelector('[data-global-id]').getAttribute('data-global-id') : '',
    mediaViewerHash: '',
    mediaViewerRoute: '',
    modality: 'car',
    activeLocationId: null,
    userLoggedIn: null,
    selectedCategories: [],
    expandedAccordionPanels: [],
    loadedTiles: [],
    [POI_DETAIL_DRAWER]: DRAWER_HIDDEN,
    [POI_OVERVIEW_DRAWER]: DRAWER_HIDDEN,
    modalityAndTravelToggle: {
        modality: 'car',
        travelTime: '20'
    },
    showPolygonSpinner: false
};

const getTravelTime = (state, action) => {
    const travelTime = action.data !== undefined ? action.data.displayTime + ' ' + action.data.displayDistance : null;
    return Object
        .assign({}, state, { poiTravelTime: travelTime });
};

const updatePolygonModalityAndTravelTime = (state, action) => {
    const newObject = Object.assign({}, state.modalityAndTravelToggle);
    newObject[action.selectedToggle.key] = action.selectedToggle.value;

    return Object.assign({}, state, { modalityAndTravelToggle: newObject});
};

function ui(state = initialState, action = {}) {
    switch (action.type) {

        case UPDATE_POLYGON_MODALITY_AND_TRAVEL_TIME:
            return updatePolygonModalityAndTravelTime(state, action);

        case SHOW_LOADING_SPINNER:
            return Object
                .assign({}, state, {
                    showPolygonSpinner: action.show
                });

        case SET_POI_TRAVEL_DISTANCE:
            return getTravelTime(state, action);

        case SET_GLOBAL_OBJECT_ID:
            return Object
                .assign({}, state, {
                    globalObjectId: action.globalObjectId
                });

        case SET_MEDIA_VIEWER_HASH:
            return Object
                .assign({}, state, {
                    mediaViewerHash: action.mediaViewerHash
                });

        case SET_MEDIA_VIEWER_ROUTE:
            return Object
                .assign({}, state, {
                    mediaViewerRoute: action.mediaViewerRoute
                });

        case SET_MODALITY:
            return Object
                .assign({}, state, {
                    modality: action.label
                });

        case CHANGE_DRAWER:
            return Object
                .assign({}, state, {
                    [action.drawerName]: action.drawerPosition
                });

        case SET_ACTIVE_LOCATION:
            return Object
                .assign({}, state, {
                    activeLocationId: action.id
                });

        case SET_LOGIN_STATE:
            return Object
                .assign({}, state, {
                    userLoggedIn: action.loggedIn,
                    userLogInUrl: action.loginUrl,
                });

        case ENABLE_CATEGORY:
            return Object
                .assign({}, state, {
                    selectedCategories: [
                        ...state.selectedCategories,
                        action.id
                    ]
                });

        case DISABLE_CATEGORY:
            return Object
                .assign({}, state, {
                    selectedCategories: state.selectedCategories.filter(item => item !== action.id)
                });

        case SET_CATEGORIES:
            return Object
                .assign({}, state, {
                    selectedCategories: action.ids
                });

        case ADD_LOADED_TILE:
            return Object
                .assign({}, state, {
                    loadedTiles: [
                        ...state.loadedTiles,
                        action.tileId
                    ]
                });

        case EXPAND_ACCORDION_PANEL:
            return Object
                .assign({}, state, {
                    expandedAccordionPanels: [
                        ...state.expandedAccordionPanels,
                        action.id,
                    ]
                });

        case COLLAPSE_ACCORDION_PANEL:
            return Object
                .assign({}, state, {
                    expandedAccordionPanels: state.expandedAccordionPanels.filter(item => item !== action.id)
                });

        case GET_HEADER_AS_PARTIAL_VIEW:
            return Object
                .assign({}, state, {
                    appHeader: action.appHeader
                });

        case SET_TRAVEL_TIME_POLYGON_DATA:
            return Object
                .assign({}, state, {
                    travelTimePolygon: [action.data]
                });

        default:
            return state;
    }
}

export default ui;

// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

// constants for initialization
const BP_MEDIUM = 750;
const WINDOW_WIDTH = $(window).width();
const NO_DESKTOP = WINDOW_WIDTH < BP_MEDIUM - 1;

// constant definitions
const COMPONENT_ATTR = 'data-advertisements';
const COMPONENT_ASYNC_ATTR = 'data-advertisements-async';
const COMPONENT_ASYNC_SELECTOR = `[${COMPONENT_ASYNC_ATTR}]`;
const LAZY_TYPE_ATTR = 'data-advertisements-lazy-type';
const COMPONENT_SCROLL_BASED_SELECTOR = `[${COMPONENT_ATTR}]:not([${LAZY_TYPE_ATTR}="event"])`;
const COMPONENT_EVENT_BASED_SELECTOR = `[${COMPONENT_ATTR}][${LAZY_TYPE_ATTR}="event"]`;
const CUSTOM_EVENT_HANDLER_ATTR = 'data-advertisements-event-trigger';
const DFP_TRIGGERED_CLASS = 'dfp-display-triggered';
const SLOT_CLASS = 'funda async ' + (NO_DESKTOP ? 'slot-ad-non-desktop' : 'slot-ad-desktop');
const SIZE_MAPPING_ATTR = 'data-advertisement-size-mapping';
const SIZE_DEFAULT_ATTR = 'data-advertisement-size-default';
const ADUNIT_PATH_ATTR = 'data-advertisement-adunit-path';
const CATEGORIES_URL_ATTR = 'data-advertisement-categories-url';
const RENDER_MARGIN_ATTR = 'data-advertisements-render-margin';
const DEFAULT_FETCH_MARGIN_PERCENT = 1000; // Disable fetch slots because it doesnt work with setCollapseEmptyDiv.
const DEFAULT_RENDER_MARGIN_PERCENT = 20; // Render slots if it's within 20% below viewports.
const DEFAULT_MOBILE_SCALING = 2.0; // Double the above values on mobile.
const SEARCH_AD_SELECTOR = '.search-ad';
const SEARCH_AD_LOADED_CLASS = 'search-ad--loaded';
const LAZY_SCROLL_BASED = 'scroll';
const LAZY_EVENT_BASED = 'event';
const $window = $(window);

export default class Advertisements {
    constructor($elements, slotCreatedAsync, lazyBehaviour) {
        const component = this;
        component.$elements = $elements;
        component.$currentSlot = null;
        component.cachedCategoriesByUrl = {};
        component.Adomik = window.Adomik || {};
        window.googletag = window.googletag || {};
        googletag.cmd = googletag.cmd || [];

        if (slotCreatedAsync) {
            component.prepareSlot(component.$elements, lazyBehaviour);
        } else {
            googletag.cmd.push(function () {
                component.prepareSlot(component.$elements, lazyBehaviour);
                component.attachEventListenerWhenSlotIsRendered();
                component.enableAdService();
            });
        }
    }

    prepareSlot($elements, lazyBehaviour) {
        const component = this;

        [].forEach.call($elements, (element) => {
            component.$currentSlot = $(element);

            const config = component.getAdConfig(element);

            if (config !== false) {
                element.id = config.id;

                if (config.lazyType === lazyBehaviour) {
                    component.createSlot(config);
                }
            }
        });
    }

    attachEventListenerWhenSlotIsRendered() {
        const component = this;

        window.googletag.pubads().addEventListener('slotRenderEnded', function (event) {
            const slotId = event.slot.getSlotId();
            const $slot = $('#' + slotId.getDomId());
            const $injectedFromGoogle = $slot.find('div[id^=google_ads_iframe]');

            // DFP adds two iframes, one for calling scripts and one for displaying the ad. we want the one that is not hidden
            component.addClassNameIfCreativeLoaded($slot, $injectedFromGoogle.length > 0);
        });
    }

    enableAdService() {
        const renderMarginPercent = this.$elements.attr(RENDER_MARGIN_ATTR) || DEFAULT_RENDER_MARGIN_PERCENT;

        window.googletag.pubads().disableInitialLoad();
        window.googletag.pubads().enableLazyLoad({
            fetchMarginPercent: DEFAULT_FETCH_MARGIN_PERCENT,
            renderMarginPercent: parseInt(renderMarginPercent, 8),
            mobileScaling: DEFAULT_MOBILE_SCALING
        });
        window.googletag.enableServices();
    }

    getAdConfig(element) {
        const $element = $(element);

        return {
            id: $element.attr('id') || 'ad-' + (this.$elements.index($element) + 1),
            adunitpath: $element.attr(ADUNIT_PATH_ATTR),
            sizemapping: JSON.parse($element.attr(SIZE_MAPPING_ATTR)),
            sizedefault: JSON.parse($element.attr(SIZE_DEFAULT_ATTR)),
            categoriesUrl: $element.attr(CATEGORIES_URL_ATTR),
            lazyType: $element.attr(LAZY_TYPE_ATTR) || LAZY_SCROLL_BASED
        };
    }

    createSlot(config) {
        const component = this;
        const cachedCategories = component.cachedCategoriesByUrl[config.categoriesUrl];

        if (component.$currentSlot.hasClass(DFP_TRIGGERED_CLASS))
            return;

        component.$currentSlot.addClass(DFP_TRIGGERED_CLASS);

        if (cachedCategories) {
            component.pushAdSlot(config, cachedCategories);
        } else {
            $.get({
                url: config.categoriesUrl,
                success:
                    function (categories) {
                        component.pushAdSlot(config, categories);
                    },
                error:
                    function () {
                        component.pushAdSlot(config, {});
                    }
            });
        }
    }

    pushAdSlot(config, categories) {
        const adUnitTag = this.setAdUnit(config);

        if (typeof this.Adomik.randomAdGroup !== 'undefined') {
            this.setReportingTargeting(adUnitTag);
        }

        for (let key in categories) {
            if (categories.hasOwnProperty(key)) {
                adUnitTag.setTargeting(key, categories[key]);
            }
        }

        window.googletag.cmd.push(() => {
            window.googletag.display(config.id);
            window.googletag.pubads().refresh([adUnitTag]);
        });
    }

    setAdUnit(config) {
        return window.googletag
            .defineSlot(config.adunitpath, config.sizedefault, config.id)
            .defineSizeMapping(config.sizemapping)
            .setCollapseEmptyDiv(true, true)
            .addService(window.googletag.pubads());
    }

    setReportingTargeting(adUnitTag) {
        adUnitTag.setTargeting('ad_group', this.Adomik.randomAdGroup());
        adUnitTag.setTargeting('ad_h', this.Adomik.hours);
    }

    addClassNameIfCreativeLoaded($slot, isAdInjectedFromGoogle) {
        if (isAdInjectedFromGoogle === false)
            return;

        $slot.addClass('advertisement dfp-callback-successful slot-' + SLOT_CLASS);
        // Needed to avoid jumping effect on search results
        if ($(SEARCH_AD_SELECTOR).length) {
            $slot.parents(SEARCH_AD_SELECTOR).addClass(SEARCH_AD_LOADED_CLASS);
        }
        $slot
            .find('iframe')
            .contents().find('body')
            .addClass('body-' + SLOT_CLASS);
    }

    static populateCustomEvents($elements) {
        let customEvents = [];
        let eventTrigger;

        $elements.each((index, element) => {
            eventTrigger = $(element).attr(CUSTOM_EVENT_HANDLER_ATTR) || null;

            if (eventTrigger != null) {
                customEvents.push(eventTrigger);
            }
        });

        customEvents = new Set(customEvents); // eslint-disable-line no-new

        return Array.from(customEvents).join(' ');
    }

    static destroyAllSlots() {
        window.googletag.destroySlots();
    }
}

if ($(COMPONENT_SCROLL_BASED_SELECTOR).length > 0) {
    new Advertisements($(COMPONENT_SCROLL_BASED_SELECTOR), false, LAZY_SCROLL_BASED); // eslint-disable-line no-new
}

if ($(COMPONENT_EVENT_BASED_SELECTOR).length > 0) {
    const customEvents = Advertisements.populateCustomEvents($(COMPONENT_EVENT_BASED_SELECTOR));
    $window.on(customEvents, (e) => new Advertisements($(`${COMPONENT_EVENT_BASED_SELECTOR}[${CUSTOM_EVENT_HANDLER_ATTR}="${e.type}"]`), false, LAZY_EVENT_BASED));
}

if ($(COMPONENT_ASYNC_SELECTOR).length > 0) {
    // After the search results are updated, it should destroy all the existing slots and then re-init the component.
    $(document).on('resultsUpdated', () => {
        Advertisements.destroyAllSlots();
        new Advertisements($(COMPONENT_ASYNC_SELECTOR), true, LAZY_SCROLL_BASED); // eslint-disable-line no-new
    });
}

import $ from 'jquery';
import SaveLocationForm from '../save-location-form/save-location-form';
import PersonalPlacesList from '../personal-places-list/personal-places-list';
import reduxStore from '../store/store';
import Connect from '../store/connect';
import AuthenticationRedux from '../authentication/authentication-redux';
import {
    getPersonalPlaces,
    setActiveLocation,
    addPersonalPlace,
    deletePersonalPlace
} from '../store/actions';
import { SELECTOR as SAVE_LOCATION_FORM_SELECTOR } from '../save-location-form/save-location-form';
import { SELECTOR as SAVED_PLACES_LIST_SELECTOR } from '../personal-places-list/personal-places-list';

const SAVE_LOCATION_FORM_CONTENT_SELECTOR = '[data-travel-time-flyout-wrapper-save-location]';
const PERSONAL_PLACES_LIST_CONTENT_SELECTOR = '[data-travel-time-flyout-wrapper-personal-places-list]';
const ADD_PERSONAL_PLACE_BUTTON_SELECTOR = '[data-travel-time-flyout-wrapper-add-personal-place]';
const LOGIN_BUTTON_SELECTOR = '[data-login-button]';

const BACKGROUND_WHITE_SELECTOR = '.white-bg';
const IS_HIDDEN = 'is-hidden';

const isUserLoggedIn = state => state.ui.userLoggedIn;
const personalPlaces = state => state.entities.personalPlaces;

const calledBackendWithoutError = (prop, state, prevState) => {
    return state[prop].loaded &&
        !state[prop].error &&
        state[prop].loaded !== prevState[prop].loaded;
};

const noop = () => {};

export default class TravelTimeFlyoutWrapper {
    constructor(element, store, closeFlyout) {
        this.closeFlyout = closeFlyout || noop;
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.personalPlaces',
            'ui.userLoggedIn',
            'addPersonalPlace.loaded',
            'addPersonalPlace.error',
            'getPersonalPlaces.loaded',
            'deletePersonalPlace.loaded'
        ]);

        this.handleAutoSuggestSuggestionsToggle = this.handleAutoSuggestSuggestionsToggle.bind(this);
        this.onSaveLocationFormSubmit = this.onSaveLocationFormSubmit.bind(this);
        this.removePersonalPlace = this.removePersonalPlace.bind(this);
        this.cancelSaveLocation = this.cancelSaveLocation.bind(this);

        this.$element = $(element);

        this.$personalPlacesListContent = this.$element.find(PERSONAL_PLACES_LIST_CONTENT_SELECTOR);
        this.$saveLocationFormContent = this.$element.find(SAVE_LOCATION_FORM_CONTENT_SELECTOR);
        this.$addNewPersonalPlace = this.$element.find(ADD_PERSONAL_PLACE_BUTTON_SELECTOR);

        this.$whiteBackground = this.$element.find(BACKGROUND_WHITE_SELECTOR);
        this.$saveLocationForm = this.$element.find(SAVE_LOCATION_FORM_SELECTOR);
        this.$personalPlacesList = this.$element.find(SAVED_PLACES_LIST_SELECTOR);
        this.$loginButton = this.$element.find(LOGIN_BUTTON_SELECTOR);

        this.saveLocationForm = new SaveLocationForm(
            this.$saveLocationForm,
            this.onSaveLocationFormSubmit,
            this.cancelSaveLocation,
            this.handleAutoSuggestSuggestionsToggle
        );

        this.personalPlacesList = new PersonalPlacesList(
            this.$personalPlacesList,
            this.store,
            this.setActiveLocation,
            this.removePersonalPlace
        );

        this.authenticationRedux = new AuthenticationRedux();

        this.subscribeToStore();
        this.bindEvents();
        this.layout();
        this.authenticationRedux.fetchUserAuthenticationInfo();
    }

    bindEvents() {
        this.$addNewPersonalPlace.on('click', () => this.showSaveLocationForm());

        this.$loginButton.on('click', (e) => {
            e.preventDefault();
            this.authenticationRedux
                .openLoginDialog()
                .then(() => {
                    this.authenticationRedux.closeLoginDialog();
                });
        });
    }

    subscribeToStore() {
        this.connect.subscribe((state, prevState) => {
            isUserLoggedIn(state) !== true ? this.showLoginButton() : this.removeLoginButton();

            if (isUserLoggedIn(state) === true && isUserLoggedIn(prevState) !== true) {
                this.fetchSavedPersonalPlaces();
            }

            if (calledBackendWithoutError('addPersonalPlace', state, prevState)) {
                this.fetchSavedPersonalPlaces();
            }

            if (personalPlaces(state) !== personalPlaces(prevState)) {
                this.layout();
            }
        });
    }

    layout() {
        if (this.amountOfPersonalPlaces() === 0) {
            this.showSaveLocationForm();
        } else {
            this.showPersonalPlacesList();
        }
    }

    showLoginButton() {
        this.$loginButton.removeClass(IS_HIDDEN);
    }

    removeLoginButton() {
        this.$loginButton.addClass(IS_HIDDEN);
    }

    onSaveLocationFormSubmit(locationData) {
        const state = this.store.getState();
        if (!isUserLoggedIn(state)) {
            this.authenticationRedux
                .openLoginDialog()
                .then(() => {
                    this.saveLocation(locationData);
                    this.authenticationRedux.closeLoginDialog();
                });
        } else {
            this.saveLocation(locationData);
        }
    }

    saveLocation(locationData) {
        const data = { location: locationData.location, label: locationData.label, lat: locationData.coordinates.lat, lng: locationData.coordinates.lng };
        this.store.dispatch(addPersonalPlace(data));
        this.saveLocationForm.clear();
    }

    amountOfPersonalPlaces() {
        const state = this.store.getState();
        return Object.keys(personalPlaces(state)).length;
    }

    cancelSaveLocation() {
        if (this.amountOfPersonalPlaces() > 0) {
            this.showPersonalPlacesList();
        } else {
            this.closeFlyout();
        }
    }

    removePersonalPlace(personalPlaceId) {
        const currentState = this.store.getState();
        this.store.dispatch(deletePersonalPlace(personalPlaceId));
        if (currentState.ui.activeLocationId === personalPlaceId) {
            this.setActiveLocation(undefined);
        }
    }

    showPersonalPlacesList() {
        this.$personalPlacesListContent.removeClass(IS_HIDDEN);
        this.$saveLocationFormContent.addClass(IS_HIDDEN);
    }

    showSaveLocationForm() {
        this.$personalPlacesListContent.addClass(IS_HIDDEN);
        this.$saveLocationFormContent.removeClass(IS_HIDDEN);
    }

    fetchSavedPersonalPlaces() {
        this.store.dispatch(getPersonalPlaces());
    }

    setActiveLocation(locationId) {
        this.store.dispatch(setActiveLocation(locationId));
    }

    handleAutoSuggestSuggestionsToggle(isSuggesting) {
        this.$whiteBackground.toggleClass(IS_HIDDEN, !isSuggesting);
    }
}

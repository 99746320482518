import $ from 'jquery';
import TravelModeToggle from '../travel-mode-toggle/travel-mode-toggle';
import reduxStore from '../store/store';
import Connect from '../store/connect';
import {
    updatePolygonModalityAndTravelTime,
} from '../store/actions';
import {hasActiveLocation} from '../store/utils/conditions';

const COMPONENT_SELECTOR = '[data-travel-mode-toggle-zok-wrapper]';
const MODALITY_TOGGLE_SELECTOR = '[data-travel-mode-toggle-modality]';
const TRAVEL_TIME_TOGGLE_SELECTOR = '[data-travel-mode-toggle-travel-time]';
const IS_HIDDEN = 'is-hidden';

export default class TravelModeToggleZokWrapper {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'ui.activeLocationId',
            'ui.modalityAndTravelToggle',
        ]);
        this.onToggleSelect = this.onToggleSelect.bind(this);

        this.$element = $(element);
        this.$modalityToggle = this.$element.find(MODALITY_TOGGLE_SELECTOR);
        this.$travelTimeToggle = this.$element.find(TRAVEL_TIME_TOGGLE_SELECTOR);


        this.travelModeModalityToggle = new TravelModeToggle(this.$modalityToggle, this.onToggleSelect);
        this.travelModeTravelTimeToggle = new TravelModeToggle(this.$travelTimeToggle, this.onToggleSelect);
        this.subscribeToStore();
        this.bindEvents();
    }
    subscribeToStore() {
        this.connect.subscribe((state) => {
            const shouldShowModalityAndTravelTimeToggle = hasActiveLocation(state);
            this.showModalityAndTravelTimeToggle(shouldShowModalityAndTravelTimeToggle);
        });
    }

    bindEvents() {
        // Collapses the toggle when clicked outside.
        $(document).bind('mouseup touchend', (e) => {
            const container = $(COMPONENT_SELECTOR);
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                this.hideModalityAndTravelToggle();
            }
        });
    }

    hideModalityAndTravelToggle() {
        this.travelModeModalityToggle.toggle();
        this.travelModeTravelTimeToggle.toggle();
    }

    onToggleSelect(item) {
        this.store.dispatch(updatePolygonModalityAndTravelTime(item));
    }

    showModalityAndTravelTimeToggle(show) {
        if (show) {
            this.$element.removeClass(IS_HIDDEN);
        } else {
            this.$element.addClass(IS_HIDDEN);
        }
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new TravelModeToggleZokWrapper(element));

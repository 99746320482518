import './../_base-view/base-view';
import '../../components/dialog/dialog';
import '../../components/instant-search/instant-search';
import '../../components/filter-reset/filter-reset';
import '../../components/makelaars-name/makelaars-name';
import '../../components/notification/notification';
import '../../components/pagination/pagination';
import '../../components/radio-group/radio-group';
import '../../components/search-header/search-header';
import '../../components/search-sidebar/search-sidebar';

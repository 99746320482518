import $ from 'jquery';

export default UserMyHouseHeader;

// component configuration
const COMPONENT_SELECTOR = '[data-my-house-header]';
const DROPDOWN_SELECTOR = '[data-my-house-header-dropdown]';
const URL_PATH_BASE_ATTR = 'data-my-house-base-url';

/**
 * Constructor method, links child elements to variables for internal use
 * @param {HTMLElement} element     The HTML element to bind to.
 */
function UserMyHouseHeader(element) {
    const component = this;
    component.$element = $(element);
    component.$dropdown = component.$element.find(DROPDOWN_SELECTOR);
    component.urlPathBase = component.$dropdown.attr(URL_PATH_BASE_ATTR);
    component.selectedOptionValue = component.getSelectedOptionValue();

    component.bindEvents();
}

/**
 * Bind component events.
 * Listen for changes on the dropdown.
 */
UserMyHouseHeader.prototype.bindEvents = function() {
    const component = this;

    component.$dropdown.on('change', function() {
        component.onChange();
    });
};

/**
 * Gets path to redirect to and redirects to that path
 */
UserMyHouseHeader.prototype.onChange = function() {
    const component = this;

    window.location.pathname = component.getPathToRedirect();
};

/**
 * Returns the value from the selected option in the dropdown.
 * @returns {string}        Value of the selected option. Should be global id.
 */
UserMyHouseHeader.prototype.getSelectedOptionValue = function() {
    const component = this;
    component.selectedOptionValue = component.$dropdown.val();

    return component.selectedOptionValue;
};

/**
 * Returns the complete path to redirect to.
 * @returns {string}        Should be a complete URL.
 */
UserMyHouseHeader.prototype.getPathToRedirect = function() {
    const component = this;

    return component.urlPathBase + component.getSelectedOptionValue() + '/';
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new UserMyHouseHeader(element));

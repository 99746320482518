// import this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default ObjectMeldEenFoutForm;

// component configuration
const CLASS_HIDDEN = 'is-hidden';
const CLASS_INPUT_ERROR = 'input-validation-error';
const COMPONENT_SELECTOR = '[data-object-meld-een-fout-form]';
const CONFIG_SELECTOR = '[data-object-meld-een-fout-config]';
const ERROR_MESSAGE_SELECTOR = '[data-meld-een-fout-error-message]';
const FORM_SELECTOR = '[data-object-meld-een-fout-form-form]';
const INPUT_SELECTOR = '[data-meld-een-fout-input]';
const INPUT_SUBQUESTION_SELECTOR = '[data-meld-een-fout-input-subquestion]';
const INPUT_TEXTAREA_LABEL_SELECTOR = '[data-meld-een-fout-textarea-label="Foutmelding"]';
const INPUT_TEXTAREA_SELECTOR = '[data-meld-een-fout-input-message="textarea"]';
const INPUT_TEXTAREA_SUBQUESTION_LABEL_SELECTOR = '[data-meld-een-fout-textarea-label="FoutieveInformatie"]';
const INPUT_TEXTAREA_SUBQUESTION_SELECTOR = '[data-meld-een-fout-input-subquestion="textarea"]';
const SUBQUESTIONS_SELECTOR = '[data-meld-een-fout-subquestions]';
const TYPE_RADIO = 'radio';
const TYPE_TEXTAREA = 'textarea';

/**
 * Constructor method, links child elements to variables for internal use
 *
 * @param {HTMLElement} element The Html element to bind to
 */
function ObjectMeldEenFoutForm(element) {
    const component = this; // Not necessary in ES2015, keep using it for consistency
    component.config = {};
    component.$element = $(element);
    component.$errorMessage = $(ERROR_MESSAGE_SELECTOR);
    component.$form = $(FORM_SELECTOR);
    component.$inputs = $(INPUT_SELECTOR);
    component.$inputsSubquestion = $(INPUT_SUBQUESTION_SELECTOR);
    component.$subquestions = $(SUBQUESTIONS_SELECTOR);
    component.$textAreaMessage = $(INPUT_TEXTAREA_SELECTOR);
    component.$textAreaMessageLabel = $(INPUT_TEXTAREA_LABEL_SELECTOR);
    component.$textAreaSubquestion = $(INPUT_TEXTAREA_SUBQUESTION_SELECTOR);
    component.$textAreaSubquestionLabel = $(INPUT_TEXTAREA_SUBQUESTION_LABEL_SELECTOR);

    if (component.$element.find(CONFIG_SELECTOR).length > 0) {
        component.config = JSON.parse(component.$element.find(CONFIG_SELECTOR).text());
    }

    component.$form.attr('novalidate', true);

    component.attachEventListeners();
    component.setFormState();
}

/**
 * Registers event listeners
 */
ObjectMeldEenFoutForm.prototype.attachEventListeners = function attachEventListeners() {
    const component = this;
    component.$inputs.on('change', () => component.onChangeHandler());
    component.$form.on('submit', (event) => component.onSubmitHandler(event));
};

/**
 * Parses the form data and constructs an object with state properties.
 * Properties:
 * - isOtherMessage     - The other option is selected in the main options list
 * - isOtherWrongInfo   - The other option is selected in the subquestions list
 * - isWrongInfo        - The subquestions should be shown.
 * - messageOtherText   - Is the messages text area filled
 * - messageSet         - Has the Foutmelding radio group a value
 * - wrongInfoOptionSet - Has the FoutieveInformatie radio group a value
 * - wrongInfoOtherText - Is the wrong info text area filled
 *
 * @return {Object} The form state object
 */
ObjectMeldEenFoutForm.prototype.getFormState = function getFormState() {
    const component = this;
    const serializedForm = serializeForm(component.$form);
    const isOtherMessage = !!(serializedForm.Foutmelding === component.config.foutmeldingOtherRadioValue);
    const isOtherWrongInfo = !!(serializedForm.FoutieveInformatie === component.config.foutieveInformatieOtherRadioValue);
    const isWrongInfo = !!(serializedForm.Foutmelding === component.config.foutmeldingWrongInfoRadioValue);
    const messageOtherText = !!(serializedForm.FoutmeldingText);
    const messageSet = !!(serializedForm.Foutmelding);
    const wrongInfoOptionSet = !!(serializedForm.FoutieveInformatie);
    const wrongInfoOtherText = !!(serializedForm.FoutieveInformatieText);

    return {isOtherMessage, isOtherWrongInfo, isWrongInfo, messageOtherText, messageSet, wrongInfoOptionSet, wrongInfoOtherText};
};

ObjectMeldEenFoutForm.prototype.onChangeHandler = function onChangeHandler() {
    const component = this;
    component.setFormState();
    hideSections(component.$errorMessage);
    removeElementErrorState(component.$textAreaMessageLabel, component.$textAreaSubquestionLabel);
};

ObjectMeldEenFoutForm.prototype.onSubmitHandler = function onSubmitHandler(event) {
    const component = this;
    if (!component.validateForm()) {
        event.preventDefault();
    }
};

/**
 * Hides and shows form elements based on the form values
 */
ObjectMeldEenFoutForm.prototype.setFormState = function setFormState() {
    var component = this;
    const formSate = component.getFormState();
    const $textAreaMessageLabel = component.$textAreaMessageLabel;
    const $inputsSubquestion = component.$inputsSubquestion;
    const $subquestions = component.$subquestions;

    (formSate.isOtherMessage) ? showSections($textAreaMessageLabel) : hideSections($textAreaMessageLabel);
    (formSate.isWrongInfo) ? showSections($subquestions, $inputsSubquestion) : hideSections($inputsSubquestion, $subquestions);
};

/**
 * Validates the form before submitting. It returns a boolean value indicating
 * if the form is valid or not. When it is not, it sets the correct error
 * error messages
 * @return {Boolean} Is the form valid or not
 */
ObjectMeldEenFoutForm.prototype.validateForm = function validateForm() {
    const component = this;
    const formState = component.getFormState();

    if (!formState.messageSet) {
        showSections(component.$errorMessage);
        return false;
    }

    if (formState.isWrongInfo &&
        !formState.wrongInfoOptionSet) {
        showSections(component.$errorMessage);
        return false;
    }

    if (formState.isWrongInfo &&
        formState.wrongInfoOptionSet &&
        formState.isOtherWrongInfo &&
        !formState.wrongInfoOtherText) {
        setElementErrorState(component.$textAreaSubquestion);
        showSections(component.$errorMessage);
        return false;
    }

    if (formState.isOtherMessage &&
        !formState.messageOtherText) {
        showSections(component.$errorMessage);
        setElementErrorState(component.$textAreaMessage);
        return false;
    }

    hideSections(component.$errorMessage);
    removeElementErrorState(component.$textAreaMessage, component.$textAreaSubquestion);
    return true;
};

/**
 * Clears the input value for an element.
 * First it checks the type of the element, based on that it clears a checked
 * property (radio buttons) or sets the value to null (textareas)
 *
 * @param  {HTMLElement} element The element to clear
 */
function clearInputValue(element) {
    const $element = $(element).get(0);

    if ($element.type === TYPE_RADIO) {
        $(element).prop('checked', false);
    }

    if ($element.type === TYPE_TEXTAREA) {
        $(element).val(null);
    }
}

/**
 * Hides one or more elements
 * It loops over the provided elements, for every element it clears the value
 * and it adds a hidden class to the elemenet
 * @param  {...HTMLElement} sections A jQuery element collection
 */
function hideSections(...sections) {
    sections.forEach((section) => {
        section.each((index, element) => {
            clearInputValue(element);
            $(element).addClass(CLASS_HIDDEN);
        });
    });
}

/**
 * Shows one or more elements
 * It loops over the provided elements, for every element it removes a
 * hidden class
 * @param  {...HTMLElement} sections A jQuery element collection
 */
function showSections(...sections) {
    sections.forEach((section) => {
        section.each((index, element) => {
            $(element).removeClass(CLASS_HIDDEN);
        });
    });
}

/**
 * Sets an error class on the provided elements
 * It loops over the provided elements, for every element it adds a error class
 * @param  {...HTMLElement} sections A jQuery element collection
 */
function setElementErrorState(...elements) {
    elements.forEach((element) => {
        element.each((index, elem) => {
            $(elem).addClass(CLASS_INPUT_ERROR);
        });
    });
}

/**
 * Removes an error class on the provided elements
 * It loops over the provided elements, for every element it removes error class
 * @param  {...HTMLElement} sections A jQuery element collection
 */
function removeElementErrorState(...elements) {
    elements.forEach((element) => {
        element.each((index, elem) => {
            $(elem).removeClass(CLASS_INPUT_ERROR);
        });
    });
}

/**
 * Serializes the values of a form element into an object. It expects an jQuery
 * object with a form. Via $.serialize() it parses the key/values string. That
 * string is mapped into an object where the keys are the property keys.
 *
 * Object example:
 * {
 *     'Foutmelding': 2
 * }
 *
 * @param  {jQuery Object} $form The jQuery object containing a form element
 * @return {Object}              The serialized object
 */
function serializeForm($form) {
    return $form.serialize()
        .split('&')
        .map((stringvalue) => stringvalue.split('='))
        .map((arr) => {
            const obj = {};
            obj[arr[0]] = arr[1];
            return obj;
        })
        .reduce((curr, next) => Object.assign(curr, next));
}

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function createNewComponent(index, element) {
    return new ObjectMeldEenFoutForm(element);
});
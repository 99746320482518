/* global google, gtmDataLayer */

import $ from 'jquery';

import gmapsAsyncLoad from '../gmaps-async/gmaps-async';
import MapKadaster from '../object-map-kadastraal/object-map-kadastraal';
import MapStreetview from '../object-map-streetview/object-map-streetview';
import MapSituatiekaart from '../object-map-situatiekaart/object-map-situatiekaart';

const MEDIA_VIEWER_MAP_NWN_COMPONENT_ATTR = 'data-media-viewer-map-nwn';
const MAP_TYPE_SELECT_SELECTOR = '[data-object-map-type-select]';
const MEDIA_VIEWER_MAP_NWN_CANVAS_SELECTOR = '[data-media-viewer-map-nwn-canvas]';
const MEDIA_VIEWER_MAP_NWN_CONFIG_SELECTOR = '[data-media-viewer-map-nwn-config]';

const MEDIA_VIEWER_MAP_NWN_ZOOM_CONTROLS_SELECTOR = '[data-media-viewer-map-nwn-zoom-controls]';
const MEDIA_VIEWER_MAP_NWN_ZOOM_ZOOMIN_SELECTOR = '[data-media-viewer-map-nwn-zoom-controls-in]';
const MEDIA_VIEWER_MAP_NWN_ZOOM_ZOOMOUT_SELECTOR = '[data-media-viewer-map-nwn-zoom-controls-out]';
const MEDIA_VIEWER_MAP_NWN_LEGENDS_SELECTOR = '[data-object-map-legend]';

const EVENT_COMPONENT_NAMESPACE = 'media-viewer-map-nwn';
const EVENT_MAP_TYPE_SELECT = `map-type-select-event-${EVENT_COMPONENT_NAMESPACE}`;
const EVENT_SHOW_LEGEND = `show-map-legend-${EVENT_COMPONENT_NAMESPACE}`;
const EVENT_CLOSE_DRAWER = 'event-situatiekaart-close-drawer';

const EVENT_MAP_ZOOM_CONTROLS = 'event-map-zoom-controls';

const DEFAULT_ZOOM_LEVEL = 16;
const MIN_ZOOM_LEVEL = 0;
const MAX_ZOOM_LEVEL = 21;

const IS_VISIBLE_CLASS = 'is-visible';
const IS_HIDDEN_CLASS = 'is-hidden';

const $window = $(window);

export default class MediaViewerMapNWN {

    constructor(element) {
        const component = this;

        component.$element = $(element);
        component.mapId = component.$element.attr(MEDIA_VIEWER_MAP_NWN_COMPONENT_ATTR);
        component.$mapTypeSelect = component.$element.find(MAP_TYPE_SELECT_SELECTOR);
        component.$canvas = component.$element.find(MEDIA_VIEWER_MAP_NWN_CANVAS_SELECTOR);
        component.config = JSON.parse(component.$element.find(MEDIA_VIEWER_MAP_NWN_CONFIG_SELECTOR).text());
        component.$mapLegends = component.$element.find(MEDIA_VIEWER_MAP_NWN_LEGENDS_SELECTOR);
        component.$mapZoomControls = component.$element.find(MEDIA_VIEWER_MAP_NWN_ZOOM_CONTROLS_SELECTOR);

        component.loadDependencies();
        component.bindEvents();
    }

    dispose() {
        $window.trigger(EVENT_CLOSE_DRAWER);
    }

    loadDependencies() {
        const component = this;

        gmapsAsyncLoad().then(() => {
            component.createMap();
            component.switchMapType(component.config.defaultMapType);
        });
    }

    bindEvents() {
        const component = this;

        $window.on(EVENT_MAP_TYPE_SELECT, (event, data) => {
            component.switchMapType(data.value);
        });

        $window.on(EVENT_SHOW_LEGEND, (event, data) => {
            component.showLegend(data.legendSelector);
        });

        $window.on(EVENT_MAP_ZOOM_CONTROLS, (event, data) => {
            component.$mapZoomControls.toggleClass(IS_HIDDEN_CLASS, data.hidden);
        });

        component.$mapZoomControls.on('click', MEDIA_VIEWER_MAP_NWN_ZOOM_ZOOMIN_SELECTOR, (event) => {
            component.zoomIn(event);
        });

        component.$mapZoomControls.on('click', MEDIA_VIEWER_MAP_NWN_ZOOM_ZOOMOUT_SELECTOR, (event) => {
            component.zoomOut(event);
        });
    }

    unbindEvents() {
        const component = this;

        $window.off(EVENT_MAP_TYPE_SELECT);
        $window.off(EVENT_SHOW_LEGEND);

        component.$mapZoomControls.off('click', MEDIA_VIEWER_MAP_NWN_ZOOM_ZOOMIN_SELECTOR);
        component.$mapZoomControls.off('click', MEDIA_VIEWER_MAP_NWN_ZOOM_ZOOMOUT_SELECTOR);
    }

    createMap() {
        const component = this;
        const google = window.google;
        const latLng = new google.maps.LatLng(component.config.lat, component.config.lng);

        component.map = new google.maps.Map(component.$canvas[0], component.getMapOptions());
        component.marker = component.getMarker(latLng);
        component.kadasterPlugin = new MapKadaster(component.map, component.config);
        component.streetviewPlugin = new MapStreetview(component.map, component.config, component.marker);
        component.situatiekaartPlugin = new MapSituatiekaart(component.map, component.config, component.marker, component.mapId);

        google.maps.event.addListener(component.map, 'click', () => {
            $window.trigger(EVENT_CLOSE_DRAWER);
        });

        google.maps.event.addListener(component.map, 'bounds_changed', () => {
            $window.trigger(EVENT_CLOSE_DRAWER);
        });
    }

    getMarker(latLng) {
        const component = this;
        const google = window.google;

        const marker = {
            url: component.config.markerUrl,
            anchor: new google.maps.Point(30, 54),
            scaledSize: new google.maps.Size(60, 62)
        };

        return new google.maps.Marker({
            position: latLng,
            map: component.map,
            title: component.config.markerTitle,
            icon: marker,
            shape: {
                coords: [8, 8, 52, 52],
                type: 'rect'
            },
            zIndex: 9999
        });
    }

    switchMapType(mapType) {
        const component = this;

        component.map.overlayMapTypes.clear();
        component.map.data.setMap(null);
        component.streetviewPlugin.setStreetview(false);
        component.hideLegends();
        component.removeAttributions();

        switch (mapType) {
            case 'default':
            case 'roadmap':
                component.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
                break;

            case 'satellite':
                component.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
                break;

            case 'kadastraal':
                component.kadasterPlugin.enableKadaster(EVENT_COMPONENT_NAMESPACE);
                break;

            case 'streetview':
                component.streetviewPlugin.setStreetview(true);
                break;
        }

        component.updateGTMMapType(mapType);
    }

    getMapOptions() {
        const config = this.config;
        const google = window.google;

        return {
            zoom: config.zoom || DEFAULT_ZOOM_LEVEL,
            center: new google.maps.LatLng(config.lat, config.lng),
            scrollwheel: true,
            gestureHandling: 'greedy',

            // Map control options
            disableDefaultUI: true,
            mapTypeControlOptions: {
                mapTypeIds: [
                    google.maps.MapTypeId.ROADMAP,
                    google.maps.MapTypeId.SATELLITE,
                    'kadastraal'
                ]
            },
            mapTypeId: config.defaultMapType,
            scaleControl: true,
            overviewMapControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            },

            clickableIcons: false,

            // Remove POI
            styles: [
                {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                }
            ]
        };
    }

    zoomIn(event) {
        const component = this;
        const currentZoomLevel = component.map.getZoom();

        event.preventDefault();

        if (currentZoomLevel !== MAX_ZOOM_LEVEL) {
            component.map.setZoom(currentZoomLevel + 1);
        }
    }

    zoomOut(event) {
        const component = this;
        const currentZoomLevel = component.map.getZoom();

        event.preventDefault();

        if (currentZoomLevel !== MIN_ZOOM_LEVEL) {
            component.map.setZoom(currentZoomLevel - 1);
        }
    }

    removeAttributions() {
        this.map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].clear();
    }

    showLegend(selector) {
        this.$element.find(selector).addClass(IS_VISIBLE_CLASS);
    }

    hideLegends() {
        this.$mapLegends.removeClass(IS_VISIBLE_CLASS);
    }

    updateGTMMapType(mapTypeName) {
        if (window.gtmDataLayer !== undefined) {
            gtmDataLayer.push({
                'event': 'mapTypeChanged',
                'mapTypeName': mapTypeName
            });
        }
    }

}

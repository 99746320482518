import $ from 'jquery';
import find from 'lodash/find';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import {changeDrawer, setMediaViewerHash} from '../store/actions';
import {
    DRAWER_MAXIMISED,
    DRAWER_MINIMISED,
    DRAWER_HIDDEN,
    DRAWER_SEMI
} from '../store/constants/drawer-states';
import {
    POI_DETAIL_DRAWER
} from '../store/constants/drawer-names';

import { default as gaLog, EVENT_TYPE } from '../media-viewer-map/media-viewer-gtm-events';

const DRAWER_HANDLE_SELECTOR = '[data-drawer-handle]';
const DRAWER_DISMISS_SELECTOR = '[data-drawer-dismiss]';
const DRAWER_EXPAND_SELECTOR = '[data-drawer-expand]';
const OVERVIEW_DRAWER_SELECTOR = '[data-object-map-drawer-clickable-area]';
const DRAWER_SWIPE_OVERRIDE_SELECTOR = '[data-drawer-swipe-override]';
const DRAWER_KEYBOARD_DISMISS_ON_SCROLL = '[data-drawer-dismiss-keyboard-on-scroll]';
const ICON_DRAWER_SELECTOR = '[data-drawer-icon]';


const EVENT_MEDIA_VIEWER_DRAWER_EXPANDED = 'media-viewer-drawer-expanded';

const MAXIMISED_CLASS = 'is-maximised';
const SEMI_CLASS = 'is-semi';
const MINIMISED_CLASS = 'is-minimised';
const ANIMATED_CLASS = 'is-animated';
const INACTIVE_CLASS = 'is-inactive';
const DRAWER_EXPANDED_CLASS = 'expanded';
const ICON_EXPANDED_CLASS = 'is-open';
const IS_HIDDEN_CLASS = 'is-hidden';
const ACTION_DISTANCE_THRESHOLD = 30; //px
const BP_MEDIUM = '750px';
const VISIBLE_HEIGHT_MINIMISED = 56; //px. Match SCSS $object-map-drawer-minimised-height
const VISIBLE_HEIGHT_SEMI = 285; //px. Match SCSS $object-map-drawer-semi-height

const SWIPE_LIMIT = {
    NONE: 1,
    UP: 2,
    DOWN: 3,
    ALL: 4,
};

export default class ObjectMapDrawer {
    constructor(element, store, name, openStateName) {
        console.assert(!!name, 'The name of the drawer should be specified');

        this.name = name;
        this.openStateName = openStateName || DRAWER_MAXIMISED;
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [`ui[${this.name}]`]);
        this.$element = $(element);
        this.$drawerHandle = this.$element.find(DRAWER_HANDLE_SELECTOR);
        this.$dismissButton = this.$element.find(DRAWER_DISMISS_SELECTOR);
        this.$expandButton = this.$element.find(DRAWER_EXPAND_SELECTOR);
        this.$swipeOverride = this.$element.find(DRAWER_SWIPE_OVERRIDE_SELECTOR);
        this.$drawerClickableArea = this.$element.find(OVERVIEW_DRAWER_SELECTOR);
        this.$hideKeyboardOnScrollElement = this.$element.find(DRAWER_KEYBOARD_DISMISS_ON_SCROLL);

        this.touchStartY = 0;
        this.moveDistanceScrollableElement = 0;
        this.moveDistanceDrawer = 0;
        this.touchOverridden = false;
        this.yPositionWhenOpen = null;
        this.yPositionWhenClosed = null;
        this.inLargeView = null;
        this.subscribeToStore();
        this.bindEvents();
        this.layout();
    }

    subscribeToStore() {
        this.connect.subscribe((state, prevState) => {
            if (state.ui[this.name] !== prevState.ui[this.name]) {
                this.layout();
            }
        });
    }

    bindEvents() {
        this.$drawerHandle
            .on('click', () => {
                this.handleDrawerAction();
                if (this.isOpen() && this.name === POI_DETAIL_DRAWER) {
                    gaLog.travelTime(EVENT_TYPE.MOBILE_DETAIL_DRAWER_COLLAPSE, 'mobile-detail-drawer-collapse');
                } else {
                    gaLog.travelTime(EVENT_TYPE.MOBILE_DETAIL_DRAWER_EXPAND, 'mobile-detail-drawer-expand');
                }
            });

        this.$expandButton
            .on('click', () => {
                this.handleDrawerAction();
                this.store.dispatch(setMediaViewerHash('kaart'));
            });

        this.$dismissButton
            .on('click', () => {
                this.handleDrawerDismiss();
                this.store.dispatch(setMediaViewerHash('kaart'));
            });

        this.$drawerClickableArea.on('click', this.onDrawerClick.bind(this));

        $(document).ready(this.checkUntilElementIsVisible.bind(this));

        let mediaQuery = window.matchMedia(`(min-width: ${BP_MEDIUM})`);

        mediaQuery.addListener((mediaQueryListener) => {
            this.inLargeView = mediaQueryListener.matches;
            if (mediaQueryListener.matches) {
                this.unbindTouchEvents();
                this.showDrawerClickableArea();
            } else {
                this.bindTouchEvents();
                this.removeDrawerClickableArea();
            }
        });

        this.inLargeView = mediaQuery.matches;

        if (mediaQuery.matches) {
            this.$element.addClass(INACTIVE_CLASS);
        } else {
            this.bindTouchEvents();
        }
    }

    onDrawerClick() {
        if (!this.inLargeView) {
            gaLog.travelTime(EVENT_TYPE.MOBILE_OVERVIEW_DRAWER_TAP_GRIP, 'mobile-overview-drawer-tap-grip');
        }
        this.handleDrawerAction();
        this.store.dispatch(setMediaViewerHash('kaart'));
    }

    showDrawerClickableArea() {
        this.$drawerClickableArea.on('click', this.onDrawerClick.bind(this));
        this.$drawerClickableArea.removeClass(IS_HIDDEN_CLASS);
    }

    removeDrawerClickableArea() {
        this.$drawerClickableArea.off('click');
        this.$drawerClickableArea.addClass(IS_HIDDEN_CLASS);
    }

    checkUntilElementIsVisible () {
        if (this.$element.is(':visible')) {
            this.$element.addClass(ANIMATED_CLASS);
        } else {
            setTimeout(this.checkUntilElementIsVisible.bind(this), 50);
        }
    }
    bindTouchEvents() {
        this.$element
            .on('touchstart', (e) => this.handleTouchStart(e))
            .on('touchmove', (e) => this.handleTouchMove(e))
            .on('touchend', () => this.handleTouchEnd())
            .on('touchcancel', () => this.handleTouchCancel());
    }

    unbindTouchEvents() {
        this.$element.off('touchstart touchmove touchend touchcancel');
    }

    handleTouchStart(e) {
        const touchObj = e.changedTouches[0];
        this.touchStartY = parseInt(touchObj.clientY, 10);
        this.$element.removeClass(ANIMATED_CLASS);

        this.yPositionWhenClosed = this.getDrawerMinimisedYPosition();
        this.yPositionWhenOpen = this.getDrawerOpenYPosition();

        if (this.$hideKeyboardOnScrollElement[0].contains(touchObj.target)) {
            this.$hideKeyboardOnScrollElement.focus();
        }
    }

    /**
     * Makes sure that, while dragging, the drawer is only repositioned within its area of movement
     * @param {TouchEvent} e
     */
    handleTouchMove(e) {
        const swipeLimit = this.getSwipeLimit(e.target);
        const touchObj = e.changedTouches[0];
        const touchMoveDistance = parseInt(touchObj.clientY, 10) - this.touchStartY;

        this.touchOverridden = false;

        if (swipeLimit !== SWIPE_LIMIT.NONE) {
            if ((swipeLimit === SWIPE_LIMIT.UP && touchMoveDistance > 0)
              || (swipeLimit === SWIPE_LIMIT.DOWN && touchMoveDistance < 0)
              || swipeLimit === SWIPE_LIMIT.ALL) {
                this.touchOverridden = true;
                this.moveDistanceScrollableElement = touchMoveDistance;
                return;
            }
        }

        this.moveDistanceDrawer = touchMoveDistance - this.moveDistanceScrollableElement;
        if (this.isOpen() && this.moveDistanceDrawer > 0 && this.moveDistanceDrawer <= this.yPositionWhenClosed) {
            this.positionDrawer(this.yPositionWhenOpen + this.moveDistanceDrawer);
        } else if (!this.isOpen() && this.moveDistanceDrawer < 0 && this.moveDistanceDrawer >= -this.yPositionWhenClosed) {
            this.positionDrawer(this.yPositionWhenClosed + this.moveDistanceDrawer);
        }
    }

    handleTouchEnd() {
        this.clearDrawerPositionCss();
        this.$element.addClass(ANIMATED_CLASS);

        if (!this.touchOverridden) {
            if ((this.isOpen() && this.moveDistanceDrawer > ACTION_DISTANCE_THRESHOLD)
                || (!this.isOpen() && this.moveDistanceDrawer < -ACTION_DISTANCE_THRESHOLD)) {
                this.handleDrawerAction();
            } else {
                this.layout();
            }
        }
        this.moveDistanceScrollableElement = 0;
        this.moveDistanceDrawer = 0;
        this.touchOverridden = false;
    }

    handleTouchCancel() {
        this.clearDrawerPositionCss();
        this.$element.addClass(ANIMATED_CLASS);
        this.layout();
        this.moveDistanceScrollableElement = 0;
        this.moveDistanceDrawer = 0;
        this.touchOverridden = false;
    }

    /**
     * Handles typical drawer action (open/close) and sets appropriate states
     * Typical states: DRAWER_MAXIMISED/SEMI to DRAWER_MINIMISED
     */
    handleDrawerAction() {
        if (this.isOpen()) {
            this.$drawerClickableArea.removeClass(DRAWER_EXPANDED_CLASS);
            this.$drawerHandle.find(ICON_DRAWER_SELECTOR).removeClass(ICON_EXPANDED_CLASS);
            this.store.dispatch(changeDrawer(this.name, DRAWER_MINIMISED));
            if (this.inLargeView) {
                gaLog.travelTime(EVENT_TYPE.DESKTOP_OVERVIEW_DRAWER_COLLAPSE, 'desktop-overview-drawer-collapse');
            }
        } else {
            this.$drawerClickableArea.addClass(DRAWER_EXPANDED_CLASS);
            this.$drawerHandle.find(ICON_DRAWER_SELECTOR).addClass(ICON_EXPANDED_CLASS);
            this.store.dispatch(changeDrawer(this.name, this.openStateName));
            if (this.inLargeView) {
                gaLog.travelTime(EVENT_TYPE.DESKTOP_OVERVIEW_DRAWER_EXPAND, 'desktop-overview-drawer-expand');
            }
        }
    }

    handleDrawerDismiss() {
        this.store.dispatch(changeDrawer(this.name, DRAWER_HIDDEN));
        if (this.inLargeView) {
            gaLog.travelTime(EVENT_TYPE.DESKTOP_DETAIL_DRAWER_DISMISS, 'desktop-detail-drawer-dismiss');
        } else {
            gaLog.travelTime(EVENT_TYPE.MOBILE_DETAIL_DRAWER_DISMISS, 'mobile-detail-drawer-dismiss');
        }
    }

    /**
     * Layout. Hides all classes for drawer visible state as default. Additional actions taken for hiding the detail
     * drawer in the larger view.
     */
    layout() {
        const drawerPosition = this.store.getState().ui[this.name];
        const hidingDetailDrawerInLargeView = drawerPosition === DRAWER_HIDDEN
            && this.inLargeView
            && this.name === POI_DETAIL_DRAWER;

        if (this.name === 'poi_overview_drawer' && drawerPosition === 'drawer_hidden') {
            this.$drawerHandle.find(ICON_DRAWER_SELECTOR).removeClass(ICON_EXPANDED_CLASS);
        }

        this.hideDrawer();
        if (hidingDetailDrawerInLargeView) {
            this.additionalHideHandlingLargeView();
        } else {
            this.showDrawer(drawerPosition);
        }
    }

    /**
     * Used to set the drawer to any visible position. In large view, special care is taken via requestAnimationFrame()
     * in order to separate two class changes.
     * @param {string} drawerPosition (expecting DRAWER_MAXIMISED, DRAWER_SEMI, DRAWER_MINIMISED)
     */
    showDrawer(drawerPosition) {
        new Promise((resolve) => {
            if (this.inLargeView) {
                this.$element.removeClass(INACTIVE_CLASS);
                window.requestAnimationFrame(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        }).then(() => {
            switch (drawerPosition) {
                case DRAWER_MAXIMISED:
                    this.maximiseDrawer();
                    break;
                case DRAWER_SEMI:
                    this.semiDrawer();
                    break;
                case DRAWER_MINIMISED:
                    this.minimiseDrawer();
                    break;
            }
        });
    }

    /**
     * Make the detail drawer not capture clicks in its hidden state.
     */
    additionalHideHandlingLargeView() {
        this.$element.one('transitionend', () => {
            this.$element.addClass(INACTIVE_CLASS);
        });

        setTimeout(() => {
            //remove handler if not triggered. Happens when closing media viewer.
            this.$element.off('transitionend');
        }, 1000);
    }


    /**
     * On desktop it should always set the focus on the suggest input when the drawer is maximised.
     */
    maximiseDrawer() {
        this.$element.addClass(MAXIMISED_CLASS);
        if (this.isDesktop()) {
            $(window).trigger(EVENT_MEDIA_VIEWER_DRAWER_EXPANDED);
        }
    }

    semiDrawer() {
        this.$element.addClass(SEMI_CLASS);
    }

    minimiseDrawer() {
        this.$element.addClass(MINIMISED_CLASS);
    }

    hideDrawer() {
        this.$element.removeClass(`${MAXIMISED_CLASS} ${MINIMISED_CLASS} ${SEMI_CLASS}`);
    }

    positionDrawer(yValue) {
        this.$element.css({transform: `translate3d(0, ${yValue}px, 0)`});
    }

    /**
     * Unset applied style to allow the style set by CSS to have specificity
     */
    clearDrawerPositionCss() {
        this.$element.css('transform', '');
    }

    /**
     * @returns {boolean} true if the drawer is in one of the designated 'open' states
     */
    isOpen() {
        const state = this.store.getState();
        return [DRAWER_MAXIMISED, DRAWER_SEMI].indexOf(state.ui[this.name]) > -1;
    }

    /**
     * @returns {number} of pixels the drawer, in minimised state, is translated down along the positive Y-axis
     */
    getDrawerMinimisedYPosition() {
        const height = this.$element[0].getBoundingClientRect().height;
        return height - VISIBLE_HEIGHT_MINIMISED;
    }

    /**
     * @returns {number} of pixels the drawer, in its specific open state, is translated down along the positive Y-axis
     */
    getDrawerOpenYPosition() {
        if (this.openStateName === DRAWER_MAXIMISED) {
            return 0;
        } else {
            const height = this.$element[0].getBoundingClientRect().height;
            return height - VISIBLE_HEIGHT_SEMI;
        }
    }

    /**
     * @param element
     * @returns {number} swipe limit: the kind of limit that is applicable to the touched element. When touch-start happens on
     *          non-scrollable element no limits are imposed (SWIPE_LIMIT.NONE). If you touch-start on a scrollable
     *          element the correct swipe limit is returned
     */
    getSwipeLimit(element) {
        if (!this.inScrollableElement(element)) {
            return SWIPE_LIMIT.NONE;
        }

        const activeContainer = find(this.$swipeOverride, (cNode) => {
            return cNode.contains(element);
        });
        if (activeContainer.scrollTop === 0) {
            return SWIPE_LIMIT.DOWN;
        }
        if (activeContainer.scrollTop === activeContainer.scrollHeight - activeContainer.clientHeight) {
            return SWIPE_LIMIT.UP;
        }

        return SWIPE_LIMIT.ALL;
    }

    inScrollableElement(element) {
        let inOverride = false;
        this.$swipeOverride.each(function() {
            if (this.contains(element)) {
                inOverride = true;
            }
        });
        return inOverride;
    }
    isDesktop() {
        const minDesktopWidth = 749;
        return window.innerWidth > minDesktopWidth;
    }

}

/* global google */

import VoorzieningenMapType from './object-map-voorzieningen-map-type';
import Connect from '../store/connect';

const MIN_ZOOM_LEVEL_FEATURES = 13;

export default class MapVoorzieningen {
    constructor(map, config, store) {
        this.store = store;
        this.map = map;
        this.config = config;

        this.connect = new Connect(this.store, [
            'ui.selectedCategories'
        ]);

        this.isBaseUrlVoorzieningenAvailable = Boolean(this.config.baseUrlVoorzieningen);

        if (this.isBaseUrlVoorzieningenAvailable) {
            this.voorzieningenMapType = new VoorzieningenMapType(this.map, this.config, this.store);
            this.subscribeToStore();
            this.bindEvents();

            const state = this.store.getState();

            if (state.ui.selectedCategories.length) {
                this.showOverlayMapType();
            }
        }

        this.showOverlayMapTypeIfNeeded();
    }

    subscribeToStore() {
        this.connect
            .subscribe((state, prevState) => {
                const shouldShowLayer = state.ui.selectedCategories.length > 0 && prevState.ui.selectedCategories.length === 0;

                if (shouldShowLayer) {
                    this.showOverlayMapType();
                } else if (state.ui.selectedCategories.length === 0) {
                    this.hideOverlayMapType();
                }
            });
    }

    showOverlayMapTypeIfNeeded() {
        const currentZoomLevel = this.map.getZoom();
        if (currentZoomLevel < MIN_ZOOM_LEVEL_FEATURES) {
            this.hideOverlayMapType();
        } else {
            this.showOverlayMapType();
        }
    }

    bindEvents() {
        google.maps.event.addListener(this.map, 'zoom_changed', () => {
            this.showOverlayMapTypeIfNeeded();
        });
    }

    showOverlayMapType() {
        this.map.overlayMapTypes.insertAt(0, this.voorzieningenMapType);
    }

    hideOverlayMapType() {
        this.map.overlayMapTypes.clear();
    }
}

// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';
import URLparser from 'url-parse';
import objectAssign from 'object-assign';

export default MaandlastenIndicator;

// component configuration
const COMPONENT_SELECTOR = '[data-maandlasten-indicator]';
const FORM_SELECTOR = '[data-maandlasten-indicator-form]';
const URL_SMALL_ATTR = 'data-maandlasten-indicator-small';
const URL_LARGE_ATTR = 'data-maandlasten-indicator-large';
const viewport = $(window).width();
const bpMortgage = 643;

const KEY_CODE = {
    ENTER: 13
};

function MaandlastenIndicator(element) {
    const component = this;
    component.$element = $(element);
    component.$form = component.$element.find(FORM_SELECTOR);
    component.$input = component.$form.find('input');
    component.urlSmall = element.getAttribute(URL_SMALL_ATTR);
    component.urlLarge = element.getAttribute(URL_LARGE_ATTR);

    // activate the mask
    component.$input.blur();

    component.injectIframe();
    component.bindEvents();
}

MaandlastenIndicator.prototype.bindEvents = function() {
    const component = this;

    component.$input.on('keypress', (event) => {
        if (event.keyCode === KEY_CODE.ENTER) {
            // trigger submit manually here because otherwise the default behaviour will not be fired because of input-number.js mask
            component.$form.submit();
        }
    });

    component.$form.submit((e) => {
        e.preventDefault();
        let vraagprijs = component.$form.find('input').val();
        let parsedUrl = new URLparser(component.iframeUrl, true);

        parsedUrl.set('query', objectAssign({}, parsedUrl.query, {
            'vraagprijs': vraagprijs
        }));

        component.iframeUrl = parsedUrl.toString();
        component.populateIframeUrl();
    });
};

MaandlastenIndicator.prototype.injectIframe = function() {
    let component = this;
    let iframeEl = $('<iframe frameborder="0" width="100%"/>');
    iframeEl.appendTo(component.$element);
    component.iframe = component.$element.find('iframe');

    if (viewport >= bpMortgage && component.urlLarge !== null) {
        component.iframeUrl = component.urlLarge;
    } else {
        component.iframeUrl = component.urlSmall;
    }

    component.populateIframeUrl();
};

MaandlastenIndicator.prototype.populateIframeUrl = function() {
    var component = this;

    component.iframe.attr({
        src: component.iframeUrl,
        width: '100%',
    });
};

// turn all elements with the default selector into components
if (COMPONENT_SELECTOR.length) {
    $(COMPONENT_SELECTOR).each(function(index, element) {
        return new MaandlastenIndicator(element);
    });
}

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default AppSpinner;

// component configuration
var KEYFRAME_ANIMATION_SUPPORTED_CLASS = 'keyframe-animation-supported';
var VISIBLE_CLASS = 'is-visible';
var VISIBLE_DELAY = 1500;

function AppSpinner(element) {
    var component = this;

    component.$element = $(element);

    if (browserSupportsKeyframeAnimations()) {
        component.$element.addClass(KEYFRAME_ANIMATION_SUPPORTED_CLASS);
    }
}

function browserSupportsKeyframeAnimations() {
    // https://hacks.mozilla.org/2011/09/detecting-and-generating-css-animations-in-javascript/
    var supportsAnimation = false;
    var domPrefixes = ['Webkit', 'Moz', 'O', 'ms', 'Khtml'];
    var element = document.createElement('div');

    if (element.style.animationName !== undefined) {
        supportsAnimation = true;
    }

    if (supportsAnimation === false) {
        for (var i = 0; i < domPrefixes.length; i++) {
            if (element.style[ domPrefixes[i] + 'AnimationName' ] !== undefined) {
                supportsAnimation = true;
                break;
            }
        }
    }
    return supportsAnimation;
}

AppSpinner.prototype.show = function() {
    var component = this;

    component.spinnerDelay = window.setTimeout(function() {
        component.$element.addClass(VISIBLE_CLASS);
    }, VISIBLE_DELAY);
    return $.when();
};

AppSpinner.prototype.hide = function() {
    var component = this;

    window.clearTimeout(component.spinnerDelay);
    component.$element.removeClass(VISIBLE_CLASS);
    return $.when();
};

AppSpinner.prototype.isVisible = function() {
    var component = this;
    return component.$element.hasClass(VISIBLE_CLASS);
};

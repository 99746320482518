import $ from 'jquery';
import { POI_DETAIL_DRAWER } from '../store/constants/drawer-names';

import ObjectMapDrawer from './object-map-drawer';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import { getLocationById } from '../store/reducers';
import LocationDetail from '../location-detail/location-detail';
import DetailDrawerSaveLocationFormContainer from '../detail-drawer-save-location-form-container/detail-drawer-save-location-form-container';
import {DRAWER_SEMI} from '../store/constants/drawer-states';

const SAVE_LOCATION_URL_REGEX = /locatie\/(.*)\/bewaren/;

const DETAIL_DRAWER_SAVE_LOCATION_FORM_CONTAINER = '[data-detail-drawer-save-location-form-container]';
const LOCATION_DETAIL_SELECTOR = '[data-location-detail]';
const HIDDEN_CLASS = 'is-hidden';

const activeLocationIdSelector = state => state.ui.activeLocationId;

export default class ObjectMapDetailDrawer {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.personalPlaces',
            'entities.travelTimes',
            'ui.activeLocationId',
            'ui.mediaViewerRoute',
            'ui.modality'
        ]);
        this.$element = $(element);
        this.$detailDrawerSaveLocationContainer = this.$element.find(DETAIL_DRAWER_SAVE_LOCATION_FORM_CONTAINER);
        this.$locationDetail = $(LOCATION_DETAIL_SELECTOR, this.$element);

        this.drawer = new ObjectMapDrawer(this.$element, this.store, POI_DETAIL_DRAWER, DRAWER_SEMI);
        this.locationDetail = new LocationDetail(this.$locationDetail, this.store);
        this.detailDrawerSaveLocationFormContainer = new DetailDrawerSaveLocationFormContainer(this.$detailDrawerSaveLocationContainer);

        const state = this.store.getState();
        const location = getLocationById(state, activeLocationIdSelector(state));

        this.bindEvents();
        this.layout(location);
    }

    bindEvents() {
        this.connect.subscribe((state, prevState) => {
            const routeDataChanged = state.ui.modality !== prevState.ui.modality;
            const appRouteChanged = state.ui.mediaViewerRoute !== prevState.ui.mediaViewerRoute;
            const detailDataChanged = state.entities.personalPlaces !== prevState.entities.personalPlaces
                || state.entities.travelTimes !== prevState.entities.travelTimes;
            const locationIdChanged = activeLocationIdSelector(state) !== activeLocationIdSelector(prevState);

            if (routeDataChanged || appRouteChanged || detailDataChanged || locationIdChanged) {
                if (activeLocationIdSelector(state)) {
                    const location = getLocationById(state, activeLocationIdSelector(state));
                    this.layout(location);
                }
            }
        });
    }

    layout(location) {
        const state = this.store.getState();
        if (SAVE_LOCATION_URL_REGEX.test(state.ui.mediaViewerRoute)) {
            this.showSaveLocation(location);
        } else {
            this.showLocationDetail(location);
        }
    }

    showSaveLocation(location) {
        this.$detailDrawerSaveLocationContainer.removeClass(HIDDEN_CLASS);
        this.$locationDetail.addClass(HIDDEN_CLASS);
        this.detailDrawerSaveLocationFormContainer.setLocation(location);
    }

    showLocationDetail(location) {
        this.locationDetail.showLocation(location);

        this.$detailDrawerSaveLocationContainer.addClass(HIDDEN_CLASS);
        this.$locationDetail.removeClass(HIDDEN_CLASS);
    }

    showAddNewLocationForm() {
        this.showSaveLocation();
    }
}

import $ from 'jquery';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import Cookies from '../app-utils/app-utils-cookies';

import googleMapsLoader from '../gmaps-async/gmaps-async';
import '../notification/notification';
import PersonalPlaceMarkers from '../markers/personal-place-markers';
import FacilityMarkers from '../markers/facility-markers';
import MapRoute from '../map-route/map-route';
import MapVoorzieningen from '../object-map-voorzieningen/object-map-voorzieningen';
import MapKadaster from '../object-map-kadastraal/object-map-kadastraal';
import MapStreetview from '../object-map-streetview/object-map-streetview';
import MapSituatiekaart from '../object-map-situatiekaart/object-map-situatiekaart';
import {
    setActiveLocation,
    deleteLocation,
    setMediaViewerHash
} from '../store/actions';
import {
    hasActiveLocation,
    hasFacilityCategoriesSelected,
    selectedFacilityCategoriesChanged,
    activeLocationChanged
} from '../store/utils/conditions';

import { hashToList } from '../store/utils/data-structures';
import MapTypeEventLogger from '../gtm-event-logger/event-loggers/map-type-event-logger';
import MapCreationEventLogger from '../gtm-event-logger/event-loggers/map-creation-event-logger';

const CLOSE_BUTTON_SELECTOR = '[data-media-viewer-close]';
const CANVAS_SELECTOR = '[data-media-viewer-map-canvas]';
const CONFIG_SELECTOR = '[data-media-viewer-map-config]';
const MEDIA_VIEWER_MAP_ZOOM_ZOOMIN_SELECTOR = '[data-media-viewer-map-zoom-controls-in]';
const MEDIA_VIEWER_MAP_ZOOM_ZOOMOUT_SELECTOR = '[data-media-viewer-map-zoom-controls-out]';
const MEDIA_VIEWER_MAP_CONTAINER = '.media-viewer-map-container';
const TRAVEL_MAP_ZOOM_CONTROLS_SELECTOR = '[data-media-viewer-map-zoom-controls]';
const LEGEND_SELECTOR = '[data-object-map-legend]';
const IS_IOS_CLASS = 'media-viewer-map--is-ios';
const COMMON_ROUTE_ERROR_SELECTOR = '[data-media-viewer-map-route-common-error]';
const LIMIT_ROUTE_ERROR_SELECTOR = '[data-media-viewer-map-route-limit-error]';
const NOTIFICATION_SELECTOR = '[data-notification]';
const FACILITY_ZOOM_INFORMATION_SELECTOR = '[data-media-viewer-map-route-facility-information]';
const SHOW_ROUTE_ERROR_EVENT = 'map-route-error-event';
const LIMIT_ROUTE_ERROR_TYPE = 'map-route-limit-error';
const HIDE_ALL_ROUTE_ERROR_TYPE = 'map-route-hide-error';

const DEFAULT_ZOOM_LEVEL = 16;
const MIN_ZOOM_LEVEL = 0;
const MAX_ZOOM_LEVEL = 21;
const FACILITY_ZOOM_LIMIT = 14;

const IS_HIDDEN_CLASS = 'is-hidden';
const IS_VISIBLE_CLASS = 'is-visible';
const $window = $(window);

const EVENT_COMPONENT_NAMESPACE = 'media-viewer-map';
const EVENT_MAP_TYPE_SELECT = `map-type-select-event-${EVENT_COMPONENT_NAMESPACE}`;
const EVENT_SHOW_LEGEND = `show-map-legend-${EVENT_COMPONENT_NAMESPACE}`;

const NOTIFICATION_COOKIE_KEY_ATTRIBUTE = 'data-notification-store-cookie-on-close';
const NOTIFICATION_COOKIE_KEY_SELECTOR = `[${NOTIFICATION_COOKIE_KEY_ATTRIBUTE}]`;

/**
 * We target iOS specifically for this component, sorry.
 * This is due to functionality that should be disabled when the height of a device is < 480px.
 *
 * Approach:
 * 1. Use CSS Media Queries to target orientation: landscape
 *    This doesn't work because Android devices detect landscape when opening the keyboard
 * 2. Use CSS Media Queries to target devices with a min-height of 480px
 *    This doesn't work because when the keyboard goes up, the remaining height is too small
 * 3. Use CSS Media Queries to target devices with a min-device-height of 480px
 *    This doesn't work because the min-device-height on iOS always detects the height for portrait mode.
 *    While on Android, the min-device-height is the height based on the orientation of the device.
 * 4. Current solution: use min-device-height for all devices, except iOS. Use orientation: landscape for iOS.
 *
 * Reference links:
 * - https://stackoverflow.com/q/8883163/363448
 * - https://www.quirksmode.org/blog/archives/2010/04/the_orientation.html
 */

const locationsListSelector = state => hashToList(state.entities.locations);
const locationsWithPersonalPlaceSelector = state =>
    locationsListSelector(state)
        .filter(location => !!location.personalPlace);

export default class MediaViewerMap {
    constructor(element, store, mapStreetView) {
        this.store = store || reduxStore;
        this.$element = $(element);
        this.$closeButton = $(CLOSE_BUTTON_SELECTOR);
        this.$canvas = this.$element.find(CANVAS_SELECTOR);
        this.config = this.$element.find(CONFIG_SELECTOR).text() ? JSON.parse(this.$element.find(CONFIG_SELECTOR).text()) : {};
        this.$mapLegend = this.$element.find(LEGEND_SELECTOR);
        this.$mapZoomControls = this.$element.find(TRAVEL_MAP_ZOOM_CONTROLS_SELECTOR);
        this.$commonError = this.$element.find(COMMON_ROUTE_ERROR_SELECTOR);
        this.$limitError = this.$element.find(LIMIT_ROUTE_ERROR_SELECTOR);
        this.$facilityZoomInfo = this.$element.find(FACILITY_ZOOM_INFORMATION_SELECTOR);
        this.facilityZoomCookieName = this.$facilityZoomInfo.find(NOTIFICATION_COOKIE_KEY_SELECTOR)[0].getAttribute(NOTIFICATION_COOKIE_KEY_ATTRIBUTE);
        this.mapTypeEventLogger = new MapTypeEventLogger();
        this.mapCreationEventLogger = new MapCreationEventLogger();

        this.connect = new Connect(this.store, [
            'ui.mediaViewerRoute',
            'ui.activeLocationId',
            'getPersonalPlaces.loaded',
            'getPersonalPlaces.error',
            'addPersonalPlace.loaded',
            'addPersonalPlace.error',
            'deletePersonalPlace.loaded',
            'ui.selectedCategories'
        ]);

        this.onPersonalPlaceMarkerClickedHandler = this.onPersonalPlaceMarkerClickedHandler.bind(this);

        // This fix is for resizing the viewport for mobile devices
        $(MEDIA_VIEWER_MAP_CONTAINER).height(window.innerHeight + 'px');

        if (MediaViewerMap.isIos()) {
            this.$element.addClass(IS_IOS_CLASS);
        }

        this
            .waitUntilGoogleMapsIsLoaded()
            .then(() => {
                this.google = window.google;
                this.gtmDataLayer = window.gtmDataLayer;

                const mapOptions = this.getMapOptions(this.config);
                this.map = this.createMapOnElement(this.$canvas[0], mapOptions);

                const huisLocation = this.config;
                this.addHuisMarkerToMap(huisLocation, this.config.markerUrl, this.config.markerTitle);

                this.personalPlaceMarkers = new PersonalPlaceMarkers(this.google.maps, this.map, this.config, this.store, this.onPersonalPlaceMarkerClickedHandler);
                this.facilityMarkers = new FacilityMarkers(this.google.maps, this.map, this.config, this.store);
                this.mapRoute = new MapRoute(this.google.maps, this.map, this.config, this.store);

                this.kadasterPlugin = new MapKadaster(this.map, this.config);
                this.streetviewPlugin = mapStreetView || new MapStreetview(this.map, this.config, this.marker);
                this.situatiekaartPlugin = new MapSituatiekaart(this.map, this.config, this.marker, this.mapId);
                this.voorzieningenPlugin = new MapVoorzieningen(this.map, this.config, this.store);

                this.handleZoomNotification();
                this.subscribeToStore();
                this.bindEvents();
            });
    }

    bindEvents() {
        this.google.maps.event.addListener(this.map, 'idle', () => {
            this.google.maps.event.trigger(this.map, 'resize');
        });

        this.google.maps.event.addListener(this.map, 'zoom_changed', () => {
            this.handleZoomNotification();
        });

        $window
            .on(EVENT_MAP_TYPE_SELECT, (event, data) => {
                this.switchMapType(data.value);
            })
            .on(EVENT_SHOW_LEGEND, (event, data) => {
                this.showLegend(data.legendSelector);
            })
            .on(SHOW_ROUTE_ERROR_EVENT, (event, data) => {
                this.handleRouteError(data);
            });

        this.$mapZoomControls
            .on('click', MEDIA_VIEWER_MAP_ZOOM_ZOOMIN_SELECTOR, event => {
                this.zoomIn(event);
            })
            .on('click', MEDIA_VIEWER_MAP_ZOOM_ZOOMOUT_SELECTOR, event => {
                this.zoomOut(event);
            });
    }

    onPersonalPlaceMarkerClickedHandler(locationId) {
        $(document).trigger(SHOW_ROUTE_ERROR_EVENT, {type: HIDE_ALL_ROUTE_ERROR_TYPE});
        this.store.dispatch(setMediaViewerHash(`kaart/locatie/${locationId}`));
    }

    subscribeToStore() {
        this.connect.subscribe((state, prevState) => {
            // when the personal places are loaded after a place has been added
            if (
                !prevState.getPersonalPlaces.loaded &&
                state.getPersonalPlaces.loaded &&
                !state.getPersonalPlaces.error &&
                state.addPersonalPlace.loaded
            ) {
                const locations = locationsWithPersonalPlaceSelector(state);
                const lastLocationId = locations[locations.length - 1].id;
                this.store.dispatch(setActiveLocation(lastLocationId));
            }

            // delete any previous location marker after changing
            if (activeLocationChanged(state, prevState)
                && (
                    !prevState.entities.locations[prevState.ui.activeLocationId] ||
                    (
                        !prevState.entities.locations[prevState.ui.activeLocationId].personalPlace &&
                        !prevState.entities.voorzieningen[prevState.ui.activeLocationId]
                    )
                )) {
                this.store.dispatch(deleteLocation(prevState.ui.activeLocationId));
            }

            if (
                activeLocationChanged(state, prevState) && !hasActiveLocation(state) ||
                selectedFacilityCategoriesChanged(state, prevState)
            ) {
                this.handleZoomNotification();
            }
        });
    }

    waitUntilGoogleMapsIsLoaded() {
        return googleMapsLoader();
    }

    handleZoomNotification() {
        const errorIsVisible =
            this.isNotificationVisible(this.$limitError) ||
            this.isNotificationVisible(this.$commonError);
        const zoomNotificationDismissed = Cookies.getCookie(this.facilityZoomCookieName) !== undefined;
        const limitExceeded = this.map.getZoom() < FACILITY_ZOOM_LIMIT;
        const state = this.store.getState();

        const shouldShowZoomNotification =
            !zoomNotificationDismissed &&
            !errorIsVisible &&
            limitExceeded &&
            !hasActiveLocation(state) &&
            hasFacilityCategoriesSelected(state);

        this.setNotification(this.$facilityZoomInfo, shouldShowZoomNotification);
    }

    handleRouteError(data) {
        this.hideAllNotifications();

        if (data.type !== HIDE_ALL_ROUTE_ERROR_TYPE) { //in other words, an actual error
            const $notificationTarget = data.type === LIMIT_ROUTE_ERROR_TYPE ? this.$limitError : this.$commonError;
            this.setNotification($notificationTarget, true);
        }
    }

    /**
     * @param {jQuery} $notificationWrapper
     * @returns {boolean} the notification is visible
     */
    isNotificationVisible($notificationWrapper) {
        const $innerNotification = $notificationWrapper.find(NOTIFICATION_SELECTOR);

        return !$innerNotification.hasClass(IS_HIDDEN_CLASS);
    }

    /**
     * Show or hide the notification inside the supplied $wrapper element
     * @param {jQuery} $notificationWrapper
     * @param {boolean} show - indicates if the notification should be shown or hidden
     */
    setNotification($notificationWrapper, show) {
        const $innerNotification = $notificationWrapper.find(NOTIFICATION_SELECTOR);

        if (show) {
            $innerNotification.removeClass(IS_HIDDEN_CLASS);
        } else {
            $innerNotification.addClass(IS_HIDDEN_CLASS);
        }
    }

    hideAllNotifications() {
        this.setNotification(this.$facilityZoomInfo, false);
        this.setNotification(this.$commonError, false);
        this.setNotification(this.$limitError, false);
    }

    createMapOnElement(domElement, options) {
        this.mapCreationEventLogger.log('media-viewer-map');
        return new this.google.maps.Map(domElement, options);
    }

    addHuisMarkerToMap(location, markerUrl, markerTitle) {
        const latLng = new this.google.maps.LatLng(location.lat, location.lng);

        const icon = {
            url: markerUrl,
            anchor: new this.google.maps.Point(30, 54),
            scaledSize: new this.google.maps.Size(60, 62)
        };

        this.marker = new this.google.maps.Marker({
            position: latLng,
            map: this.map,
            title: markerTitle,
            icon: icon,
            zIndex: 9999
        });
    }

    getMapOptions() {
        const google = window.google;
        const config = this.config;

        return {
            zoom: config.zoom || DEFAULT_ZOOM_LEVEL,
            center: new google.maps.LatLng(config.lat, config.lng),
            scrollwheel: true,
            gestureHandling: 'greedy',

            // Map control options
            disableDefaultUI: true,
            mapTypeControlOptions: {
                mapTypeIds: [
                    google.maps.MapTypeId.ROADMAP,
                    google.maps.MapTypeId.SATELLITE,
                    'kadastraal'
                ]
            },
            mapTypeId: config.defaultMapType,
            scaleControl: true,
            overviewMapControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            },
            clickableIcons: false,

            // Remove POI and Transit labels
            styles: [
                {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }]
                },
                {
                    featureType: 'transit',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }]
                }
            ]
        };
    }

    switchMapType(mapType) {
        this.map.overlayMapTypes.clear();
        this.map.data.setMap(null);
        this.streetviewPlugin.setStreetview(false);
        this.hideLegends();
        this.removeAttributions();

        switch (mapType) {
            case 'roadmap':
                this.map.setMapTypeId(this.google.maps.MapTypeId.ROADMAP);
                break;

            case 'satellite':
                this.map.setMapTypeId(this.google.maps.MapTypeId.HYBRID);
                break;

            case 'kadastraal':
                this.kadasterPlugin.enableKadaster(EVENT_COMPONENT_NAMESPACE);
                break;

            case 'streetview':
                this.streetviewPlugin.setStreetview(true);
                break;
        }

        this.updateGTMMapType(mapType);
    }

    zoomIn(event) {
        event.preventDefault();

        const currentZoomLevel = this.map.getZoom();
        if (currentZoomLevel !== MAX_ZOOM_LEVEL) {
            this.map.setZoom(currentZoomLevel + 1);
        }
    }

    zoomOut(event) {
        event.preventDefault();
        const currentZoomLevel = this.map.getZoom();

        if (currentZoomLevel !== MIN_ZOOM_LEVEL) {
            this.map.setZoom(currentZoomLevel - 1);
        }
    }

    static isIos() {
        // via https://stackoverflow.com/a/9039885/363448
        return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    }

    removeAttributions() {
        this.map.controls[this.google.maps.ControlPosition.BOTTOM_RIGHT].clear();
    }

    showLegend(selector) {
        this.$element.find(selector).addClass(IS_VISIBLE_CLASS);
    }

    hideLegends() {
        this.$mapLegend.removeClass(IS_VISIBLE_CLASS);
    }

    updateGTMMapType(mapTypeName) {
        const component = this;
        component.mapTypeEventLogger.log(mapTypeName);
    }
}

import $ from 'jquery';

const COMPONENT_SELECTOR = '[data-search-sidebar]';
const FLYOUT_SELECTOR = '[data-filter-flyout]';
const SIDEBAR_HANDLE = '[data-sidebar-handle]';
const CLOSE_FLYOUT_SELECTOR = '[data-search-close-flyout]';
const OPEN_FLYOUT_CLASS = 'has-open-flyout';
const FLYOUT_CLOSE_EVENT = 'filterflyoutclose';
const FLYOUT_CLOSED_EVENT = 'filterflyoutclosed';
const FLYOUT_OPEN_EVENT = 'filterflyoutopened';
const IS_EXTENDABLE_CLASS = 'is-extendable';
const IS_EXTENDED_CLASS = 'is-extended';
const SIDEBAR_IS_EXTENDED = 'sidebar-is-extended';
const SIDEBAR_CLOSED_EVENT = 'sidebarclosed';
const ERROR_BAR_SELECTOR = '[data-error-bar]';

const FLYOUT_HAS_FILTER_SELECTOR = '[data-has-preview-filter]';
const FLYOUT_FILTER_OPEN_CLASS = 'has-preview-filter';

export default class SearchSidebar {
    constructor(element, htmlElement, windowObject) {
        this.$html = htmlElement ? $(htmlElement) : $('html');
        this.window = windowObject || window;

        this.scrollPosition = 0;

        this.$element = $(element);
        this.$form = this.$element.closest('form');

        this.initialize();
        this.bindEvents();
    }

    initialize() {
        this.$element.addClass(IS_EXTENDABLE_CLASS);
        this.close();
    }

    bindEvents() {
        this.$form.on(FLYOUT_OPEN_EVENT, FLYOUT_SELECTOR, () => {
            this.$element.addClass(OPEN_FLYOUT_CLASS);
            this.scrollPosition = this.$element.scrollTop();
            this.$element.animate({scrollTop: 0}, 0);
        });

        this.$form.on(FLYOUT_CLOSED_EVENT, FLYOUT_SELECTOR, () => {
            this.$element.removeClass(OPEN_FLYOUT_CLASS);
            this.$element.animate({scrollTop: this.scrollPosition}, 0);
        });

        this.$form.on('click', FLYOUT_HAS_FILTER_SELECTOR, () => {
            this.$element.toggleClass(FLYOUT_FILTER_OPEN_CLASS);
        });

        this.$form.on(FLYOUT_CLOSED_EVENT, FLYOUT_SELECTOR, () => {
            this.$element.removeClass(FLYOUT_FILTER_OPEN_CLASS);
        });

        this.$html.on('click', CLOSE_FLYOUT_SELECTOR, () => {
            this.$element.find(FLYOUT_SELECTOR).trigger(FLYOUT_CLOSE_EVENT);
        });

        this.$html.on('click', SIDEBAR_HANDLE, event => {
            event.preventDefault();
            this.toggleSidebarVisibility();
        });

        this.$html.on('click', (event) => {
            const $target = $(event.target);
            const targetSidebarHandle = $target.is(SIDEBAR_HANDLE);
            const targetInsideHandle = $target.closest(SIDEBAR_HANDLE).length > 0;
            const targetInsideSidebar = $target.closest(COMPONENT_SELECTOR).length > 0 && !targetSidebarHandle;
            const targetInsideErrorBar = $target.closest(ERROR_BAR_SELECTOR).length > 0;
            const targetFlyoutHandle = $target.is(CLOSE_FLYOUT_SELECTOR);

            // The `isPersonalPlaceListItem` check has been added as part of SF-16.
            // When a personal place is being removed, we don't wan't the sidebar to close.
            // Without this check, the `targetInsideSidebar` would equal false because the target itself would be
            // removed already, before performing this 'sidebar-close-logic'.
            const isPersonalPlaceListItem = $target.closest('li.personal-place').length > 0;

            if (!targetSidebarHandle && !targetInsideHandle && !targetInsideSidebar &&
                !targetInsideErrorBar && !targetFlyoutHandle && !isPersonalPlaceListItem && this.isExtended) {
                this.close();
            }
        });

        $(this.window).on('sidebar-refresh', () => {
            const $newElement = $(COMPONENT_SELECTOR);
            this.sidebarRefreshHandler($newElement);
        });
    }

    toggleSidebarVisibility() {
        if (this.isExtended) {
            this.close();
        } else {
            this.open();
        }
    }

    sidebarRefreshHandler($newElement) {
        if ($newElement.length !== 1) {
            return;
        }

        this.$element = $newElement;
        this.initialize();
    }

    open() {
        this.isExtended = true;
        this.$element.addClass(IS_EXTENDED_CLASS);
        this.$html.addClass(SIDEBAR_IS_EXTENDED);
        this.window.parent.postMessage('isSidebarOpen=true', '*');
    }

    close() {
        this.window.scrollTo(0, 0);
        this.isExtended = false;
        this.$element.removeClass(IS_EXTENDED_CLASS);
        this.$element.trigger(SIDEBAR_CLOSED_EVENT);
        this.$html.removeClass(SIDEBAR_IS_EXTENDED);
        this.window.parent.postMessage('isSidebarOpen=false', '*');
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new SearchSidebar(element));

export default Menu;

import $ from 'jquery';

var MEDIA_VIEWER_SELECTOR = '.media-viewer';
var MENU_TOGGLE = 'data-menu-toggle';
var MENU_TOGGLE_SELECTOR = '[' + MENU_TOGGLE + ']';
var MENU_DROPUP_TITLE_SELECTOR = '[data-dropup-title]';
var MENU_ITEMS_SELECTOR = '[data-menu-items]';
var MENU_SUB_ITEMS = 'data-menu-sub-items';
var MENU_SUB_ITEMS_SELECTOR = '[' + MENU_SUB_ITEMS + ']';
var MENU_ITEM = 'data-menu-item-param';
var MENU_ITEM_SELECTOR = '[' + MENU_ITEM + ']';
var ACTIVE_CLASS = 'active';
var ACTIVE_CLASS_SELECTOR = '.' + ACTIVE_CLASS;
var OPEN_CLASS = 'open';
var MORE_TOGGLE_TEMPLATE_SELECTOR = '[data-menu-more-toggle-template]';

function Menu($headerElement, $footerElement) {
    this.$headerElement = $headerElement;
    this.$footerElement = $footerElement;
    this.moreTemplate = $headerElement.find(MORE_TOGGLE_TEMPLATE_SELECTOR).html();

    var $footerMenuItems = $footerElement.find(MENU_ITEMS_SELECTOR);

    $(MEDIA_VIEWER_SELECTOR).on('click', function() {
        $headerElement.find(MENU_SUB_ITEMS_SELECTOR).removeClass(OPEN_CLASS);

        var subItems = $headerElement.find(MENU_SUB_ITEMS_SELECTOR);
        if (subItems.find(ACTIVE_CLASS_SELECTOR).length === 0) {
            subItems.removeClass(ACTIVE_CLASS);
        }

        $footerMenuItems.removeClass(OPEN_CLASS);
        $footerElement.find(MENU_TOGGLE_SELECTOR).removeClass(ACTIVE_CLASS);
    });

    $headerElement.find(MENU_ITEM_SELECTOR).on('click', function() {
        var $el = $(this);
        $headerElement.find(MENU_ITEM_SELECTOR).removeClass(ACTIVE_CLASS);
        $headerElement.find(MENU_TOGGLE_SELECTOR).removeClass(ACTIVE_CLASS);
        $el.addClass(ACTIVE_CLASS);

        if ($el.parents(MENU_SUB_ITEMS_SELECTOR).length > 0) {
            $headerElement.find(MENU_TOGGLE_SELECTOR).addClass(ACTIVE_CLASS);
            $headerElement.find(MENU_SUB_ITEMS_SELECTOR).removeClass(OPEN_CLASS);
        }

        $(window).trigger('mediaviewermenuitemselected',
            {
                type: this.getAttribute('data-view-type'),
                menuParam: this.getAttribute(MENU_ITEM)
            });
    });

    $footerElement.find(MENU_TOGGLE_SELECTOR).on('click', function(e) {
        var $el = $(this);
        $el.toggleClass(ACTIVE_CLASS);

        $footerMenuItems.toggleClass(OPEN_CLASS);
        e.stopPropagation();
    });

    $footerElement.find(MENU_ITEM_SELECTOR).on('click', function() {
        var $el = $(this);
        $footerElement.find(MENU_DROPUP_TITLE_SELECTOR).html($el.html());
        $footerMenuItems.find(MENU_ITEM_SELECTOR).removeClass(ACTIVE_CLASS);
        $el.addClass(ACTIVE_CLASS);

        $(window).trigger('mediaviewermenuitemselected',
            {
                type: this.getAttribute('data-view-type'),
                menuParam: this.getAttribute(MENU_ITEM)
            });
    });
}

Menu.prototype.setActive = function(id) {
    var MENU_ACTIVE_ITEM_SELECTOR = '[' + MENU_ITEM + '="' + id + '"' + ']';

    // header menu
    this.$headerElement.find(MENU_ITEM_SELECTOR).removeClass(ACTIVE_CLASS);
    this.$headerElement.find(MENU_ACTIVE_ITEM_SELECTOR).addClass(ACTIVE_CLASS);

    // footer menu
    this.$footerElement.find(MENU_ITEM_SELECTOR).removeClass(ACTIVE_CLASS);
    var $footerItem = this.$footerElement.find(MENU_ACTIVE_ITEM_SELECTOR);
    $footerItem.addClass(ACTIVE_CLASS);
    this.$footerElement.find(MENU_DROPUP_TITLE_SELECTOR).html($footerItem.html());
};

Menu.prototype.toggleMore = function(event) {
    var $el = $(this);
    $el.parent().toggleClass(ACTIVE_CLASS);
    $el.next().toggleClass(OPEN_CLASS);
    event.stopPropagation();
};

/**
 * Transforms initial menu with UL+LI structure to collapse menu with 'more' button.
 */
Menu.prototype.transformHeader = function() {
    var $menu = this.$headerElement.find(MENU_ITEMS_SELECTOR);
    var hasMenuItems = $menu.find(MENU_ITEM_SELECTOR).length > 0;
    var ulWidth = $menu.width();
    if (ulWidth === 0 || hasMenuItems === false) {
        return;
    }

    var parentWidth = this.$headerElement.parent().width();

    var floors = [];
    while (((parentWidth - ulWidth) / 2) < 220) {
        floors.push($menu.children().last().detach());
        ulWidth = $menu.width();
    }

    if (floors.length > 0) {
        floors.push($menu.children().last().detach());

        var more = $(this.moreTemplate);
        more.find(MENU_TOGGLE_SELECTOR).on('click', this.toggleMore);

        $.each(floors.reverse(), function(index, item) {
            more.find(MENU_SUB_ITEMS_SELECTOR).append(item);
        });

        $menu.append(more);
    }
};

Menu.prototype.dispose = function() {
    $(MEDIA_VIEWER_SELECTOR).off('click');
    this.$headerElement.find(MENU_ITEM_SELECTOR).off('click');
    this.$footerElement.find(MENU_TOGGLE_SELECTOR).off('click');
    this.$footerElement.find(MENU_ITEM_SELECTOR).off('click');
};

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import isEqual from 'lodash/isEqual';
import Separator from '../thousand-separator/thousand-separator';

// what does this module expose?
export default Autocomplete;

// component configuration
const CLEAR_HANDLER_SELECTOR = '[data-autocomplete-clear]';
const DEFAULT_SELECTOR = '[data-autocomplete]';
const IS_ASYNC_ATTR = 'data-autocomplete-is-async';
const INPUT_SELECTOR = '[data-autocomplete-input]';
const DIRTY_CLASS = 'is-dirty';
const ITEM_TEMPLATE_SELECTOR = '[data-autocomplete-item-template]';
const NIVEAU_ID_ATTRIBUTE = 'data-autocomplete-niveau-id';
const PARENT_ID_ATTRIBUTE = 'data-autocomplete-parent-id';
const LIST_SELECTOR = '[data-autocomplete-list]';
const MATCH_CLASS = 'is-match';
const MATCH_SELECTOR = '[data-autocomplete-match]';
const MINLENGTH_ATTR = 'data-autocomplete-minlength';
const MINLENGTH = 3;
const OPENED_CLASS = 'is-open';
const OUTPUT_SELECTOR = '[data-autocomplete-output]';
const SELECTED_CLASS = 'is-selected';
const UPDATING_CLASS = 'is-updating';
const ALT_SUGGESTIONS_CLASS = 'has-alt-suggestions';
const NO_SUGGESTIONS_CLASS = 'has-no-suggestions';
const URL_SELECTOR = '[data-autocomplete-url]';
const TRACK_COMPONENT_ATTR = 'data-track-component-selector';
const TRACK_VALUE__ATTR = 'data-track-component-value';
const ALT_URL_SELECTOR = '[data-autocomplete-alt-url]';
const SUGGESTIONS_SELECTOR = '[data-autocomplete-suggestions]';
const THOUSAND_SEPARATOR = 'data-thousand-separator';

// trigger/listen event constants
const SEARCH_QUERY_UPDATED_EVENT = 'searchqueryupdated';
const SEARCH_QUERY_SET_EVENT = 'searchQuerySet';
const RESET_FILTER_EVENT = 'reset';
const SEARCH_INVALID_LOCATION_EVENT = 'invalidlocation';

// keyboard definitions
const KEY_CODE = {
    UP: 38,
    DOWN: 40,
    ENTER: 13,
    LEFT: 37,
    RIGHT: 39,
    ESCAPE: 27,
    BACKSPACE: 8,
    PLUS: 187,
    PLUSNUMERICPAD: 107,
    SHIFT: 16,
    CTRL: 17,
    COMMAND_FIREFOX: 224,
    COMMAND: 91
};

/**
 * @param {HTMLElement} element any block level HTML element
 */
function Autocomplete(element) {
    const component = this;
    component.$element = $(element);
    component.$form = component.$element.closest('form');
    component.isAsync = component.$element[0].hasAttribute(IS_ASYNC_ATTR);
    component.$input = component.$element.find(INPUT_SELECTOR);
    component.$suggestions = component.$element.find(SUGGESTIONS_SELECTOR);
    component.$list = component.$element.find(LIST_SELECTOR);
    component.$output = component.$element.find(OUTPUT_SELECTOR);
    component.$clearHandle = component.$element.find(CLEAR_HANDLER_SELECTOR);
    component.itemTemplate = component.$element.find(ITEM_TEMPLATE_SELECTOR).html();
    component.minLength = component.$input.attr(MINLENGTH_ATTR) || MINLENGTH;
    component.queryObjectIndex = 0;
    component.query = '';
    component.isOpen = false;
    component.options = [];
    component.selectedIndex = -1;
    component.$autoCompleteUrl = component.$element.find(URL_SELECTOR);
    component.$autoCompleteAltUrl = component.$element.find(ALT_URL_SELECTOR);
    component.url = component.$autoCompleteUrl.val();
    component.altUrl = component.$autoCompleteAltUrl.val();
    component.activeOptions = [];
    component.request = null;
    component.requestCount = 0;
    component.firstSubmitAttempt = false;
    component.trackComponentSelector = component.$element.attr(TRACK_COMPONENT_ATTR);
    component.trackComponentValue = component.$element.attr(TRACK_VALUE__ATTR);
    component.close();
    component.bindEvents();
    element.autoComplete = component;
    component.altSuggestionsIsOpen = false;
    component.noSuggestionsFoundIsOpen = false;
    component.separator = component.$element.attr(THOUSAND_SEPARATOR);
    // initialise server side set active options
    component.setActiveOptions();
}

/**
 *  Binds all necessary events for the autocomplete
 */

Autocomplete.prototype.bindEvents = function() {
    const component = this;

    // listen to submit event and determine if location is valid
    component.$form.on('submit', (event) => {
        if (component.trackedComponentAllowsSubmit() && !component.isValidLocation() && !component.firstSubmitAttempt) {
            // location is invalid and it's typed for the first time wrong
            event.preventDefault();
            component.searchAlternativeSuggestions();
            component.altSuggestionsIsOpen = true;
            return false;
        }
    });

    component.$form.on(SEARCH_INVALID_LOCATION_EVENT, () => {
        component.$suggestions.addClass(NO_SUGGESTIONS_CLASS);
        if (!component.isOpen) {
            component.open();
        }
    });

    component.$input.on('blur', () => {
        setTimeout(() => component.closeIfValidLocation(), 400);
    });

    component.$input.on('keydown', (e) => {
        switch (e.keyCode) {
            case KEY_CODE.ENTER:
                // there is no suggestion found and this is the second attempt, submit the form
                if (component.firstSubmitAttempt) {
                    component.$element.trigger(SEARCH_QUERY_UPDATED_EVENT);
                    return false;
                }
                // if you selected an item from the list then you can search for the next location
                else if (component.selectedIndex >= 0) {
                    component.altSuggestionsIsOpen = false;
                    component.$element.trigger(SEARCH_QUERY_UPDATED_EVENT, {isOpen: component.isOpen});
                    return false;
                }
                // if the first item is exact then that one can be automatically selected and the user can search on the next location
                else if (component.options.length && component.options[0].isExact && !component.altSuggestionsIsOpen) {
                    component.selectItem(0);
                    component.altSuggestionsIsOpen = false;
                    component.$element.trigger(SEARCH_QUERY_UPDATED_EVENT, {isOpen: component.isOpen});
                    return false;
                }
                // on a slow connection the request can be canceled by incrementing the request count so that the user can search on the next location and doesn't have to wait.
                else if (component.request && component.request.state() === 'pending') {
                    component.requestCount++; //reset request count
                    component.altSuggestionsIsOpen = false;
                    component.$element.trigger(SEARCH_QUERY_UPDATED_EVENT);
                    return false;
                }
                // else do an alternative search
                else {
                    component.searchAlternativeSuggestions();
                    component.altSuggestionsIsOpen = true;
                    return false;
                }
            case KEY_CODE.PLUS:
            case KEY_CODE.PLUSNUMERICPAD:
                var inputElement = this;
                // if you selected an item from the list then you can search for the next location
                if (component.selectedIndex >= 0) {
                    component.altSuggestionsIsOpen = false;
                    component.addPlus(inputElement);
                    // return false because we added the plus already
                    return false;
                }
                // if the first item is exact then that one can be automatically selected and the user can search on the next location
                else if (component.options.length && component.options[0].isExact && !component.altSuggestionsIsOpen) {
                    component.selectItem(0);
                    component.altSuggestionsIsOpen = false;
                    component.addPlus(inputElement);
                    return false;
                }
                // on a slow connection the request can be canceled by incrementing the request count so that the user can search on the next location and doesn't have to wait.
                else if (component.request && component.request.state() === 'pending') {
                    component.requestCount++; //reset request count
                    component.altSuggestionsIsOpen = false;
                    component.addPlus(inputElement);
                    return false;
                }
                // to prevent empty zbox addition of "+"
                else if (component.$input.val().length > 0) {
                    component.altSuggestionsIsOpen = false;
                    component.addPlus(inputElement);
                    // return false because we added the plus already
                    return false;
                }
                // else do an alternative search
                else {
                    component.searchAlternativeSuggestions();
                    component.altSuggestionsIsOpen = true;
                    return false;
                }
        }
    });

    component.$input.on('keyup', (e) => {
        component.setDirty();

        switch (e.keyCode) {
            case KEY_CODE.SHIFT:
                break;
            case KEY_CODE.LEFT:
                break;
            case KEY_CODE.RIGHT:
                break;
            case KEY_CODE.UP:
                if (component.isOpen) {
                    component.selectPrevItem();
                }
                break;
            case KEY_CODE.DOWN:
                if (component.isOpen) {
                    component.selectNextItem();
                }
                break;
            case KEY_CODE.BACKSPACE:
                component.altSuggestionsIsOpen = false;
                component.synchroniseActiveOptions();
                component.search();
                break;
            case KEY_CODE.ESCAPE:
                component.synchroniseActiveOptions();
                component.close();
                break;
            case KEY_CODE.ENTER:
            case KEY_CODE.PLUS:
            case KEY_CODE.PLUSNUMERICPAD:
                if (!component.altSuggestionsIsOpen) {
                    component.addNewActiveOption = true;
                    component.close();
                }
                break;
            case KEY_CODE.CTRL:
            case KEY_CODE.COMMAND:
            case KEY_CODE.COMMAND_FIREFOX:
                if (component.altSuggestionsIsOpen) {
                    // user is probably using the selecting or pasting shortcut
                    // the suggestion is visible, user is not selecting one, close the suggestions
                    component.altSuggestionsIsOpen = false;
                    component.close();
                }
                break;
            default:
                if (component.altSuggestionsIsOpen) {
                    // the suggestion is visible, user is not selecting one, close the suggestions
                    component.altSuggestionsIsOpen = false;
                    component.close();
                } else {
                    // alternate suggestion isn't open
                    component.synchroniseActiveOptions();
                    component.search();
                    component.setDummyOption();
                }
        }
    });

    component.$list.on('click', 'li', (event) => {
        const index = $(event.currentTarget).index();
        component.selectItem(index);
        component.$input.focus();
        component.$element.trigger(SEARCH_QUERY_UPDATED_EVENT, {isOpen: component.isOpen});
        component.close();
    });

    /**
     * prevents the mousedown event from propagating and causing the element
     * to lose focus before click is fired:
     */
    component.$clearHandle.on('mousedown', function (event) {
        event.preventDefault();
    }).on('click', function () {
        component.reset();
    });

    component.$input.on('focus', () => {
        component.setDirty();
    });

    component.$element.on(RESET_FILTER_EVENT, () => component.reset());

    component.$autoCompleteUrl.on('change', () => {
        component.url = component.$autoCompleteUrl.val();
    });

    component.$autoCompleteAltUrl.on('change', () => {
        component.altUrl = component.$autoCompleteAltUrl.val();
    });

    component.$element.on(SEARCH_QUERY_UPDATED_EVENT, () => {
        if (component.isAsync) {
            component.$input.blur(); // hide virtual keyboard on mobile devices
        }
    });
};

Autocomplete.prototype.trackedComponentAllowsSubmit = function() {
    const component = this;

    // are we tracking a component
    if (component.trackComponentSelector === undefined) return true;
    // is tracked component present
    let $trackedComponent = $(component.trackComponentSelector);
    if ($trackedComponent.length !== 1) return true;
    // does tracked component have correct value
    let trackedValue = $trackedComponent.val();
    return component.trackComponentValue === trackedValue;
};

/**
 * reset the autocomplete and empty the input field
 */
Autocomplete.prototype.reset = function() {
    const component = this;
    component.$input.val('');
    component.synchroniseActiveOptions();
    component.setDirty();
    component.close();
};

/**
 * Trim spaces, add a plus and force input to end of the input
 */
Autocomplete.prototype.addPlus = function(inputElement) {
    inputElement.$input.val(inputElement.$input.val().replace(/^\s+|\s?\+?\s+$/g, '') + ' + ');

    const inputLength = inputElement.$input.val().length;
    inputElement.selectionStart = inputLength;
    inputElement.selectionEnd = inputLength;
};

/**
 * Sets component.activeOptions based on input value, hidden ids input element, parentId and niveauId data attributes
 */
Autocomplete.prototype.setActiveOptions = function() {
    const component = this;
    const parentId = component.$input.attr(PARENT_ID_ATTRIBUTE);
    const niveauId = component.$input.attr(NIVEAU_ID_ATTRIBUTE);

    const inputValue = component.$input.val();
    const strippedInputValue = inputValue.replace(/(\s+)?\+(\s+)?/g, '+');
    const splittedInput = strippedInputValue.split('+');
    const splittedOutput = component.$output.val().split(',');

    splittedInput.forEach((value, index) => {
        component.activeOptions[index] = {
            value: value,
            id: splittedOutput[index],
            parentId: parentId,
            niveauId: niveauId
        };
    });
};

/**
 * Sets/removes the dirty state as a class in the html
 */
Autocomplete.prototype.setDirty = function() {
    const component = this;
    if (component.$input.val() === '') {
        component.$element.removeClass(DIRTY_CLASS);
    } else {
        component.$element.addClass(DIRTY_CLASS);
    }
};

/**
 * Determines the active query object based on the caret position,
 * also sets component.queryObjectIndex based on the matching of the caret position and the active options object
 * @return {Object} the active query object based on caret position
 */
Autocomplete.prototype.getQueryObjectOnPosition = function() {
    const component = this;
    const inputValue = component.$input.val();
    const splittedInput = inputValue.split('+');
    const caretPosition = component.getCaretPosition();

    let addedWordLength = 0;
    let activeQuery;
    for (var i = 0; i < splittedInput.length; i++) {
        var value = splittedInput[i];
        addedWordLength += value.length + 1;
        if (addedWordLength > caretPosition) {
            activeQuery = component.activeOptions[i];
            // store query object position
            component.queryObjectIndex = i;
            break;
        }
    }

    return activeQuery;
};

/**
 * Gets the relevant query string based on caret position
 * @return {String} query string
 */
Autocomplete.prototype.getQuery = function() {
    const component = this;
    if (component.caretPositionIsOnEnd()) {
        // would be better to do something like set query object index, because we only need component.queryObjectIndex to be set.
        // We do not need the return value
        component.getQueryObjectOnPosition();

        return component.getLastValue();
    } else {
        return component.getQueryObjectOnPosition().value;
    }
};

/**
 * Gets the caret position
 * @return {Number} the caret position
 */
Autocomplete.prototype.getCaretPosition = function() {
    const component = this;
    // assume for now we do not have something selected, because then selectionEnd should be taken into account
    return component.$input[0].selectionStart;
};

/**
 * Determines if the caret position is at the end of the input field
 * @return {Boolean} caret position is at end of input
 */
Autocomplete.prototype.caretPositionIsOnEnd = function() {
    const component = this;
    const caretPosition = component.getCaretPosition();
    const inputLength = component.$input.val().length;

    return caretPosition === inputLength;
};

/**
 * Opens the suggestion list
 */
Autocomplete.prototype.open = function() {
    const component = this;
    component.isOpen = true;
    component.$element.addClass(OPENED_CLASS);
    component.$list.addClass(OPENED_CLASS);
};

/**
 * CLoses the suggestion list
 */
Autocomplete.prototype.close = function() {
    const component = this;
    component.isOpen = false;
    component.altSuggestionsIsOpen = false;
    component.noSuggestionsFoundIsOpen = false;
    component.$element.removeClass(OPENED_CLASS);
    component.$list.removeClass(OPENED_CLASS);
};

Autocomplete.prototype.closeIfValidLocation = function() {
    const component = this;
    if (!component.noSuggestionsFoundIsOpen) {
        component.close();
    }
};

/**
 *  Synchronise activeOptions with input fields, call this function if there are bugs ¯\_(ツ)_/¯
 */
Autocomplete.prototype.synchroniseActiveOptions = function() {
    const component = this;
    const inputValue = component.$input.val();
    const strippedInputValue = inputValue.replace(/(\s+)?\+(\s+)?/g, '+');
    const splittedInput = strippedInputValue.split('+');

    // disable straal if multiple selections
    if (splittedInput.length <= 1 || (splittedInput.length === 2 && splittedInput[splittedInput.length - 1] === '')) {
        component.$element.trigger(SEARCH_QUERY_SET_EVENT, {multiple: false});
    } else {
        component.$element.trigger(SEARCH_QUERY_SET_EVENT, {multiple: true});
    }

    const filteredActiveOptions = component.activeOptions.map((option, index) => {
        if (splittedInput[index] && (splittedInput[index].trim() === option.value)) {
            return option;
        } else {
            return {};
        }
    });

    let mappedActiveOptions = [];
    component.activeOptions.forEach((option, index) => {
        if (filteredActiveOptions[index] === option) {
            mappedActiveOptions.push(option);
        } else if (splittedInput[index]) {
            mappedActiveOptions.push({id: 0, value: splittedInput[index]});
        }
    });

    component.activeOptions = mappedActiveOptions;

    // should we set dummy option?
    const activeOptionsChanged = !isEqual(component.activeOptions, filteredActiveOptions);

    // first reset output value
    component.$output.val('');
    // different value is typed, reset the first submit attempt
    component.firstSubmitAttempt = false;

    mappedActiveOptions.forEach((option, index) => {
        // update hidden input
        if (index === 0) {
            component.$output[0].value = option.id;
        } else {
            component.$output[0].value += ',' + option.id;
        }
    });

    // if last item in the splitted input array is a empty string then it means that the user has
    // used backspace untill the `+` and instead of replacing we should add a dummy option.
    if (splittedInput[splittedInput.length - 1] === '') {
        component.addNewActiveOption = true;
    }

    if (activeOptionsChanged) {
        component.setDummyOption();
    }
};

/**
 * Returns the last query string from the input
 * @return {String} last query string from the input
 */
Autocomplete.prototype.getLastValue = function() {
    const inputValue = this.$input[0].value;
    const strippedInputValue = inputValue.replace(/(\s+)?\+(\s+)?/g, '+');
    const splittedValues = strippedInputValue.split('+');
    return splittedValues[splittedValues.length - 1];
};

Autocomplete.prototype.constructQueryUrl = function(url, query) {
    const component = this;
    const firstSelectedOption = component.activeOptions[0];

    let niveauId = '';
    let parentId = '';
    if (firstSelectedOption && typeof (firstSelectedOption.niveauId) !== 'undefined' && typeof (firstSelectedOption.parentId) !== 'undefined') {
        niveauId = firstSelectedOption.niveauId;
        parentId = firstSelectedOption.parentId;
    }

    return url
        .replace('{queryPlaceholder}', query)
        .replace('{niveauPlaceholder}', niveauId)
        .replace('{parentPlaceholder}', parentId);
};

/**
 * Searches for suggestions
 */
Autocomplete.prototype.search = function() {
    const component = this;
    component.$suggestions
        .removeClass(NO_SUGGESTIONS_CLASS)
        .removeClass(ALT_SUGGESTIONS_CLASS);
    component.query = component.getQuery();

    if (component.query.length < component.minLength) {
        component.close();
        return;
    }
    component.selectedIndex = -1;
    component.options = [];

    const url = component.constructQueryUrl(component.url, component.query);
    component.$element.addClass(UPDATING_CLASS);

    component.request = $.ajax({
        url: url,
        requestCount: ++component.requestCount,
        dataType: 'jsonp',
        success: (response) => {
            if (component.requestCount !== this.requestCount) {
                return;
            }
            component.options = component.transformResponse(response);
            component.renderList();
            if (component.options.length) {
                component.open();
            } else {
                component.close();
            }
        },
        error: () => {
            component.options = [];
            component.renderList();
        }
    });
};

/**
 * Searches for alternative suggestions
 */
Autocomplete.prototype.searchAlternativeSuggestions = function() {
    const component = this;

    if (component.query.length < component.minLength) {
        component.close();
        return;
    }

    component.selectedIndex = -1;
    component.options = [];

    const url = component.constructQueryUrl(component.altUrl, component.query);
    component.$element.addClass(UPDATING_CLASS);

    component.request = $.ajax({
        url: url,
        requestCount: ++component.requestCount,
        dataType: 'jsonp',
        success: (response) => {
            if (component.requestCount !== this.requestCount) {
                return; // not the latest request so don't do anything
            }

            component.options = component.transformResponse(response);
            component.renderList();

            if (component.options.length) {
                component.$suggestions.addClass(ALT_SUGGESTIONS_CLASS);
            } else {
                component.firstSubmitAttempt = true; // first search submit event
                component.noSuggestionsFoundIsOpen = true;
                component.$suggestions.addClass(NO_SUGGESTIONS_CLASS);
            }
            component.open();
        },
        error: () => {
            component.options = [];
            component.renderList();
        }
    });
};

/**
 * Creates the html for the suggestion list
 * @return {String} html for suggestion list
 */
Autocomplete.prototype.renderList = function() {
    const component = this;
    let items = $('<ul>');
    component.selectedIndex = -1;
    component.options.forEach((option, index) => {
        option.id = option.id || component.$list[0].id + '-option' + index;

        // ¯\_(ツ)_/¯ ask backend
        if (option.niveauId !== 3 &&
            option.niveauId !== 5 &&
            option.niveauId !== 15) {
            option.parentId = '';
        }

        let item = component.renderOption(option);
        items.append(item);
    });
    const html = items.html();
    component.$list.html(html);
    component.$element.removeClass(UPDATING_CLASS);
    return html;
};

/**
 * Renders and returns an option as HTML using the item template.
 * Any option property can be used in this template using `{propName}`.
 * @param {Object} option
 * @returns {String} html
 */
Autocomplete.prototype.renderOption = function(option) {
    const component = this;

    let html = this.itemTemplate;
    // replace placeholders in template by values from option data
    for (var prop in option) {
        if (option.hasOwnProperty(prop)) {
            html = html.replace('{' + prop + '}', option[prop]);
        }
    }
    html = component.highlightMatches(html);
    return html;
};

/**
 * Select previous item in list, or last item when already at the top of the list.
 * @returns {{index, item, option}} object with index of selected item, the item itself and the related option.
 */
Autocomplete.prototype.selectPrevItem = function() {
    const component = this;
    let index = component.selectedIndex - 1;
    if (index < 0) {
        index = component.options.length - 1;
    }
    return component.selectItem(index);
};

/**
 * Select next item in list, or first item when already at the end of the list.
 * @returns {{index, item, option}} object with index of selected item, the item itself and the related option.
 */
Autocomplete.prototype.selectNextItem = function() {
    const component = this;
    let index = component.selectedIndex + 1;
    if (index > component.options.length - 1) {
        index = 0;
    }
    return component.selectItem(index);
};

/**
 * @param {Number} index
 * @returns {{index, item, option}} object with index of selected item, the item itself and the related option.
 */
Autocomplete.prototype.selectItem = function(index) {
    const component = this;
    const selectedOption = component.options[index];
    component.isDirty = (!isEqual(component.selectedOption, selectedOption));
    component.selectedOption = selectedOption;
    component.selectedIndex = index;

    // set selected state in autocomplete list
    const $items = component.$list.children();
    $items.removeClass(SELECTED_CLASS);
    $items.eq(index).addClass(SELECTED_CLASS);

    const activeOption = {
        id: selectedOption.identifier,
        value: selectedOption.displayValue,
        parentId: selectedOption.parentId,
        niveauId: selectedOption.niveauId
    };

    let lastIndex;
    if (component.activeOptions.length > 0) {
        lastIndex = component.queryObjectIndex;
    } else {
        lastIndex = 0;
    }

    // set the last value to the selected value
    component.activeOptions[lastIndex] = activeOption;

    component.updateActiveOptions();
};

/**
 * Sets an active option when no match with autosuggest has been made
 */
Autocomplete.prototype.setDummyOption = function() {
    const component = this;
    const inputValue = component.$input.val();
    const strippedInputValue = inputValue.replace(/(\s+)?\+(\s+)?/g, '+');
    const inputValues = strippedInputValue.split('+');
    const lastValue = inputValues[inputValues.length - 1];

    if (inputValue.length >= component.minLength) {
        component.isDirty = true;
    }

    // lastValue can be an empty string if key combinations are used
    if (!lastValue || (component.activeOptions.length && lastValue.trim() === component.activeOptions[component.activeOptions.length - 1].value)) {
        return;
    }

    const activeOption = {
        id: 0,
        value: lastValue.replace(/^\s+/g, '')
    };

    if (component.addNewActiveOption) {
        component.activeOptions.push(activeOption);
        // reset addNewActiveOption
        component.addNewActiveOption = false;
    } else {
        const lastIndex = component.activeOptions.length > 0 ? component.activeOptions.length - 1 : 0;
        component.activeOptions[lastIndex] = activeOption;
    }

    component.updateActiveOptions();
};

Autocomplete.prototype.updateActiveOptions = function() {
    const component = this;
    component.activeOptions.forEach((option, index) => {
        if (index === 0) {
            component.$input[0].value = option.value;
            component.$output[0].value = option.id;
        } else {
            component.$input[0].value += ' + ' + option.value;
            component.$output[0].value += ',' + option.id;
        }
    });
};

/**
 * Transform JSON response into manageable object
 * @param {Object} response JSON query response
 * @return {Object} transformed response
 */
Autocomplete.prototype.transformResponse = function(response) {
    const component = this;
    const options = [];

    response.Results.forEach((option) => {
        let identifier = option.GeoIdentifier;
        let name = option.Display.Naam.replace('+', ' ');
        let niveau = option.Display.Niveau ? (option.Display.Niveau + ' ') : '';
        let parent = option.Display.Parent ? (', ' + option.Display.Parent) : '';
        let count = option.Aantal ? Separator.format(option.Aantal, component.separator) : '';

        let displayValue = niveau + name + parent;

        options.push({
            identifier: identifier,
            value: name,
            label: name,
            description: option.Display.NiveauLabel + parent,
            count: count,
            niveauId: option.Niveau,
            parentId: option.Parent,
            displayValue: displayValue,
            isExact: option.Exact
        });
    });

    return options;
};


/**
 * Highlights parts of html string matching the current input value.
 * @param {String} html
 * @return {String} html with highlighted matches
 */
Autocomplete.prototype.highlightMatches = function(html) {
    const component = this;
    let item = $('<temp>').html(html);
    let pattern = component.query.replace(/[()]/gi, '');
    pattern = new RegExp(pattern, 'ig');
    item.find(MATCH_SELECTOR).each((index, element) => {
        let $element = $(element);
        let highlightedHtml = $element.html().replace(pattern, (match) => {
            return '<span class="' + MATCH_CLASS + '">' + match + '</span>';
        });
        $element.html(highlightedHtml);
    });
    return item.html();
};

Autocomplete.prototype.isValidLocation = function() {
    const component = this;
    if (component.options.length && component.options[0].isExact && !component.altSuggestionsIsOpen) {
        return true;
    }
    return component.$output.val() !== '0';
};

// automatically turn all elements with the default selector into components
$(DEFAULT_SELECTOR).each((index, element) => new Autocomplete(element));
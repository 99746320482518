export class GtmEventLogger {
    constructor(gtmDataLayer) {
        this.gtmDataLayer = gtmDataLayer || window.gtmDataLayer;
    }

    isAvailable() {
        return this.gtmDataLayer !== undefined;
    }

    log({ event = 'GAEvent', eventCategory, eventAction, eventLabel, eventValue, nonInteraction = false, ...rest } = {}) {
        if (!this.isAvailable()) {
            return;
        }

        this.gtmDataLayer.push({
            event: event,
            eventCategory: eventCategory,
            eventAction: eventAction,
            eventLabel: eventLabel,
            eventValue: eventValue,
            nonInteraction: nonInteraction,
            ...rest
        });
    }
}

export default new GtmEventLogger();

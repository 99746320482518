// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import AppliedFilters from '../applied-filters/applied-filters';

// what does this module expose?
export default MakelaarNameInput;

// component configuration
var COMPONENT_NAME = 'makelaars-name';
var MAKELAARS_NAME_ATTR = 'data-' + COMPONENT_NAME;
var COMPONENT_SELECTOR = '[' + MAKELAARS_NAME_ATTR + ']';

var CLEAR_HANDLE_SELECTOR = '[data-makelaars-name-clear-handle]';
var INPUT_SELECTOR = '[data-makelaars-name-input]';
var SUBMIT_EVENT = 'makelaarsnameupdated';
var KEY_CODE_ENTER = 13;
var DIRTY_CLASS = 'is-dirty';
var BLUR_UPDATE_INPUT_DELAY = 250; // ms

function MakelaarNameInput(element) {
    var component = this;
    component.$element = $(element);
    component.$input = component.$element.find(INPUT_SELECTOR);
    component.$clearHandle = component.$element.find(CLEAR_HANDLE_SELECTOR);
    component.inputValue = component.$input.val() || '';
    component.time = null;

    if (component.inputValue !== '') {
        component.addFilter(component.inputValue);
    }
    component.bindEvents();
}

MakelaarNameInput.prototype.bindEvents = function() {
    var component = this;

    component.$input.on('keydown', function(e) {
        if (e.keyCode === KEY_CODE_ENTER) {
            e.preventDefault();
            component.onUpdateInput();
        }
    });

    component.$input.on('keyup', function() {
        if (component.$input.val() === '') {
            component.clear();
        } else {
            component.$element.addClass(DIRTY_CLASS);
        }
    });

    component.$input.on('blur', function() {
        // if clear handle is clicked the input field is already been emptied; not a new filter will set
        component.time = setTimeout(function() {
            component.onUpdateInput();
        }, BLUR_UPDATE_INPUT_DELAY);
    });

    component.$element.on('resetfilter', function() {
        component.clear();
    });

    component.$clearHandle.on('click', function() {
        component.clear();
    });
};

/**
 * clears the field
 * @param {component} makelaarNameComponent; the makelaar name component it self
 */
MakelaarNameInput.prototype.clear = function(makelaarNameComponent) {
    var component = makelaarNameComponent || this;

    component.$input.val('');
    component.$element.removeClass(DIRTY_CLASS);
    AppliedFilters.remove(COMPONENT_NAME);
    component.$input.trigger(SUBMIT_EVENT);
};

MakelaarNameInput.prototype.onUpdateInput = function() {
    var component = this;
    component.updateFilter();
};

MakelaarNameInput.prototype.updateFilter = function() {
    var component = this;
    var newInputValue = component.$input.val();

    if (newInputValue !== '') {
        component.inputValue = newInputValue;
        component.addFilter(component.inputValue);
    }
    component.$input.trigger(SUBMIT_EVENT);
};

MakelaarNameInput.prototype.addFilter = function(name) {
    var component = this;
    var appliedFilter = {
        filterGroupId: COMPONENT_NAME,
        filterGroupName: component.$element.attr(MAKELAARS_NAME_ATTR),
        filterName: name,
        labelText: name
    };
    AppliedFilters.add(appliedFilter, function() {
        component.clear(component);
    }, true);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new MakelaarNameInput(element);
});

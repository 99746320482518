import '../expandible/expandible';
import $ from 'jquery';

const COMPONENT_ATTR = 'data-tooltip-inline';
const COMPONENT_SELECTOR = `[${COMPONENT_ATTR}]`;
const HANDLE = '[data-tooltip-handle]';
const VISIBLE_CLASS = 'is-expanded';

export default function TooltipInline(element) {
    const component = this;
    component.$element = $(element);
    component.$handler = this.$element.find(HANDLE);
    this.$handler.on('click', event => component.open(event));
    component.content = this.$element.attr('title');
    component.createTooltip();
}

TooltipInline.prototype.open = function (event) {
    const component = this;
    //prevent event bubbling
    event.preventDefault();
    event.stopPropagation();
    component.$element.addClass(VISIBLE_CLASS);
    this.$handler.off('click');
    $(window).on('click', closeEvent => component.close(closeEvent));
};

TooltipInline.prototype.close = function (event) {
    const component = this;
    event.stopPropagation();
    component.$element.removeClass(VISIBLE_CLASS);
    this.$handler.on('click', openEvent => component.open(openEvent));
};

TooltipInline.prototype.createTooltip = function () {
    const component = this;
    component.$tooltip = $('<div/>');
    component.$tooltip.addClass('tooltip__content');
    component.$tooltip.html(component.content);
    component.$element.append(component.$tooltip);
};

$(COMPONENT_SELECTOR).each((index, element) => new TooltipInline(element));

import $ from 'jquery';
import debounce from 'lodash/debounce';
import AutoSuggest from '../auto-suggest/auto-suggest';
import { default as gaLog, EVENT_TYPE } from '../../media-viewer-map/media-viewer-gtm-events';

const SUGGEST_ENDPOINT_DATA_ATTR = 'suggest-endpoint';
const COORDINATE_ENDPOINT_DATA_ATTR = 'coordinate-endpoint';
const AUTO_SUGGEST_SELECTOR = '[data-travel-time-suggest]';
const AUTO_SUGGEST_DEBOUNCE_TIME = 120;

export const SELECTOR = '[data-auto-suggest-locations]';

const noop = () => {};

export default class AutoSuggestLocations {
    constructor(element, onLocationSelectedHandler, onLocationClearedHandler, onAutoSuggestFocus, onSuggestingResultsChangedHandler) {
        this.onLocationSelectedHandler = onLocationSelectedHandler || noop;
        this.onSuggestingResultsChangedHandler = onSuggestingResultsChangedHandler || noop;
        this.onLocationClearedHandler = onLocationClearedHandler || noop;

        this.getSuggestions = this.getSuggestions.bind(this);
        this.getSuggestionsDebounced = debounce(this.getSuggestions, AUTO_SUGGEST_DEBOUNCE_TIME);
        this.getCoordinates = this.getCoordinates.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationClearedHandler = this.onLocationClearedHandler.bind(this);
        this.onSuggestingResultsChanged = this.onSuggestingResultsChanged.bind(this);

        this.$element = $(element);
        this.$autoSuggest = this.$element.find(AUTO_SUGGEST_SELECTOR);

        this.autoSuggest = new AutoSuggest(
            this.$autoSuggest,
            this.getSuggestionsDebounced,
            this.onLocationSelected,
            this.onLocationClearedHandler,
            onAutoSuggestFocus,
            this.onSuggestingResultsChanged
        );

        this.suggestEndpoint = this.$element.data(SUGGEST_ENDPOINT_DATA_ATTR);
        this.coordinateEndpoint = this.$element.data(COORDINATE_ENDPOINT_DATA_ATTR);

        // in case of a slow network, we don't want the results to be shown when the
        // results are loaded but the user has already lost focus on this element
        this._cancelAutoSuggestResultsResponseHandler = false;
    }

    onSuggestingResultsChanged(isSuggesting) {
        if (!isSuggesting) {
            this._cancelAutoSuggestResultsResponseHandler = true;
            this.getSuggestionsDebounced.cancel();
        }

        this.onSuggestingResultsChangedHandler(isSuggesting);
    }

    getSuggestions(query) {
        this._cancelAutoSuggestResultsResponseHandler = false;

        const queryIsEmpty = (!query || query.length < 1);

        return Promise
            .resolve(query)
            .then(theQuery => {
                if (queryIsEmpty) {
                    this.updateAutoSuggestResults([]);
                    this.autoSuggest.clearSuggestion();
                } else {
                    return this
                        .fetchSuggestions(theQuery)
                        .then(suggestions => {
                            if (this._cancelAutoSuggestResultsResponseHandler) {
                                return;
                            }

                            this.updateAutoSuggestResults(suggestions);
                        });
                }
            })
            .catch(() => {
                this.updateAutoSuggestResults([]);
            });
    }

    fetchSuggestions(query) {
        return $
            .ajax({
                url: this.suggestEndpoint,
                data: { query },
                dataType: 'json'
            })
            .then(data => this.mapSuggestionResponseToAutoSuggestFormat(data.result));
    }

    onLocationSelected(location) {
        return this
            .getCoordinates(location)
            .then(coordinates => {
                this.autoSuggest.setValue(location.value);

                this.onLocationSelectedHandler({
                    location: location.value,
                    placeId: location.key,
                    coordinates: coordinates
                });
            });
    }

    getCoordinates(location) {
        gaLog.travelTime(EVENT_TYPE.COORDINATE, location.value);

        return $
            .ajax({
                url: this.coordinateEndpoint,
                data: { placeId: location.key },
                dataType: 'json'
            })
            .then(response => response.result)
            .then(result => ({
                lat: result.Lat,
                lng: result.Lng
            }));
    }

    mapSuggestionResponseToAutoSuggestFormat(response) {
        return response.map(item => ({ value: item.Text, key: item.PlaceId }));
    }

    updateAutoSuggestResults(items) {
        this.autoSuggest.updateList(items);
    }

    setValue(value) {
        this.autoSuggest.setValue(value);
    }

    focus() {
        this.autoSuggest.focus();
    }

    disable() {
        this.autoSuggest.disable();
    }

    enable() {
        this.autoSuggest.enable();
    }
}

import $ from 'jquery';
import ClearableTextbox from '../save-location-form/clearable-textbox/clearable-textbox';
import AutoSuggestLocations from '../save-location-form/auto-suggest-locations/auto-suggest-locations';
import { SELECTOR as AUTO_SUGGEST_LOCATIONS_ELEMENT_SELECTOR } from '../save-location-form/auto-suggest-locations/auto-suggest-locations';

export const SELECTOR = '[data-save-location-form]';

const AUTO_SUGGEST_COMPONENT_ELEMENT_SELECTOR = '[data-save-location-form-auto-suggest-wrapper]';
const LABEL_INPUT_ELEMENT_SELECTOR = '[data-save-location-form-label-wrapper] .clearable-textbox';

const SAVE_LOCATION_FORM_SUBMIT_BUTON_SELECTOR = '[data-save-location-submit-button]';
const SAVE_LOCATION_FORM_CANCEL_BUTON_SELECTOR = '[data-save-location-cancel-button]';

const PROMOLABEL_ICON_NAME = 'icon-promolabel-grey';
const FORM_ERROR_MESSAGE_SELECTOR = '.save-location-form-error';

const IS_HIDDEN_CLASSNAME = 'is-hidden';

const noop = () => {};

export default class SaveLocationForm {
    constructor(element, onSubmit, onCancel, onSuggestingResults) {
        this.onSubmit = onSubmit || noop;
        this.onCancel = onCancel || noop;

        this.onSelectLocation = this.onSelectLocation.bind(this);
        this.onClearLocation = this.onClearLocation.bind(this);
        this.removeErrorMessage = this.removeErrorMessage.bind(this);

        const labelEvents = {
            input: event => this.onLabelChange(event)
        };

        this.$element = $(element);
        this.$autoSuggestLocations = this.$element.find(AUTO_SUGGEST_COMPONENT_ELEMENT_SELECTOR).find(AUTO_SUGGEST_LOCATIONS_ELEMENT_SELECTOR);
        this.$label = this.$element.find(LABEL_INPUT_ELEMENT_SELECTOR);
        this.$submit = this.$element.find(SAVE_LOCATION_FORM_SUBMIT_BUTON_SELECTOR);
        this.$cancel = this.$element.find(SAVE_LOCATION_FORM_CANCEL_BUTON_SELECTOR);
        this.$errorMessage = this.$element.find(FORM_ERROR_MESSAGE_SELECTOR);

        this.label = new ClearableTextbox(this.$label, labelEvents, PROMOLABEL_ICON_NAME);

        this.addressSuggest = new AutoSuggestLocations(
            this.$autoSuggestLocations,
            this.onSelectLocation,
            this.onClearLocation,
            this.removeErrorMessage,
            onSuggestingResults
        );

        this.resetFormData();

        this.bindEvents();
    }

    bindEvents() {
        this.$submit.on('click', event => {
            event.preventDefault();
            this.submitForm();
        });
        this.$cancel.on('click', () => this.cancelForm());
    }

    resetFormData() {
        this.formData = {
            location: null,
            label: null
        };
    }

    submitForm() {
        if (this.isFormValid()) {
            this.formData.label = this.formData.label || '';
            this.onSubmit(this.formData);
        } else {
            this.addressSuggest.autoSuggest.clearableTextbox.setErrorState();
            this.showErrorMessage();
        }
    }

    cancelForm() {
        this.onCancel();
    }

    isFormValid() {
        return !!this.formData.location;
    }

    showErrorMessage() {
        this.$errorMessage.removeClass(IS_HIDDEN_CLASSNAME);
        this.autoSuggestLocations = new AutoSuggestLocations(this.$autoSuggestLocations);
        this.label = new ClearableTextbox(this.$label, {}, PROMOLABEL_ICON_NAME);
    }

    removeErrorMessage() {
        this.$errorMessage.addClass(IS_HIDDEN_CLASSNAME);
    }

    onLabelChange(event) {
        this.formData = {
            ...this.formData,
            label: event.target.value
        };
    }

    onSelectLocation(location) {
        this.formData = {
            ...this.formData,
            coordinates: location.coordinates,
            placeId: location.placeId,
            location: location.location
        };
    }

    onClearLocation() {
        this.formData.location = undefined;
        this.formData.coordinates = undefined;
        this.formData.placeId = undefined;
        this.removeErrorMessage();
    }

    setLocation(id, location, lat, lng) {
        this.resetFormData();
        this.removeErrorMessage();
        this.addressSuggest.enable();

        this.addressSuggest.setValue(location);

        this.formData = {
            coordinates: {
                lat: lat,
                lng: lng
            },
            placeId: id,
            location: location,
        };

        this.label.focus();
    }

    disableLocationInput() {
        this.addressSuggest.disable();
    }

    clear() {
        this.addressSuggest.setValue('');
        this.label.setValue('');
        this.resetFormData();
    }
}

import $ from 'jquery';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';

export default class HistoryState {

    initialize() {
        const component = this;
        let originalPushState = window.history.pushState;
        let originalReplaceState = window.history.replaceState;

        window.history.pushState = function (state, ...otherArgs) {
            const newStuff = component.processState(state);

            originalPushState.apply(window.history, [newStuff].concat(otherArgs));
        };

        window.history.replaceState = function (state, ...otherArgs) {
            const newStuff = component.processState(state);

            originalReplaceState.apply(window.history, [newStuff].concat(otherArgs));
        };

        window.onpopstate = function (event) {
            let state = event.state;

            if (!isObject(state)) return;

            if (state.scrollX && state.scrollY && isNumber(state.scrollX) && isNumber(state.scrollY)) {
                window.scrollTo(state.scrollX, state.scrollY);
            }
        };
    }

    processState(state) {
        let newStuff = {
            scrollX: window.scrollX,
            scrollY: window.scrollY,
        };
        if (typeof state === 'string') newStuff.state = state;
        else if (isArray(state)) newStuff.state = state;
        else if (isObject(state)) newStuff = $.extend(newStuff, state);

        return newStuff;
    }
}

import {
    GET_VOORZIENINGEN,
    GET_VOORZIENINGEN_SUCCESS,
    GET_VOORZIENINGEN_FAIL,
} from '../constants/action-types';

const initialState = {
    loaded: false,
    loading: false,
    error: null
};

export default function getVoorzieningen(state = initialState, action = {}) {
    switch (action.type) {
        case GET_VOORZIENINGEN:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: null
            };
        case GET_VOORZIENINGEN_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null
            };
        case GET_VOORZIENINGEN_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        default:
            return state;
    }
}

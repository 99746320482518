// require this module where needed, either in a specific view or component or generically in src/index.js
// what does this module expose?
export default ThousandSeparator;

function ThousandSeparator() {}

/**
 * Returns a 1000 => 1.000
 *
 * @param {Integer} num
 * @param {String} separator (period or comma)
 * @returns {String}
 */
ThousandSeparator.format = function(num, separator) {
    var data = num.toString();

    if (data.length > 3) {
        data = data.replace(/(\d)(?=(?:[0-9]{3})+\b)/gm, '$1' + separator);
    }
    return data;
};

/**
 * Returns a 1.000 => 1000
 * @param {Integer, String} num
 * @returns {Number}
 */
ThousandSeparator.parse = function(num) {
    var data = num.toString();

    if (data.length > 3) {
        data = data.replace(/[.,]/gm, '');
    }
    return parseInt(data, 10);
};
import $ from 'jquery';

export default AsyncTextValidate;

// constant definitions
const COMPONENT_DATA = 'async-text-validate';
const COMPONENT_SELECTOR = `[data-${COMPONENT_DATA}]`;

const ERRORS_SELECTOR = '[data-async-text-error-messages-container]';

const TEXTAREA_SELECTOR = '[data-async-text-textarea]';
const VALID_SELECTOR = '[data-async-text-valid-container]';
const INITIAL_ERRORS_SELECTOR = '[data-async-text-invalid]';
const TEXT_EVENTS = 'blur';

const TEXT_VALIDATIONS_SELECTOR = '[data-async-text-underlines]';
const TEXT_VALIDATIONS_EVENTS = 'mousedown';

const IS_HIDDEN_CLASS = 'is-hidden';
const INPUT_ERROR_CLASS = 'input-validation-error';

const Component = AsyncTextValidate;

function AsyncTextValidate(element) {
    const component = this;
    component.$element = $(element);
    component.url = component.$element.data(COMPONENT_DATA);
    component.$textarea = component.$element.find(TEXTAREA_SELECTOR);
    component.$divUnderlines = component.$element.find(TEXT_VALIDATIONS_SELECTOR);
    component.$validCheckIcon = component.$element.find(VALID_SELECTOR);
    component.$errorMessages = component.$element.find(ERRORS_SELECTOR);

    component.handleInitialErrors();

    component.bindEvents();
}

AsyncTextValidate.prototype.bindEvents = function () {
    const component = this;

    component.$textarea.on(TEXT_EVENTS, () => component.validate());
    component.$divUnderlines.on(TEXT_VALIDATIONS_EVENTS, (e) => component.restoreTextArea(e));
};

AsyncTextValidate.prototype.handleInitialErrors = function () {
    const component = this;

    const $initialErrors = component.$element.find(INITIAL_ERRORS_SELECTOR);
    if ($initialErrors.length > 0) {
        const errors = JSON.parse($initialErrors.html());
        component.responseHandler(errors);
    }
};

AsyncTextValidate.prototype.validate = function () {
    const component = this;

    const ajaxData = {
        url: component.url,
        data: {
            Explanation: component.$textarea.val()
        }
    };

    $.post(ajaxData).then((message) => component.responseHandler(message));
};

AsyncTextValidate.prototype.responseHandler = function (message) {
    const component = this;

    if ((Array.isArray(message.invalidWords) && message.invalidWords.length > 0) ||
        (Array.isArray(message.errorMessages) && message.errorMessages.length > 0)) {
        component.showUnderlinedWords(message.invalidWords);
        component.showErrorMessages(message.errorMessages);
    } else {
        component.$validCheckIcon.removeClass(IS_HIDDEN_CLASS);
    }
};

AsyncTextValidate.prototype.showUnderlinedWords = function (invalidWords) {
    const component = this;

    const words = Component.adaptMessage(invalidWords);

    if (Array.isArray(words) && words.length > 0) {
        const divPre = '<span class="highlight">';
        const divPost = '</span>';
        let text = component.$textarea.val().trim();
        let offset = 0;

        for (let i = 0; i < words.length; i++) {
            const startPos = offset + words[i].StartPos;
            const endPos = offset + words[i].EndPos;

            const pre = text.substring(0, startPos);
            const word = text.substring(startPos, endPos);
            const post = text.substring(endPos);

            text = pre + divPre + word + divPost + post;
            offset += divPre.length + divPost.length;
        }

        component.$textarea.addClass(IS_HIDDEN_CLASS);

        component.$divUnderlines.html(text);
        component.$divUnderlines.removeClass(IS_HIDDEN_CLASS);
        component.$divUnderlines.addClass(INPUT_ERROR_CLASS);

        component.$validCheckIcon.addClass(IS_HIDDEN_CLASS);
    }
};

AsyncTextValidate.adaptMessage = function (invalidWords) {
    const res = [];

    if (!Array.isArray(invalidWords) || invalidWords.length === 0) {
        return res;
    }

    //Sort
    invalidWords.sort((a, b) => a.StartPos - b.StartPos);

    //Add endPos
    for (let i = 0; i < invalidWords.length; i++) {
        invalidWords[i].EndPos = invalidWords[i].StartPos + invalidWords[i].Word.length;
    }

    //First value always in
    res.push(invalidWords[0]);

    for (let i = 1; i < invalidWords.length; i++) {
        const previous = res.pop();
        const current = invalidWords[i];

        if (current.StartPos <= previous.EndPos) {
            res.push({
                StartPos: previous.StartPos,
                EndPos: Math.max(previous.EndPos, current.EndPos)
            });
        } else {
            res.push(previous);
            res.push(current);
        }
    }

    return res;
};

AsyncTextValidate.prototype.showErrorMessages = function (errors) {
    const component = this;

    if (Array.isArray(errors) && errors.length > 0) {
        component.$errorMessages.html(errors.join('<br/>'));
        component.$errorMessages.removeClass(IS_HIDDEN_CLASS);
    }
};

AsyncTextValidate.prototype.restoreTextArea = function (event) {
    const component = this;

    if (typeof event === 'object' && typeof event.preventDefault === 'function') {
        event.preventDefault();
        event.stopPropagation(); //CANCEL MOUSEDOWN EVENT
    }

    component.$divUnderlines.addClass(IS_HIDDEN_CLASS);
    component.$errorMessages.addClass(IS_HIDDEN_CLASS);

    component.$textarea.removeClass(IS_HIDDEN_CLASS);
    component.$textarea.focus();
};

$(COMPONENT_SELECTOR).each((index, element) => new AsyncTextValidate(element));

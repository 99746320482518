import $ from 'jquery';
import AppliedFilters from '../applied-filters/applied-filters';
import TravelTimeWrapper from '../travel-time-flyout-wrapper/travel-time-flyout-wrapper';
import ToggleAllInputs from '../filter-flyout/toggle-all-inputs';

export default FilterFlyout;

var COMPONENT_SELECTOR = '[data-filter-flyout]';
var SELECTED_ITEMS_SELECTOR = '[data-flyout-selected-options]';
var SELECTED_ITEM_SELECTOR = '[data-flyout-selected-option]';
var SELECTED_ITEM_TEMPLATE = '[filter-flyout-selected-option-template]';
var SELECTED_FILTER_ID = 'data-filter-id';
var SELECTED_FILTER_GROUP_ID = 'data-filter-group-id';
var SELECTED_FILTER_GROUP_NAME = 'data-filter-group-name';
var FILTER_GROUPNAME_SELECTOR = '[' + SELECTED_FILTER_GROUP_NAME + ']';
var FLYOUT_BUTTON_SELECTOR = '[data-filter-open-flyout]';
var FLYOUT_SELECTOR = '[data-filter-flyout-panel]';
var FLYOUT_PREVIEW_OPTIONS_SELECTOR = '[data-filter-flyout-preview-options]';
var FLYOUT_BACKDROP_SELECTOR = '[data-filter-flyout-backdrop]';
var FLYOUT_CLOSE_SELECTOR = '[data-filter-close-flyout]';
var FLYOUT_CLOSED_EVENT = 'filterflyoutclosed';
var FLYOUT_CLOSE_EVENT = 'filterflyoutclose';
var FLYOUT_OPENED_EVENT = 'filterflyoutopened';
var FILTER_ITEM_SELECTOR = '[data-flyout-item]';
var FLYOUT_ITEM_LABEL_ATTR = 'data-filter-flyout-option-label';
var FLYOUT_ITEM_LABEL_SELECTOR = '[' + FLYOUT_ITEM_LABEL_ATTR + ']';
var PARENT_DESELECT_EVENT = 'deselect-filter-item';
var PARENT_SELECTOR = '[data-nested-filter]';
var TOGGLE_ALL_INPUT_SELECTOR = '[data-toggle-all-inputs]';
var ZOEK_OP_KAART_SELECTOR = 'main > .zoek-op-kaart';
var ZOEK_OP_KAART_MAP_SELECTOR = '.zoek-op-kaart__map';
var FLYOUT_WRAPPER_SELECTOR = '[data-flyout-wrapper]';

var RANGE_MIN = '[data-range-min]';
var RANGE_MAX = '[data-range-max]';
var RANGE_SUBMIT = '[data-range-submit]';
var RANGE_TEMPLATE = '[data-range-label-template]';
var RANGE_SELECT_EVENT = 'rangeselected';
var RANGE_DESELECT_EVENT = 'rangedeselected';
var RANGE_INITIALIZED_EVENT = 'rangeinitialized';
var CHANGE_BUURT_EVENT = 'changeBuurt';
var CHANGE_RADIUS_EVENT = 'changeRadius';

var FLYOUT_OPEN_CLASS = 'is-open';
var FLYOUT_BOTTOM_CLASS = 'is-bottom';
var FLYOUT_HAS_SELECTED_FILTER = 'has-selected-filter';
var FLYOUT_BODY_OPEN_CLASS = 'zoek-op-kaart-flyout-open';
var LABEL_CLASS = '.label-text';
var LABEL_ADD_GROUP_NAME_SELECTOR = '[data-filter-flyout-add-groupname-to-label]';
var FILTER_ADD_GROUP_NAME_SELECTOR = '[data-filter-flyout-add-groupname-to-filter]';

var KEY_CODE = {
    ENTER: 13,
    ESC: 27,
    SPACE: 32
};

var $body = $('body');
var $kaart = $(ZOEK_OP_KAART_MAP_SELECTOR);

function FilterFlyout(element, reinitiate) {
    var component = this;

    component.uniqueId = null;
    component.bindToElements(element, reinitiate);

    component.bindEvents();

    component.setInitialSelectedFilters();

    $(window).on('sidebar-refresh', function() {
        if (component.uniqueId == null) return;
        var $myNewElement = $(COMPONENT_SELECTOR + '[data-unique="' + component.uniqueId + '"]');
        component.bindToElements($myNewElement, false);
        component.bindEvents();
    });
}

FilterFlyout.prototype.bindToElements = function(element, reinitiate) {
    const component = this;

    component.$element = $(element);
    component.$filterOption = component.$element.find(FILTER_ITEM_SELECTOR);
    component.$filterOptionContainer = component.$element.find(FLYOUT_ITEM_LABEL_SELECTOR);
    component.$selectedOptions = component.$element.find(SELECTED_ITEMS_SELECTOR);
    component.selectedOptionsTemplate = component.$element.find(SELECTED_ITEM_TEMPLATE).html();
    component.$flyout = component.$element.find(FLYOUT_SELECTOR);
    component.$flyoutButton = component.$element.find(FLYOUT_BUTTON_SELECTOR);
    component.$flyoutBackdrop = component.$element.find(FLYOUT_BACKDROP_SELECTOR);
    component.$flyoutClose = component.$element.find(FLYOUT_CLOSE_SELECTOR);
    component.flyoutIsOpen = false;
    component.$previewOptions = component.$element.find(FLYOUT_PREVIEW_OPTIONS_SELECTOR);
    component.$parentFilter = component.$element.closest(PARENT_SELECTOR);
    component.hasParentFilter = component.$parentFilter.length > 0;
    component.$form = component.$element.closest('form');
    component.$rangeInputSubmit = component.$element.find(RANGE_SUBMIT);
    component.$rangeMin = component.$element.find(RANGE_MIN);
    component.$rangeMax = component.$element.find(RANGE_MAX);
    component.rangeTemplate = component.$element.find(RANGE_TEMPLATE).data('range-label-template');

    this.$travelTimeFlyoutWrapper = component.$flyout.find(FLYOUT_WRAPPER_SELECTOR);

    this.closeFlyout = this.closeFlyout.bind(this);

    if (this.$travelTimeFlyoutWrapper.length > 0) {
        this.travelTime = new TravelTimeWrapper(this.$travelTimeFlyoutWrapper, null, this.closeFlyout);
    }

    if (reinitiate) {
        // this filter flyout was already initiated; make sure there are no available filters left.
        var groupName = null;
        var groupList = [];

        component.$element.find(FILTER_GROUPNAME_SELECTOR).each(function() {
            var $element = $(this);
            groupName = $element.attr(SELECTED_FILTER_GROUP_NAME).toLowerCase();
            if (groupList.indexOf(groupName) < 0) {
                groupList.push(groupName);
                AppliedFilters.remove(groupName);
            }
        });
    }

    if (component.hasParentFilter) {
        component.$parentFilter.on(PARENT_DESELECT_EVENT, function() {
            var numberOfActiveSubfilters = component.$selectedOptions.find('li').length;
            if (numberOfActiveSubfilters > 0) {
                component.$filterOption.each(function() {
                    var $filterOption = $(this);
                    component.removeFilterLabel($filterOption);
                });
                component.$filterOption.prop('checked', false);
                component.$selectedOptions.empty();
            }
        });
    }

    if (component.$element.find(TOGGLE_ALL_INPUT_SELECTOR)) {
        var toggleAllInputs = component.$element.find(TOGGLE_ALL_INPUT_SELECTOR)[0];
        component.toggleAllInputs = new ToggleAllInputs(toggleAllInputs);
    }

    // remember uniqueId (first time)
    if (component.uniqueId == null) {
        component.uniqueId = component.$element.data('unique');
    }
};

FilterFlyout.prototype.bindEvents = function() {
    const component = this;

    component.$rangeInputSubmit.on('click', function() {
        component.sendRangeEvent();
        component.closeFlyout();
    });
    component.$element.on('sendrangeinputvalues', function() {
        component.sendRangeEvent();
    });
    component.$element.on(RANGE_DESELECT_EVENT, function() {
        component.$rangeMin.val('');
        component.$rangeMax.val('');
    });
    component.$flyoutButton.on('click', function() {
        component.toggleFlyout();
    });
    component.$flyoutBackdrop.on('click', function() {
        component.closeFlyout();
    });
    component.$flyoutClose.on('click', function() {
        component.closeFlyout();
    });
    component.$element.on('resetfilter', function() {
        component.$filterOption.each(function(index, filterOption) {
            var $filterOption = $(filterOption);
            var isChecked = $filterOption[0].checked;
            if (isChecked) {
                component.removeFilterLabel($filterOption);
            }
        });
    });
    component.$element.on('change', FILTER_ITEM_SELECTOR, function() {
        var $filterOption = $(this);
        var isChecked = $filterOption[0].checked;
        var numFiltersSelected = null;

        if (isChecked) {
            numFiltersSelected = component.addSelectedFilterLabel($filterOption);
        } else {
            // filter isn't checked
            numFiltersSelected = component.removeSelectedFilter($filterOption);
        }
        var isBuurtFilter = $filterOption.parents('[data-instant-search-output=wijkNaamFilter]').length > 0;
        if (isBuurtFilter) {
            $(document).trigger(CHANGE_BUURT_EVENT, {selectedFilterCount: numFiltersSelected});
        }
    });
    component.$selectedOptions.on('click', SELECTED_ITEM_SELECTOR, function(e) {
        e.preventDefault();
        e.stopPropagation();
        var $li = $(this);
        var selectedOptionId = $li.attr(SELECTED_FILTER_ID);
        var $input = component.$flyout.find('input[id="' + selectedOptionId + '"]');
        $input.prop('checked', false);
        $input.trigger('change');
        $li.remove();
    });
    component.$element.on(FLYOUT_CLOSE_EVENT, function() {
        component.closeFlyout();
    });
    component.$filterOptionContainer.on('changed', function() {
        if (component.previewTimer) {
            clearTimeout(component.previewTimer);
        }
        component.previewTimer = setTimeout(function() {
            component.setPreviewOptions();
        }, 300);
    });
    $(document).on(CHANGE_RADIUS_EVENT, (event, data) => {
        var buurtFilterVisible = data.selectedRadius == 0;
        component.togggleBuurtFilter(buurtFilterVisible);
    });
};

FilterFlyout.prototype.sendRangeEvent = function() {
    var component = this;
    var data = {};

    data.min = component.$rangeMin.val();
    data.max = component.$rangeMax.val();

    if (data.max || data.min) {
        component.$element.trigger(RANGE_SELECT_EVENT, data);
    }
};

FilterFlyout.prototype.sendInitializedRangeEvent = function() {
    var component = this;
    var data = {};

    data.min = component.$rangeMin.val();
    data.max = component.$rangeMax.val();

    if (data.max || data.min) {
        component.$element.trigger(RANGE_INITIALIZED_EVENT, data);
    }
};

FilterFlyout.prototype.setInitialSelectedFilters = function() {
    var component = this;

    if (component.$filterOption.length > 0) {
        component.$filterOption.each(function (index, filterOption) {
            var $filterOption = $(filterOption);
            var isChecked = $filterOption[0].checked;

            if (isChecked) {
                component.addSelectedFilterLabel($filterOption);
            }
        });
    } else {
        setTimeout(function() {
            component.sendInitializedRangeEvent();
        }, 125);
    }
};

FilterFlyout.prototype.toggleFlyout = function() {
    var component = this;
    component.flyoutIsOpen = !component.flyoutIsOpen;
    if (component.flyoutIsOpen) {
        component.openFlyout();
    } else {
        component.closeFlyout();
    }
};

FilterFlyout.prototype.openFlyout = function() {
    var component = this;
    component.flyoutIsOpen = true;

    var zoekopkaart = document.querySelector(ZOEK_OP_KAART_SELECTOR);
    if (zoekopkaart !== null) {
        $body.addClass(FLYOUT_BODY_OPEN_CLASS);
    }

    component.$flyout.addClass(FLYOUT_OPEN_CLASS).attr('aria-expanded', component.flyoutIsOpen);
    if (component.$flyout.hasClass(FLYOUT_BOTTOM_CLASS)) {
        component.$flyout.toggleClass(FLYOUT_BOTTOM_CLASS);
    }
    var flyoutRect = component.$flyout[0].getBoundingClientRect();
    if (flyoutRect.top + flyoutRect.height > $kaart.height()) {
        component.$flyout.addClass(FLYOUT_BOTTOM_CLASS);
    }
    component.$flyoutButton.addClass(FLYOUT_OPEN_CLASS);
    component.$element.trigger(FLYOUT_OPENED_EVENT);
    component.$element.on('keydown', function(e) {
        if (e.keyCode === KEY_CODE.ENTER) {
            e.preventDefault();
            component.closeFLyoutOnEnter(e, e.keyCode);
        }
    });

    component.$element.on('keypress', function(e) {
        var pressedKey = e.keyCode || e.charCode;
        var key = String.fromCharCode(pressedKey);
        // understand why this.
        if (!/[0-9]/.test(key) && pressedKey >= KEY_CODE.SPACE) {
            // e.preventDefault();
        }
    });

    $(document).on('keydown.closeFlyout', function (e) {
        if (e.keyCode === KEY_CODE.ESC) {
            component.closeFlyout();
        }
    });
};

FilterFlyout.prototype.closeFlyout = function() {
    $body.removeClass(FLYOUT_BODY_OPEN_CLASS);
    var component = this;
    component.flyoutIsOpen = false;
    component.$flyout.removeClass(FLYOUT_OPEN_CLASS).attr('aria-expanded', component.flyoutIsOpen);
    component.$flyoutButton.removeClass(FLYOUT_OPEN_CLASS);
    component.$element.trigger(FLYOUT_CLOSED_EVENT);
    component.$element.off('keydown');
    $(document).off('keydown.closeFlyout');
};

FilterFlyout.prototype.closeFLyoutOnEnter = function() {
    $body.removeClass(FLYOUT_BODY_OPEN_CLASS);
    var component = this;
    component.sendRangeEvent();
    component.$element.trigger(FLYOUT_CLOSE_EVENT);
    component.$element.off('keydown');
};

FilterFlyout.prototype.addSelectedFilterLabel = function($filterOption) {
    var component = this;
    var isRadioGroup = component.filterIsRadioButton($filterOption);
    var filterLabeltext = $filterOption.next(LABEL_CLASS)[0].childNodes[0].nodeValue;
    var filterGroupId = $filterOption.attr(SELECTED_FILTER_GROUP_ID);
    var filterGroupName = $filterOption.attr(SELECTED_FILTER_GROUP_NAME);
    var html = component.selectedOptionsTemplate;
    var pattern = new RegExp('{id}', 'g');
    var appliedFilter = {
        filterGroupId: $filterOption[0].id,
        filterGroupName: filterGroupName.toLowerCase(),
        filterName: filterLabeltext,
        labelText: filterLabeltext
    };
    if (isRadioGroup) {
        component.removeSelectedFilter($filterOption);
        appliedFilter.filterGroupId = filterGroupId;
    }
    if ($filterOption.is(LABEL_ADD_GROUP_NAME_SELECTOR)) {
        filterLabeltext = filterLabeltext + ' (' + filterGroupName.toLowerCase() + ')';
    }
    if ($filterOption.is(FILTER_ADD_GROUP_NAME_SELECTOR)) {
        appliedFilter.labelText = appliedFilter.labelText + ' (' + appliedFilter.filterGroupName + ')';
    }

    html = html.replace(pattern, $filterOption[0].id)
        .replace(new RegExp('({label})', 'gm'), filterLabeltext)
        .replace(new RegExp('({group-id})', 'gm'), filterGroupId);
    component.$selectedOptions.append(html);
    var numFiltersSelected = component.determineFilterSelected();

    AppliedFilters.add(appliedFilter, function() {
        component.removeFilterLabel($filterOption, component);
    }, isRadioGroup);

    return numFiltersSelected;
};

/**
 * remove the selected filter
 * @param {Obj} $filterOption
 */
FilterFlyout.prototype.removeSelectedFilter = function($filterOption) {
    var component = this;
    var $filters = null;
    var filterOptionId = $filterOption[0].id;

    if (component.filterIsRadioButton($filterOption)) {
        filterOptionId = $filterOption.attr(SELECTED_FILTER_GROUP_ID);
        $filters = component.$selectedOptions.find('[' + SELECTED_FILTER_GROUP_ID + '="' + filterOptionId + '"]');
    } else {
        // filter is checkbox
        $filters = component.$selectedOptions.find('[' + SELECTED_FILTER_ID + '="' + filterOptionId + '"]');
    }

    if ($filters.length > 0) {
        $filters.remove();
        component.determineFilterSelected();
        AppliedFilters.remove(filterOptionId);
    }
    return component.determineFilterSelected();
};

/**
 * remove the selected filter and fires the trigger
 * @param $filterOption
 * @param {component} filterFlyoutComponent; the filter flyout component it self
 */
FilterFlyout.prototype.removeFilterLabel = function($filterOption, filterFlyoutComponent) {
    var component = filterFlyoutComponent || this;

    component.removeSelectedFilter($filterOption);
    $filterOption.prop('checked', false);
    $filterOption.trigger('change');
};

FilterFlyout.prototype.filterIsRadioButton = function($filter) {
    return $filter.attr('type') === 'radio';
};

FilterFlyout.prototype.determineFilterSelected = function() {
    var component = this;
    var selectedFilters = component.$element.find(SELECTED_ITEM_SELECTOR).length;
    if (selectedFilters > 0) {
        component.$element.addClass(FLYOUT_HAS_SELECTED_FILTER);
    } else {
        component.$element.removeClass(FLYOUT_HAS_SELECTED_FILTER);
    }
    return selectedFilters;
};

FilterFlyout.prototype.setPreviewOptions = function () {
    var component = this;
    var optionList = [];

    component.$filterOptionContainer.each(function() {
        var $element = $(this);
        if (!$element.hasClass('is-disabled')) {
            optionList.push($element.attr(FLYOUT_ITEM_LABEL_ATTR));
        }
    });
    component.$previewOptions.text(optionList.join(', '));
};

FilterFlyout.prototype.togggleBuurtFilter = function(newState) {
    var $buurtFilter = $body.find('[data-instant-search-output="wijkNaamFilter"]');
    if ($buurtFilter.length == 0) return;

    if (newState) {
        $('.filter-flyout', $buurtFilter).removeClass('is-disabled');
    } else {
        $('.filter-flyout-fieldset input[type=checkbox]:checked', $buurtFilter).click();
        $('.filter-flyout', $buurtFilter).addClass('is-disabled');
    }
};

$(COMPONENT_SELECTOR).each(function(index, element) {
    return new FilterFlyout(element, false);
});

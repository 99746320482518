// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';
import browserSupportsAnimations from '../app-utils/app-utils-browser-supports-animations';

export default SearchAssistant;

// constant definitions
const COMPONENT_SELECTOR = '[data-search-assistant]';
const CLOSE_SELECTOR = '[data-search-assistant-close]';
const BODY_SELECTOR = 'body';
const BODY_OPEN_CLASS = 'search-assistant-is-open';
const OPEN_CLASS = 'is-open';
const $body = $(BODY_SELECTOR);
const AUTOCOMPLETE_SELECTOR = '[data-autocomplete]';
const $autoCompleteInAssistant = $body.find(COMPONENT_SELECTOR).find('.autocomplete-input');

// trigger/listen event constants
const SEARCH_BOX_FOCUS_EVENT = 'focusonsearchbox';

// variable definitions
let scrollPosition;

function SearchAssistant(element) {
    const component = this;
    component.$element = $(element);
    component.$autocomplete = component.$element.find(AUTOCOMPLETE_SELECTOR);
    component.animationsSupported = browserSupportsAnimations();
    component.$closeButton = component.$element.find(CLOSE_SELECTOR);
    component.bindEvents();
}

SearchAssistant.prototype.bindEvents = function () {
    const component = this;
    component.listenOpenEvent();
};

SearchAssistant.prototype.listenOpenEvent = function () {
    const component = this;
    // Clicked/focused on search box
    $(window).on(SEARCH_BOX_FOCUS_EVENT, () => {
        component.open();
    });
};

SearchAssistant.prototype.open = function () {
    const component = this;
    if (component.isOpen()) { return; }

    $body.css('top', -1 * scrollPosition);
    $body.addClass(BODY_OPEN_CLASS);
    component.$closeButton.attr('href', window.location);
    component.$element.addClass(OPEN_CLASS);
    scrollPosition = $(window).scrollTop();
    $autoCompleteInAssistant.focus();
};

SearchAssistant.prototype.isOpen = function () {
    const component = this;
    return component.$element.hasClass(OPEN_CLASS);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new SearchAssistant(element));
import $ from 'jquery';
import InstantSearch from '../instant-search/instant-search';

// component configuration
const COMPONENT_SELECTOR = '[data-instant-map-search]';
const MAP_SELECTOR = '[data-map]';

const CENTER_MAP_EVENT = 'center_map_on';
const SEARCH_QUERY_UPDATED_EVENT = 'zo_updated';

const SEARCH_SIDEBAR_SELECTOR = '[data-search-sidebar]';
const EXTENDED_CLASS = 'is-extended';

/**
 * @param {HTMLFormElement} form
 * @constructor
 */
class InstantMapSearch extends InstantSearch {
    constructor(form) {
        super(form);

        const component = this;
        component.$form = $(form);
        component.$map = $(MAP_SELECTOR);

        component.overrideEvents();

        $(window).on('sidebar-refresh', function () {
            const $myNewElement = $(COMPONENT_SELECTOR);
            if ($myNewElement.length === 1) {
                component.bindToElements($myNewElement);
                component.bindToEvents();
            }
        });
    }

    /**
     * override events from parent class
     */
    overrideEvents() {
        const component = this;
        window.removeEventListener('popstate', component.onpopstate, false);
    }

    /**
     * Update changes in map for updated result
     */
    updateResults(data) {
        const component = this;
        if (data.geo) {
            component.updateCenterMap(data.geo);
        }
        data.url = data.url + window.location.search;
        component.updateHistory(data.url);
        component.updateMapTiles(data.zo);

        if (component.$form.find(SEARCH_SIDEBAR_SELECTOR).hasClass(EXTENDED_CLASS)) {
            component.asyncResult = data;
        } else {
            component.renderOutputResults(data);
        }
    }

    /**
     * trigger center_event
     */
    updateCenterMap(geo) {
        const component = this;
        component.$map.trigger(CENTER_MAP_EVENT, geo);
    }

    /**
     * trigger query_updated_event
     */
    updateMapTiles(searchQuery) {
        const component = this;
        component.$map.trigger(SEARCH_QUERY_UPDATED_EVENT, {zo: searchQuery});
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new InstantMapSearch(element));

import $ from 'jquery';

export default IframeHeightUpdater;

const COMPONENT_SELECTOR = '[data-iframe-height-updater]';
const MARGIN_SIZE = 50;

function IframeHeightUpdater(element) {
    const component = this;
    component.$element = $(element);

    component.bindEvents();
    component.updateHeight();
}

IframeHeightUpdater.prototype.bindEvents = function () {
    const component = this;

    $(window).on('resize click', () => component.updateHeight());
    setInterval(() => component.updateHeight(), 500);
};

IframeHeightUpdater.prototype.updateHeight = function () {
    const component = this;
    const currentHeight = MARGIN_SIZE + component.$element.height();

    if (currentHeight !== component.Height) {
        component.Height = currentHeight;
        window.parent.postMessage('height=' + currentHeight, '*');
    }
};

$(COMPONENT_SELECTOR).each(function (index, element) {
    return new IframeHeightUpdater(element);
});

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

import LoginDialog from '../login-dialog/login-dialog';

import controllerService from '../service-controller/service-controller';
import Observable from '../class-observable/class-observable';
import browserSupportsAnimations from '../app-utils/app-utils-browser-supports-animations';

export default SaveObject;

// component configuration
const COMPONENT_SELECTOR = '[data-save-object]';
const HANDLE_SELECTOR = '[data-save-object-handle]';
const IS_SAVED_CLASS = 'is-saved';
const LOGIN_DIALOG_SELECTOR = '[data-dialog="user-form"]';
const MULTIPLE_SAVE_COMPONENT_SELECTOR = '[data-connect-save-object-instances]';

const ANIMATION_END_EVENTS = 'webkitAnimationEnd oAnimationEnd msAnimationEnd animationend';

function SaveObject(element) {
    var component = this;
    component.$element = $(element);
    component.$saveHandle = $(element).find(HANDLE_SELECTOR);
    component.doPageRefresh = false;
    component.isSaved = component.$element.hasClass(IS_SAVED_CLASS);
    component.observers = new Observable();
    component.saveUrl = component.$saveHandle.attr('href');

    component.animationsSupported = browserSupportsAnimations();

    //To be used by observers!
    component.onSaved = function (observer) {
        component.observers.listen(observer);
    };

    component.$element.on('click', HANDLE_SELECTOR, function (event) {
        event.preventDefault();
        component.event = (event !== undefined) ? event : !component.event;
        component.saveIfLoggedIn(this.href, component.isSaved);
    });
}

SaveObject.prototype.saveIfLoggedIn = function (url, isSaved) {
    var component = this;
    component.isSaved = (isSaved !== undefined) ? isSaved : !component.isSaved;

    component.isUserLoggedIn(function onSuccessfulLogin() {
        component.toggleSavedState(url, component.isSaved);
    });
};

SaveObject.prototype.isUserLoggedIn = function (onLoggedIn) {
    var component = this;
    var dialogElement = document.querySelector(LOGIN_DIALOG_SELECTOR);
    var url = dialogElement.dialog.isUserLoggedInUrl;

    return $.ajax({
        url: url,
        success: function (response) {
            if (response.LoggedIn === true) {
                onLoggedIn();
            } else {
                component.userLoginStatus = new LoginDialog(response.LoginUrl, function onSuccessfulLogin() {
                    component.doPageRefresh = true;
                    onLoggedIn();
                });
            }
        },
        error: function (response) {
            console.error('Error calling', url, response);
        }
    });
};

/**
 * Does an ajax request and toggles the 'is-saved' class if the response is
 * successful
 */
SaveObject.prototype.toggleSavedState = function (url, isSaved) {
    var component = this;
    component.isSaved = (isSaved !== undefined) ? isSaved : !component.isSaved;
    component.multipleInstances = component.$element.find(MULTIPLE_SAVE_COMPONENT_SELECTOR).length;

    return $.ajax({
        url: url,
        success: function (response) {
            if (SaveObject.isSuccesfulResponse(response)) {
                if (component.multipleInstances) {
                    $(COMPONENT_SELECTOR).each(function () {
                        component.toggleSaveIcon($(this));
                    });
                } else {
                    component.toggleSaveIcon(component.$element);
                }
                /* component.isSaved gives me an event, this is clearly wrong, but the whole component is dirty */
                component.observers.notify(component.$element.hasClass(IS_SAVED_CLASS));
                $(window).trigger('objectsaved');
            } else {
                SaveObject.onError(response);
            }
        },
        error: SaveObject.onError,
        complete: function () {
            if (component.doPageRefresh === true) {
                window.location.reload();
            }
        }
    });
};

SaveObject.prototype.save = function (isSaved) {
    var component = this;
    return component.toggleSavedState(component.saveUrl, isSaved);
};

SaveObject.prototype.toggleSaveIcon = function ($item) {
    const component = this;

    if ($item.hasClass(IS_SAVED_CLASS)) {
        $item.removeClass(IS_SAVED_CLASS);
    } else {
        $item.addClass('is-saved is-saving');

        let onAnimationEndHandler = function() {
            $item.removeClass('is-saving');
        };

        if (component.animationsSupported) {
            $item.one(ANIMATION_END_EVENTS, onAnimationEndHandler);
        } else {
            onAnimationEndHandler.bind(this)();
        }
    }
};

SaveObject.isSuccesfulResponse = function (response) {
    return response.Result === 'OK';
};

SaveObject.onError = function (response) {
    var component = this;
    console.error('Error trying to save object using', component.url, response);
};

// turn all elements with the default selector into components
// $(COMPONENT_SELECTOR).each(function(index, element) {
//     return new SaveObject(element);
// });
SaveObject.getSelector = () => COMPONENT_SELECTOR;
controllerService.getAllInstances(SaveObject);
// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

export default CustomSelectBox;

// constant definitions
const COMPONENT_SELECTOR = '[data-custom-select-box]';
const DATA_URL = 'data-request-url';
const HANDLE_SELECTOR = '[data-instant-search-handle]';
const SELECTED_OPTION = '[data-selected-option]';
const OPTIONS_LIST = '[data-selectbox-list]';
const OPENED_CLASS = 'is-open';
const SELECTED_CLASS = 'is-selected';
const NO_RESULTS = 'no-results';
const DATA_PLACEHOLDER = 'data-custom-select-placeholder';
const DATA_NO_OPTIONS = 'data-custom-select-nooptions';

const optionTemplate = `<li role="option"
    id="{Id}"
    data-selectbox-option-display-value="{Value}"
    class="selectbox-option">
    <span class="selectbox-label" data-selectbox-match>{Label}</span>
    <div class="selectbox-option-count">{ResultCount}</div>
</li>`;

function CustomSelectBox(element) {
    const component = this;
    component.$element = $(element);
    component.sourceUrl = component.$element.attr(DATA_URL);
    component.$handler = component.$element.find(HANDLE_SELECTOR);
    component.$selectedOption = component.$element.find(SELECTED_OPTION);
    component.$optionsList = component.$element.find(OPTIONS_LIST);
    component.isOpen = false;
    component.selectedIndex = -1;
    component.placeholder = component.$element.attr(DATA_PLACEHOLDER);
    component.noOptions = component.$element.attr(DATA_NO_OPTIONS);
    component.init();
}

CustomSelectBox.prototype.init = function () {
    const component = this;
    component.$selectedOption.html(component.placeholder);
    if (component.sourceUrl) { // for client-side rendering
        component.fetchOptions();
    } else { // for server-side rendering
        component.fetchOptionsFromPage();
    }
    component.checkOptions();
    component.bindEvents();
};

CustomSelectBox.prototype.renderOptionsList = function () {
    const component = this;
    component.$optionsList.html('');
    component.selectedIndex = -1;
    component.options.forEach((option) => {
        let item = component.renderOption(option);
        component.$optionsList.append(item);
    });
};

CustomSelectBox.prototype.renderOption = function (option) {
    let itemTemplate = optionTemplate;

    for (let prop in option) {
        if (option.hasOwnProperty(prop)) {
            itemTemplate = itemTemplate.replace('{' + prop + '}', option[prop]);
        }
    }
    return itemTemplate;
};

CustomSelectBox.prototype.fetchOptions = function () {
    const component = this;
    $.ajax({
        url: component.sourceUrl,
        success: (response) => {
            component.options = Array.isArray(response) ? response : [];
            component.renderOptionsList();
            component.handleZeroResults();
        },
        error: () => {
            component.options = [];
            component.$selectedOption.html(component.noOptions);
        }
    });
};

CustomSelectBox.prototype.handleZeroResults = function () {
    const component = this;

    component.$optionsList.find('li').each(function (id, item) {
        let option = $(item);
        let resultCount = $(option.find('.selectbox-option-count')).html();

        if (parseInt(resultCount, 8) == 0) {
            option.addClass(NO_RESULTS);
        }
    });
};

// Getting the options list for server-side rendering
CustomSelectBox.prototype.fetchOptionsFromPage = function () {
    const component = this;
    component.options = $('.selectbox-list').find('li').map(function () {
        let item = {};
        item.Id = $(this).attr('id');
        item.Value = $(this).data('selectboxOptionDisplayValue');
        let children = $(this).children();
        if (children !== undefined) {
            if (children[0] !== undefined) {
                item.Label = $(this).children()[0].textContent;
            }
            if (children[1] !== undefined) {
                item.ResultCount = $(this).children()[1].textContent;
            }
        }
        return item;
    }) || [];
};

// If already selected item exist in url
// User can refresh the page or copy/paste the url
// This method checks the existence of selected item
// To select
CustomSelectBox.prototype.checkOptions = function () {
    const component = this;
    let selectedItem = component.$optionsList.find('.selectbox-option.' + SELECTED_CLASS);
    if (selectedItem.length) {
        component.selectOption(selectedItem.index());
    }
};

CustomSelectBox.prototype.bindEvents = function () {
    const component = this;
    component.bindSelectboxEvent();
    component.bindSelectOptionEvent();
    component.bindClickOutsideEvent();
};

CustomSelectBox.prototype.bindSelectboxEvent = function () {
    const component = this;
    component.$selectedOption.on('click', () => {
        if (component.isOpen) {
            component.closeOptions();
        } else {
            component.openOptions();
        }
    });
};

CustomSelectBox.prototype.openOptions = function () {
    const component = this;
    component.isOpen = true;
    component.$element.addClass(OPENED_CLASS);
    component.$optionsList.addClass(OPENED_CLASS);
};

CustomSelectBox.prototype.closeOptions = function () {
    const component = this;
    component.isOpen = false;
    component.$element.removeClass(OPENED_CLASS);
    component.$optionsList.removeClass(OPENED_CLASS);
};

CustomSelectBox.prototype.bindSelectOptionEvent = function () {
    const component = this;
    component.$optionsList.on('click', 'li', (event) => {
        const index = $(event.currentTarget).index();
        component.selectOption(index);
        component.updatePage();
        component.closeOptions();
    });
};

CustomSelectBox.prototype.selectOption = function (index) {
    const component = this;
    const selectedOption = component.options[index];
    component.selectedOption = selectedOption;
    component.selectedIndex = index;

    // set selected state in option list
    const $items = component.$optionsList.children();
    $items.removeClass(SELECTED_CLASS);
    $items.eq(index).addClass(SELECTED_CLASS);

    // select option
    component.$selectedOption.html(selectedOption.Label);
};

CustomSelectBox.prototype.updatePage = function () {
    const component = this;
    component.$handler.val(component.selectedOption.Value);
    component.$handler.trigger('change');
};

CustomSelectBox.prototype.bindClickOutsideEvent = function () {
    const component = this;
    $(document).on('click', function (event) {
        if ($(event.target).closest('.custom-select-box').length === 0) {
            component.closeOptions();
        }
    });
};

$(COMPONENT_SELECTOR).each((index, element) => new CustomSelectBox(element));
import $ from 'jquery';
import ajax from '../ajax/ajax';
import ControllerService from '../service-controller/service-controller';
import UserSaveObject from '../user-save-object/user-save-object';
import MapTileCache from '../map/map-tile-cache';
import ObjectUrlTracking from '../object-url-tracking/object-url-tracking';
import 'funda-slick-carousel';

// what does this module expose?
export default SearchMapInfoWindow;

// component configuration
const OBJECT_LIST_SELECTOR = '[data-search-map-infowindow-list]';
const OBJECT_INFO_URL_ATTR = 'data-search-map-infowindow-object-url';
const MAP_SELECTOR = '[data-map]';
const CLOSE_BUTTON_SELECTOR = '[data-close-infowindow]';
const TIMEOUT_SEARCH_REQUEST = 30000; // 30 sec

const POSITION_OBJECT_SELECTOR = '[data-search-map-infowindow-position]';
const POSITION_SEPARATOR_ATTR = 'data-search-map-infowindow-separator';
const NAVIGATION_SELECTOR = '[data-search-map-infowindow-navigation]';
const PREVIOUS_SELECTOR = '[data-search-map-infowindow-previous]';
const NEXT_SELECTOR = '[data-search-map-infowindow-next]';

const IS_VISIBLE = 'is-visible';
const IS_UPDATING = 'is-updating';
const MEASURE = 'measure';
const SHOW_OBJECT_INFO_EVENT = 'show_object_info';
const HIDE_OBJECT_INFO_EVENT = 'hide_object_info';

const SLICK_SLIDE_SPEED = 250;
const SLICK_SWIPE_SENSITIVITY = 12; // 1/12th of the image width

const EVENT_NAMESPACE = '.keyupBinding';
const SLIDE_RESET = 0;
const KEY_CODE = {
    ESCAPE: 27,
    LEFT: 37,
    RIGHT: 39
};


function SearchMapInfoWindow(element, parent) {
    const component = this;
    component.$element = $(element);
    component.$map = $(MAP_SELECTOR);
    component.objects = [];
    component.objectsAreVisible = false;
    component.objectUrl = component.$element.attr(OBJECT_INFO_URL_ATTR);
    component.$objectList = component.$element.find(OBJECT_LIST_SELECTOR);
    //navigation buttons
    component.$navigation = component.$element.find(NAVIGATION_SELECTOR);
    component.$previousButton = component.$navigation.find(PREVIOUS_SELECTOR);
    component.$nextButton = component.$navigation.find(NEXT_SELECTOR);

    component.slideshowIsInitialized = false;
    component.currentSlide = 0;
    component.$positionObject = component.$navigation.find(POSITION_OBJECT_SELECTOR);
    component.positionSeparator = component.$positionObject.attr(POSITION_SEPARATOR_ATTR);
    // caching
    component.mapTileCache = MapTileCache;
    component.parent = parent;
    component.skipUpdate = false;
    component.selectedObjectScreenPos = null;

    component.bindEvents();
}

SearchMapInfoWindow.prototype.bindEvents = function () {
    const component = this;

    component.$map.on(SHOW_OBJECT_INFO_EVENT, (event, eventArgs) => component.processEvent(eventArgs));
    component.$map.on(HIDE_OBJECT_INFO_EVENT, () => component.hide());
    component.$objectList.on('beforeChange', (event, slick, currentSlide, nextSlide) => component.updatePosition(nextSlide));
    component.bindCloseButtonEvent();
};

SearchMapInfoWindow.prototype.bindCloseButtonEvent = function() {
    const component = this;

    component.$element.on('click', CLOSE_BUTTON_SELECTOR, () => {
        component.hide();
        component.parent.recordMapState({id: null});
    });
};

SearchMapInfoWindow.prototype.processEvent = function(eventArgs) {
    const component = this;

    let toBeLoadedObjectIds = null;
    component.selectedObjectScreenPos = null;
    // default selected slide
    component.currentSlide = SLIDE_RESET;
    if (eventArgs.initial) {
        let storedId = MapTileCache.getMarkerIdByPartial(eventArgs.objectId);
        toBeLoadedObjectIds = storedId.split('-');
        if (toBeLoadedObjectIds.length > 1) {
            component.currentSlide = toBeLoadedObjectIds.indexOf(eventArgs.objectId);
        }
        component.skipUpdate = true;
    } else {
        toBeLoadedObjectIds = eventArgs.objectId.split('-');
    }
    // is info-window going to obscure the marker?
    let selectedObjectId = MapTileCache.getMarkerByPartial(toBeLoadedObjectIds[0]);
    if (selectedObjectId) {
        component.selectedObjectScreenPos = component.getSelectedScreenCoordinates(selectedObjectId);
    }
    component.loadObjectInfo(toBeLoadedObjectIds);
};

SearchMapInfoWindow.prototype.getSelectedScreenCoordinates = function(selectedObjectId) {
    const google = window.google;

    let topRight = this.parent.map.getProjection().fromLatLngToPoint(this.parent.map.getBounds().getNorthEast());
    let bottomLeft = this.parent.map.getProjection().fromLatLngToPoint(this.parent.map.getBounds().getSouthWest());
    let latLng = new google.maps.LatLng(selectedObjectId.lat, selectedObjectId.lng);
    let scale = Math.pow(2, this.parent.map.getZoom());
    let latLngPoint = this.parent.map.getProjection().fromLatLngToPoint(latLng);
    let point2 = new google.maps.Point((latLngPoint.x - bottomLeft.x) * scale, (latLngPoint.y - topRight.y) * scale);
    // add offset to map
    let mapPos = this.parent.$element.offset();
    point2.x += mapPos.left;
    point2.y += mapPos.top;
    // modify for marker image
    let s = {w: 22, h: 28};
    if (this.parent.mapMarkerManager.pixelDistance == 14) {
        point2.x -= 11;
        point2.y -= 28;
    } else {
        point2.x -= 9;
        point2.y -= 22;
        s = {w: 18, h: 22};
    }
    return {
        x1: point2.x,
        y1: point2.y,
        x2: point2.x + s.w,
        y2: point2.y + s.h
    };
};

SearchMapInfoWindow.prototype.show = function() {
    const component = this;
    component.$element.removeClass(MEASURE);
    if (!component.objectsAreVisible) {
        component.$element.addClass(IS_VISIBLE);
        component.objectsAreVisible = true;
    }
};

SearchMapInfoWindow.prototype.measure = function() {
    const component = this;
    const google = window.google;

    component.$element.addClass(MEASURE);
    let $ele = component.$element.find('.search-map-infowindow__list');
    let point = $ele.offset();
    let size = {w: $ele.width(), h: $ele.height()};
    // add padding
    point.left -= 10; point.top -= 69; size.w += 20; size.h += 89;
    if (component.selectedObjectScreenPos !== null) {
        let markerPosition = component.selectedObjectScreenPos;
        // is marker within region of info-window
        if (markerPosition.x1 >= point.left && markerPosition.x1 <= point.left + size.w && markerPosition.y1 >= point.top && markerPosition.y1 <= point.top + size.h) {
            let yOffset = markerPosition.y2 - point.top - 20;
            google.maps.event.addListenerOnce(this.parent.map, 'idle', function () {
                component.show();
            });
            this.parent.mapEventProxy.forwardProxiedEvents(false);
            this.parent.map.panBy(0, yOffset);
            this.parent.mapEventProxy.forwardProxiedEvents(true);
        } else {
            component.show();
        }
    }
};

SearchMapInfoWindow.prototype.hide = function() {
    const component = this;
    component.$element.removeClass(IS_VISIBLE);
    component.objectsAreVisible = false;
    $(document).off('keydown' + EVENT_NAMESPACE);
};

/**
 * Load the object information from server
 * @param {Integer} tinyId
 */
SearchMapInfoWindow.prototype.loadObjectInfo = function (objectIds) {
    const component = this;
    component.objects = component.getObjectList(objectIds);
    component.$objectList.addClass(IS_UPDATING);

    let objectIdsQuery = component.objects.join('-');
    let currentSearchQuery = encodeURIComponent(component.parent.currentSearch);

    let url = component.objectUrl.replace('{objectId}', objectIdsQuery).replace('{searchQuery}', currentSearchQuery);

    // abort previous request if still pending
    if (component.request) {
        component.request.abort();
    }
    component.request = ajax({
        url: url,
        method: 'GET',
        dataType: 'JSON',
        timeout: TIMEOUT_SEARCH_REQUEST
    });
    component.request.done((data) => {
        if (data !== undefined && data !== null) {
            component.updateResults(data);
        }
    });

    component.request.fail((err) => {
        if (err.statusText !== 'abort') {
            component.isUpdated();
        }
    });
};

SearchMapInfoWindow.prototype.isUpdated = function() {
    const component = this;
    setTimeout(() => {
        component.$objectList.removeClass(IS_UPDATING);
    }, 250);
    $(document).trigger('resultsUpdated');
};

/**
 * Set new results on the page
 * @param {Object} data
 */
SearchMapInfoWindow.prototype.updateResults = function(data) {
    const component = this;
    const content = data.content;
    if (content !== '') {
        component.removeSlickBinding();
        component.$objectList.html(content);
        ObjectUrlTracking.initialize();
        ControllerService.getAllInstances(UserSaveObject);
        if (component.objects.length > 1) {
            component.addSlickBinding();
        }
        $(document).on('keyup' + EVENT_NAMESPACE, (e) => {
            if (e.keyCode == KEY_CODE.ESCAPE) {
                component.parent.recordMapState({id: null});
                component.parent.markerOverlay.hideObjectInfo();
            }
        });

        component.measure();
    }
    component.isUpdated();
};

/**
 * Initialize slick and determine current slide
 * @param {Number} currentSlide
 */
SearchMapInfoWindow.prototype.addSlickBinding = function() {
    const component = this;
    // start by a given point
    component.updatePosition(component.currentSlide);
    // bind carousel to results
    component.$objectList.slick({
        speed: SLICK_SLIDE_SPEED,
        cssEase: 'ease',
        mobileFirst: true,
        infinite: false,
        useTransform: true,
        touchThreshold: SLICK_SWIPE_SENSITIVITY,
        initialSlide: component.currentSlide || 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: component.$nextButton,
        prevArrow: component.$previousButton
    });
    component.slideshowIsInitialized = true;
    // add key bindings for easy navigating through the results
    $(document).on('keyup' + EVENT_NAMESPACE, (e) => {
        if (e.keyCode == KEY_CODE.LEFT && !e.altKey) {
            component.$objectList.slick('slickPrev', false);
        }
        else if (e.keyCode == KEY_CODE.RIGHT && !e.altKey) {
            component.$objectList.slick('slickNext', false);
        }
    });
    // show navigation
    component.$navigation.addClass(IS_VISIBLE);
};

SearchMapInfoWindow.prototype.removeSlickBinding = function() {
    const component = this;
    // hide navigation and undo key binding
    component.$navigation.removeClass(IS_VISIBLE);
    $(document).off('keydown' + EVENT_NAMESPACE);

    if (component.slideshowIsInitialized) {
        component.$objectList.slick('unslick');
        component.slideshowIsInitialized = false;
    }
};

/**
 * update position result
 * @param {Number} nextSlide; zero index based number
 */
SearchMapInfoWindow.prototype.updatePosition = function(nextSlide) {
    const component = this;
    if (nextSlide < 0) {
        nextSlide = 0;
    }
    const currentSlide = (parseInt(nextSlide, 10) + 1);
    const amountObjects = component.objects.length;
    const positionDescription = currentSlide + ' ' + component.positionSeparator + amountObjects;

    //history.pushState(null, null, window.location.search.replace(/id=.*(&?)/, 'id=' + component.objects[currentSlide - 1]));
    if (!component.skipUpdate) {
        component.parent.recordMapState({id: component.objects[currentSlide - 1]});
    } else {
        component.skipUpdate = false;
    }

    component.$positionObject.text(positionDescription);
};

/**
 * Set string to array
 * @param {string} objects
 * @returns {*|Array}
 */
SearchMapInfoWindow.prototype.getObjectList = function(objectIds) {
    if (typeof objectIds == 'string') {
        return objectIds.split('-');
    }
    return objectIds;
};

// turn all elements with the default selector into components
//$(COMPONENT_SELECTOR).each((index, element) => new SearchMapInfoWindow(element));

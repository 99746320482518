// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import debounce from 'lodash/debounce';
import MapEventProxy from '../map/map-event-proxy';
import MapEvents from '../map/map-events';
import MapLocalStorage from '../map-localstorage/map-localstorage';
import gmapsAsyncLoad from '../gmaps-async/gmaps-async';

import MapTypeEventLogger from '../gtm-event-logger/event-loggers/map-type-event-logger';
import MapCreationEventLogger from '../gtm-event-logger/event-loggers/map-creation-event-logger';

// component configuration
const CONFIG_SELECTOR = '[data-map-config]';
const FULL_VIEWPORT_HANDLER = '[data-map-full-viewport]';
const CANVAS_SELECTOR = '[data-map-canvas]';
const EVENT_NAMESPACE = '.map';

const MAP_ZOOM_ZOOMIN_SELECTOR = '[data-map-zoom-zoomin]';
const MAP_ZOOM_ZOOMOUT_SELECTOR = '[data-map-zoom-zoomout]';
const MIN_ZOOM = 8;
const MAX_ZOOM = 19;

const HEADER_HEIGHT = 52; // px
const IS_LOADED_CLASS = 'is-loaded';
const IS_TOUCH_DEVICE_CLASS = 'is-touch-device';

const HISTORY_PUSH_STATE = 'push_state_set_by_funda';
const CENTER_MAP_EVENT = 'center_map_on';
const MAP_VIEW_SELECTOR_CLOSE_EVENT = 'close_view_type_box';
const MAP_VIEW_TYPE_SELECT_SELECTOR = '[data-search-map-type-select]';
const MAP_VIEW_TYPE_DEFAULT_SELETBOX_SELECTOR = '[id^=search-map-type-default]';
const MAP_VIEW_TYPE_SATELLITE_SELETBOX_SELECTOR = '[id^=search-map-type-satellite]';

const $window = $(window);
const MINIMUM_INTERVAL_BETWEEN_RESIZE_CANVAS = 250; // ms
const TILE_SIZE = 256;

/**
 * Will create the Google Map
 * @param {Element} element
 * @param {Function} mapLoadedHandler
 * @constructor
 */
export default class Map {
    constructor(element, mapLoadedHandler) {
        const component = this;
        component.$element = $(element);
        component.$canvas = component.$element.find(CANVAS_SELECTOR);
        component.config = JSON.parse(component.$element.find(CONFIG_SELECTOR).text());
        component.isFullViewport = component.$element.is(FULL_VIEWPORT_HANDLER);
        // event proxy
        component.mapEventProxy = MapEventProxy;
        // map data
        component.map = null;
        component.mapState = null;
        component.tileSize = null;
        component.mapLoadedHandler = mapLoadedHandler || null;
        // map controls
        component.isTouchDevice = false;
        component.$zoomIn = component.$element.find(MAP_ZOOM_ZOOMIN_SELECTOR);
        component.$zoomOut = component.$element.find(MAP_ZOOM_ZOOMOUT_SELECTOR);
        // map view switch
        component.$defaultMapTypeSelector = $(MAP_VIEW_TYPE_DEFAULT_SELETBOX_SELECTOR);
        component.$satelliteMapTypeSelector = $(MAP_VIEW_TYPE_SATELLITE_SELETBOX_SELECTOR);

        component.mapTypeEventLogger = new MapTypeEventLogger();
        component.mapCreationEventLogger = new MapCreationEventLogger();

        if (component.isFullViewport) {
            component.setCanvasHeight();
        }

        // initialize popstate
        window.history.replaceState(HISTORY_PUSH_STATE, window.title);

        component.initialize();
        component.bindEvents();
    }

    /**
     * Bind all events and build the map
     * All events are bounded we can load the map. For other load events we can overwrite this function
     */
    bindEvents() {
        const component = this;

        $(document).on('change', MAP_VIEW_TYPE_SELECT_SELECTOR, (event) => {
            component.switchMapType(event.currentTarget.value);
        });
    }

    initialize() {
        const component = this;

        gmapsAsyncLoad()
            .then(function () {
                component.create();
            });
    }

    switchMapType(mapType) {
        const component = this;
        const google = window.google;

        switch (mapType) {
            case 'roadmap':
                component.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
                component.$defaultMapTypeSelector.prop('checked', true); // sync the checkboxes
                break;
            case 'satellite':
                component.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
                component.$satelliteMapTypeSelector.prop('checked', true); // sync the checkboxes
                break;
            default:
                component.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
                component.$defaultMapTypeSelector.prop('checked', true); // sync the checkboxes
                break;
        }

        component.updateGTMMapType(mapType);
    }

    updateGTMMapType(mapTypeName) {
        const component = this;
        component.mapTypeEventLogger.log(mapTypeName);
    }

    /**
     * Set the height of the canvas
     */
    setCanvasHeight() {
        const component = this;
        const height = ($window.height() - HEADER_HEIGHT);
        component.$element.css({ 'height': height });
    }

    /**
     * Create and insert map on component's canvas element.
     * @return {*} component's Google Map instance.
     */
    create() {
        const component = this;
        const google = window.google;

        // Create the Map with Street View with options
        component.mapCreationEventLogger.log('search-on-map');
        component.map = new google.maps.Map(component.$canvas[ 0 ], component.mapSettings());
        component.tileSize = new google.maps.Size(TILE_SIZE, TILE_SIZE);

        // Map is created, we can bind now the events
        component.mapEventProxy.setMapInstance(component.map);
        component.bindMapEvents();

        const centerPosition = MapEvents.getValueFromHash('center');
        if (!component.isValidLatlng(centerPosition) && component.config.geo) {
            component.setMapToBounds(component.config.geo);
        }
    }

    /**
     * Bind all Map events
     */
    bindMapEvents() {
        const component = this;

        // On idle state the map is loaded
        // http://stackoverflow.com/questions/832692/how-can-i-check-whether-google-maps-is-fully-loaded
        component.mapEventProxy.addListenerOnce('idle', () => {
            component.$element.addClass(IS_LOADED_CLASS);
            if (component.mapLoadedHandler !== null) {
                component.mapLoadedHandler.apply();
            }
            // initial trigger makes sure current state is saved (it will not update the hash)
            component.recordMapState();
        });
        component.zoomLimitHandlerId = component.mapEventProxy.addListener('zoom_changed', () => {
            const currentZoom = component.map.getZoom();
            if (currentZoom > MAX_ZOOM) {
                component.map.setZoom(MAX_ZOOM);
            }
            else if (currentZoom < MIN_ZOOM) {
                component.map.setZoom(MIN_ZOOM);
            }
        });

        component.$zoomIn.on('click', (event) => {
            event.preventDefault();
            const currentZoomLevel = component.map.getZoom();

            if (currentZoomLevel !== MAX_ZOOM) {
                component.map.setZoom(currentZoomLevel + 1);
            }
        });
        component.$zoomOut.on('click', (event) => {
            event.preventDefault();
            const currentZoomLevel = component.map.getZoom();

            if (currentZoomLevel !== MIN_ZOOM) {
                component.map.setZoom(currentZoomLevel - 1);
            }
        });
        component.$element.on(CENTER_MAP_EVENT, (event, eventArgs) => component.setMapToBounds(eventArgs));

        // https://css-tricks.com/debouncing-throttling-explained-examples/`
        function debouncedResizeCanvas() {
            return debounce(function () {
                if (component.isFullViewport) {
                    component.setCanvasHeight();
                }
                component.updateCenterMap();
                component.$element.trigger(MAP_VIEW_SELECTOR_CLOSE_EVENT);
            }, MINIMUM_INTERVAL_BETWEEN_RESIZE_CANVAS, { leading: false, trailing: true });
        }

        $window
            .on('resize' + EVENT_NAMESPACE, debouncedResizeCanvas())
            .on('touchstart' + EVENT_NAMESPACE, function () {
                component.setIsTouchDevice();
            });
    }

    /**
     * When the viewport is resized, update the center of the map
     */
    updateCenterMap() {
        const component = this;
        const google = window.google;

        google.maps.event.trigger(component.map, 'resize');
        component.map.setCenter(component.mapState.center);
    }

    recordMapState(param) {
        const component = this;
        let newState = {
            center: component.map.getCenter(),
            zoom: component.map.getZoom(),
            id: null,
        };
        if (param !== undefined && 'id' in param) {
            newState.id = param.id;
            if (param.id) {
                MapLocalStorage.storeViewedId(param.id);
            }
        }
        if (component.mapState !== null) {
            MapEvents.updateHash(component.mapState, newState);
        } else {
            const idParam = MapEvents.getValueFromHash('id');
            if (idParam !== null && idParam !== '') {
                newState.id = idParam;
            }
        }
        component.mapState = newState;
    }

    /**
     * Determine if device is touch. on touch device the zoom buttons will be hidden
     */
    setIsTouchDevice() {
        const component = this;
        component.isTouchDevice = true;
        component.$element.addClass(IS_TOUCH_DEVICE_CLASS);
        $window.off('touchstart' + EVENT_NAMESPACE);
    }

    /**
     * All configuration settings for the map are set
     * @returns {{center: {lat: *, lng: *}, zoom: number}}
     */
    mapSettings() {
        const component = this;
        const config = component.config;
        const google = window.google;

        let center = null;
        const centerPosition = MapEvents.getValueFromHash('center');
        if (component.isValidLatlng(centerPosition)) {
            const latlng = centerPosition.split(',');
            center = {
                lat: parseFloat(latlng[ 0 ]),
                lng: parseFloat(latlng[ 1 ])
            };
        } else {
            center = { lat: config.lat, lng: config.lng };
        }

        return {
            center: center,
            zoom: parseInt(MapEvents.getValueFromHash('zoom'), 10) || 10,
            draggableCursor: 'default',
            draggingCursor: 'default',
            gestureHandling: 'greedy',

            // Map control options
            disableDefaultUI: true,
            // Ignore clicking on public transport icons
            clickableIcons: false,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            },

            // Remove POI
            styles: [
                {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [
                        { visibility: 'off' }
                    ]
                }
            ]
        };
    }

    /**
     * Determine if given string is a valid latlng
     * @param {string} latlng; Expected: 52.1179698200444,6.092906208496065 (example)
     * @returns {boolean}
     */
    isValidLatlng(latlng) {
        // there is no latlng given
        if (latlng === undefined || latlng === null) return false;
        // determine if latlng is valid
        const hasLatlngMatch = latlng.search(/(^[0-9]{1,3}\.)([\d]+),([0-9]{1,3}\.)([\d]+)/g);
        return hasLatlngMatch === 0;
    }

    /**
     * sets map to given bounds
     */
    setMapToBounds(geoLocation) {
        const component = this;
        const google = window.google;

        component.map.panTo(new google.maps.LatLng(
            ((geoLocation.MaxYWgs + geoLocation.MinYWgs) / 2.0),
            ((geoLocation.MaxXWgs + geoLocation.MinXWgs) / 2.0)
        ));
        component.map.fitBounds(new google.maps.LatLngBounds(
            new google.maps.LatLng(geoLocation.MinYWgs, geoLocation.MinXWgs),
            new google.maps.LatLng(geoLocation.MaxYWgs, geoLocation.MaxXWgs)
        ));
        component.recordMapState();
        component.switchMapType(component.map.mapTypeId === 'hybrid' ? 'satellite' : 'roadmap');
    }
}
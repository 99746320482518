// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default ObjectDetailInteraction;

// component configuration
var COMPONENT_SELECTOR = '[data-interaction-logging]';
var SCROLL_WATCH_SELECTOR = '[data-scroll-watch]';
var SWIPE_SELECTOR = '[data-interaction-swipe]';
var CLICK_SELECTOR = '[data-interaction-click]';

var LOGGING_ATTR = 'data-interaction';
var URL_LOGGING_ATTR = 'data-interaction-url';
var TINYID_ATTR = 'data-interaction-tinyid';
var OVERRIDE_ATTR = 'data-interaction-override';

var $body = $('body');

function ObjectDetailInteraction(element) {
    var component = this;

    component.$element = $(element);
    component.loggingUrl = element.getAttribute(URL_LOGGING_ATTR);
    component.tinyId = element.getAttribute(TINYID_ATTR);
    component.loggedActions = [];

    $body.on('click', `*${CLICK_SELECTOR}`, (event) => this.registerInteractionHandler(event));

    $body.on('swipe', `*${SWIPE_SELECTOR}`, (event) => this.registerInteractionHandler(event));

    registerInteractionWatchers(component);
}

ObjectDetailInteraction.prototype.registerInteractionHandler = function(event) {
    // when override selector is found prevent default action and axecute when post returns
    if (event.currentTarget.hasAttribute(OVERRIDE_ATTR)) {
        event.preventDefault();
    }
    this.logInteraction(event.currentTarget);
};

function registerInteractionWatchers(component) {
    // handle scroll interactions
    var scrollWatchers = $(SCROLL_WATCH_SELECTOR);
    if (scrollWatchers.length > 0) {
        // add watched items (currently not in viewport) to array
        component.watchedScrollElements = [];
        for (var watchIdX = 0; watchIdX < scrollWatchers.length; watchIdX++) {
            if (!component.isElementInViewport(scrollWatchers[watchIdX])) {
                component.watchedScrollElements.push(scrollWatchers[watchIdX]);
            }
        }
        // if any items in array bind to scroll event
        if (component.watchedScrollElements.length > 0) {
            $(window).on('scroll', component.debounce(function() {
                for (var watchIdY = 0; watchIdY < component.watchedScrollElements.length; watchIdY++) {
                    var checkElement = component.watchedScrollElements[watchIdY];
                    if (component.isElementInViewport(checkElement)) {
                        component.logInteraction(checkElement);
                    }
                }
            }, 100));
        }
    }
}

ObjectDetailInteraction.prototype.logInteraction = function(element) {
    var component = this;
    var logAction = $(element).attr(LOGGING_ATTR);
    // only send item once
    if (component.loggedActions.indexOf(logAction) === -1) {
        component.loggedActions.push(logAction);
        $.ajax({
            type: 'POST',
            url: component.loggingUrl,
            data: {tinyId: component.tinyId, logAction: logAction}
        }).always(function() {
            // act when override selector is found
            if (element.hasAttribute(OVERRIDE_ATTR)) {
                var href = element.getAttribute('href');
                if (href !== null && href !== '') {
                    document.location = href;
                }
            }
        });
    }
};

ObjectDetailInteraction.prototype.debounce = function(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this;
        var args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
};

ObjectDetailInteraction.prototype.isElementInViewport = function(element) {
    var checkElement = element;
    // get DOM element from jquery
    if (typeof $ === 'function' && element instanceof $) {
        checkElement = element[0];
    }
    var rect = checkElement.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new ObjectDetailInteraction(element);
});
/**
 * App
 * include all views, which in turn include all their components
 */

// prevent console errors in legacy browsers (needs to be included first)
import './components/_dev-console/dev-console';

// required to use funda-floorplanner on IE
import 'babel-polyfill';

// validation library used in forms
import 'jquery-validation-unobtrusive';

// views (alphabetically)
import './views/home/home';

import './views/aanmeld-form/aanmeld-form';
import './views/beoordelingen-widget/beoordelingen-widget';
import './views/contact-form-brochure/contact-form-brochure';
import './views/contact-form-makelaar/contact-form-makelaar';
import './views/contact-form-object/contact-form-object';
import './views/contact-form-plan-viewing/contact-form-plan-viewing';
import './views/content-trending/content-trending';
import './views/fib-home/fib-home';
import './views/fib-makelaars-landing/fib-makelaars-landing';
import './views/fib-search/fib-search';
import './views/hypotheek-contact/hypotheek-contact';
import './views/maandlasten-indicator-landing/maandlasten-indicator-landing';
import './views/makelaars-aanbod/makelaars-aanbod';
import './views/makelaars-kantoor/makelaars-kantoor';
import './views/makelaars-landing/makelaars-landing';
import './views/makelaars-collaborators/makelaars-collaborators';
import './views/nieuwbouwproject-detail/nieuwbouwproject-detail';
import './views/makelaars-search-results/makelaars-search-results';
import './views/object-detail/object-detail';
import './views/object-detail-print/object-detail-print';
import './views/object-meld-een-fout/object-meld-een-fout';
import './views/object-share-email/object-share-email';
import './views/search/search';
import './views/search-no-results/search-no-results';
import './views/user-cadeauflow/user-cadeauflow';
import './views/user-claimmyhouse/user-claimmyhouse';
import './views/user-login/user-login';
import './views/user-my-house/user-my-house';
import './views/user-object-rating/user-object-rating';
import './views/user-profile/user-profile';
import './views/user-recovery/user-recovery';
import './views/user-register/user-register';
import './views/user-report-abuse/user-report-abuse';
import './views/user-reviews/user-reviews';
import './views/user-reviews-write/user-reviews-write';
import './views/user-saved-objects/user-saved-objects';
import './views/verkoopleads/verkoopleads';
import './views/cookie-policy-page/cookie-policy-page';
import './views/zoek-op-kaart/zoek-op-kaart';

/* This component is not used in any view, that's why it is listed here */
import './components/media-foto360/media-foto360';

/* Do not add any components here. Import them in their parent views or other components */

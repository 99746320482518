// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

export default Goto;

// constant definitions
const COMPONENT_SELECTOR = '[data-goto]';
const SPEED_MILLIS = 600;

function Goto(element) {
    const component = this;
    component.$element = $(element);

    component.bindEvents();
}

Goto.prototype.bindEvents = function () {
    const component = this;

    component.$element.click((e) => component.goToElement(e));
};

Goto.prototype.goToElement = function (event) {
    const component = this;

    event.preventDefault();

    $('html, body').animate({
        scrollTop: $(component.$element.attr('href')).offset().top
    }, SPEED_MILLIS);

};

$(COMPONENT_SELECTOR).each((index, element) => new Goto(element));
import $ from 'jquery';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import { getLocationById } from '../store/reducers';

import MapMarkersOverlay from '../map-markers-overlay/map-markers-overlay';
import GeometryUtils from './geometry-utils';
import { setPoiTravelDistance } from '../store/actions';

const EVENT_ROUTE_SHOW_SPINNER = 'map-route-show-spinner';
const EVENT_ROUTE_HIDE_SPINNER = 'map-route-hide-spinner';
const ROUTE_POLY = [
    {strokeColor: '#fff', strokeOpacity: 0.8, strokeWeight: 10, zIndex: 1},
    {strokeColor: '#0085ff', strokeOpacity: 1, strokeWeight: 6, zIndex: 2}
];
const SHOW_ROUTE_ERROR_EVENT = 'map-route-error-event';
const COMMON_ROUTE_ERROR_TYPE = 'map-route-common-error';
const LIMIT_ROUTE_ERROR_TYPE = 'map-route-limit-error';
const HIDE_ALL_ROUTE_ERROR_TYPE = 'map-route-hide-error';

const $document = $(document);

export default class MapRoute {
    constructor(googleMap, map, mapConfig, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'ui.activeLocationId',
            'ui.modality',
            'getPersonalPlaces'
        ]);
        this.googleMap = googleMap;
        this.map = map;
        this.mapConfig = mapConfig;
        this.lastDrawnRoute = null;

        if (googleMap) {
            this.mapMarkerOverlay = new MapMarkersOverlay(googleMap, map, 'media-viewer-map');
        }

        this.bindEvents();

        const state = this.store.getState();
        const activeLocationId = state.ui.activeLocationId;
        if (activeLocationId) {
            this.layout(state, activeLocationId);
        }
    }

    bindEvents() {
        this.connect.subscribe((state, prevState) => {
            const activeLocationId = state.ui.activeLocationId;
            const prevActiveLocationId = prevState.ui.activeLocationId;

            const activeLocationChanged = activeLocationId && (activeLocationId !== prevActiveLocationId);
            const modalityChanged = state.ui.modality !== prevState.ui.modality;

            if ((activeLocationId !== null) && (activeLocationChanged || modalityChanged || state.getPersonalPlaces.loaded)) {
                this.layout(state, activeLocationId);
            }

            if (!activeLocationId) {
                if (this.mapMarkerOverlay) {
                    this.mapMarkerOverlay.clear();
                }

                this.clearRoute();
            }
        });
    }

    layout(state, locationId) {
        const location = getLocationById(state, locationId);
        if (!location) {
            return;
        }
        this.recalculateAndDrawRoute(
            { lat: this.mapConfig.lat, lng: this.mapConfig.lng },
            { lat: location.lat, lng: location.lng },
            state.ui.modality
        );
    }

    recalculateAndDrawRoute(from, to, modality) {
        if (this.currentRouteRequest && this.currentRouteRequest.state() === 'pending') {
            this.currentRouteRequest.abort();
        }

        this.currentRouteRequest = this.createFetchRoutePolygonsRequest(from, to, modality);
    }

    createFetchRoutePolygonsRequest(from, to, modality) {
        this.store.dispatch(setPoiTravelDistance());

        if (!this.mapMarkerOverlay) {
            return;
        }

        this.mapMarkerOverlay.clear();
        this.clearRoute();

        $document.trigger(EVENT_ROUTE_SHOW_SPINNER);

        return $.ajax({url: this.createRouteQueryUrl(from, to, modality)})
            .done((response) => {
                if (response.routes && response.routes.length > 0) {
                    this.store.dispatch(setPoiTravelDistance(response));
                    const {routeBounds, routePolyline} = MapRoute.calculateRoute(response.routes);
                    this.drawRoute(routePolyline, response.displayTime, routeBounds);
                    $document.trigger(SHOW_ROUTE_ERROR_EVENT, {type: HIDE_ALL_ROUTE_ERROR_TYPE});
                }
            })
            .fail((response) => {
                const isLimitError = response.getResponseHeader('X-RouteError') === 'Limit exceeded';
                this.store.dispatch(setPoiTravelDistance());
                $document.trigger(SHOW_ROUTE_ERROR_EVENT, {type: isLimitError ? LIMIT_ROUTE_ERROR_TYPE : COMMON_ROUTE_ERROR_TYPE});
            })
            .always(() => {
                $document.trigger(EVENT_ROUTE_HIDE_SPINNER);
            });
    }

    createRouteQueryUrl(from, to, modality) {
        return `${this.mapConfig.baseRouteUrl}?sourceCoords=${from.lat},${from.lng}&destCoords=${to.lat},${to.lng}&travelmode=${modality}`;
    }

    logErrorToServer(message, errorObject) {
        return $.post(this.mapConfig.baseJsErrorUrl, {
            message,
            stack: errorObject.hasOwnProperty('stack') && errorObject.stack ? errorObject.stack : null
        });
    }

    clearRoute() {
        if (this.lastDrawnRoute) {
            for (let itemIdx in this.lastDrawnRoute.routeItems) {
                this.lastDrawnRoute.routeItems[itemIdx].setMap(null);
            }
            this.lastDrawnRoute = null;
        }
    }

    static calculateRoute(routes) {
        let routeBounds = new window.google.maps.LatLngBounds();
        let routePolyline = [];

        for (let routeIdx = 0; routeIdx < routes.length; routeIdx++) {
            const route = routes[routeIdx];
            for (let segmentIdx = 0; segmentIdx < route.segments.length; segmentIdx++) {
                const segment = route.segments[segmentIdx];
                if (segment.type !== 'TRANSFER') {
                    for (let pointIdx = 0; pointIdx < segment.points.length; pointIdx++) {
                        const point = segment.points[pointIdx];
                        const latLng = GeometryUtils.webMercatorToLatLng({x: point[1], y: point[0]});
                        routePolyline.push(latLng);
                        routeBounds.extend(latLng);
                    }
                }
            }
        }
        return {routeBounds, routePolyline};
    }

    drawRoute(routePolyPoints, displayTime, routeBounds) {
        const google = window.google;

        const routeData = {
            bounds: routeBounds,
            routeItems: ROUTE_POLY.map(poly => {
                let polyOptions = {
                    path: routePolyPoints,
                    map: this.map,
                };
                return new google.maps.Polyline(Object.assign(polyOptions, poly));
            })
        };
        // add midway marker
        const routeItemPath = routeData.routeItems[0].getPath();
        const fullLength = google.maps.geometry.spherical.computeLength(routeItemPath);
        const halfWay = MapRoute.getPointAtDistance(routeItemPath, fullLength / 2);
        if (halfWay) {
            const markerHtml = this.getMarkerHtml(displayTime);
            routeData.marker = {
                halfWay,
                html: $(markerHtml)[0]
            };
            this.mapMarkerOverlay.addMarker(halfWay, $(markerHtml)[0], 'route');
        } else {
            let errMsg = 'Failed to add route marker, could not calculate halfway point.';
            console.error(errMsg);
            this.logErrorToServer(errMsg, new Error());
        }

        const routeIsNotInMapBounds = this.map.getBounds().contains(routeBounds.getNorthEast()) == false
            || this.map.getBounds().contains(routeBounds.getSouthWest()) == false;

        if (routeIsNotInMapBounds) {
            this.map.fitBounds(routeBounds);
        }

        // cache drawn route
        this.lastDrawnRoute = routeData;
    }

    getMarkerHtml(displayTime) {
        const modality = this.store.getState().ui.modality;
        let iconClass = modality === 'car' ? 'icon-car' :
            modality === 'transit' ? 'icon-bus' :
                modality === 'bike' ? 'icon-bike' : 'icon-walk';

        return `<div class="markers-overlay map-route-marker">
                <div class="marker">
                    <span class="marker__icon ${iconClass}"></span>
                    <span class="marker__duration">${displayTime}</span>
                </div>
            </div>`;
    }

    static getPointAtDistance(path, distance) {
        const google = window.google;

        // some awkward special cases
        if (distance === 0) return path.getAt(0);
        if (distance < 0 || path.getLength() < 2) return null;

        // trace path until we find correct step
        const areWeThereYet = {dist: 0};
        for (let index = 0; index < path.getLength() - 1 && areWeThereYet.dist < distance; index++) {
            areWeThereYet.from = path.getAt(index);
            areWeThereYet.to = path.getAt(index + 1);
            areWeThereYet.stepDist = google.maps.geometry.spherical.computeDistanceBetween(areWeThereYet.from, areWeThereYet.to);
            areWeThereYet.dist += areWeThereYet.stepDist;
        }
        // bail if we haven't found step that does not pass distance
        if (areWeThereYet.dist < distance) return null;
        // calculate median point
        const fromDist = areWeThereYet.dist - areWeThereYet.stepDist;
        const stepOffset = (distance - fromDist) / (areWeThereYet.dist - fromDist);
        return new google.maps.LatLng(
            areWeThereYet.from.lat() + (areWeThereYet.to.lat() - areWeThereYet.from.lat()) * stepOffset,
            areWeThereYet.from.lng() + (areWeThereYet.to.lng() - areWeThereYet.from.lng()) * stepOffset
        );
    }
}

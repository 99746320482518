import $ from 'jquery';
import contentFetch from '../content-fetch/content-fetch';
export default AdvertisementHypotheekAdvies;

// component configuration
const COMPONENT_SELECTOR = '[data-hypotheek-advies]';
const COMPONENT_SELECTOR_TARGET = '[data-hypotheek-advies-box]';
const COMPONENT_TRIGGER_SELECTOR_TARGET = '[data-hypotheek-advies-handle]';
const COMPONENT_TARGET_SELECTOR_TARGET = '[data-hypotheek-advies-target]';
const CATEGORIES_URL_ATTR = 'data-content-fetch-url';
const fetchDataCallback = function() { AdvertisementHypotheekAdvies.prototype.handleComplete(); };

function AdvertisementHypotheekAdvies(element) {
    const component = this;
    component.urlFeed = element.getAttribute(CATEGORIES_URL_ATTR);
    contentFetch(element, component.urlFeed, fetchDataCallback);
}

AdvertisementHypotheekAdvies.prototype.handleComplete = function() {
    const component = this;
    $(COMPONENT_SELECTOR_TARGET).each(function(index, element) {
        component.toggleForm($(element));
    });
};

AdvertisementHypotheekAdvies.prototype.toggleForm = function($element) {
    const component = this;
    component.$trigger = $element.find(COMPONENT_TRIGGER_SELECTOR_TARGET);
    component.$target = $element.find(COMPONENT_TARGET_SELECTOR_TARGET);
    component.$inputs = component.$target.find('input');
    component.handleChange(component.$trigger, component.$inputs);
};

AdvertisementHypotheekAdvies.prototype.handleChange = function($trigger, $inputs) {
    const component = this;
    $trigger.on('change', function() {
        if ($(this).is(':checked')) {
            component.checkedFeedback($inputs);
        } else {
            component.unCheckedFeedback($inputs);
        }
    });
};

AdvertisementHypotheekAdvies.prototype.checkedFeedback = function(input) {
    $(input).parents(COMPONENT_SELECTOR_TARGET).addClass('is-expanded');
    input.prop('required', true);
};

AdvertisementHypotheekAdvies.prototype.unCheckedFeedback = function(input) {
    $(input).parents(COMPONENT_SELECTOR_TARGET).removeClass('is-expanded');
    input.prop('required', false);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new AdvertisementHypotheekAdvies(element);
});

import $ from 'jquery';
import AppSpinner from '../app-spinner/app-spinner';

// component configuration
const COMPONENT_SELECTOR = '[data-media-viewer-loading-overlay]';
const COMPONENT_SPINNER_SELECTOR = '[data-media-viewer-map-spinner]';
const IS_HIDDEN_CLASS = 'is-hidden';
const EVENT_ROUTE_SHOW_SPINNER = 'map-route-show-spinner';
const EVENT_ROUTE_HIDE_SPINNER = 'map-route-hide-spinner';


class MediaViewerSpinner {
    constructor(element) {
        const component = this;
        component.element = element;
        component.$element = $(element);

        // if appSpinner isn't called the loader won't be shown
        component.spinner = new AppSpinner(COMPONENT_SPINNER_SELECTOR);

        $(document).on(EVENT_ROUTE_SHOW_SPINNER, () => {
            component.show();
        });

        $(document).on(EVENT_ROUTE_HIDE_SPINNER, () => {
            component.hide();
        });
    }

    show() {
        const component = this;

        component.$element.removeClass(IS_HIDDEN_CLASS);
        component.spinner.show();
    }

    hide() {
        const component = this;

        component.$element.addClass(IS_HIDDEN_CLASS);
        component.spinner.hide();
    }
}

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function (index, element) {
    return new MediaViewerSpinner(element);
});

// require this module where needed, either in a specific view or component or generically in src/index.js
import isFunction from 'lodash/isFunction';
import union from 'lodash/union';


function MapEventProxy() {
    this._map = null;
    this._handlerId = 0;
    this._proxiedHandlers = {
        'click': {},
        'dblclick': {},
        'center_changed': {},
        'zoom_changed': {},
        'idle': {},
        'dragstart': {},
    };
    this._eventForwarding = true;
    this._enableProxyAfterIdle = false;
}

/**
 * Bind to map and proxy events
 */
MapEventProxy.prototype.setMapInstance = function(map) {
    if (map === undefined || map === null) {
        console.error('Invalid map instance');
    }
    const component = this;
    const google = window.google;

    component._map = map;
    // immediately hook all events
    for (let eventName in component._proxiedHandlers) {
        google.maps.event.addListener(component._map, eventName, (args) => {
            component._proxyEvent(eventName, args);
        });
    }
};

/**
 * Adds listener as target for proxied event
 */
MapEventProxy.prototype.addListener = function(eventName, handler) {
    if (!this._checkAddListenerParameters(eventName, handler)) return;

    return this._actuallyAddListener(eventName, handler);
};

/**
 * Adds listener as target for proxied event (fires once)
 */
MapEventProxy.prototype.addListenerOnce = function(eventName, handler) {
    if (!this._checkAddListenerParameters(eventName, handler)) return;

    return this._actuallyAddListener(eventName, handler, true);
};

/**
 * Verify if handler can be registered
 */
MapEventProxy.prototype._checkAddListenerParameters = function(eventName, handler) {
    if (!(eventName in this._proxiedHandlers)) {
        console.error('Cannot attach to event: \'' + eventName + '\'');
        return false;
    }
    if (!isFunction(handler)) {
        console.error('handler is not a function');
        return false;
    }
    return true;
};

/**
 * Store handler to be proxied
 */
MapEventProxy.prototype._actuallyAddListener = function(eventName, handler, runOnce=false) {
    this._proxiedHandlers[eventName][++this._handlerId] = {
        function: handler,
        runOnce: runOnce
    };
    return this._handlerId;
};

/**
 * Enable/disable triggering events on registered listeners
 */
MapEventProxy.prototype.forwardProxiedEvents = function(state=true) {
    if (!state) {
        // disable takes effect immediately
        this._eventForwarding = state;
    } else {
        // enable will be triggered after idle event (so map can settle after moving to previous location)
        this._enableProxyAfterIdle = true;
    }
};

/**
 * Remove listener
 */
MapEventProxy.prototype.removeListener = function(handlerId) {
    if (!this._isHandlerRegistered(handlerId)) return;

    let eventName = this._getHandlerEventName(handlerId);
    if (eventName === null) return;

    delete this._proxiedHandlers[eventName][handlerId];
};

/**
 * Checks if handler is registered
 */
MapEventProxy.prototype._isHandlerRegistered = function(handlerId) {
    // collect all ids from proxiedHandlers
    let allIds = [];
    for (let eventName in this._proxiedHandlers) {
        allIds = union(allIds, Object.keys(this._proxiedHandlers[eventName]));
    }

    return allIds.indexOf(handlerId) >= 0;
};

/**
 * Get event name for given handlerId
 */
MapEventProxy.prototype._getHandlerEventName = function(handlerId) {
    // collect all ids from proxiedHandlers
    for (let eventName in this._proxiedHandlers) {
        if (handlerId in this._proxiedHandlers[eventName]) {
            return eventName;
        }
    }
    return null;
};

/**
 * Forward events to register listeners
 */
MapEventProxy.prototype._proxyEvent = function(eventName, args) {
    const component = this;

    // skip not forwarding or when no handlers registered
    if (!this._eventForwarding) {
        // check if re-enaling events is requested (only on force idle event)
        if (this._enableProxyAfterIdle && eventName == 'idle') {
            this._eventForwarding = true;
            this._enableProxyAfterIdle = false;
        }
        return;
    }
    // skip if no events registered
    if (Object.keys(component._proxiedHandlers[eventName]).length == 0) return;

    for (var handlerId in component._proxiedHandlers[eventName]) {
        let handlerObject = component._proxiedHandlers[eventName][handlerId];
        handlerObject.function(args);
        if (handlerObject.runOnce) {
            delete component._proxiedHandlers[eventName][handlerId];
        }
    }
};

export default new MapEventProxy();
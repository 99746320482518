import {
    GET_CATEGORIES
} from '../../constants/action-types';

import { listToHash } from '../../utils/data-structures';

const initialState = {};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_CATEGORIES:
            return listToHash(action.data, 'id');
        default:
            return state;
    }
};

import LocalStorageService from '../local-storage-service/local-storage-service';

const VIEWED_IDS_KEY = 'viewedIds';
const MAX_VIEWED_ITEMS = 1000;

class MapLocalStorage {
    constructor() {
        this._localStorageService = new LocalStorageService();
        this._hasLocalStorage = this._localStorageService.isSupported();
    }

    /**
     * Stores the id of a viewed object
     */
    storeViewedId(id) {
        const component = this;

        if (!component._hasLocalStorage) {
            return;
        }

        let viewed = component.getViewed();
        // act when not already present
        if (id in viewed) {
            return;
        }
        // delete oldest item from storage
        if (Object.keys(viewed).length >= MAX_VIEWED_ITEMS) {
            component.deleteOldestViewedItem();
        }
        viewed[id] = {
            ts: parseInt(new Date().getTime() / 1000, 10)
        };
        component.setViewed(viewed);
    }

    /**
     * returns true if object is present in localstorage
     */
    isViewed(objectId, capped=false) {
        const component = this;

        if (!component._hasLocalStorage || capped) {
            return false;
        }

        let markerId = objectId;
        let singleId = objectId.indexOf('-') < 0;
        let viewed = component.getViewed();

        if (viewed.length == 0) {
            return false;
        }
        if (!singleId) {
            let markerIds = markerId.split('-');
            for (let i = 0; i < markerIds.length; i++) {
                if (!(markerIds[i] in viewed)) {
                    return false;
                }
            }
            return true;
        }

        return markerId in viewed;
    }

    /**
     * Retrieve array from storage (where data is delimited by SPLIT_CHAR)
     */
    getViewed() {
        return this._localStorageService.getAsParsedJson(VIEWED_IDS_KEY) || {};
    }

    /**
     * Saves array to storage (where data is delimited by SPLIT_CHAR)
     */
    setViewed(value) {
        this._localStorageService.saveAsStringified(VIEWED_IDS_KEY, value);
    }

    /**
     * Remove oldest viewed item from localstorage
     */
    deleteOldestViewedItem() {
        const component = this;

        let current = component.getViewed();
        let max = 0;
        let maxId = null;
        for (let item in current) {
            if (current[item].ts > max) {
                max = current[item];
                maxId = item;
            }
        }
        if (maxId != null && current[maxId]) {
            delete current[maxId];
        }
        component.setViewed(current);
    }
}

export default new MapLocalStorage();

import $ from 'jquery';

const TOGGLE_ITEM_SELECTOR = '[data-travel-mode-toggle-item]';
const ACTIVE_ELEMENTS_SELECTOR = 'li.is-active';
const CLOSE_TOGGLE_ELEMENT_SELECTOR = '.close';
const ACTIVE_ELEMENTS_CLASS = 'is-active';
const ICON_CLOSE_BLUE_CLASS = 'icon-close-blue';

export default class TravelModeToggle {
    constructor(element, onToggleSelected) {
        this.onToggleSelected = onToggleSelected;
        this.$element = $(element);
        this.bindEvents();
        this.toggleSelectedItem();
    }
    bindEvents() {
        this.$element.on('click', TOGGLE_ITEM_SELECTOR, (event) => { this.itemClicked(event); });
    }

    itemClicked(event) {
        this.$element.find(CLOSE_TOGGLE_ELEMENT_SELECTOR).addClass(ICON_CLOSE_BLUE_CLASS);
        this.$element.find(CLOSE_TOGGLE_ELEMENT_SELECTOR).text('');
        if (event.currentTarget.tagName === 'LI') {
            const values = { value: event.currentTarget.dataset.itemValue, key: event.currentTarget.dataset.itemKey };
            this.setAsActiveItem(values, event);
            this.$element.toggleClass('expand');
        }
    }
    setAsActiveItem(selectedItem, event) {
        if (selectedItem.value !== 'close') {
            this.$element.find(ACTIVE_ELEMENTS_SELECTOR).removeClass(ACTIVE_ELEMENTS_CLASS);
            this.$element.find(event.currentTarget).addClass(ACTIVE_ELEMENTS_CLASS);
            this.setItemAsActive(event.target.textContent);
            this.onToggleSelected(selectedItem);
        } else if (this.$element.hasClass('expand')) { //means that the toggle is closing
            this.toggleSelectedItem();
        }
    }

    toggleSelectedItem() {
        const currentSelected = this.$element.find(ACTIVE_ELEMENTS_SELECTOR);
        this.setItemAsActive(currentSelected.text());
    }

    setItemAsActive(text) {
        this.$element.find(CLOSE_TOGGLE_ELEMENT_SELECTOR).text(text);
        this.$element.find(CLOSE_TOGGLE_ELEMENT_SELECTOR).removeClass(ICON_CLOSE_BLUE_CLASS);
    }

    toggle() {
        this.toggleSelectedItem();
        this.$element.removeClass('expand');
    }
}

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default MapTileServer;

/**
 * Initiate Map Tile Server
 * @param {Array} tileServers
 * @param {String} tilePath
 * @constructor
 */
function MapTileServer(tileServers, tilePath, zoom) {
    const component = this;
    component.tileServers = tileServers;
    component.tilePath = tilePath;
    component.zoom = zoom;
}

/**
 * Creates the tile url for getting the tile data according the coordination and zoom level
 * @param {Integer} zoom
 * @param {Object} coord {x: *, y, *}
 * @returns {string} the url
 */
MapTileServer.prototype.createUrl = function(zoom, coord, searchQuery, cluster) {
    const component = this;
    const urlAttributes = {
        zoom: {find: '{zoom}', replace: zoom},
        coordX: {find: '{coordx}', replace: coord.x},
        coordY: {find: '{coordy}', replace: coord.y},
        query: {find: '{searchQuery}', replace: encodeURIComponent(searchQuery)},
        cluster: {find: '{cluster}', replace: cluster},
        screen: {find: '{screen}', replace: $(document).width() < 750 ? 'm' : 'd'}
    };

    let path = component.tilePath;
    let regexPattern;
    for (let key in urlAttributes) {
        if (urlAttributes[key] !== undefined) {
            regexPattern = new RegExp('(' + urlAttributes[key].find + ')', 'gm');
            path = path.replace(regexPattern, urlAttributes[key].replace);
        }
    }
    // combine domain + path to create the complete url
    return component.getTileServer(coord) + path;
};

/**
 * Returns one domain randomized from the list
 * https://www.maxcdn.com/one/visual-glossary/domain-sharding-2/
 * @param {Object} coord {x: *, y, *}
 * @returns {String} domain
 */
MapTileServer.prototype.getTileServer = function(coord) {
    const component = this;
    const mapTileServers = component.tileServers;
    /* eslint-disable no-useless-escape */
    const pattern = new RegExp('([\/])$', 'g');

    if (mapTileServers.length === 0) {
        return '.';
    } else {
        let tileServer = mapTileServers[(coord.x + coord.y) % mapTileServers.length];
        if (window.location.protocol === 'https:') {
            tileServer = tileServer.replace('http://', 'https://');
        }
        return tileServer.replace(pattern, '');
    }
};

/**
 * Make sure the server load valid tiles, Netherlands only
 * @param {Integer} zoom
 * @param {Object} coord {x: *, y, *}
 */
MapTileServer.prototype.isValidTile = function(zoom, coord) {
    const component = this;
    const x = coord.x;
    const y = coord.y;
    let validTile = true;

    if (zoom < component.zoom.min || zoom > component.zoom.max) {
        validTile = false;
    } else {
        switch (zoom) {
            case 6:
                validTile = (x >= 32 && x <= 33 && y >= 20 && y <= 21);
                break;
            case 7:
                validTile = (x >= 65 && x <= 66 && y >= 41 && y <= 42);
                break;
            case 8:
                validTile = (x >= 130 && x <= 133 && y >= 82 && y <= 85);
                break;
            case 9:
                validTile = (x >= 260 && x <= 266 && y >= 165 && y <= 171);
                break;
            case 10:
                validTile = (x >= 521 && x <= 532 && y >= 331 && y <= 343);
                break;
            case 11:
                validTile = (x >= 1043 && x <= 1065 && y >= 662 && y <= 687);
                break;
            case 12:
                validTile = (x >= 2086 && x <= 2130 && y >= 1324 && y <= 1375);
                break;
            case 13:
                validTile = (x >= 4172 && x <= 4260 && y >= 2649 && y <= 2751);
                break;
            case 14:
                validTile = (x >= 8344 && x <= 8520 && y >= 5298 && y <= 5503);
                break;
            case 15:
                validTile = (x >= 16689 && x <= 17041 && y >= 10596 && y <= 11006);
                break;
        }
        //TODO: research boundaries for zoom 16 t/m 20
    }
    return validTile;
};
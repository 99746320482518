import $ from 'jquery';
export default HypotheekContact;

// component configuration
const COMPONENT_SELECTOR = '[data-hypotheek-contact]';
const SUBMIT_BUTTON_SELECTOR = '[data-hypotheek-contact-submit]';
const OPTIN_SELECTOR = '[data-hypotheek-contact-optin]';
const DISABLED_ATTR = 'disabled';

function HypotheekContact(element) {
    const component = this;
    component.$element = $(element);
    component.$form = component.$element.find('form');
    component.$submitButton = component.$element.find(SUBMIT_BUTTON_SELECTOR);
    component.$optinCheckbox = component.$element.find(OPTIN_SELECTOR);

    component.bindEvents();
}

HypotheekContact.prototype.bindEvents = function() {
    const component = this;

    component.$form.on('submit', () => component.checkValidity());
    component.$optinCheckbox.on('change', () => component.validateCheckbox());
};

HypotheekContact.prototype.checkValidity = function() {
    const component = this;

    if (!component.$form.valid()) {
        component.disableSubmitButton();
        component.uncheckOptinCheckbox();
    }
};

HypotheekContact.prototype.uncheckOptinCheckbox = function() {
    const component = this;
    component.$optinCheckbox.prop('checked', false);
};

HypotheekContact.prototype.validateCheckbox = function() {
    const component = this;

    if (component.$optinCheckbox.prop('checked'))
        component.enableSubmitButton();
    else
        component.disableSubmitButton();
};

HypotheekContact.prototype.enableSubmitButton = function() {
    const component = this;

    component.$submitButton.removeAttr(DISABLED_ATTR);
};

HypotheekContact.prototype.disableSubmitButton = function() {
    const component = this;

    component.$submitButton.attr(DISABLED_ATTR, DISABLED_ATTR);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new HypotheekContact(element);
});

import './../_base-view/base-view';
import '../../components/advertisements/advertisements';
import '../../components/search-content/search-content';
import '../../components/dialog/dialog';
import '../../components/filter-flyout/filter-flyout';
import '../../components/instant-search/instant-search';
import '../../components/makelaar-ads/makelaar-ads';
import '../../components/filter-visibility/filter-visibility';
import '../../components/pagination/pagination';
import '../../components/radio-group/radio-group';
import '../../components/search-header/search-header';
import '../../components/search-save/search-save';
import '../../components/search-sidebar/search-sidebar';
import '../../components/search-price-toggle/search-price-toggle';
import '../../components/top-position/top-position';
import '../../components/applied-filters/applied-filters';

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

export default SearchMapContent;

// component configuration
const COMPONENT_SELECTOR = '[data-search-map-content]';
const MAP_SELECTOR = '[data-map]';
const URL_ATTR = 'data-search-map-content-url';
const AUTOCOMPLETE_SELECTOR = '[data-autocomplete]';
const SUBMIT_LOCATION_SELECTOR = '[data-search-map-content-submit-location]';
const GEO_INDENTIFIER_SELECTOR = '[data-instant-search-output="zoekboxHiddenValue"]';
const FORM_SELECTOR = '[data-instant-map-search]';
const GEO_SELECTOR = '#sidebar-autocomplete-identifier';
const MAP_OPTIES_SELECTOR = '[data-map-options]';
const MAP_VIEW_TYPE_SELECTOR = '[data-map-type-select-top]';

const SEARCH_QUERY_UPDATED_EVENT = 'searchqueryupdated';
const MAP_VIEW_SELECTOR_CLOSE_EVENT = 'close_view_type_box';

const IS_VISIBLE_CLASS = 'is-visible';
const IS_HIDDEN_CLASS = 'is-hidden';


function SearchMapContent(element) {
    const component = this;

    component.bindToElements(element);
    component.bindEvents();

    $(window).on('sidebar-refresh', function () {
        var $myNewElement = $(COMPONENT_SELECTOR);
        if ($myNewElement.length == 1) {
            component.bindToElements($myNewElement);
            component.bindEvents();
        }
    });
}

SearchMapContent.prototype.bindToElements = function (element) {
    const component = this;

    component.$element = $(element);
    component.$map = $(MAP_SELECTOR);
    component.url = component.$element.attr(URL_ATTR);
    component.$autocomplete = component.$element.find(AUTOCOMPLETE_SELECTOR);
    component.$submitButton = component.$element.find(SUBMIT_LOCATION_SELECTOR);
    component.$geoIdentifier = component.$element.find(GEO_INDENTIFIER_SELECTOR);
    component.$geo = $(FORM_SELECTOR).find(GEO_SELECTOR);
    component.$mapOptionsLink = component.$element.find(MAP_OPTIES_SELECTOR);
    component.$mapOptionsList = component.$element.find(MAP_VIEW_TYPE_SELECTOR);
};

SearchMapContent.prototype.bindEvents = function () {
    const component = this;

    component.$autocomplete.on(SEARCH_QUERY_UPDATED_EVENT, () => component.submitLocation());
    component.$submitButton.on('click', () => component.submitLocation());

    $(document).on('resultsUpdated', () => {
        component.$geo.val('no-update');
    });

    component.$mapOptionsLink.on('click', () => {
        component.openMapViewOptions();
    });

    $(document).on(MAP_VIEW_SELECTOR_CLOSE_EVENT, () => component.closeMapViewOptions());
};

SearchMapContent.prototype.submitLocation = function () {
    const component = this;
    const locationId = component.$geoIdentifier.val();

    // Only update in case of (valid) changes
    if (locationId !== '' && locationId !== '0') {
        component.changeCenterMap(locationId);
    }
};

SearchMapContent.prototype.changeCenterMap = function (locationId) {
    const component = this;

    component.$geo.val(locationId);
    component.$geo.trigger('change');
};

SearchMapContent.prototype.openMapViewOptions = function () {
    const component = this;
    component.$mapOptionsList.removeClass(IS_HIDDEN_CLASS).addClass(IS_VISIBLE_CLASS);
};

SearchMapContent.prototype.closeMapViewOptions = function () {
    const component = this;
    component.$mapOptionsList.removeClass(IS_VISIBLE_CLASS).addClass(IS_HIDDEN_CLASS);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new SearchMapContent(element));
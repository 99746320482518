import $ from 'jquery';

export default FormHidden;

const COMPONENT_SELECTOR = '[data-form-hidden]';

const EDIT_TRIGGER = '[data-form-hidden-edit-trigger]';
const READ_TRIGGER = '[data-form-hidden-read-trigger]';
const EDIT_CONTAINER = '[data-form-hidden-edit-container]';
const READ_CONTAINER = '[data-form-hidden-read-container]';

function FormHidden(element) {
    const component = this;

    component.$element = $(element);
    component.editContainer = component.$element.find(EDIT_CONTAINER);
    component.readContainer = component.$element.find(READ_CONTAINER);
    component.editTrigger = component.$element.find(EDIT_TRIGGER);
    component.readTrigger = component.$element.find(READ_TRIGGER);

    component.disableFallbackLinks();
    component.bindEvents();
}

FormHidden.prototype.disableFallbackLinks = function () {
    const component = this;

    component.readTrigger.removeAttr('href');
    component.editTrigger.removeAttr('href');
};

FormHidden.prototype.bindEvents = function () {
    const component = this;

    component.editTrigger.click(() => component.toggle());
    component.readTrigger.click(() => component.toggle());
    component.$element.on('submit-success', () => component.toggle());
};

FormHidden.prototype.toggle = function () {
    const component = this;

    component.editTrigger.toggle();
    component.readContainer.toggle();
    component.editContainer.toggle();
};

$(COMPONENT_SELECTOR).each((index, element) => new FormHidden(element, index));


/**
 * makelaars-kantoor view
 * [description]
 */

import '../../components/makelaars-about/makelaars-about';

import '../../components/object-contact/object-contact';
import '../../components/makelaars-contact/makelaars-contact';
import '../../components/makelaars-features/makelaars-features';
import './../../components/related-objects/related-objects';
import '../../components/makelaars-twitterfeed/makelaars-twitterfeed';
import './../../components/media-viewer/media-viewer';

// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

export default ObjectMapTypeSelect;

// constant definitions
const MAP_TYPE_ATTR = 'data-object-map-type-select';
const MAP_TYPE_SELECTOR = `[${MAP_TYPE_ATTR}]`;
const MAP_TYPE_SELECT_RADIO = '[data-object-map-type-select-radio]';
const MAP_TYPE_SELECT_STREET_VIEW_SELECTOR = '.object-map-type-select-item-streetview';

const MENU_TOGGLE_SELECTOR = '[data-menu-toggle]';
const MENU_TITLE_SELECTOR = '[data-menu-title]';
const MENU_ITEM = 'data-menu-item';
const MENU_ITEM_SELECTOR = '[' + MENU_ITEM + ']';

const OPEN_CLASS = 'open';
const IS_VISIBLE_CLASS = 'is-visible';

const STREET_VIEW_AVAILABLE_EVENT = 'streetview-available;';

const $window = $(window);

function ObjectMapTypeSelect(element) {
    const component = this;
    component.$element = $(element);

    component.mapId = component.$element.attr(MAP_TYPE_ATTR);
    component.mapTypeSelectEvent = `map-type-select-event-${component.mapId}`;

    component.$menuToggle = component.$element.find(MENU_TOGGLE_SELECTOR);
    component.$menuTitle = component.$element.find(MENU_TITLE_SELECTOR);
    component.$menuItems = component.$element.find(MENU_ITEM_SELECTOR);

    component.$selectRadios = component.$element.find(MAP_TYPE_SELECT_RADIO);

    component.bindEvents();
}

ObjectMapTypeSelect.prototype.bindEvents = function () {
    const component = this;

    component.$element.on('change', component.$selectRadios, event => {
        component.updateMenuItem(event);
    });

    component.$menuToggle.on('click', event => {
        component.toggleMenuVisibility(event);
    });

    $window.on('click', (event) => {
        component.closeMenu(event);
    });

    $window.on(STREET_VIEW_AVAILABLE_EVENT, () => {
        $(MAP_TYPE_SELECT_STREET_VIEW_SELECTOR).addClass(IS_VISIBLE_CLASS);
    });

    $window.on(component.mapTypeSelectEvent, (event, data) => {
        component.$selectRadios.map((index, item) => {
            const $item = $(item);

            $item.prop('checked', false);

            if ($item.val() == data.value) {
                $item.prop('checked', true);

                component.updateMenuTitle($item);
            }
        });
    });
};

ObjectMapTypeSelect.prototype.toggleMenuVisibility = function(event) {
    const component = this;

    component.$element.toggleClass(OPEN_CLASS);
    event.stopPropagation();
};

ObjectMapTypeSelect.prototype.closeMenu = function() {
    const component = this;

    component.$element.removeClass(OPEN_CLASS);
};

ObjectMapTypeSelect.prototype.updateMenuItem = function(event) {
    const component = this;

    event.preventDefault();

    component.$element.removeClass(OPEN_CLASS);

    $window.trigger(component.mapTypeSelectEvent, { value: event.target.value });
};

ObjectMapTypeSelect.prototype.updateMenuTitle = function($item) {
    const component = this;

    const $label = component.$element.find(`label[for="${$item.attr('id')}"]`);

    component.$menuTitle.html($label.text().trim());
};

$(MAP_TYPE_SELECTOR).each((index, element) => new ObjectMapTypeSelect(element));
// require this module where needed, either in a specific view or component or generically in src/index.js
// dependencies (alphabetically)
import $ from 'jquery';

import debounce from 'lodash/debounce';

export default ObjectDescription;

// Component configuration
var COMPONENT_ATTR = 'data-object-description';
var COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';
var BODY_SELECTOR = '[data-object-description-body]';
var CONFIG_SELECTOR = '[data-object-description-config]';
var CONTENT_TYPE_SELECTOR = '[data-object-description-strip-markup]';
var ENHANCED_CLASS = 'is-expandable';
var EXPANDED_CLASS = 'is-expanded';
var DEFAULT_CONFIG = { '0px': 350 };

function ObjectDescription(element) {
    var component = this;

    // Enhance the element to trigger styles for the enhanced UI elements
    component.$element = $(element);
    component.$element.addClass(ENHANCED_CLASS);

    component.$body = component.$element.find(BODY_SELECTOR);
    component.htmlContent = component.$body.html();
    component.textContent = component.$body.text();

    // If there is no config on the template
    if (component.$element.find(CONFIG_SELECTOR).length > 0) {
        component.config = JSON.parse(
            component.$element.find(CONFIG_SELECTOR).text()
        );
    } else {
        component.config = DEFAULT_CONFIG;
    }

    // Collapse the component only if it has more than 300 characters of content
    if (component.textContent.length < 300) {
        component.$element.addClass(EXPANDED_CLASS);
    } else {
        component.checkBreakpoints(component.config);
        component.bindEvents();
    }
}

ObjectDescription.prototype.bindEvents = function() {
    var component = this;

    // Wire-up the event handler to a handle element nested inside the component
    component.$element.on('click', function() {
        component.expand();
    });

    // Attach event on resize for breakpoints
    $(window).on(
        'resize',
        debounce(function() {
            component.checkBreakpoints(component.config);
        }, 100)
    );
};

ObjectDescription.prototype.checkBreakpoints = function(breakpoints) {
    var component = this;
    var windowSize = $(window).width();
    var matchedBreakpointCharacterCount;

    Object.getOwnPropertyNames(breakpoints).forEach(function(value) {
        // Remove px from values
        var size = value.substr(0, value.length - 2);
        if (windowSize > size) {
            matchedBreakpointCharacterCount = breakpoints[value];
        }
    });

    // Only collapse if there is a matching breaking point on the config
    if (
        !component.$element.hasClass(EXPANDED_CLASS) &&
  typeof matchedBreakpointCharacterCount !== 'undefined'
    ) {
        component.collapse(matchedBreakpointCharacterCount);
    } else {
        component.expand();
    }
};

ObjectDescription.prototype.getCorrectOriginalContent = function() {
    var component = this;

    // Only show original version of the text if it was set as a config option.
    if (component.$element.find(CONTENT_TYPE_SELECTOR).length > 0) {
        return component.textContent;
    } else {
        return component.htmlContent;
    }
};

ObjectDescription.prototype.collapse = function(maxTextLength) {
    var component = this;

    // With the chosen content, create a shortened version of it
    var text = component.getCorrectOriginalContent();
    var shortenedText = text.substr(0, maxTextLength) + ' …';
    component.$body.html(shortenedText);
    component.$element.attr('aria-expanded', 'false');
};

ObjectDescription.prototype.expand = function() {
    var component = this;

    if (component.$element.hasClass(EXPANDED_CLASS)) {
        return;
    }

    // Restore the original content
    component.$body.html(component.htmlContent);
    component.$element.addClass(EXPANDED_CLASS);
    component.$element.attr('aria-expanded', 'true');
    $.event.trigger({ type: 'desciptionExpanded' });
};

$(COMPONENT_SELECTOR).each(function(index, element) {
    return new ObjectDescription(element);
});
// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';
export default ContentFetch;

/*
  `element` is the element where you'd like to render the complete callback
  `urlFeed` is the URL API
  `onComplete` is the callback (function)
*/
function ContentFetch(element, urlFeed, onComplete = function() {}) {
    $.get({
        url: urlFeed,
        crossDomain: true,
        dataType: 'html',
        success: (data, responseText, jqXHR) => {
            ContentFetch.showData(element, data);
            if (jqXHR.status == 204) {
                ContentFetch.hideWidget(element);
            }
        },
        error: () => {
            ContentFetch.hideWidget(element);
        },
        complete: onComplete
    });
}

ContentFetch.showData = function(element, data) {
    $(element).html(data);
};

ContentFetch.hideWidget = function(element) {
    $(element).addClass('is-hidden');
};
import $ from 'jquery';
import Observable from '../class-observable/class-observable';
import controllerService from '../service-controller/service-controller';

export default Rating;

// component configuration
var COMPONENT_ATTR = 'data-rating';
var COMPONENT_SELECTOR = '[data-rating]';
var HOVER_CLASS = 'is-hovered';
var RATED_CLASS = 'is-rated';

//This is a static instance of observable, so ratings can observe themselves
const ratingService = (() => {
    return new Observable();
})();

function Rating(element) {
    var component = this;
    component.$element = $(element);
    component.ratingId = component.$element.attr(COMPONENT_ATTR) || false;
    component.$inputs = component.$element.find('input');
    component.selectedValue = 0;

    component.run = () => {
        setWatchers();
        setRatingFromRadioButton();
    };

    //region Observer facade
    component.observers = new Observable();
    component.TOPICS = {
        CHANGE: 0,
        HOVER_IN: 1,
        HOVER_OUT: 2
    };

    component.onChange = function (observer) {
        component.observers.listen(component.TOPICS.CHANGE, observer);
    };
    component.onHoverIn = function (observer) {
        component.observers.listen(component.TOPICS.HOVER_IN, observer);
    };
    component.onHoverOut = function (observer) {
        component.observers.listen(component.TOPICS.HOVER_OUT, observer);
    };

    function notify(topic, newValue) {
        ratingService.notify(component.ratingId, {
            topic: topic,
            value: newValue
        });
        component.observers.notify(topic, newValue);
    }

    function notifyChange(newValue) {
        notify(component.TOPICS.CHANGE, newValue);
    }

    function notifyHoverIn(newValue) {
        notify(component.TOPICS.HOVER_IN, newValue);
    }

    function notifyHoverOut(newValue) {
        notify(component.TOPICS.HOVER_OUT, newValue);
    }

    //endregion

    //region Handlers
    component.clickHandler = (value, skipSync) => {
        const newValue = parseInt(value, 10);
        //Update checked attribute in the inputs
        $(component.$inputs[component.selectedValue]).prop('checked', false).removeAttr('checked');
        $(component.$inputs[newValue]).prop('checked', true).attr('checked', 'checked');
        // Update the rated-class. Add rated class all until the selected value, and remove the next ones.
        component.$inputs.slice(0, newValue + 1).addClass(RATED_CLASS);
        component.$inputs.slice(newValue + 1).removeClass(RATED_CLASS);
        //Cache value
        component.selectedValue = newValue;
        //Triggers and notifies
        if (typeof skipSync === 'undefined' || !skipSync) {
            notifyChange(newValue);
            if (component.ratingId) {
                component.$element.trigger('selectrating', {score: newValue});
            }
        }
    };

    component.hoverInHandler = (value, skipSync) => {
        const val = parseInt(value, 10);
        component.$inputs.slice(0, val + 1).addClass(HOVER_CLASS);
        component.hoverValue = val;
        if (typeof skipSync === 'undefined' || !skipSync) {
            notifyHoverIn(val);
        }
    };

    component.hoverOutHandler = (value, skipSync) => {
        const val = parseInt(value, 10);
        component.$inputs.removeClass(HOVER_CLASS);
        component.hoverValue = 0;
        if (typeof skipSync === 'undefined' || !skipSync) {
            notifyHoverOut(val);
        }
    };

    component.messageHandler = (message) => {
        const value = message.value;
        switch (message.topic) {
            case component.TOPICS.CHANGE:
                component.clickHandler(value, true);
                break;
            case component.TOPICS.HOVER_IN:
                component.hoverInHandler(value, true);
                break;
            case component.TOPICS.HOVER_OUT:
                component.hoverOutHandler(value, true);
                break;
        }
    };
    //endregion

    function setWatchers() {
        //Observe ratings with same ID and link
        if (component.ratingId) {
            ratingService.listen(component.ratingId, component.messageHandler);
        }

        // store the label's index in memory and set watchers
        component.$inputs.each(function (index, elem) {
            var $elem = $(elem);
            var $label = $('label[for=' + $elem.attr('id') + ']');
            $label.data('index', index);
            $label.on('mouseenter', () => component.hoverInHandler(index));
            $label.on('mouseleave', () => component.hoverOutHandler(index));
            $elem.on('change', () => component.clickHandler(index));
        });
    }

    function setRatingFromRadioButton() {
        component.$inputs.each((idx, input) => {
            let $input = $(input);
            if ($input.is(':checked')) {
                component.clickHandler($input.val());
            }
        });
    }

    /**
     * Alternative name to click Handler. For backwards compatibility.
     * @type {(function())|*} see clickHandler
     */
    component.selectRating = component.clickHandler;
    component.run();
}

Rating.getSelector = () => COMPONENT_SELECTOR;
Rating.initialize = () => controllerService.getAllInstances(Rating);
Rating.initialize();

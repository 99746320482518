// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default ContentTabs;

// component configuration
const COMPONENT_SELECTOR = '[data-content-tabs]';
const COMPONENT_NAME_ATTR = 'data-content-tabs-name';
const COMPONENT_TRIGGER_ATTR = 'data-tabs-trigger';
const COMPONENT_TARGET = '[data-tabs-target]';
const EXPANDIBLE_SELECTOR = '[data-expandible]';
const ACTIVE_CLASS = 'active';
const EXPANDIBLE_TOGGLE_EVENT = 'expandibletoggle';
const HIDDEN_CLASS = 'is-hidden';

function ContentTabs(element) {
    const component = this; // Not necessary in ES2015, keep using it for consistency

    component.$element = $(element);
    component.$trigger = component.$element.find('[' + COMPONENT_TRIGGER_ATTR + ']');
    component.$target = component.$element.find(COMPONENT_TARGET);
    component.initTabs();
    component.bindEvents();
}

ContentTabs.prototype.initTabs = function () {
    const component = this;
    const $currentActiveTabs = component.$target.find('> ' + ACTIVE_CLASS);

    if ($currentActiveTabs.length == 0) {
        component.$target.addClass(HIDDEN_CLASS);
        component.$target.first().removeClass(HIDDEN_CLASS);
    }

    component.setActiveTab();
};

ContentTabs.prototype.bindEvents = function () {
    const component = this;

    component.$trigger.find('a:not([data-expandible-handle])').click(function (event) {
        event.preventDefault();

        const $this = $(this);

        component.showTargetTab($this);
        component.setActiveTrigger($this);
        component.scrollToActiveTabs($this);
    });
};

/**
 * The tab trigger can be used to refer to another content-tab container
 * This method is used to retrieve the container that is being called by the trigger
 * @param targetName    content-tab name
 * @returns {*}         content-tab element
 */
ContentTabs.prototype.getTabsContainer = function (targetName) {
    const component = this;
    let $contentTab;

    if (targetName === '') {
        $contentTab = component.$element;
    } else {
        $contentTab = $('[' + COMPONENT_NAME_ATTR + '="' + targetName + '"]');
    }

    return $contentTab;
};

/**
 * Used to toggle the visibility of the targeted tab
 * @param $element      tab trigger
 */
ContentTabs.prototype.showTargetTab = function ($element) {
    const component = this;
    const $trigger = $element.closest('[' + COMPONENT_TRIGGER_ATTR + ']');
    const targetTabContainerName = $trigger.attr(COMPONENT_TRIGGER_ATTR);
    const $targetTabContainer = component.getTabsContainer(targetTabContainerName);
    const targetTabItem = $element.attr('href');

    $targetTabContainer.find(COMPONENT_TARGET).addClass(HIDDEN_CLASS);
    $targetTabContainer.find(targetTabItem).removeClass(HIDDEN_CLASS);
};

/**
 * Set the trigger to active state
 * If the targeted tab is from another component, then it also checks if the targeted tab has its own trigger or not
 * If the targeted tab has its own trigger, then that trigger is also being activated
 * @param $element      tab trigger
 */
ContentTabs.prototype.setActiveTrigger = function ($element) {
    const component = this;
    const $trigger = $element.closest('[' + COMPONENT_TRIGGER_ATTR + ']');
    const targetTabContainerName = $trigger.attr(COMPONENT_TRIGGER_ATTR);
    const $targetTabContainer = component.getTabsContainer(targetTabContainerName);
    const targetTabItem = $element.attr('href');
    const $targetTabTrigger = $targetTabContainer.find('[' + COMPONENT_TRIGGER_ATTR + ']');

    // Toggle tab trigger in own container
    $trigger.find('li').removeClass(ACTIVE_CLASS);
    $element.parent('li').addClass(ACTIVE_CLASS);

    // if targeted component is not the same as own component then toggle the class of the targeted component also
    if ($targetTabContainer.is(component.$element) === false) {
        // Toggle tab trigger in target container
        const $activeTrigger = $targetTabTrigger.find('[href="' + targetTabItem + '"]');
        $targetTabTrigger.find('li').removeClass(ACTIVE_CLASS);
        $activeTrigger.parent('li').addClass(ACTIVE_CLASS);

        component.expandTab($activeTrigger);
    }
};

/**
 * Expand activated tabs trigger if it's expandible component
 * @param $element      activated tab trigger
 */
ContentTabs.prototype.expandTab = function ($element) {
    const $expandible = $element.closest(EXPANDIBLE_SELECTOR);

    if ($expandible.length > 0) {
        $expandible.trigger(EXPANDIBLE_TOGGLE_EVENT, true);
    }
};

/**
 * If the targeted tab is from another component, the window will be scrolled to the targeted tab
 * @param $element      tab trigger
 */
ContentTabs.prototype.scrollToActiveTabs = function ($element) {
    const component = this;
    const $trigger = $element.closest('[' + COMPONENT_TRIGGER_ATTR + ']');
    const targetTabContainerName = $trigger.attr(COMPONENT_TRIGGER_ATTR);
    const $targetTabContainer = component.getTabsContainer(targetTabContainerName);

    if ($targetTabContainer.is(component.$element) === false) {
        window.scrollTo(0, $targetTabContainer.offset().top);
    }
};

/**
 * Set active tab on page load
 * If there's no active tab is set, then it takes the first tab item
 */
ContentTabs.prototype.setActiveTab = function () {
    const component = this;

    //only add active class if it's not set yet
    const $activeTrigger = component.$trigger.find('li.' + ACTIVE_CLASS);

    if ($activeTrigger.length === 0) {
        component.$trigger.children('li').first().addClass(ACTIVE_CLASS);
    } else {
        component.showTargetTab($activeTrigger.find('a'));
    }
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function (index, element) {
    return new ContentTabs(element);
});
/**
 * user-login view
 * [description]
 */

import '../../components/dialog/dialog';

import '../../components/notification/notification';
import '../../components/placeholder-fallback/placeholder-fallback';
import '../../components/social-login/social-login';
import '../../components/user-login/user-login.js';

import $ from 'jquery';

export default FormMinChars;

const COMPONENT_SELECTOR = '[data-form-textarea-countdown]';
const INPUT_SELECTOR = '[data-form-input]';
const ICON_SELECTOR = '[data-icon]';
const COUNTDOWN_SELECTOR = '[data-countdown]';
const ZERO_INPUT_TEXT_DATA = 'data-zero-input-text';
const MORE_INPUT_PRE_TEXT_DATA = 'data-more-input-pre-text';
const MORE_INPUT_POST_TEXT_DATA = 'data-more-input-post-text';
const IS_CORRECT_CLASS = 'is-correct';
const IS_HIDDEN_CLASS = 'is-hidden';
const MIN_LENGTH_ATTR = 'minlength';
const EVENTS_TO_LISTEN = 'input propertychange';

function FormMinChars(element) {
    const component = this;

    component.$element = $(element);
    component.$textarea = component.$element.find(INPUT_SELECTOR);
    component.$countDown = component.$element.find(COUNTDOWN_SELECTOR);
    component.$icon = component.$element.find(ICON_SELECTOR);
    component.minChars = component.$textarea.attr(MIN_LENGTH_ATTR);
    component.zeroInputText = component.$textarea.attr(ZERO_INPUT_TEXT_DATA);
    component.moreInputPreText = component.$textarea.attr(MORE_INPUT_PRE_TEXT_DATA);
    component.moreInputPostText = component.$textarea.attr(MORE_INPUT_POST_TEXT_DATA);

    component.bindEvents();
    component.updateCountDown();
}


FormMinChars.prototype.bindEvents = function () {
    const component = this;

    component.$textarea.bind(EVENTS_TO_LISTEN, () => component.updateCountDown());
};

FormMinChars.prototype.updateCountDown = function () {
    const component = this;

    const currentChars = component.$textarea.val().length;

    if (currentChars >= component.minChars) {
        component.$icon.removeClass(IS_HIDDEN_CLASS);
        component.$countDown.addClass(IS_CORRECT_CLASS);
        component.$countDown.html('');
    } else {
        const displayedText = currentChars > 0 ?
            `${component.moreInputPreText}${(component.minChars - currentChars)}${component.moreInputPostText}`
            : component.zeroInputText;
        component.$icon.addClass(IS_HIDDEN_CLASS);
        component.$countDown.removeClass(IS_CORRECT_CLASS);
        component.$countDown.html(displayedText);
    }
};

$(COMPONENT_SELECTOR).each((i, element) => new FormMinChars(element));

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import Expandible from '../expandible/expandible';
import AppSpinner from '../app-spinner/app-spinner';
import Authentication from '../authentication/authentication';

// component configuration
const COMPONENT_ATTR = 'data-save-search';
const COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';
const HANDLE_ATTR = 'data-save-search-handle';
const HANDLE_SELECTOR = '[' + HANDLE_ATTR + ']';
const SUBMIT_BUTTON_SELECTOR = '[data-save-search-submit-button]';
const SAVE_SEARCH_DIALOG_SELECTOR = '[data-dialog="save-search-form"]';
const SAVE_SEARCH_DIALOG_ENDPOINT_ATTR = 'data-save-search-dialog-endpoint';
const DIALOG_SUBMIT_BUTTON_SELECTOR = SAVE_SEARCH_DIALOG_SELECTOR + ' ' + SUBMIT_BUTTON_SELECTOR;
const FLYOUT_FORM_SELECTOR = '[data-save-search="flyout"]';
const EXPANDIBLE_SELECTOR = '[data-expandible]';
const LOGIN_DIALOG_SELECTOR = '[data-dialog="user-form"]';
const CONTAINER_ATTR = 'data-save-search-container';
const SPINNER_SELECTOR = '[data-save-search-spinner]';
const LOADED_CLASS = 'is-loaded';
const EXPANDED_CLASS = 'is-expanded';
const OPEN_CLASS = 'is-open';
const FLYOUT_CLOSE = '.search-close-flyout';
const SAVE_SEARCH_TOGGLE_SELECTOR = '.search-sidebar .search-save';
const CHECKED_OBJECTBESCHIKBAARHEID_SELECTOR = '[name="filter_ObjectBeschikbaarheid"]:checked';
const RESULTLIST_SELECTOR = '.search-main';
const HISTORIC_CLASS = 'historic';

const $body = $('body');

class UserSaveSearch {
    constructor(element, $bodyElement) {
        const component = this;
        component.$bodyElement = $bodyElement || $body;
        component.element = element;
        component.$element = $(element);
        component.saveSearchId = element.getAttribute(COMPONENT_ATTR) || false;
        component.$saveSearchHandle = component.$bodyElement.find(HANDLE_SELECTOR);
        component.$container = component.$bodyElement.find('[' + CONTAINER_ATTR + '="' + component.saveSearchId + '"]');
        component.$antiForgeryTokenInput = this.getAntiForgeryTokenInput();
        component.loginDialogElement = document.querySelector(LOGIN_DIALOG_SELECTOR);
        component.authentication = new Authentication();

        component.$bodyElement.on('click', '[' + HANDLE_ATTR + '="' + component.saveSearchId + '"]', event => {
            event.preventDefault();
            this.onBewaarZoekopdrachtClickHandler(event.target);
        });

        $(document).on('resultsUpdating', function() {
            const selectedObjectBeschikbaarheid = $(CHECKED_OBJECTBESCHIKBAARHEID_SELECTOR).val();

            if (selectedObjectBeschikbaarheid === 'onbeschikbaar') {
                $(RESULTLIST_SELECTOR).addClass(HISTORIC_CLASS);
                $(SAVE_SEARCH_TOGGLE_SELECTOR).addClass('is-hidden');
            } else {
                $(RESULTLIST_SELECTOR).removeClass(HISTORIC_CLASS);
                $(SAVE_SEARCH_TOGGLE_SELECTOR).removeClass('is-hidden');
            }
        });

        component.$element.on('click', SUBMIT_BUTTON_SELECTOR, function(event) {
            if (component.saveSearchId === 'flyout') {
                $(FLYOUT_CLOSE).click();
            }

            event.preventDefault();
            component.submitForm();
        });

        if (component.saveSearchId === 'dialog') {
            component.$bodyElement.on('click', DIALOG_SUBMIT_BUTTON_SELECTOR, function(event) {
                event.preventDefault();
                component.submitForm();
            });
        }
    }

    onBewaarZoekopdrachtClickHandler(clickedElement) {
        const component = this;

        if (component.saveSearchId === 'dialog') {
            this.showDialogToBewaarZoekopdracht(clickedElement);
        } else {
            const isVisible = $(clickedElement).hasClass(EXPANDED_CLASS) || $(clickedElement).hasClass(OPEN_CLASS);

            component.$container.removeClass(LOADED_CLASS);
            component.$element.html('');

            if (isVisible) {
                component.getForm(clickedElement);
            }
        }
    }

    showDialogToBewaarZoekopdracht(clickedElement) {
        this.dialog = this.findDialogInstance();

        this.dialog.onFormSuccess = function() {
            // on successful login a lot of things need to update on the page,
            // so it's easier just to reload the entire page entirely.
        };

        this.dialog.open();
        const dialogHtmlUrl = $(clickedElement).attr(SAVE_SEARCH_DIALOG_ENDPOINT_ATTR);
        this.dialog.getContent(dialogHtmlUrl);
    }

    findDialogInstance() {
        const dialogElement = document.querySelector('[data-dialog="save-search-form"]');
        return dialogElement.dialog;
    }

    submitForm() {
        return this
            .authentication
            .getUserAuthenticationInfo()
            .then(info => {
                if (info.isLoggedIn) {
                    return;
                }

                return this
                    .authentication
                    .openLoginDialog(info.loginUrl)
                    .then(() => {
                        $('.search-map-content__filter-button').click();
                    })
                    .then(() => this.updateAntiForgeryToken());
            })
            .then(() => {
                const saveSearchUrl = this.getSaveSearchUrl();
                return this.saveSearch(saveSearchUrl);
            });
    }

    getAntiForgeryTokenInput() {
        return this.$element.find('input[name="__RequestVerificationToken"]');
    }

    getSaveSearchUrl() {
        return this.$saveSearchHandle.attr('href');
    }

    getNewAntiForgeryToken() {
        const url = '/mijn/savedsearch/refreshtoken/';
        return $
            .ajax({
                url: url,
                dataType: 'html',
            })
            .then(response => $(response).attr('value'))
            .catch(response => {
                console.error('Error calling', url, response);
            });
    }

    setAntiforgeryToken(token) {
        this.getAntiForgeryTokenInput().attr('value', token);
    }

    updateAntiForgeryToken() {
        return this
            .getNewAntiForgeryToken()
            .then(token => this.setAntiforgeryToken(token));
    }

    getForm(element) {
        const component = this;
        const url = element.getAttribute(SAVE_SEARCH_DIALOG_ENDPOINT_ATTR);
        component.spinner = new AppSpinner(SPINNER_SELECTOR);
        component.spinner.show();

        return $.ajax({
            url: url,
            data: {
                currentUrl: window.location.pathname
            },
            success: function(response) {
                if (response.Result === 'OK') {
                    component.$element.append(response.Html);
                    //extra expandible for mobile - toon oude opdracht
                    const extraExpandibleElement = component.$element.find(EXPANDIBLE_SELECTOR);
                    const hasExtraExpandibleElement = extraExpandibleElement.length;
                    if (hasExtraExpandibleElement) {
                        component.extraExpandible = new Expandible(extraExpandibleElement[0]);
                    }
                }
            },
            error: function(response) {
                console.error('Error trying to open save search form', url, response);
            },
            complete: function() {
                component.spinner.hide();
                component.$container.addClass(LOADED_CLASS);
            }
        });
    }

    saveSearch(url) {
        const component = this;
        let data = component.$element.find(':input').serialize();
        const isKaartPage = component.$element.parents('.search-save.kaart-page').length > 0;
        // rewrite requests from /mijn/savedsearch/kaart/koop/ to /mijn/savedsearch/koop/
        url = url.replace('/kaart', '');
        if (component.saveSearchId === 'dialog') {
            data = $(SAVE_SEARCH_DIALOG_SELECTOR).find(':input').serialize();
        } else if (component.saveSearchId === 'flyout') {
            data = $(FLYOUT_FORM_SELECTOR).find(':input').serialize();
        }

        return $.ajax({
            method: 'POST',
            url: url,
            data: data,
            success: function onSuccess(response) {
                if (response.Result !== 'OK') {
                    console.error('Error trying to save search', response);
                } else if (component.saveSearchId == 'dialog') {
                    $('.current-query-kaart').html(response.Html);
                    $('.overwrite-alert').remove();
                    $('.save-search-actions *:not(.map-dialog-visible)').remove();
                    $('.notification .notification-buttons').remove();
                    $('.save-search-actions .map-dialog-visible').show();
                    $('.save-search-actions .map-dialog-visible').click(function() {
                        let $this = $(this);
                        let $dlgContent = $this.parents('.dialog-content');
                        $dlgContent.find('[data-dialog-close]').click();
                    });
                } else if (component.saveSearchId === 'flyout' && isKaartPage) {
                    $('.search-sidebar .filter-reset').before(response.Html);
                    $('.notification .notification-buttons').remove();
                    setTimeout(function() {
                        $('.search-sidebar .notification').remove();
                    }, 3000);
                }
            },
            error: function onError(response) {
                console.error('Error trying to save search', response);
            },
            complete: function() {
                if ((component.saveSearchId === 'flyout' && !isKaartPage) || component.saveSearchId === 'expandible') {
                    window.location.reload();
                }
            }
        });
    }
}

// what does this module expose?
export default UserSaveSearch;

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new UserSaveSearch(element);
});

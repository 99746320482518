const LOGIN_DIALOG_SELECTOR_LOGIN = '[data-dialog="user-form"]';
const LOGIN_DIALOG_SELECTOR_REGISTER = '[data-dialog="save-search-form"]';

export default function LoginDialog(contentUrl, onSuccess) {
    const component = this;
    const dialogElementLogin = document.querySelector(LOGIN_DIALOG_SELECTOR_LOGIN);
    const dialogElementRegister = document.querySelector(LOGIN_DIALOG_SELECTOR_REGISTER);

    if (!dialogElementLogin || !dialogElementLogin.dialog) {
        return;
    }

    dialogElementLogin.dialog.onFormSuccess = function() {
        onSuccess();
        // on successful login a lot of things need to update on the page,
        // so it's easier just to reload the entire page entirely.
    };

    if (dialogElementRegister !== null) {
        dialogElementRegister.dialog.onFormSuccess = function() {
            onSuccess();
            // when will be called when the register form is successfuly saved.
        };
    }

    component.dialog = dialogElementLogin.dialog;
    component.dialog.open();
    component.dialog.getContent(contentUrl);
}

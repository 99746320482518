// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';
import Cookies from '../app-utils/app-utils-cookies';

export default Popover;

// constant definitions
const COMPONENT_ATTR = 'data-popover';
const COMPONENT_SELECTOR = `[${COMPONENT_ATTR}]`;
const COMPONENT_CLOSE_TRIGGER_ATTR = 'data-popover-close';
const ADD_COOKIE_ON_CLOSE_ATTR = 'data-popover-store-cookie-on-close';
const ADD_COOKIE_ON_CLOSE_SELECTOR = `[${ADD_COOKIE_ON_CLOSE_ATTR}]`;
const HIDDEN_CLASS = 'is-hidden';

function Popover(element) {
    const component = this;

    //Dependencies
    component.Cookies = Cookies;

    component.$element = $(element);

    const elementName = component.$element.attr(COMPONENT_ATTR);
    component.$triggerClose = $(`[${COMPONENT_CLOSE_TRIGGER_ATTR}="${elementName}"]`);
    component.cookie = component.$element.find(ADD_COOKIE_ON_CLOSE_SELECTOR).attr(ADD_COOKIE_ON_CLOSE_ATTR);

    component.bindEvents();
    component.initializeVisibility();
}

Popover.prototype.bindEvents = function () {
    const component = this;
    component.$triggerClose.click(() => component.hide());
};

Popover.prototype.initializeVisibility = function () {
    const component = this;

    if ($('#content [data-notification]').length > 0 || component.cookieExists()) {
        component.$element.addClass(HIDDEN_CLASS);
    } else {
        component.show();
    }
};

Popover.prototype.cookieExists = function () {
    const component = this;

    if (component.shouldDropCookieOnClose()) {
        return component.Cookies.getCookie(component.cookie) || false;
    }

    return false;
};

Popover.prototype.shouldDropCookieOnClose = function () {
    const component = this;
    return typeof component.cookie !== 'undefined' && component.cookie.length > 0;
};

Popover.prototype.show = function () {
    const component = this;
    component.$element.removeClass(HIDDEN_CLASS);
};

Popover.prototype.hide = function () {
    const component = this;

    if (component.isHidden())
        return;

    component.$element.addClass(HIDDEN_CLASS);

    if (typeof component.cookie !== 'undefined' && component.cookie.length > 0) {
        component.Cookies.setCookie(component.cookie, 'true', 100);
    }
};

Popover.prototype.isHidden = function () {
    const component = this;
    return component.$element.hasClass(HIDDEN_CLASS);
};


Popover.initialize = function () {
    $(COMPONENT_SELECTOR).each((index, element) => new Popover(element));
};

Popover.initialize();

import find from 'lodash/find';


function MapTileCache() {
    this._storedTiles = {};
    this._storedMarkers = {};
    this._historyCache = {};
}

/**
 * Stores the tile / markers
 */
MapTileCache.prototype.storeMarker = function(tileId, tileData, zoom) {
    const objects = tileData.points;
    // store tile data
    this._storedTiles[tileId] = tileData;

    if (objects !== undefined) {
        for (let i = 0, n = objects.length; i < n; i++) {
            // This is the object we've found.
            let object = objects[i];
            this._storedMarkers[object.id] = {
                lat: object.lat,
                lng: object.lng,
                zoom: zoom
            };
        }
    }
};

/**
 * Get nearesst marker from cache
 */
MapTileCache.prototype.getNearestMarker = function(clickLatLng, zoom) {
    const google = window.google;

    let minDistance = 7199254740666;
    let nearestId;
    for (let storeId in this._storedMarkers) {
        let storedObject = this._storedMarkers[storeId];
        if (storedObject && storedObject.zoom == zoom) {
            let objLatLng = new google.maps.LatLng(storedObject.lat, storedObject.lng);
            let dist = google.maps.geometry.spherical.computeDistanceBetween(clickLatLng, objLatLng);
            if (dist < minDistance) {
                minDistance = dist;
                nearestId = storeId;
            }
        }
    }
    for (let storeId in this._historyCache) {
        let storedObject = this._storedMarkers[storeId];
        if (storedObject && storedObject.zoom == zoom) {
            let objLatLng = new google.maps.LatLng(storedObject.lat, storedObject.lng);
            let dist = google.maps.geometry.spherical.computeDistanceBetween(clickLatLng, objLatLng);
            if (dist < minDistance) {
                minDistance = dist;
                nearestId = storeId;
            }
        }
    }
    return nearestId;
};

/**
 * Fetch marker from cache
 */
MapTileCache.prototype.getMarkerById = function(markerId) {
    return this._historyCache[markerId] || this._storedMarkers[markerId];
};


/**
 * Retrieves markerId from cache (allows patial matching)
 */
MapTileCache.prototype.getMarkerIdByPartial = function(markerId) {
    let ret = find(Object.keys(this._storedMarkers), function(m) {
        return m.indexOf(markerId) >= 0;
    });
    if (ret === undefined) {
        ret = find(Object.keys(this._historyCache), function(m) {
            return m.indexOf(markerId) >= 0;
        });
    }
    return ret;
};

/**
 * Retrieves marker from cache (allows patial matching)
 */
MapTileCache.prototype.getMarkerByPartial = function(markerId) {
    let foundMarkerId = this.getMarkerIdByPartial(markerId);
    if (foundMarkerId !== undefined) {
        return this._historyCache[foundMarkerId] ||this._storedMarkers[foundMarkerId] ;
    }
};

/**
 * Gets a stored tile
 */
MapTileCache.prototype.getStoredTiles = function(tileId) {
    if (Object.keys(this._storedTiles).length > 0 && this._storedTiles[tileId] !== undefined) {
        return this._storedTiles[tileId];
    } else {
        return {};
    }
};

/**
 * Store given objectId indefinitely (umtil page reload)
 */
MapTileCache.prototype.rememberSelectObjects = function(objectIds) {
    let objectId = find(Object.keys(this._storedMarkers), function(m) {
        return m == objectIds;
    });
    if (objectId) {
        this._historyCache[objectIds] = this._storedMarkers[objectId];
    }
};

/**
 * clear local cache
 */
MapTileCache.prototype.clear = function() {
    this._storedMarkers = [];
    this._storedTiles = {};
};

export default new MapTileCache();

/**
 * search-no-results view
 * displays message when applying a filter set with no matches
 */

import './../_base-view/base-view';
import '../../components/expandible/expandible';
import '../../components/instant-search/instant-search';
import '../../components/makelaar-ads/makelaar-ads';
import '../../components/save-search/save-search';
import '../../components/search-header/search-header';
import '../../components/search-sidebar/search-sidebar';
import '../../components/search-no-results/search-no-results';
import '../../components/radius-filter/radius-filter';
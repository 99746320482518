import $ from 'jquery';

export default UserMyHouseOptions;

// component configuration
const COMPONENT_SELECTOR = '[data-my-house-options]';
const URL_STRING_ATTR = 'data-url-string';
const URL_STRING_SELECTOR = '[' + URL_STRING_ATTR + ']';
const REPORT_HOUSE_DIALOG_SELECTOR = '[data-dialog-handle]';

/**
 * Constructor method, links child elements to variables for internal use
 *
 * @param {HTMLElement} element The Html element to bind to
 */
function UserMyHouseOptions(element) {
    const component = this;
    component.$element = $(element);
    component.urlAttribute = component.$element.find(URL_STRING_SELECTOR);
    component.urlValue = component.urlAttribute[0].getAttribute(URL_STRING_ATTR);
    component.$link = $(REPORT_HOUSE_DIALOG_SELECTOR);

    // Check if url comes from an email link.
    if (component.checkUrl()) {

        // Trigger the click event for the user. This shows the dialog tof the user.
        component.$link.trigger('click');
    }
}

/**
 * Check if the url contains the correct string.
 * @returns {boolean}
 */
UserMyHouseOptions.prototype.checkUrl = function () {
    const component = this;
    const url = window.location.href;

    return (url.indexOf(component.urlValue) > -1);
};

$(COMPONENT_SELECTOR).each((index, element) => new UserMyHouseOptions(element));

// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

export default Twitterfeed;

// component configuration
const COMPONENT_ATTR = 'data-makelaars-twitterfeed';
const COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';
const LIST_TEMPLATE_SELECTOR = '[data-makelaars-twitterfeed-list]';
const TWEET_TEMPLATE_SELECTOR = '[data-makelaars-twitterfeed-item-template]';
const HAS_RESULTS_CLASS = 'has-results';

function Twitterfeed(element) {
    const $element = $(element);
    this.$element = $element;
    this.apiEndpoint = $element.attr(COMPONENT_ATTR);
    this.template = $element.find(TWEET_TEMPLATE_SELECTOR).html();
    this.$list = $element.find(LIST_TEMPLATE_SELECTOR);
    this.getTweets();
}

Twitterfeed.prototype.getTweets = function() {
    $.ajax(this.apiEndpoint)
        .then(response => {
            if (!response.tweets.length) { throw Error('No tweets'); }
            return response;
        }).then(response => {
            const template = Twitterfeed.render(this.template, {
                user: response.user,
                profileImage: response.profileImage
            });
            const html = Twitterfeed.renderTweets(template, response.tweets);
            this.update(html);
        }).catch(err => console.error(err));
};

Twitterfeed.prototype.update = function(html) {
    this.$list.html(html);
    this.$element.addClass(HAS_RESULTS_CLASS);
};

Twitterfeed.render = function(template, data) {
    return Object.keys(data).reduce((html, prop) => {
        var pattern = new RegExp('{' + prop + '}', 'g');
        return html.replace(pattern, data[prop]);
    }, template);
};

Twitterfeed.renderTweets = function(template, tweets) {
    return tweets.map(item => Twitterfeed.render(template, item)).join('');
};

$(COMPONENT_SELECTOR).each((index, element) => new Twitterfeed(element));

/**
 * object-detail Print-friendly view
 */

import './../_base-view/base-view';

const PRINT_PAGE_SELECTOR = '.object-detail-print';
const PRINT_DELAY = 1000;

if (document.querySelector(PRINT_PAGE_SELECTOR)) {
    window.onload = () => {
        setTimeout(() => window.print(), PRINT_DELAY);
    };
}

// dependencies (alphabetically)
import $ from 'jquery';

export default ObjectKenmerken;

// component configuration
const COMPONENT_ATTR = 'data-object-kenmerken';
const COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';
const HANDLE_ATTR = 'data-object-kenmerken-handle';
const HANDLE_SELECTOR = '[' + HANDLE_ATTR + ']';
const ENHANCED_CLASS = 'is-expandible';
const EXPANDED_CLASS = 'is-expanded';
const CONTENT_ATTR = 'data-object-kenmerken-body';
const CONTENT_SELECTOR = '[' + CONTENT_ATTR + ']';

function ObjectKenmerken(element) {
    const component = this;
    component.$element = $(element);

    if (window.matchMedia) {
        const mq = '(max-width: 750px)';
        // collapsed by default on smaller screens
        if (window.matchMedia(mq).matches) {
            component.$element.removeClass(EXPANDED_CLASS);
        }

        // add listener for when screen is resized
        matchMedia(mq).addListener(function(mql) {
            if (mql.matches) {
                // screen became small
                component.collapsedHeight = component.$content.children().eq(0).outerHeight() +
                    component.$content.children().eq(1).outerHeight() +
                    component.$handle.outerHeight();

                component.toggleExpand(false);
            } else {
                component.toggleExpand(true);
            }
        });
    }

    // define relevant dom elements and initial value
    component.expandibleId = element.getAttribute(COMPONENT_ATTR) || false;
    component.isExpanded = component.$element.hasClass(EXPANDED_CLASS);
    component.$handle = component.$element.find(HANDLE_SELECTOR);
    component.$handlesOutside = $();

    // if component has an ID, look for handles outside the component with that ID
    if (component.expandibleId) {
        component.$handlesOutside = $('body').find('[' + HANDLE_ATTR + '="' + component.expandibleId + '"]');
    }

    // get full height before the collapsing
    component.$content = component.$element.find(CONTENT_SELECTOR).first();
    component.fullHeight = component.$content.outerHeight(true);

    // enhance, set initial state, and toggle when handles are triggered
    component.$element.addClass(ENHANCED_CLASS);

    // and one more time after collapsing
    component.collapsedHeight = component.$content.outerHeight(true);

    component.toggleExpand(component.isExpanded); //init once

    component.$element.on('click', HANDLE_SELECTOR, function() {
        component.toggleExpand();
    });

    component.$handlesOutside.on('click', function() {
        component.toggleExpand();
    });
}

/**
 * Toggle (expand / collapse) the expand state by adding / removing the expanded class.
 * @param {Boolean} [isExpanded]    Set true to force component to expand (optional).
 * @returns {Boolean}               True if component is expanded.
 */
ObjectKenmerken.prototype.toggleExpand = function(isExpanded) {
    const component = this;
    component.isExpanded = (isExpanded !== undefined) ? isExpanded : !component.isExpanded;
    component.$element.toggleClass(EXPANDED_CLASS, component.isExpanded);
    component.$handlesOutside.toggleClass(EXPANDED_CLASS, component.isExpanded);
    component.$element.attr('aria-expanded', component.isExpanded);

    if (component.isExpanded) {
        component.$content.css('height', 'auto');
        ObjectKenmerken.sendKenmerkenExpandedEvent();
    } else {
        component.$content.css('height', component.collapsedHeight + 'px');
    }

    return component.isExpanded;
};

ObjectKenmerken.sendKenmerkenExpandedEvent = function() {
    $(document).trigger('kenmerkenExpanded', {selector: '[data-object-kenmerken]'});
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new ObjectKenmerken(element));


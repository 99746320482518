/* globals embedpano removepano */

export default Foto360View;

import $ from 'jquery';
import load from 'load-script';
import Menu from '../media-viewer-menu/media-viewer-menu';
import '../krpano-loader/krpano-loader';

var COMPONENT_SELECTOR = '[data-media-viewer-foto360]';
var FOTO_360_CONTAINER_SELECTOR = '.media-viewer-foto360-container';
var KRPANO_CONTAINER_ID = 'media-viewer-foto360-krpano';
var KRPANO_CONTAINER_OBJECT = '#krpanoSWFObject';
var KRPANO_BASE_URL_ATTR = 'data-media-viewer-foto360-krpano-base-url';
var ITEMS_SELECTOR = '[data-media-viewer-foto360-items]';
var FOTO_360_MENU_HEADER_SELECTOR = '[data-foto360-menu-header]';
var FOTO_360_MENU_FOOTER_SELECTOR = '[data-foto360-menu-footer]';
var VR_BUTTON_SELECTOR = '[data-vr-view-select]';
var HOTSPOT_CLICKED_EVENT = 'krpanohotspotclicked';
var MENU_ITEM_SELECTED_EVENT = 'mediaviewermenuitemselected';
var WEBVR_ACTIVE_CLASS = 'active';
var WEBVR_AVAILABLE_CLASS = 'available';
var WEBVR_AVAILABLE_EVENT = 'webvravailable';
var WEBVR_ENABLED_EVENT = 'webvrenabled';
var WEBVR_DISABLED_EVENT = 'webvrdisabled';
var WEBVR_OPEN_CLASS = 'krpano-webvr-enabled';

function Foto360View(element, foto360Id) {
    var view = this;

    view.$container = $(FOTO_360_CONTAINER_SELECTOR);
    view.baseUrl = view.$container.attr(KRPANO_BASE_URL_ATTR) || '';
    view.items = JSON.parse($(element).find(ITEMS_SELECTOR).text());
    view.$vrButton = $(VR_BUTTON_SELECTOR);

    if (view.baseUrl !== '') {
        view.baseUrl = this.normalizeUrl(view.baseUrl);
    }

    // if there are multiple 360 views
    if (Object.keys(view.items).length > 1) {
        var $header360Select = $(FOTO_360_MENU_HEADER_SELECTOR);
        var $footer360Select = $(FOTO_360_MENU_FOOTER_SELECTOR);
        view.menu = new Menu($header360Select, $footer360Select);
    }

    view.src = view.items[foto360Id];

    if (view.isKrpanoInitialized === true) {
        view.krpano.call('loadpano(' + view.src + ', null, null, BLEND(0.05))');
    } else {
        this.initKrpano();
        if (view.menu) {
            view.menu.transformHeader();
        }
    }

    if (view.menu) {
        view.menu.setActive(foto360Id);
    }

    view.bindEvents();
}

Foto360View.prototype.bindEvents = function() {
    const view = this;

    $(window).on(HOTSPOT_CLICKED_EVENT, function(e, data) {
        if (data.name) {
            view.gotoPano(data.name, data.referer);
            if (view.menu) {
                view.menu.setActive(data.name);
            }
        }
    });

    $(window).on(MENU_ITEM_SELECTED_EVENT, function(e, data) {
        if (data.type && data.type === 'foto360') {
            view.gotoPano(data.menuParam);
        }
    });

    $(window).on(WEBVR_AVAILABLE_EVENT, function() {
        $(VR_BUTTON_SELECTOR).addClass(WEBVR_AVAILABLE_CLASS);
    });

    view.$vrButton.on('click', function() {
        view.krpano.call('funda_enter_vr()');
    });

    $(window).on(WEBVR_ENABLED_EVENT, function() {
        $(KRPANO_CONTAINER_OBJECT).addClass(WEBVR_OPEN_CLASS);

        $(VR_BUTTON_SELECTOR).addClass(WEBVR_ACTIVE_CLASS);
    });

    $(window).on(WEBVR_DISABLED_EVENT, function() {
        $(KRPANO_CONTAINER_OBJECT).removeClass(WEBVR_OPEN_CLASS);

        $(VR_BUTTON_SELECTOR).removeClass(WEBVR_ACTIVE_CLASS);
    });
};

Foto360View.prototype.gotoPano = function(name, referer) {
    var view = this;
    var variables = (referer) ? 'referer=' + referer : 'null';
    view.krpano.call('loadpano(' + view.items[name] + ', ' + variables + ', null, BLEND(0.05))');
    window.history.replaceState({}, '', '#360-foto-' + name);
};

Foto360View.prototype.normalizeUrl = function(url) {
    var aElement = document.createElement('a');
    aElement.href = url;
    return aElement.href;
};

Foto360View.prototype.initKrpano = function() {
    var view = this;
    var debugging = window.location.search.indexOf('krpanodebug=true') !== -1;
    var krpanoContainer = document.createElement('div');

    krpanoContainer.id = KRPANO_CONTAINER_ID;
    view.$container.html(krpanoContainer);

    load(view.baseUrl + '/assets/vendor-krpano.js', function(error) {
        if (!error) {
            embedpano({
                html5: 'prefer',
                swf: view.baseUrl + '/assets/components/krpano-loader/krpano.swf',
                xml: view.src,
                target: KRPANO_CONTAINER_ID,
                consolelog: debugging,
                onready: onReady
            });
        }
    });

    function onReady(krpano) {
        view.krpano = krpano;

        // Hack for the relative path of the stub pano's in the guide.
        // Krpano sets its basedir automatically to the path of the first loaded pano,
        // therefore the next pano's relative path becomes relative to the first one's path.
        krpano.set('basedir', '');
        // end hack
    }

    view.isKrpanoInitialized = true;
};

Foto360View.prototype.dispose = function() {
    var view = this;
    removepano(KRPANO_CONTAINER_ID);
    view.isKrpanoInitialized = false;
    view.unbindEvents();
    if (view.menu) {
        view.menu.dispose();
    }
};

Foto360View.prototype.unbindEvents = function() {
    $(window).off(HOTSPOT_CLICKED_EVENT);
    $(window).off(MENU_ITEM_SELECTED_EVENT);
    $(window).off(WEBVR_ENABLED_EVENT);
    $(window).off(WEBVR_DISABLED_EVENT);
};

// turn all elements with the default selector into components
// Auto-initialize means the component should instantiate itself as normal
// Otherwise this view's constructor will likely be called from the media viewer
$(COMPONENT_SELECTOR).each(function(index, element) {
    var autoInitialize = element.getAttribute('data-media-viewer-auto-initialize');

    if (autoInitialize === true) {
        return new Foto360View(element, 'straat');
    }
});

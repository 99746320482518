import reduxStore from '../store/store';
import Connect from '../store/connect';
import { setMediaViewerRoute, setMediaViewerHash, setActiveLocation } from '../store/actions';

const LOCATION_DETAIL_REGEX = /locatie\/([^/]*)/;
const currentUrlHash = () => window.location.hash.replace(/^#/, '');
const isMediaViewerHash = hash => hash.match(/^(foto|video|360-foto|plattegrond|overzicht|kaart|nwn-kaart)/) !== null;

class MediaViewerRouter {
    constructor(store) {
        this.store = store || reduxStore;
        this.initialUrl = window.location.href.replace(window.location.hash, '');
        this.lastPushedHash = '';
        this.hasPushed = false;

        this.connect = new Connect(this.store, [
            'ui.mediaViewerHash'
        ]);

        this.bindEvents();
        this.store.dispatch(setMediaViewerHash(currentUrlHash()));

        if (window.location.hash) {
            this.hasPushed = true;
        }
    }

    bindEvents() {
        this.connect.subscribe((state, prevState) => {
            if (state.ui.mediaViewerHash !== prevState.ui.mediaViewerHash) {
                const enteringViewer = !isMediaViewerHash(prevState.ui.mediaViewerHash) && isMediaViewerHash(state.ui.mediaViewerHash);
                const leavingViewer = isMediaViewerHash(prevState.ui.mediaViewerHash) && !isMediaViewerHash(state.ui.mediaViewerHash);
                const hashIsDifferent = currentUrlHash() !== state.ui.mediaViewerHash;
                if (hashIsDifferent || enteringViewer || leavingViewer) {
                    // when navigating to empty hash, replace it with full url (so # does not show up in address bar)
                    const destHash = state.ui.mediaViewerHash ? `#${state.ui.mediaViewerHash}` : this.initialUrl;
                    if (!this.hasPushed) {
                        window.history.pushState('media-viewer-router', null, destHash);
                        this.hasPushed = true;
                    } else {
                        window.history.replaceState('media-viewer-router', null, destHash);
                    }
                }
                // remember last pushed hash, so we can correctly perform history flattening
                this.lastPushedHash = state.ui.mediaViewerHash;
                // just echo stuff back

                const mediaViewerRouteContainsLocationId = this.lastPushedHash.match(LOCATION_DETAIL_REGEX);
                if (mediaViewerRouteContainsLocationId) {
                    const activeLocationId = mediaViewerRouteContainsLocationId[1];
                    this.store.dispatch(setActiveLocation(activeLocationId));
                } else {
                    this.store.dispatch(setActiveLocation(null));
                }

                this.store.dispatch(setMediaViewerRoute(this.lastPushedHash));
            }
        });

        window.onpopstate = () => {
            // flatten history when popping non-empty mediaViewerLocation
            if (this.lastPushedHash !== '') {
                this.store.dispatch(setMediaViewerHash(''));
                this.hasPushed = false;
                this.lastPushedHash = '';
            } else {
                const routeHash = window.location.hash.replace(/^#/, '');
                this.hasPushed = true;
                this.store.dispatch(setMediaViewerHash(routeHash));
                this.lastPushedHash = routeHash;
            }
        };
    }
}

export default new MediaViewerRouter();
export { MediaViewerRouter as MockableMediaViewerRouter };

import './../../components/tooltip-inline/tooltip-inline';
import './../../components/tooltip/tooltip';

import $ from 'jquery';

const COMPONENT_SELECTOR = '[data-aanmeld-invoice]';
const CONTAINER_SELECTOR = '[data-aanmeld-invoice-container]';
const INVOICE_INPUT_SELECTOR = '[data-aanmeld-invoice-inputs]';

function MakelaarAanmeldToggle(element) {
    const component = this;
    component.$element = $(element);
    component.container = $(CONTAINER_SELECTOR);
    component.inputs = component.container.find(INVOICE_INPUT_SELECTOR);
    component.$element.on('change', () =>
        component.toggleActive(!element.checked)
    );
    component.toggleActive(!element.checked);
}

MakelaarAanmeldToggle.prototype.toggleActive = function (status) {
    const component = this;
    component.container.toggleClass('is-hidden', status);
    component.inputs.attr('required', !status);
};

$(COMPONENT_SELECTOR).each((index, element) => new MakelaarAanmeldToggle(element));

import $ from 'jquery';
import '../../components/expandible/expandible';

export default ObjectShareMenu;

const COMPONENT_SELECTOR = '[data-object-share-menu]';
const COPY_LINK_SELECTOR = '[data-object-share-menu-copy-link]';

function ObjectShareMenu(element) {
    const component = this;

    component.$element = $(element);
    component.$itemCopyLink = component.$element.find(COPY_LINK_SELECTOR);

    component.$itemCopyLink.on('click', (event) => {
        event.preventDefault();

        copyToClipboard(component.$itemCopyLink.attr('href'));
    });
}

// From: https://github.com/feross/clipboard-copy/
function copyToClipboard(text) {
    // A <span> contains the text to copy
    var span = document.createElement('span');
    span.textContent = text;
    span.style.whiteSpace = 'pre'; // Preserve consecutive spaces and newlines

    // An <iframe> isolates the <span> from the page's styles
    var iframe = document.createElement('iframe');
    iframe.sandbox = 'allow-same-origin';
    document.body.appendChild(iframe);

    var win = iframe.contentWindow;
    win.document.body.appendChild(span);

    var selection = win.getSelection();

    // Firefox fails to get a selection from <iframe> window, so fallback
    if (!selection) {
        win = window;
        selection = win.getSelection();
        document.body.appendChild(span);
    }

    var range = win.document.createRange();
    selection.removeAllRanges();
    range.selectNode(span);
    selection.addRange(range);

    var success = false;
    try {
        success = win.document.execCommand('copy');
    } catch (err) {
        console.log(err);
    }

    // Hide the <iframe>, because IE11 can't remove the <iframe>
    iframe.style.display = 'none';

    selection.removeAllRanges();
    span.remove();
    iframe.remove();

    return success;
}

ObjectShareMenu.initialize = function () {
    $(COMPONENT_SELECTOR).each((index, element) => new ObjectShareMenu(element));
};

ObjectShareMenu.initialize();

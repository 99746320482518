// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default NotificationStatus;

// component configuration
var COMPONENT_SELECTOR = '[data-notification-status]';
var STATUS_DOMAIN = 'data-funda-status-domain';
var CONFIG_URL_RANDOM = '.js?rnd=' + Math.random().toString();
var STATUS_AREA = 'data-notification-status-area';
var STATUS_NOTIFICATION_HTML = 'data-notification-status-html';
var STATUS_INSERT_TOP = 'data-notification-status-insert-top';

function NotificationStatus(element) {
    var component = this;
    var area = element.getAttribute(STATUS_AREA);
    var html = element.getAttribute(STATUS_NOTIFICATION_HTML);
    var insertTop = element.getAttribute(STATUS_INSERT_TOP);
    component.statusDomain = element.getAttribute(STATUS_DOMAIN);
    // 'window.statusScriptSettings' is a dependency of the script that gets attached to the DOM.
    window.statusScriptSettings = {
        options: { },
        site: 'funda'
    };
    window.statusScriptSettings.options[area] = true;
    if (html) {
        window.statusScriptSettings.options.html = html;
    }
    if (insertTop && insertTop == 'true') {
        window.statusScriptSettings.options.insertTop = true;
    }
    component.loadStatus();
}

NotificationStatus.prototype.loadStatus = function() {
    var component = this;
    var statusScript = document.createElement('script');
    var anchor = document.body.appendChild(statusScript);
    var statusScriptSettings = '/js/' + window.statusScriptSettings.site + CONFIG_URL_RANDOM;
    statusScript.setAttribute('defer', 'defer');
    statusScript.setAttribute('src', (document.location.protocol) + '//' + component.statusDomain + statusScriptSettings);
    anchor.parentNode.insertBefore(statusScript, anchor);
};

// turn all elements with the default selector into components
if (COMPONENT_SELECTOR.length) {
    $(COMPONENT_SELECTOR).each(function(index, element) {
        return new NotificationStatus(element);
    });
}
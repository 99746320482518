import $ from 'jquery';
import extend from 'lodash/extend';
import isFunction from 'lodash/isFunction';

import {EVENT_TYPE} from '../media-viewer-map/media-viewer-gtm-events';

export default AsyncForm;

// component configuration
var AJAX_SUBMIT_SELECTOR = '[dialog-ajax-submit]';
var OPTIONS = {
    body: null,
    success: null,
    error: null,
    submitCallback: null
};
var EVENT_USER_AUTHENTICATE = 'user-authenticated-event';

function AsyncForm($element, options) {
    var component = this;
    component.$element = $element.find('form');
    component.options = extend(OPTIONS, options);

    if (component.$element.length === 0) {
        return;
    }
    component.hookForm();
}

AsyncForm.prototype.hookForm = function() {
    var component = this;
    var url = component.$element.attr('action');

    // binding to specific form/action
    $('form[action="' + url + '"]').on('submit', function(event) {
        event.preventDefault();

        var submitButtons = AsyncForm.getSubmitButtons($(event.target));

        //disable all submit buttons
        for (let i = 0, length = submitButtons.length; i < length; i++) {
            $(submitButtons[i]).prop('disabled', true);
        }

        var data = $(this).find(':input').serialize();
        var eventType = url.indexOf('/mijn/login/dialog/') !== -1 ? EVENT_TYPE.LOGIN_EMAIL : EVENT_TYPE.REGISTER_EMAIL;
        // post it
        $.post({
            url: url,
            data: data,
            cache: false,
            xhrFields: {
                withCredentials: true
            },
            dataType: 'json'
        })
            .then(function(response) {
                if (response.Result === 'OK') {
                    if (response.View === null || typeof response.View === 'undefined') {
                        if (isFunction(component.options.success)) {
                            $(document).trigger(EVENT_USER_AUTHENTICATE, {loginType: eventType});
                            component.options.success();
                        }
                    } else {
                        var $viewHtml = $(response.View);
                        component.options.body.html(response.View);
                        // bind social login
                        if (component.options.submitCallback) {
                            component.options.submitCallback(component.options.body);
                        }
                        // bind new instance to form
                        if (AsyncForm.wantsAjaxSubmit($viewHtml)) {
                            return new AsyncForm($viewHtml, {
                                success: component.options.success,
                                error: component.options.error
                            });
                        }
                    }
                }
                // TODO: add handling of errors (eg. result = 'ERROR' and errorMessage != null)
            })
            .catch(function(reason) {
                console.log(reason); // TODO: add handling of errors (eg. result = 'ERROR' and errorMessage != null)

                //enable all submit buttons
                for (let i = 0, length = submitButtons.length; i < length; i++) {
                    $(submitButtons[i]).prop('disabled', false);
                }
            });
    });
};

AsyncForm.wantsAjaxSubmit = function($viewHtml) {
    var $ajaxSubmit = AsyncForm.getSubmitButtons($viewHtml);
    return $ajaxSubmit.length > 0;
};
AsyncForm.getSubmitButtons = function($viewHtml) {
    return $viewHtml.find(AJAX_SUBMIT_SELECTOR);
};

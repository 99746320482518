import $ from 'jquery';

const controllerService = (() => {
    const publics = {};
    const SERVICE_DATA = 'controller-uid';
    const controllers = [];

    let uid = 0;

    function getUid() {
        return uid++;
    }

    function get$element(element, ControllerClass) {
        return typeof ControllerClass.getSelector === 'function' ?
            $(element).find(ControllerClass.getSelector())
            : $(element);
    }

    function getControllerBy$element($element, ControllerClass) {
        if ($element.length !== 1) {
            console.error('For binding a controller we need to have exactly one html, but we have ' + $element.length);
            console.error($element, ControllerClass.getSelector());
        }

        let id = $element.data(SERVICE_DATA);
        if (typeof id === 'undefined') {
            id = getUid();
            controllers[id] = new ControllerClass($element);
            $element.attr('data-' + SERVICE_DATA, id);
        }
        return controllers[id];
    }

    /**
     * Features (a.k.a. limtitations) one html attribute can have at most one controller!
     * @param element
     * @param ControllerClass
     * @param useSelector
     * @returns {*}
     */
    publics.getInstance = (ControllerClass, element) => {
        const $element = get$element(element, ControllerClass);
        return getControllerBy$element($element, ControllerClass);
    };

    /**
     *
     * @param ControllerClass
     * @param scope
     * @returns {Array}
     */
    publics.getAllInstances = (ControllerClass, scope) => {
        const componentSelector = ControllerClass.getSelector();
        const results = [];
        const $scope = (typeof scope === 'undefined') ? $(componentSelector) : $(scope).find(componentSelector);

        $scope.each((i, element) => results.push(getControllerBy$element($(element), ControllerClass)));

        return results;
    };

    return publics;
})();

export default controllerService;


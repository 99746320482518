import $ from 'jquery';
import Graph from '../graph/graph';
import UserMyHouseDatasource from '../user-my-house-datasource/user-my-house-datasource';

export default UserMyHouseGraph;

// component configuration
const COMPONENT_SELECTOR = '[data-my-house-graph]';
const GRAPH_SELECTOR = '[data-graph]';
const CHART_ASSET_BASE = 'data-graph-asset-base';
const YAXIS_LABEL_SELECTOR = 'data-y-axis-label-';
const HIGHCHARTS_CONTAINER = '.highcharts-container';
const ERROR_MESSAGE_CLASS = '.graph-no-data';
const ERROR_MESSAGE_SELECTOR = '[data-graph-error-message-template]';
const TRANSLATION_DAY_LABEL = 'data-translation-day-label';

/**
 * Constructor method, links child elements to variables for internal use
 * @param {HTMLElement} element     The HTML element to bind to.
 */
function UserMyHouseGraph(element, dataSource) {
    const component = this;
    component.$element = $(element);
    const $graphElement = component.$element.find(GRAPH_SELECTOR);
    const $assetBaseUrl = $graphElement.attr(CHART_ASSET_BASE);
    const graphOptions = {
        setData: function(targetData, receivedData) {
            return component.setData(targetData, receivedData);
        }
    };

    if ($graphElement.length !== 1) {
        return;
    }

    if ($assetBaseUrl) {
        graphOptions.assetBaseUrl = component.assetBaseUrl;
    } else {
        return;
    }

    component.graph = new Graph($graphElement, graphOptions);

    // Register loading events at datasource observer
    dataSource || UserMyHouseDatasource.instance.registerDataChanged({
        onDataLoading: UserMyHouseGraph.loadingData,
        onDataLoaded: UserMyHouseGraph.loadData,
        onDataLoadError: UserMyHouseGraph.loadError,
        isLoaded: UserMyHouseGraph.isLoaded,
        context: this
    });
}

/**
 * return if this component is loaded (used by user-my-house-datasource)
 * @returns {*|boolean}     returns graph object, If component is loaded.
 */
UserMyHouseGraph.isLoaded = function() {
    return this.graph && this.graph.componentLoaded;
};

/**
 * Using the selected option format, set the data to the correct format and update the graph.
 * @param {object[]} rawData - Data received from the statistics API.
 * @param {array[]} period - Selected option from the dropdown.
 * @param {string} type - Type of axis label.
 */
UserMyHouseGraph.loadData = function(rawData, period, type) {
    const component = this;
    const selectedOptionFormat = period.data('format');

    if (selectedOptionFormat === 'Date') {
        component.setToDates(rawData.GraphData);
    } else if (selectedOptionFormat === 'DayNumber') {
        component.setToIndex(rawData.GraphData);
    }

    component.updateAxisInterval(period);
    component.updateAxisFormat(period, type);

    component.graph.processData();
};

/**
 * Transform raw data into the correct Highcharts format using the index number as the 'x' values.
 * @param {string[]} rawData
 * @returns {{Viewed: [], Flags: [], Products: []}}
 */
UserMyHouseGraph.prototype.setToIndex = function(rawData) {
    const component = this;
    const getDay = (date) => new Date(date).getDate();

    component.graph.data = {
        viewed: rawData.Viewed.map(item => [
            (rawData.Viewed.indexOf(item) + 1), item.Value
        ]),
        flags: rawData.Flags.map(item => {
            return {
                x: (item.Date = getDay(item.Date)),
                y: 0, title: 'flags'
            };
        }),
        products: rawData.Products.map(item => {
            return {
                x: (rawData.Products.indexOf(item) + 1),
                y: 0, title: item.Value, name: 'products'
            };
        }),
        weekends: rawData.Weekends
    };
};

/**
 * Transform raw data into the correct Highcharts format using dates as the 'x' values.
 * @param {string[]} rawData
 * @returns {{Viewed: [], Flags: [], Products: []}}
 */
UserMyHouseGraph.prototype.setToDates = function(rawData) {
    const component = this;
    const isoDate = (date) => new Date(date).getTime();

    // Transform all date formats.
    Object.keys(rawData).forEach(segment => {
        rawData[segment].forEach(item => (item.Date = isoDate(item.Date)));
    });

    component.graph.data = {
        viewed: rawData.Viewed.map(item => [
            item.Date,
            item.Value
        ]),
        flags: rawData.Flags.map(item => {
            return {
                x: item.Date,
                y: 0, title: 'flags'
            };
        }),
        products: rawData.Products.map(item => {
            return {
                x: item.Date,
                y: 0,
                title: item.Value,
                name: 'products'
            };
        }),
        weekends: rawData.Weekends
    };
};

/**
 * Return the correct axis label when switching between statistics types.
 * @param {string} type     Type of statistics selected.
 * @returns {string}        Axis label string.
 */
UserMyHouseGraph.prototype.setAxisLabel = function (type) {
    const component = this;
    const axisLabels = {};

    $.each(component.$element.get(0).attributes, function(i, attr) {
        let attributeName = attr.name;
        let labelName = attr.value;

        if (attributeName.indexOf(YAXIS_LABEL_SELECTOR) === 0) {
            attributeName = attributeName.substring(attributeName.lastIndexOf('-') + 1);
            axisLabels[attributeName] = labelName;
        }
    });

    return axisLabels[type];
};

/**
 * Set the format for the x-axis based on the selected option.
 * @param {array} period        Selected option from the period dropdown.
 * @param {string} type         Type of statistics selected.
 */
UserMyHouseGraph.prototype.updateAxisFormat = function(period, type) {
    const component = this;
    const selectedOptionFormat = period.data('format');

    // Update the Y-axis label when switching type of statistics.
    component.graph.chart.yAxis[0].update({
        title: {
            text: component.setAxisLabel(type)
        }
    });

    if (selectedOptionFormat === 'Date') {
        component.graph.chart.xAxis[0].update({
            type: 'datetime',
            labels: {
                format: undefined
            }
        }, false);
    } else if (selectedOptionFormat === 'DayNumber') {
        component.graph.chart.xAxis[0].update({
            tickInterval: 7,
            type: 'linear',
            labels: {
                format: component.$element.attr(TRANSLATION_DAY_LABEL)
            }
        }, false);
    }
};

/**
 *  Set the interval for the x-axis based on the selected option.
 * @param {array} period        Selected option from the period dropdown.
 */
UserMyHouseGraph.prototype.updateAxisInterval = function(period) {
    const component = this;
    const dayTick = 24 * 3600 * 1000;
    const selectedOptionFormat = period.data('interval');
    let interval;

    if (selectedOptionFormat === 'Daily') {
        interval = dayTick;
    } else if (selectedOptionFormat === 'Weekly') {
        interval = 7 * dayTick;
    } else if (selectedOptionFormat === 'Monthly') {
        interval = 30 * dayTick;
    } else if (selectedOptionFormat === 'Yearly') {
        interval = 365 * dayTick;
    }

    component.graph.chart.xAxis[0].update({
        tickInterval: interval
    }, false);
};

/**
 * Tell all related components data is being loaded.
 */
UserMyHouseGraph.loadingData = function() {
    this.graph.setLoading(true);
};

/**
 * Show user something went wrong.
 */
UserMyHouseGraph.loadError = function() {
    const component = this;
    const $errorElement = $(ERROR_MESSAGE_CLASS);
    const $errorElementTemplate = $(ERROR_MESSAGE_SELECTOR).html().trim();

    // Don't append if it already exists.
    if ($errorElement.length === 0) {
        $(HIGHCHARTS_CONTAINER).append($errorElementTemplate);
    }

    // Always stop showing the spinner.
    component.graph.setLoading(false);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new UserMyHouseGraph(element));

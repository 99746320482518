import reduxStore from '../store/store';
import Connect from '../store/connect';

const POLYGON_CONFIG = {strokeWidth: 15};

export default class TravelTimeMapPolygon {
    constructor(googleMap, store) {
        this.store = store || reduxStore;
        const TgmGoogleMapsPolygonOverlay = require('@targomo/googlemaps/targomo-googlemaps-full.umd').TgmGoogleMapsPolygonOverlay;
        this.layer = new TgmGoogleMapsPolygonOverlay(googleMap, POLYGON_CONFIG);
        this.layer.setInverse(true);

        this.connect = new Connect(this.store, [
            'ui.travelTimePolygon'
        ]);

        this.subscribeToStore();
    }

    subscribeToStore() {
        this.connect.subscribe((state) => {
            this.clearPolygon();
            this.renderPolygon(state.ui.travelTimePolygon);
        });
    }

    renderPolygon(polygonData) {
        this.layer.setData(polygonData);
    }

    clearPolygon() {
        this.layer.setData(null);
    }
}

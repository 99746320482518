// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import ajax from '../ajax/ajax';
import debounce from 'lodash/debounce';

// what does this module expose?
export default PartialPageUpdate;

// component configuration
const COMPONENT_NAME = 'data-partial-page-update';
const COMPONENT_SELECTOR = '[data-partial-page-update]';
const HANDLE_SELECTOR = '[data-partial-page-update-handle]';
const OUTPUT_SELECTOR = '[data-partial-page-update-output]';
const OUTPUT_KEY_ATTR = 'data-partial-page-update-output';

const URL_SUFFIX = '?ajax=true';
const PUSH_STATE_MARKER = 'push_state_set_by_funda';
const MINIMUM_INTERVAL_BETWEEN_REQUESTS = 500; // ms
const TIMEOUT_SEARCH_REQUEST = 30000; // 30 sec

function PartialPageUpdate(form, ajaxDependency=ajax) {
    const component = this;
    component.$form = $(form);
    component.$handle = component.$form.find(HANDLE_SELECTOR);
    component.url = component.$form.attr(COMPONENT_NAME);
    component.$outputs = component.$form.find(OUTPUT_SELECTOR);
    component.ajax = ajaxDependency;

    component.outputMap = {};
    component.$outputs.each(function(index, output) {
        var key = output.getAttribute(OUTPUT_KEY_ATTR);
        var $outputs = component.outputMap[key] || $();
        component.outputMap[key] = $outputs.add(output);
    });

    component.bindEvents();

    // initialize popstate
    window.history.replaceState(PUSH_STATE_MARKER, window.title);
}

PartialPageUpdate.prototype.bindEvents = function() {
    const component = this;

    function debouncedRequest() {
        return debounce(function() {
            component.doRequest();
        }, MINIMUM_INTERVAL_BETWEEN_REQUESTS, { leading: true });
    }

    component.$handle.on('change', debouncedRequest());
};

PartialPageUpdate.prototype.doRequest = function() {
    const component = this;
    const formData = component.$form.serialize();
    // ensure outputs indicate they are updating
    $(document).trigger('resultsUpdating');

    // abort previous request if still pending
    if (component.request) {
        component.request.abort();
    }

    component.request = component.ajax({
        url: component.url + URL_SUFFIX,
        method: 'GET',
        data: formData,
        timeout: TIMEOUT_SEARCH_REQUEST,
        success: (data => component.successHandler(data))
    });
};

PartialPageUpdate.prototype.successHandler = function (data) {
    const component = this;
    component.updatePartialPage(data);
};

/**
 * @param {Object} data
 * @param {Object} data.content
 * @param {String} data.url
 */
PartialPageUpdate.prototype.updatePartialPage = function(data) {
    var component = this;
    // update first all sync elements
    document.title = data.title;

    component.updateHistory(data.url);
    component.renderOutputResults(data);
    // content is been updated. run image-error-fallback
    $(document).trigger('resultsUpdated');
};

/**
 * Render the results from the search request
 * @param {Object} resultData with keys corresponding to output identifiers and their new HTML as values.
 */
PartialPageUpdate.prototype.renderOutputResults = function(resultData) {
    var component = this;
    var fields = resultData.content;

    for (let key in fields) {
        if (fields.hasOwnProperty(key) && component.outputMap[key] && fields[key] !== null) {
            if (component.outputMap[key].is('input')) {
                component.outputMap[key]
                    .val(fields[key])
                    .trigger('change');
            } else {
                component.outputMap[key].html(fields[key]);
            }
        }
    }
};

/**
 * Add url to browser history
 * @param {String} url
 */
PartialPageUpdate.prototype.updateHistory = function(url) {
    window.history.pushState(PUSH_STATE_MARKER, window.title, url);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new PartialPageUpdate(element));
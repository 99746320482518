/* global gtmDataLayer */
import $ from 'jquery';
import ajax from '../ajax/ajax';
import debounce from 'lodash/debounce';
import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';
import AppliedFilters from '../applied-filters/applied-filters';
import Autocomplete from '../autocomplete/autocomplete';
import CustomSelectBox from '../custom-select-box/custom-select-box';
import FilterFlyout from '../filter-flyout/filter-flyout';
import HistoryState from '../history-state/history-state';

// component configuration
const COMPONENT_SELECTOR = '[data-instant-search]';
const HANDLE_SELECTOR = '[data-instant-search-handle]';
const COMPONENT_MAP_SELECTOR = '[data-instant-map-search]';
const RANGE_SELECTOR = '[data-instant-search-range]';
const SORT_SELECTOR = '[data-sorting]';
const BODY_SELECTOR ='body';
const OUTPUT_KEY_ATTR = 'data-instant-search-output';
const OUTPUT_SELECTOR = '[data-instant-search-output]';
const SEARCH_SIDEBAR_SELECTOR = '[data-search-sidebar]';
const SIDEBAR_CLOSED_EVENT = 'sidebarclosed';
const INVALID_LOCATION_EVENT = 'invalidlocation';
const AUTOCOMPLETE_SELECTOR = '[data-autocomplete]';
const CUSTOM_SELECTBOX_SELECTOR = '[data-custom-select-box]';
const RADIUS_SELECTOR = '[data-input-radius] select';
const ZOEKTYPE_SELECTOR = 'select[data-zoektype]';
const MAKELAARNAAM_SELECTOR = '[data-makelaars-name]';
const RANGE_FLYOUT_SELECTOR = '[data-filter-flyout-range]';
const COUNT_ATTR = 'data-instant-search-count';
const SOLD_OBJECTS_ANCHOR_SELECTOR = '[data-instant-search-sold-objects]';
const FOR_SALE_OBJECTS_SELECTOR = '[data-instant-search-for-sale-objects]';
const PAGINATION_SELECTOR = '[data-pagination]';
const FILTER_COUNT_NUMBER = '[data-filter-count-number]';
const UPDATING_CLASS = 'is-updating';
const EXTENDED_CLASS = 'is-extended';
const NO_RESULTS_CLASS = 'has-no-results';
const MULTILINE_CLASS = 'multiline';
const HISTORISCH_TOGGLE_SELECTOR = '.sold-objects-toggle';
const HISTORISCH_CURRENT_SELECTOR = '[for="IndHistorisch-koop1"]';
const DISABLED_CLASS = 'disabled';
const URL_SUFFIX = '?ajax=true';
const DYNAMIC_FILTER_FIELD = 'wijkNaamFilter';
const DYNAMIC_APPLIED_FILTER = 'buurt';
const DYNAMIC_FILTER_SELECTOR = '[data-dynamic-filter]';
const PUSH_STATE_MARKER = 'push_state_set_by_funda';
const MINIMUM_INTERVAL_BETWEEN_REQUESTS = 500; // ms
const TIMEOUT_SEARCH_REQUEST = 30000; // 30 sec

const PRICE_RANGE_SELECTOR = '[data-range-filter]';
const RADIO_GROUP_SELECTOR = '[data-radio-group]';

const supportsPushState = ('pushState' in window.history);
const isCapableBrowser = (supportsPushState);

let isSortTypeChanged = false;

/**
 * @param {HTMLFormElement} form
 * @constructor
 */
export default class InstantSearch {
    constructor(form) {
        if (!isCapableBrowser) {
            return;
        }

        const component = this;
        component.bindToElements(form);
        component.bindToEvents();

        // initialize popstate
        if (component.hasReplaceState) {
            window.history.replaceState(PUSH_STATE_MARKER, window.title);
        }
        if ($(COMPONENT_MAP_SELECTOR).length === 0) {
            component.historyState = new HistoryState();
            component.historyState.initialize();
        }
    }

    /**
     * Determine if replaceState is supported and available
     */
    browserHasReplaceState() {
        try {
            window.history.replaceState(PUSH_STATE_MARKER, window.title);
            return true;
        } catch (e) {
            this.logError(e.message);
            return false;
        }
    }

    bindToElements(form) {
        const component = this;
        const $form = $(form);

        component.form = form;
        component.$form = $form;
        component.outputMap = {};
        component.request = null; // store pending xhr request
        component.asyncResult = {};
        component.soldFilterOption = component.$form.find(SOLD_OBJECTS_ANCHOR_SELECTOR);
        component.forSaleFilterOption = component.$form.find(FOR_SALE_OBJECTS_SELECTOR);
        component.soldObjectToggle = component.$form.find(HISTORISCH_TOGGLE_SELECTOR);
        component.stateObject = {};
        component.$filterCountNumber = component.$form.find(FILTER_COUNT_NUMBER);
        component.$outputs = component.getOutputs($('body'));
        component.hasReplaceState = component.browserHasReplaceState();
        component.sortTypeSelectBox = component.$form.find(SORT_SELECTOR);
        component.historyState = new HistoryState();
        component.historyState.initialize();
    }

    /**
     * Request form submit on form input changes.
     */
    bindToEvents() {
        const component = this;

        component.$form.find(SEARCH_SIDEBAR_SELECTOR).on(SIDEBAR_CLOSED_EVENT, function () {
            if (Object.keys(component.asyncResult).length > 0) {
                component.renderOutputResults(component.asyncResult);
                component.asyncResult = {}; //empty async result
            }
        });

        window.addEventListener('popstate', component.onpopstate, false);

        function debouncedRequest() {
            return debounce(function (event) {
                component.doRequest(event);
            }, MINIMUM_INTERVAL_BETWEEN_REQUESTS, {leading: true});
        }

        component.$form.on('pricerangereset', PRICE_RANGE_SELECTOR, debouncedRequest());
        component.$form.on('radiogroupreset', RADIO_GROUP_SELECTOR, debouncedRequest());
        component.$form.on('change', RADIUS_SELECTOR, debouncedRequest());
        component.$form.on('makelaarsnameupdated', MAKELAARNAAM_SELECTOR, debouncedRequest());
        component.$form.on('change', HANDLE_SELECTOR, debouncedRequest());
        component.$form.on('change', ZOEKTYPE_SELECTOR, debouncedRequest());
        component.$form.on('rangechanged', RANGE_SELECTOR, debouncedRequest());
        component.$form.on('rangeselected', RANGE_FLYOUT_SELECTOR, debouncedRequest());
        component.$form.on('pageadjusted', PAGINATION_SELECTOR, debouncedRequest());
        component.$form.on('searchqueryupdated', AUTOCOMPLETE_SELECTOR, debouncedRequest());
        // TODO: refactor all the things related below listener and its trigger
        component.$form.on('range_filter_removed', debouncedRequest());

        // listen sort selectbox
        $(BODY_SELECTOR).delegate(SORT_SELECTOR, 'change', function () {
            isSortTypeChanged = true;
        });
    }

    // reload page on browser back only if we have set the state. Safari fires the popstate on pageload (with an empty event state), and we don't want to reload on pageload :)
    onpopstate(event) {
        const state = event.state;

        if (isObject(state) && state.state === PUSH_STATE_MARKER) {
            window.location.reload();
        }
    }

    doRequest(event) {
        const component = this;
        let formData = component.$form.serialize();

        // this is here to avoid double post when clearing verkocht filter
        // since two events are fired
        if (typeof event === 'object') {
            if (event.type === 'radiogroupreset') {
                return;
            }
        }

        // ensure outputs indicate they are updating
        component.$outputs.addClass(UPDATING_CLASS);
        $(document).trigger('resultsUpdating');

        // abort previous request if still pending
        if (component.request) {
            component.request.abort();
        }

        component.request = ajax({
            url: component.form.action + URL_SUFFIX,
            method: (this.form.method || 'POST').toUpperCase(),
            data: formData,
            timeout: TIMEOUT_SEARCH_REQUEST
        });

        component.request.done(function onSuccess(data) {
            if (data.Error) {
                component.stoppedUpdating();
                component.logError(data.Error);
                component.$form.trigger(INVALID_LOCATION_EVENT);
            } else if (data.forcedRedirectUrl) {
                window.location = data.forcedRedirectUrl;
            } else {
                component.updateResults(data);

                if (isSortTypeChanged) {
                    // send the sort type of user for stats
                    component.sendSortStats();

                    // reset state
                    isSortTypeChanged = false;
                }
            }
        });

        component.request.fail(function onError(err) {
            if (err.statusText !== 'abort') {
                component.stoppedUpdating();
            }
        });
    }

    markSelectedSource(formData, event) {
        const component = this;
        const targetName = $(event.target).attr('name');

        if (targetName !== undefined) {
            const baseName = '123456789'.indexOf(targetName.slice(-1)) === -1 ? targetName : targetName.substr(0, targetName.length - 1);
            const $matchingEle = component.$form.find('input[name^=' + baseName + ']');
            const matchingNames = reduce($matchingEle, function (matches, ele) {
                const eleName = $(ele).attr('name');
                if (matches.indexOf(eleName) === -1) {
                    matches.push(eleName);
                }
                return matches;
            }, []);

            if (matchingNames.length > 1) {
                formData += '&filter_InteractionBase=' + baseName;
                formData += '&filter_InteractionPostfix=' + targetName.replace(baseName, '');
            }
        }
        return formData;
    }

    /**
     * Determine if results from search request needs to be rendered
     * @param {Object} data
     * @param {Object} data.content
     * @param {String} data.url
     */
    updateResults(data) {
        const component = this;

        // update first all sync elements
        document.title = data.title;
        component.soldFilterOption.attr('href', data.historischAanbodUrl);
        component.soldFilterOption.text(data.historischAanbodLabel);
        component.soldFilterOption.toggleClass(MULTILINE_CLASS, data.multilineHistorischAanbodLabel);
        component.forSaleFilterOption.text(data.actiefAanbodLabel);
        component.forSaleFilterOption.toggleClass(MULTILINE_CLASS, data.multilineActiefAanbodLabel);
        component.updateTotalCount(data.content.total, data.content.searchButtonTotal);
        component.updateCounts(data.counts);
        component.updateHistory(data.url);

        if (component.$form.find(SEARCH_SIDEBAR_SELECTOR).hasClass(EXTENDED_CLASS)) {
            component.asyncResult = data;
        } else {
            component.renderOutputResults(data);
        }

        if (data.content.historischAvailable) {
            component.soldObjectToggle.removeClass(DISABLED_CLASS);
        } else {
            component.soldObjectToggle.removeClass(DISABLED_CLASS).addClass(DISABLED_CLASS);
            component.soldObjectToggle.find(HISTORISCH_CURRENT_SELECTOR).click();
        }
    }

    /**
     * log the error for Google Analytics
     * @param {String} errorType
     */
    logError(errorType) {
        if (window.gtmDataLayer !== undefined) {
            // Fill GtmDataLayer with data
            gtmDataLayer.push({
                'event': 'searchResultError',
                'errorType': 'invalid-' + errorType
            });
        }
    }

    /**
     * Render the results from the search request
     * @param {Object} resultData with keys corresponding to output identifiers and their new HTML as values.
     */
    renderOutputResults(resultData) {
        const component = this;
        const fields = resultData.content;

        for (let key in fields) {
            if (fields.hasOwnProperty(key) && component.outputMap[key] && fields[key] !== null) {
                if (component.outputMap[key].is('input')) {
                    component.outputMap[key].val(fields[key]);
                } else {
                    component.outputMap[key].html(fields[key]);
                }
            }
        }
        if (Object.keys(fields).length > 0) {
            component.stoppedUpdating();
            component.sendResultsUpdatedEvent(resultData.url);
        }

        component.updateBinds(fields);
    }

    sendResultsUpdatedEvent(url) {
        $(document).trigger('resultsUpdated', {url: url});
    }

    /**
     * the results are updated or there is an error occured
     */
    stoppedUpdating() {
        const component = this;
        component.$outputs.removeClass(UPDATING_CLASS);
    }

    /**
     * @param {Object} counts;   map in which key matches count selectors (eg. `{ "my-id": "1.356" }`).
     */
    updateCounts(counts) {
        const component = this;
        for (let key in counts) {
            if (counts.hasOwnProperty(key)) {
                const $countElement = component.$form.find('[' + COUNT_ATTR + '="' + key + '"]');
                const $countWrapper = $countElement.closest('li');
                const count = counts[key];

                $countElement.text(count);

                if (count === '0') {
                    $countWrapper.addClass(NO_RESULTS_CLASS);
                } else {
                    $countWrapper.removeClass(NO_RESULTS_CLASS);
                }
            }
        }
    }

    /**
     * @param {String} totalResults;    The total number of results (eg. '650 resultaten in <location>')
     * @param {String} searchButtonTotalResults;    The total number of results within a button (eg. '650 resultaten')
     *
     * In makelaar result list, there is a difference between totalResults and searchButtonTotalResults
     */
    updateTotalCount(totalResults, searchButtonTotalResults) {
        const component = this;
        component.outputMap.total.each(function (index, element) {
            $(element).text(totalResults);
        });
        component.outputMap.searchButtonTotal.each(function (index, element) {
            $(element).text(searchButtonTotalResults);
        });
    }

    /**
     * Add url to browser history
     * @param {String} url
     */
    updateHistory(url) {
        window.history.pushState(PUSH_STATE_MARKER, window.title, url);
    }

    updateBinds(fields) {
        const component = this;

        $(AUTOCOMPLETE_SELECTOR).each((index, element) => new Autocomplete(element));
        $(CUSTOM_SELECTBOX_SELECTOR).each((index, element) => new CustomSelectBox(element));

        if (fields[DYNAMIC_FILTER_FIELD] && fields[DYNAMIC_FILTER_FIELD].length > 1) {
            const $dynamicFilter = component.$form.find(DYNAMIC_FILTER_SELECTOR);
            return new FilterFlyout($dynamicFilter, true);
        } else if (component.$form.find(DYNAMIC_FILTER_SELECTOR).length < 1) {
            /* buurtfilter not present, make sure no remaining buurten are in applied filters */
            AppliedFilters.remove(DYNAMIC_APPLIED_FILTER);
        }
    }

    /**
     * Get the outputs for the instant-search form.
     * This needs to be a prototype because user-saved-objects-sorting module extends it to find the outputs elswhere
     * @param {Object} $form
     */
    getOutputs($container) {
        const component = this;
        const $temp = $container.find(OUTPUT_SELECTOR);
        $temp.each(function (index, output) {
            const key = output.getAttribute(OUTPUT_KEY_ATTR);
            const $outputs = component.outputMap[key] || $();
            component.outputMap[key] = $outputs.add(output);
        });
        return $temp;
    }

    /**
     * Track sort type of search
     * @param {Object} $formSelect
     */
    sendSortStats() {
        const component = this;

        // Handle necessary part of value, "sorteer-postcode_Ascending" -> "postcode_Ascending"
        const statData = component.sortTypeSelectBox.val().split('-')[1];

        // Track the sort type
        if (window.gtmDataLayer !== undefined) {
            // Fill GtmDataLayer with data
            window.gtmDataLayer.push({
                'event': 'sendSortOption',
                'sortOption': statData,
            });
        }
    }
}


$(COMPONENT_SELECTOR).each(function(index, element) {
    /* eslint-disable no-new */
    new InstantSearch(element);
});

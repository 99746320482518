// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import '../filter-count/filter-count';
import '../filter-reset/filter-reset';

// what does this module expose?
export default AppliedFilters;

// component configuration
const COMPONENT_SELECTOR = '[data-applied-filters]';
const APPLIED_FILTERS_SELECTOR = '[data-applied-filter-list]';
const APPLIED_FILTER_TEMPLATE = '[data-applied-filter-template]';
const REMOVE_APPLIED_FILTER_ATTR = 'data-remove-applied-filter';
const REMOVE_APPLIED_FILTER_SELECTOR = '[' + REMOVE_APPLIED_FILTER_ATTR + ']';
const APPLIED_FILTER_GROUP_ATTR = 'data-applied-filter-group-id';
const APPLIED_FILTER_GROUPNAME_ATTR = 'data-applied-filter-group-name';
const VISIBLE_CLASS = 'is-visible';
const CLOSING_CLASS = 'is-closing';

function AppliedFilters(element) {
    const component = this;

    component.bindToElements(element);

    $(window).on('sidebar-refresh', function() {
        var $myNewElement = $(COMPONENT_SELECTOR);
        if ($myNewElement.length == 1) {
            component.bindToElements($myNewElement);
        }
    });
}

AppliedFilters.prototype.bindToElements = function (element) {
    const component = this;

    component.$element = $(element);
    component.$items = component.$element.find(APPLIED_FILTERS_SELECTOR);

    component.$element.on('update', () => component.updateState());
    component.$element.on('resetfilter', () => {
        component.removeAll();
    });
};

/**
 * on reset filters all applied filters will be removed
 */
AppliedFilters.prototype.removeAll = function() {
    const component = this;
    component.$items.html('');
    component.updateState();
};

/**
 * toggle view state of component
 */
AppliedFilters.prototype.updateState = function() {
    const component = this;
    const numberOfActiveFilters = component.$items.find(REMOVE_APPLIED_FILTER_SELECTOR).length;

    $(document).trigger('setfiltercount', {amount: numberOfActiveFilters});

    if (numberOfActiveFilters > 0) {
        component.$element
            .removeClass(CLOSING_CLASS)
            .addClass(VISIBLE_CLASS);
    } else {
        component.$element
            .removeClass(VISIBLE_CLASS)
            .addClass(CLOSING_CLASS);
    }
};

/**
 * get the html template and fill it with data from the selected filter
 * @param {Obj} filterData; selected data from filter
 * @returns {*|jQuery}
 */
AppliedFilters.getButton = function(filterData) {
    let html = $(APPLIED_FILTER_TEMPLATE).html();
    let findPattern = null;

    if (html === undefined) {
        return; // the template doesn't exist
    }

    let filterAttributes = {
        'id': {find: '{filterGroupId}', replace: filterData.filterGroupId},
        'group': {find: '{filterGroupName}', replace: filterData.filterGroupName},
        'option': {find: '{filterName}', replace: filterData.filterName},
        'label': {find: '{combinedFilterName}', replace: filterData.labelText}
    };

    for (let filter in filterAttributes) {
        if (filterAttributes[filter].replace !== undefined) {
            findPattern = new RegExp('(' + filterAttributes[filter].find + ')', 'gm');
            html = html.replace(findPattern, filterAttributes[filter].replace);
        }
    }
    return html;
};

/**
 * add a new filter by getting template/html and returns a filled template
 * @param {Obj} filterData; selected data from filter
 * @param {Function} clearFunction
 * @param {Boolean} removeOldFilter
 * @returns {*|jQuery}
 */
AppliedFilters.add = function(filterData, clearFunction, removeOldFilter) {
    // from this group filters there maybe only one visible; let's remove the previous selected first
    if (removeOldFilter) {
        AppliedFilters.remove(filterData.filterGroupId);
    }
    const $button = AppliedFilters.getButton(filterData);
    // add new filter to applied filter list
    $(APPLIED_FILTERS_SELECTOR).append(AppliedFilters.applyClearBinding($button, clearFunction));
    $(COMPONENT_SELECTOR).trigger('update');
};

/**
 * remove filter by id or by group id
 * @param {string} optionId
 */
AppliedFilters.remove = function(optionId) {
    const $filters = $(APPLIED_FILTERS_SELECTOR + ' [' + APPLIED_FILTER_GROUP_ATTR + '="' + optionId + '"], ' +
                        APPLIED_FILTERS_SELECTOR + ' [' + APPLIED_FILTER_GROUPNAME_ATTR + '="' + optionId + '"]');
    const numberOfActiveFilters = $filters.length;

    if (numberOfActiveFilters > 0) {
        $filters.detach();
        $(COMPONENT_SELECTOR).trigger('update');
    }
};

/**
 * Applies the clear filter binding to the html
 * @param {html} htmlButton; the button with selected filter
 * @param {function} clearFunction
 * @returns {*|jQuery}
 */
AppliedFilters.applyClearBinding = function(htmlButton, clearFunction) {
    const $button = $(htmlButton).on('click',
        (event) => {
            event.preventDefault();
            // run given action from component to clear the selection
            clearFunction.apply();
            return false;
        });

    return $button;
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new AppliedFilters(element);
});
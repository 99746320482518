// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

import SearchElementInViewport from '../search-element-in-viewport/search-element-in-viewport';

// what does this module expose?
export default SearchScrollNotify;

// component configuration
var COMPONENT_SELECTOR = '[data-search-scroll-notify]';
var LAST_SEARCH_RESULT_SELECTOR = '[data-search-scroll-notify-target]';
var NOTIFICATION_SELECTOR = '[data-search-scroll-notification]';
var COMPONENT_POSITION_TOP = 0.4; // 40% of the viewport
var SCROLL_UP_ANCHOR_SELECTOR = '[data-search-scroll-notify-anchor]';
var NOTIFICATION_ACTIVE_CLASS = 'is-active';
var RESULTS_UPDATED_EVENT = 'resultsUpdated';

function SearchScrollNotify(element) {
    var component = this;
    component.element = element;
    component.$element = $(element);
    component.$notification = component.$element.find(NOTIFICATION_SELECTOR);
    component.notification = new SearchElementInViewport(component.element, COMPONENT_POSITION_TOP);

    component.checkLastSearchResultVisibility();
    component.bindToEvents();
}

/**
 * Toggle notification when search results are updated
 * Scroll to top when anchor is clicked
 */
SearchScrollNotify.prototype.bindToEvents = function() {
    var component = this;

    $(document).on(RESULTS_UPDATED_EVENT, function() {
        component.checkLastSearchResultVisibility();
    });

    component.$element.on('click', SCROLL_UP_ANCHOR_SELECTOR, function() {
        component.hideNotification();
    });
};

/**
 * Check if the last search result in the list is visible for the user
 * Show scroll up notification if the last search result is outside the viewport
 */
SearchScrollNotify.prototype.checkLastSearchResultVisibility = function () {
    var component = this;
    var lastSearchResult = $(LAST_SEARCH_RESULT_SELECTOR)[0];

    if (!lastSearchResult || isElementBelowViewportTop(lastSearchResult)) {
        component.hideNotification();
    } else {
        component.showNotification();
    }
};

/**
 * Show notification
 */
SearchScrollNotify.prototype.showNotification = function () {
    var component = this;
    // offsets are relative to the current viewport
    component.$notification.addClass(NOTIFICATION_ACTIVE_CLASS);
    component.notification.updateVerticalPosition();

};

/**
 * Hide notification
 */
SearchScrollNotify.prototype.hideNotification = function () {
    var component = this;
    component.$notification.removeClass(NOTIFICATION_ACTIVE_CLASS);
    component.$element.removeAttr('style');
};

/**
 * Check if top of element is inside or below the viewport
 * Left, right and bottom position of element are ignored
 */
function isElementBelowViewportTop(element) {
    return element.getBoundingClientRect().top > 0;
}

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new SearchScrollNotify(element);
});
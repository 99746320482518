import $ from 'jquery';
import ajax from '../ajax/ajax';
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import AppSpinner from '../app-spinner/app-spinner';
import Expandible from '../expandible/expandible';
import CollapseText from '../collapse-text/collapse-text';

export default UserReviewPaging;

// component configuration
const COMPONENT_SELECTOR = '[data-user-review-paging]';
const PAGINATION_SELECTOR = '[data-pagination]';
const OUTPUT_KEY_ATTR = 'data-instant-search-output';
const OUTPUT_SELECTOR = '[data-instant-search-output]';
const PAGINATION_INPUT_SELECTOR = '[data-pagination-input-next]';
const SPINNER_SELECTOR = '[data-user-reviews-spinner]';
const UPDATING_CLASS = 'is-updating';
const MINIMUM_INTERVAL_BETWEEN_REQUESTS = 250; // ms
const PUSH_STATE_MARKER = 'pust_state_set_by_funda';

/**
 * Constructor method, links child elements to variables for internal use
 * @param {HTMLElement} element     The HTML element to bind to.
 */
function UserReviewPaging(element,
    ajaxDependency = ajax,
    collapseComponent = CollapseText
) {
    const component = this;
    const $form = $(element);
    component.form = element;
    component.$form = $form;
    component.asyncResult = {};
    component.$currentPage = component.$form.find(PAGINATION_INPUT_SELECTOR);
    component.formData = {};
    component.outputMap = {};
    component.$outputs = UserReviewPaging.getOutputs(component.$form);
    component.spinner = new AppSpinner(SPINNER_SELECTOR);
    component.ajax = ajaxDependency;
    component.collapseComponent = collapseComponent;

    // Create our outputs object.
    component.$outputs.each(function (index, output) {
        const key = output.getAttribute(OUTPUT_KEY_ATTR);
        const $outputs = component.outputMap[key] || $();
        component.outputMap[key] = $outputs.add(output);
    });

    $(document).on('isUpdatingReviews', () => {
        component.$outputs.addClass(UPDATING_CLASS);

        if (!component.spinner.isVisible()) {
            component.spinner.show();
        }
    });

    $(document).on('resultsUpdated resultsError', () => {
        component.$outputs.removeClass(UPDATING_CLASS);
        component.spinner.hide();
    });

    // Bind all events.
    component.bindToEvents();

    component.formData = component.$form.serializeArray();

    // initialize popstate
    if ('replaceState' in window.history) {
        window.history.replaceState(PUSH_STATE_MARKER, window.title);
    }
}

/**
 * Request form submit on form input changes.
 */
UserReviewPaging.prototype.bindToEvents = function () {
    const component = this;

    window.addEventListener('popstate', onpopstate, false);

    // reload page on browser back only if we have set the state. Safari fires the popstate on pageload (with an empty event state), and we don't want to reload on pageload :)
    function onpopstate(event) {
        if (event.state === PUSH_STATE_MARKER) {
            window.location.reload();
        }
    }

    function debouncedRequest() {
        return debounce(function () {
            component.doRequest();
        }, MINIMUM_INTERVAL_BETWEEN_REQUESTS, { leading: true });
    }

    component.$form.on('pageadjusted', PAGINATION_SELECTOR, debouncedRequest());
};

/**
 * Requests new reviews.
 */
UserReviewPaging.prototype.doRequest = function () {
    const component = this;
    $(document).trigger('isUpdatingReviews');


    // Abort current request if it's still pending.
    if (component.request) {
        component.request.abort();
    }

    component.request = component.ajax({
        url: component.$form.attr('action'),
        method: (component.$form.attr('method') || 'POST').toUpperCase(),
        data: component.$form.serialize(),
        success: (data => component.successHandler(data)),
        error: (error => component.errorHandler(error))
    });
};

UserReviewPaging.prototype.successHandler = function (data) {
    const component = this;
    data.formData = clone(component.$form.serializeArray());
    component.update(data);
    component.formData = component.$form.serializeArray();
};

UserReviewPaging.prototype.errorHandler = function (error) {
    const component = this;
    if (error.statusText !== 'abort') {
        component.triggerResultsError();
    }
};

UserReviewPaging.prototype.triggerResultsError = function () {
    $(document).trigger('resultsError');
};

/**
 * Update outputs on the page with the received data.
 * @param {object} data     Received data.
 */
UserReviewPaging.prototype.update = function (data) {
    const component = this;

    component.updateHistory(data.url); //change url
    component.updateOutputs(data.content); // Update outputs on page.
};

/**
 * @param {String} url
 */
UserReviewPaging.prototype.updateHistory = function (url) {
    if ('pushState' in window.history) {
        window.history.pushState(PUSH_STATE_MARKER, window.title, url);
    }
};

/**
 * Get the outputs identifiers from the form.
 * @param {Object} $form
 */
UserReviewPaging.getOutputs = function ($form) {
    return $form.find(OUTPUT_SELECTOR);
};

/**
 * Use the outputMap object to map the new, received data, to the correct ouput identifiers on the page.
 * @param {Object} fields - data with keys corresponding to output identifiers.
 */
UserReviewPaging.prototype.updateOutputs = function (fields) {
    const component = this;

    // Map keys with their corresponding output identifiers.
    for (let key in fields) {
        if (fields.hasOwnProperty(key) && component.outputMap[key] && fields[key] !== null) {
            component.outputMap[key].html(fields[key]);
        }
    }

    if (Object.keys(fields).length) {
        $(document).trigger('resultsUpdated');
    }

    // Re-initialize the Expandible and UserReview components.
    Expandible.initialize();
    component.collapseComponent.initialize();
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new UserReviewPaging(element));

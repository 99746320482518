import $ from 'jquery';
import 'funda-slick-carousel';
export default ObjectMedia;

const EVENT_NAMESPACE = '.object-media';
const COMPONENT_SELECTOR = '[data-object-media]';
const ITEMS_SELECTOR = '[data-object-media-fotos]';
const ITEM_ATTR = 'data-media-viewer-overlay'; //from media-viewer.js
const ITEM_SELECTOR = `[${ITEM_ATTR}]`; //from media-viewer.js
const PHOTO_TEASER_SELECTOR = '[data-object-media-teasers] [data-media-viewer-overlay="foto-1"]';
const SLICK_SLIDE_SPEED = 250;
const SLICK_SWIPE_SENSITIVITY = 12; // 1/12th of the image width

function ObjectMedia(element) {
    const component = this;
    let mediaQuery;

    component.$element = $(element);
    component.$items = component.$element.find(ITEMS_SELECTOR);
    component.slickWidth = 1020; // object-media.scss

    component.enhanceLinks();

    if (window.matchMedia) {
        mediaQuery = window.matchMedia('(max-width: ' + component.slickWidth + 'px)');
        mediaQuery.addListener(function(mediaQueryList) {
            if ((mediaQueryList.matches)) {
                // Became narrower than breakpoint
                component.initSlick();
            } else {
                component.unbindEvents();
                component.killSlick();
            }
        });

        if ((mediaQuery.matches)) {
            component.initSlick();
        }
    }
}

/**
 * Enhances all links that use a fallback in the 'href' attribute to use the one that accesses the Media Viewer
 */
ObjectMedia.prototype.enhanceLinks = function() {
    const $photos = this.$items.find(ITEM_SELECTOR);
    const $photoTeaser = this.$element.find(PHOTO_TEASER_SELECTOR);

    $photos.each((index, element) => {
        let attributeValue = element.getAttribute(ITEM_ATTR);
        element.setAttribute('href', '#' + attributeValue);
    });

    const teaserAttribute = $photoTeaser.attr(ITEM_ATTR);
    $photoTeaser.attr('href', '#' + teaserAttribute);
};

ObjectMedia.prototype.unbindEvents = function() {
    $(document).off('keydown' + EVENT_NAMESPACE);
};

ObjectMedia.prototype.initSlick = function() {
    this.$items.slick(
        {
            arrows: false,
            infinite: false,
            speed: SLICK_SLIDE_SPEED,
            cssEase: 'ease',
            mobileFirst: true,
            touchThreshold: SLICK_SWIPE_SENSITIVITY,
            useTransform: true,
            lazyLoad: 'anticipated',
            lazyLoadAhead: 3
        }
    );
};

ObjectMedia.prototype.killSlick = function() {
    this.$items.slick('unslick');
};

$(COMPONENT_SELECTOR).each((index, element) => new ObjectMedia(element));

import $ from 'jquery';
export default Tooltip;

const COMPONENT_SELECTOR = '[data-tooltip]';
const IS_VISIBLE_CLASS = 'is-expanded';
const TITLE_ATTR = 'title';
const TOOLTIP_TEXT_ATTR = 'data-title';
const EVENTS_TO_LISTEN = 'resize click';
const EXPAND_TO_BOTTOM_CLASS = 'bottom';
const EXPAND_TO_LEFT_CLASS = 'reverse';
const SMALL_BREAKPOINT = 500;

function Tooltip(element) {
    const component = this;

    component.$element = $(element);
    component.initialInfo = undefined;

    component.removeTitle(); //Disables the tooltip on hover

    component.bindEvents();
}

Tooltip.prototype.bindEvents = function () {
    const component = this;

    //Since the expandable component closes when anything else is clicked,
    // window needs to be bind instead of the component.
    $(window).on(EVENTS_TO_LISTEN, () => component.tooltipHandler());
};

Tooltip.prototype.tooltipHandler = function () {
    const component = this;

    //Act only when tooltip is visible.
    if (component.$element.hasClass(IS_VISIBLE_CLASS)) {
        const tooltip = component.getUpdateDescriptor();
        component.updateTooltip(tooltip);
    }
};

/**
 * Returns a description of what needs to be updated in the tooltip.
 * @returns {*} A descriptor object or undefined if no update needed.
 */
Tooltip.prototype.getUpdateDescriptor = function () {
    const component = this;
    let tooltip;

    if ($('body').outerWidth() < SMALL_BREAKPOINT) {
        return;
    }

    if (component.doesInitialTooltipFitInCurrentWindow()) {
        tooltip = {
            isBottom: component.initialInfo.isBottom,
            isReversed: component.initialInfo.isReversed
        };
    }
    else {
        tooltip = component.getCurrentTooltipDescriptor();
        const tooltipPos = component.getTooltipAbsolutePosition(tooltip);
        if (tooltipPos.bottom < 0 && tooltip.isBottom) {
            tooltip.isBottom = false;
        } else if (tooltipPos.top < 0 && !tooltip.isBottom) {
            tooltip.isBottom = true;
        }

        if (tooltipPos.left < 0 && tooltip.isReversed) {
            tooltip.isReversed = false;
        } else if (tooltipPos.right < 0 && !tooltip.isReversed) {
            tooltip.isReversed = true;
        }
    }

    return tooltip;
};

/**
 * Using the descriptor of the initial tooltip, checks if it fits in the current window.
 * If there is no initial descriptor, it stores the current one.
 * @returns {boolean} true if the initial tooltip fits.
 */
Tooltip.prototype.doesInitialTooltipFitInCurrentWindow = function () {
    const component = this;

    if (!component.initialInfo) {
        component.initialInfo = component.getCurrentTooltipDescriptor();
    }

    //How the initial tooltip would look in current window.
    const initialPos = component.getTooltipAbsolutePosition(component.initialInfo);

    return (initialPos.top > 0 && initialPos.bottom > 0 && initialPos.left > 0 && initialPos.right > 0);
};

/**
 * Returns an object describing the relevant information of the current tooltip displayed.
 * The information is relative to the parent component, not to the window.
 * This way it can be used to compare it in different windows.
 * @returns {{isReversed: *, isBottom: *, width: Number, height: Number, topFromParent: Number, leftFromParent: Number}}
 */
Tooltip.prototype.getCurrentTooltipDescriptor = function () {
    const component = this;
    const tooltipStyle = window.getComputedStyle(component.$element[0], ':after');

    return {
        isReversed: component.$element.hasClass(EXPAND_TO_LEFT_CLASS),
        isBottom: component.$element.hasClass(EXPAND_TO_BOTTOM_CLASS),
        width: parseInt(tooltipStyle.width, 10),
        height: parseInt(tooltipStyle.height, 10),
        topFromParent: parseInt(tooltipStyle.top, 10),
        leftFromParent: parseInt(tooltipStyle.left, 10)
    };
};

/**
 * Given a tooltip descriptor, returns the boundaries of it in the current document.
 * @param tooltip descriptor.
 * @returns {{top: *, left: *, right: number, bottom: number}}
 */
Tooltip.prototype.getTooltipAbsolutePosition = function (tooltip) {
    const component = this;
    const $body = $('body');
    const top = component.$element.offset().top + tooltip.topFromParent;
    const left = component.$element.offset().left + tooltip.leftFromParent;
    const right = $body.width() - tooltip.width - left;
    const bottom = $body.height() - tooltip.height - top;

    return {
        top: top,
        left: left,
        right: right,
        bottom: bottom
    };
};

/**
 * Given a tooltip descriptor, updates the displayed tooltip.
 * @param tooltip descriptor.
 */
Tooltip.prototype.updateTooltip = function (tooltip) {
    const component = this;

    if (typeof tooltip !== 'object'
        || typeof tooltip.isBottom === 'undefined'
        || typeof tooltip.isReversed === 'undefined') {
        return;
    }

    if (tooltip.isBottom) {
        component.$element.addClass(EXPAND_TO_BOTTOM_CLASS);
    } else {
        component.$element.removeClass(EXPAND_TO_BOTTOM_CLASS);
    }

    if (tooltip.isReversed) {
        component.$element.addClass(EXPAND_TO_LEFT_CLASS);
    } else {
        component.$element.removeClass(EXPAND_TO_LEFT_CLASS);
    }
};

/**
 * Disables the on hover title default functionality (non-javascript fallback)
 */
Tooltip.prototype.removeTitle = function () {
    const component = this;

    const text = component.$element.attr(TITLE_ATTR);

    component.$element.attr(TITLE_ATTR, '');
    component.$element.attr(TOOLTIP_TEXT_ATTR, text);
};

$(COMPONENT_SELECTOR).each((i, element) => new Tooltip(element));

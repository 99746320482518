import $ from 'jquery';
import template from 'lodash/template';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import { deletePersonalPlace, setMediaViewerHash } from '../store/actions';

const TEMPLATE = '[data-location-detail-template]';

const SAVE_LINK_SELECTOR = '[data-save-link]';
const REMOVE_LINK_SELECTOR = '[data-remove-link]';
const PERSONAL_PLACE_ATTRIBUTE = 'data-personal-place';
const GRAY_PLACEHOLDER_SELECTOR = '[data-location-detail-placeholder]';
const IS_HIDDEN_CLASS = 'is-hidden';

export default class LocationDetail {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.categories',
            'entities.personalPlaces',
            'entities.travelTimes',
            'entities.voorzieningen',
            'getTravelTimes',
            'ui.poiTravelTime'
        ]);

        this.$element = $(element);

        this.compiledTemplate = template(this.$element.find(TEMPLATE).html());

        this.location = null;
        this.loading = true;

        this.bindEvents();
        this.bindStoreEvents();
    }

    bindStoreEvents() {
        this.connect.subscribe((state, prevState) => {
            if (state.getTravelTimes.error !== null || state.getTravelTimes.loaded === true) {
                this.loading = false;
                this.$element.find(GRAY_PLACEHOLDER_SELECTOR).addClass(IS_HIDDEN_CLASS);
            }
            if (state.ui.poiTravelTime !== prevState.ui.poiTravelTime) {
                this.layout(this.location);
            }
        });
    }

    bindEvents() {
        this.$element.on('click', SAVE_LINK_SELECTOR, event => {
            event.preventDefault();

            const fixedHash = $(event.currentTarget).attr('href').replace(/^#/, '');
            this.store.dispatch(setMediaViewerHash(fixedHash));
        });

        this.$element.on('click', REMOVE_LINK_SELECTOR, event => {
            event.preventDefault();

            const personalPlaceId = this.$element.attr(PERSONAL_PLACE_ATTRIBUTE);
            this.store.dispatch(deletePersonalPlace(personalPlaceId));

            delete this.location.personalPlace;
            this.layout(this.location);
        });
    }

    showLocation(location) {
        this.location = Object.assign({}, location);

        this.layout(location);
    }

    layout(location) {
        const state = this.store.getState();

        let voorzieningDetails = [];
        let categoryDetails = [];
        let travelDetails;

        if (!location) {
            return;
        }

        if (state.entities.voorzieningen[location.id]) {
            voorzieningDetails = state.entities.voorzieningen[location.id];

            if (voorzieningDetails.categories.length > 0) {
                categoryDetails = state.entities.categories[voorzieningDetails.categories[0]];
            }
        }
        travelDetails = state.ui.poiTravelTime;
        this.$element.attr(PERSONAL_PLACE_ATTRIBUTE, location.personalPlace);

        const html = this.compiledTemplate({
            id: location.id,
            name: location.label || location.location,
            address: location.location,
            details: travelDetails,
            type: categoryDetails.type,
            icon: categoryDetails.icon,
            openingHoursToday: voorzieningDetails.openingHoursToday,
            phone: voorzieningDetails.phone,
            website: voorzieningDetails.website,
            source: voorzieningDetails.source
        });

        this.$element.html(html);

        this.$element.find(SAVE_LINK_SELECTOR).toggleClass(IS_HIDDEN_CLASS, !!location.personalPlace);
        this.$element.find(REMOVE_LINK_SELECTOR).toggleClass(IS_HIDDEN_CLASS, !location.personalPlace);
    }
}

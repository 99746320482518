import {
    GET_PERSONAL_PLACES,
    GET_PERSONAL_PLACES_SUCCESS,
    GET_PERSONAL_PLACES_FAIL,
} from '../constants/action-types';

const initialState = {
    loaded: false,
    loading: false,
    error: null
};

export default function getPersonalPlaces(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PERSONAL_PLACES:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: null
            };
        case GET_PERSONAL_PLACES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null
            };
        case GET_PERSONAL_PLACES_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        default:
            return state;
    }
}

// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

export default PlaceholderFallback;

// component configuration
var COMPONENT_SELECTOR = '[data-placeholder-fallback]';
var VISIBILITY_CLASS = 'a11y-sr-only';
var SUPPORTS_PLACEHOLDER = $('html').hasClass('supports-placeholder');

function PlaceholderFallback(element) {
    if (SUPPORTS_PLACEHOLDER) {
        $(element).addClass(VISIBILITY_CLASS);
    }
}

$(COMPONENT_SELECTOR).each(function(index, element) {
    return new PlaceholderFallback(element);
});

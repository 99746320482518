import $ from 'jquery';

export default CollapseText;

const COMPONENT_DATA = 'collapse-text';
const COMPONENT_SELECTOR = `[data-${COMPONENT_DATA}]`;
const TEXT_SELECTOR = '[data-text-container]';
const EXPAND_SELECTOR = '[data-text-expand]';
const COLLAPSE_SELECTOR = '[data-text-collapse]';
const CLICK_EVENT = 'click';
const COLLAPSED_CLASS = 'is-collapsed';
const IS_HIDDEN = 'is-hidden';
const DEFAULT_CHAR_COUNT = 500;

function CollapseText(element) {
    const component = this;
    component.$element = $(element);
    component.$textContainer = component.$element.find(TEXT_SELECTOR);
    component.textLong = component.$textContainer.html();
    component.charsToCollapse = component.$element.data(COMPONENT_DATA) || DEFAULT_CHAR_COUNT;

    if (component.textLong.length < component.charsToCollapse) {
        return; //IF THE TEXT IS SMALL ENOUGH WE DO NOT PROCEED WITH THE LOAD OF THE COMPONENT.
    }

    component.$expandTrigger = component.$element.find(EXPAND_SELECTOR);
    component.$collapseTrigger = component.$element.find(COLLAPSE_SELECTOR);
    component.textShort = component.textLong.substr(0, component.charsToCollapse) + '…';

    component.collapse();
    component.bindEvents();
}
CollapseText.prototype.bindEvents = function () {
    const component = this;

    component.$expandTrigger.on(CLICK_EVENT, () => component.expand());
    component.$collapseTrigger.on(CLICK_EVENT, () => component.collapse());
};

CollapseText.prototype.expand = function () {
    const component = this;

    component.$textContainer.html(component.textLong);
    component.$textContainer.removeClass(COLLAPSED_CLASS);
    component.$expandTrigger.addClass(IS_HIDDEN);
    component.$collapseTrigger.removeClass(IS_HIDDEN);
};

CollapseText.prototype.collapse = function () {
    const component = this;

    component.$textContainer.html(component.textShort);
    component.$textContainer.addClass(COLLAPSED_CLASS);
    component.$expandTrigger.removeClass(IS_HIDDEN);
    component.$collapseTrigger.addClass(IS_HIDDEN);
};

CollapseText.initialize = function() {
    $(COMPONENT_SELECTOR).each((index, element) => new CollapseText(element));
};

CollapseText.initialize();

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

const events = $({});

// what does this module expose?
export default ajax;

/**
 * Wrap jQuery ajax call and decorate with global success and error event emitters.
 * Other modules can listen to these events using `ajax.onError` and `ajax.onSuccess`.
 * @returns {*}
 */
function ajax (/* arguments */) {
    return $.ajax.apply(this, arguments)
        .done(data => events.trigger('success', data))
        .fail(err => {
            if (err.statusText === 'abort') {
                events.trigger('abort', err);
            } else {
                events.trigger('error', err);
            }
        });
}

ajax.onAbort = listener => events.on('abort', listener);
ajax.onError = listener => events.on('error', listener);
ajax.onSuccess = listener => events.on('success', listener);
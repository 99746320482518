import $ from 'jquery';

export default FormValidation;

const COMPONENT_SELECTOR = '[data-form-validation]';
const ERROR_INPUT_CLASS = 'input-validation-error';
const ERROR_INPUT_SELECTOR = `.${ERROR_INPUT_CLASS}`;
const ERROR_MESSAGE_SELECTOR = '.form-message--error';
const EVENTS_TRIGGERS = 'keypress click';

function FormValidation(element) {
    const component = this;
    component.$element = $(element);
    component.$errorInputs = component.$element.find(ERROR_INPUT_SELECTOR);

    component.bindEvents();
    component.scrollToFirstError();
}

FormValidation.prototype.scrollToFirstError = function () {
    const component = this;

    if (component.$errorInputs.length) {
        component.$errorInputs[0].scrollIntoView();
    }
};

FormValidation.prototype.bindEvents = function (rebind = false) {
    const component = this;

    const errorHandler = (e) => component.errorHandler(e);

    if (rebind) {
        component.$errorInputs.off(EVENTS_TRIGGERS, errorHandler);
        component.$errorInputs = component.$element.find(ERROR_INPUT_SELECTOR);
    }

    component.$errorInputs.on(EVENTS_TRIGGERS, errorHandler);
};

FormValidation.prototype.errorHandler = function (event) {
    const component = this;
    const $eventTrigger = $(event.target);
    const $errorInput = FormValidation.findExpanding($eventTrigger, ERROR_INPUT_SELECTOR);
    const $errorMessage = FormValidation.findExpanding($errorInput, ERROR_MESSAGE_SELECTOR);

    $errorMessage.remove(); //remove error message
    $errorInput.removeClass(ERROR_INPUT_CLASS); //remove red border in input
    component.bindEvents(true);
};

FormValidation.findExpanding = function ($element, toFind) {
    const elementToFind = $element.find(toFind).addBack(toFind);

    if (elementToFind.length) {
        return elementToFind;
    } else {
        const parent = $element.parent();
        if (parent.length) {
            return FormValidation.findExpanding(parent, toFind);
        } else {
            return parent;
        }
    }
};

$(COMPONENT_SELECTOR).each((i, element) => new FormValidation(element));

import $ from 'jquery';
import Cookies from '../app-utils/app-utils-cookies';

const COMPONENT_SELECTOR = '[data-notification]';
const CLOSE_SELECTOR = '[data-notification-close]';
const TEXT_CONTAINER_SELECTOR = '[data-text-container]';
const ADD_COOKIE_ON_CLOSE_ATTRIBUTE = 'data-notification-store-cookie-on-close';
const ADD_COOKIE_ON_CLOSE_SELECTOR = `[${ADD_COOKIE_ON_CLOSE_ATTRIBUTE}]`;
const ADD_COOKIE_SEPARATE_CONTROL_SELECTOR = '[data-notification-dismiss]';
const HIDDEN_CLASS = 'is-hidden';

export default class Notification {
    constructor(element) {
        this.Cookies = Cookies;

        this.$element = $(element);
        this.$close = this.$element.find(CLOSE_SELECTOR);
        this.$textContainer = this.$element.find(TEXT_CONTAINER_SELECTOR);

        const notificationHasCookie = this.$element.find(ADD_COOKIE_ON_CLOSE_SELECTOR).length > 0;
        if (notificationHasCookie) {
            this.cookieName = this.$element.find(ADD_COOKIE_ON_CLOSE_SELECTOR)[0].getAttribute(ADD_COOKIE_ON_CLOSE_ATTRIBUTE);
        }

        this.$dismissHandle = this.$element.find(ADD_COOKIE_SEPARATE_CONTROL_SELECTOR);
        this.cookieFromExternalHandle = this.$dismissHandle.length > 0;

        this.bindEvents();
    }

    bindEvents() {
        this.$element.on('show', (event, message) => this.show(event, message));
        this.$element.on('hide', () => this.close());
        this.$close.on('click', event => this.close(event));
        this.$dismissHandle.on('click', event => {
            this.setCookie();
            this.close(event);
        });
    }

    close(event) {

        if (typeof event !== 'undefined' && typeof event.preventDefault === 'function') {
            event.preventDefault();
        }

        this.$element.addClass(HIDDEN_CLASS);
        this.$element.trigger('close');

        if (!this.cookieFromExternalHandle) {
            this.setCookie();
        }
    }

    setCookie() {
        if (typeof this.cookieName !== 'undefined' && this.cookieName.length > 0) {
            this.Cookies.setCookie(this.cookieName, 'true');
        }
    }

    show(event, message) {

        if (typeof message === 'string') {
            this.$textContainer.html(message);
        }

        this.$element.removeClass(HIDDEN_CLASS);
    }
}

$(COMPONENT_SELECTOR).get().map(element => new Notification(element));

export default MediaViewerOverview;
import $ from 'jquery';

const IMAGE_SELECTOR = '[data-media-viewer-overview-image]';
const LAZY_SRC_ATTR = 'data-lazy';
const LAZY_SRCSET_ATTR = 'data-lazy-srcset';
const EVENT_MEDIA_VIEWER_OVERVIEW_OPENED = 'media-viewer-overview-opened-event';
const $window = $(window);

function MediaViewerOverview($elements) {
    this.$elements = $elements;

    this.loadOverviewImages();
    $window.trigger(EVENT_MEDIA_VIEWER_OVERVIEW_OPENED);
}

MediaViewerOverview.prototype.loadOverviewImages = function() {
    const $images = this.$elements.find(IMAGE_SELECTOR);

    $images.each((index, element) => {
        let src = element.getAttribute(LAZY_SRC_ATTR);
        let srcset = element.getAttribute(LAZY_SRCSET_ATTR);
        let image = $(element);

        image
            // Set the srcset first so that capable browsers won't also load the src
            .attr('srcset', srcset)
            .attr('src', src)
            .removeAttr(`${LAZY_SRC_ATTR} ${LAZY_SRCSET_ATTR}`);
    });
};


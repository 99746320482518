import $ from 'jquery';
import {POI_OVERVIEW_DRAWER} from '../store/constants/drawer-names';

import ObjectMapDrawer from './object-map-drawer';
import ObjectMapDrawerOverviewAccordion from '../object-map-drawer-overview-accordion/object-map-drawer-overview-accordion';
import reduxStore from '../store/store';
import { addLocation, setMediaViewerHash } from '../store/actions';
import AutoSuggestLocations from '../save-location-form/auto-suggest-locations/auto-suggest-locations';
import { SELECTOR as AUTO_SUGGEST_LOCATIONS_ELEMENT_SELECTOR } from '../save-location-form/auto-suggest-locations/auto-suggest-locations';

const ACCORDION_SELECTOR = '[data-object-map-drawer-overview-accordion]';
const noop = () => {};

export default class ObjectMapOverviewDrawer {
    constructor(element, store, onChangeVisibility) {
        this.onChangeVisibility = onChangeVisibility || noop;

        this.onLocationSelected = this.onLocationSelected.bind(this);

        this.store = store || reduxStore;
        this.$element = $(element);
        this.$accordion = this.$element.find(ACCORDION_SELECTOR);
        this.$autoSuggestLocations = this.$element.find(AUTO_SUGGEST_LOCATIONS_ELEMENT_SELECTOR);

        this.drawer = new ObjectMapDrawer(this.$element, this.store, POI_OVERVIEW_DRAWER);
        this.$autoSuggestLocations = this.$element.find(AUTO_SUGGEST_LOCATIONS_ELEMENT_SELECTOR);
        this.autosuggest = new AutoSuggestLocations(this.$autoSuggestLocations, this.onLocationSelected, noop, noop, this.onChangeVisibility); //implement it when we have the flyout working
        this.accordion = new ObjectMapDrawerOverviewAccordion(this.$accordion, this.store);
    }

    onLocationSelected(location) {
        this.autosuggest.setValue('');

        this.createLocationAndMakeItActive(
            location.placeId,
            location.coordinates.lat,
            location.coordinates.lng,
            location.location
        );
    }

    createLocationAndMakeItActive(id, lat, lng, location) {
        this.store.dispatch(addLocation({id, lat, lng, location, label: location}));
        this.store.dispatch(setMediaViewerHash(`kaart/locatie/${id}`));
    }
}

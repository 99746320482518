import $ from 'jquery';

import ObjectMapDetailDrawer from './object-map-detail-drawer';
import ObjectMapOverviewDrawer from './object-map-overview-drawer';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import {
    changeDrawer,
    setActiveLocation,
    setMediaViewerHash
} from '../store/actions';
import { POI_OVERVIEW_DRAWER, POI_DETAIL_DRAWER } from '../store/constants/drawer-names';
import { DRAWER_HIDDEN, DRAWER_MAXIMISED, DRAWER_MINIMISED, DRAWER_SEMI } from '../store/constants/drawer-states';

const COMPONENT_SELECTOR = '[data-object-map-drawers]';
const POI_DETAIL_DRAWER_SELECTOR = '[data-poi-detail-drawer]';
const POI_OVERVIEW_DRAWER_SELECTOR = '[data-poi-overview-drawer]';
const DATA_DRAWER_ACCORDION_WRAPPER_SELECTOR ='[data-drawer-overview-accordion-wrapper]';

const EVENT_MEDIA_VIEWER_OPENED = 'media-viewer-opened-event';
const EVENT_MEDIA_VIEWER_CLOSED = 'media-viewer-closed-event';
const EVENT_MAP_TYPE_SELECT = 'map-type-select-event-media-viewer-map';

const URL_HASH_MAP = 'kaart';
const URL_HASH_NEW_LOCATION = 'bewaren';
const SAVED_LOCATION_URL_REGEX = /locatie\/(.*)\/bewaren/;

const VISIBILITY_HIDDEN_CLASS = 'is-visibility-hidden';

const IS_HIDDEN_CLASS = 'is-hidden';

export default class ObjectMapDrawers {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            `ui[${POI_OVERVIEW_DRAWER}]`,
            `ui[${POI_DETAIL_DRAWER}]`,
            'ui.activeLocationId',
            'entities.personalPlaces',
            'ui.userLoggedIn',
        ]);
        this.$element = $(element);

        this.$detailDrawer = this.$element.find(POI_DETAIL_DRAWER_SELECTOR);
        this.$overviewDrawer = this.$element.find(POI_OVERVIEW_DRAWER_SELECTOR);
        this.$accordionWrapper = this.$element.find(DATA_DRAWER_ACCORDION_WRAPPER_SELECTOR);

        this.detailDrawer = new ObjectMapDetailDrawer(this.$detailDrawer, this.store);
        this.overviewDrawer = new ObjectMapOverviewDrawer(this.$overviewDrawer, this.store, this.onSuggestItems.bind(this));

        this.bindMediaViewerEvents();
        this.subscribeToStore();
    }

    /**
     * This will be called when the user starts to interact with the travel distance suggest input
     * the component will set it's own visibility to hidden to give a better ux for the user.
     */
    onSuggestItems(show) {
        if (show) {
            this.$accordionWrapper.addClass(VISIBILITY_HIDDEN_CLASS);
        } else {
            this.$accordionWrapper.removeClass(VISIBILITY_HIDDEN_CLASS);
        }
    }

    subscribeToStore() {
        this
            .connect
            .subscribe((state, prevState) => {
                const overviewWasHidden = [DRAWER_HIDDEN].indexOf(prevState.ui[POI_OVERVIEW_DRAWER]) > -1;
                const expandingOverviewDrawer = overviewWasHidden && state.ui[POI_OVERVIEW_DRAWER] === DRAWER_MAXIMISED;
                const hidingDetailDrawer = [DRAWER_MAXIMISED, DRAWER_SEMI, DRAWER_MINIMISED].indexOf(prevState.ui[POI_DETAIL_DRAWER]) > -1
                    && state.ui[POI_DETAIL_DRAWER] === DRAWER_HIDDEN;
                const showingDetailDrawer = prevState.ui[POI_DETAIL_DRAWER] === DRAWER_HIDDEN
                    && [DRAWER_MAXIMISED, DRAWER_SEMI, DRAWER_MINIMISED].indexOf(state.ui[POI_DETAIL_DRAWER]) > -1;

                if (expandingOverviewDrawer) {
                    this.store.dispatch(changeDrawer(POI_DETAIL_DRAWER, DRAWER_HIDDEN));
                } else if (hidingDetailDrawer && overviewWasHidden) {
                    this.store.dispatch(changeDrawer(POI_OVERVIEW_DRAWER, DRAWER_MINIMISED));
                } else if (showingDetailDrawer) {
                    this.store.dispatch(changeDrawer(POI_OVERVIEW_DRAWER, DRAWER_HIDDEN));
                }

                const changingLocation = state.ui.activeLocationId !== prevState.ui.activeLocationId
                    && state.ui.activeLocationId !== null;
                if (changingLocation) {
                    this.store.dispatch(changeDrawer(POI_DETAIL_DRAWER, DRAWER_MINIMISED));
                }

                // checks if the personal places are loadded and if there's an active location ID.
                // this is necessary to load the correct state of the drawers when loading with state on the URL.
                if (state.entities.personalPlaces !== prevState.entities.personalPlaces && state.ui.activeLocationId !== null) {
                    if (this.isValidLocationId(state.ui.activeLocationId, state)) {
                        this.setUpDrawers();
                    } else {
                        this.setDrawersToBaseState();
                        this.store.dispatch(setMediaViewerHash(URL_HASH_MAP));
                        this.store.dispatch(setActiveLocation(null));
                    }
                }
            });
    }

    isValidLocationId(id, state) {
        const location = state.entities.locations;
        const personalPlaces = state.entities.personalPlaces;
        if (personalPlaces[id] === undefined && location[id] === undefined) {
            return false;
        }
        return true;
    }

    bindMediaViewerEvents() {
        $(window).on(EVENT_MEDIA_VIEWER_OPENED, () => {
            this.setUpDrawers();
        });

        $(window).on(EVENT_MEDIA_VIEWER_CLOSED, () => {
            this.setDrawersToHiddenState();
        });

        $(window).on(EVENT_MAP_TYPE_SELECT, (event, data) => {
            if (data.value !== 'roadmap') {
                this.store.dispatch(setMediaViewerHash('kaart'));
                this.store.dispatch(setActiveLocation(null));

                this.setDrawersToHiddenState();

                this.$element.addClass(IS_HIDDEN_CLASS);
            } else {
                this.$element.removeClass(IS_HIDDEN_CLASS);
                this.setUpDrawers();
            }
        });
    }

    /**
     * (Re)set the media viewer map drawers according to the current route
     */
    setUpDrawers() {
        const state = this.store.getState();
        const currentRoute = state.ui.mediaViewerRoute;
        const baseStateRe = new RegExp(`^${URL_HASH_MAP}$`);
        const newLocationRe = new RegExp(`^${URL_HASH_MAP}/${URL_HASH_NEW_LOCATION}$`);
        const saveActiveLocationRe = new RegExp(SAVED_LOCATION_URL_REGEX);

        // if the user is not logged in and there's no locations fetched, shows de default state of the drawers. it will handle the loading with
        // unknown location id on the URL.
        if ((state.ui.userLoggedIn === false && $.isEmptyObject(state.entities.locations)) && !newLocationRe.test(currentRoute)) {
            this.setDrawersToBaseState();
            return;
        }

        if (baseStateRe.test(currentRoute)) {
            this.setDrawersToBaseState();
        } else if (newLocationRe.test(currentRoute)) {
            this.store.dispatch(changeDrawer(POI_DETAIL_DRAWER, DRAWER_SEMI));
            this.detailDrawer.showAddNewLocationForm();
        } else if (saveActiveLocationRe.test(currentRoute)) {
            this.store.dispatch(changeDrawer(POI_DETAIL_DRAWER, DRAWER_SEMI));
        } else if (state.ui.activeLocationId !== null) {
            this.store.dispatch(changeDrawer(POI_DETAIL_DRAWER, DRAWER_MINIMISED));
            this.store.dispatch(changeDrawer(POI_OVERVIEW_DRAWER, DRAWER_HIDDEN));
        }
    }

    setDrawersToBaseState() {
        this.store.dispatch(changeDrawer(POI_OVERVIEW_DRAWER, DRAWER_MINIMISED));
        this.store.dispatch(changeDrawer(POI_DETAIL_DRAWER, DRAWER_HIDDEN));
    }

    setDrawersToHiddenState() {
        //order is important here, since hiding Detail will set Overview to minimised
        this.store.dispatch(changeDrawer(POI_DETAIL_DRAWER, DRAWER_HIDDEN));
        this.store.dispatch(changeDrawer(POI_OVERVIEW_DRAWER, DRAWER_HIDDEN));
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new ObjectMapDrawers(element));

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default OptIn;

// component configuration
var COMPONENT_SELECTOR = '[data-opt-in]';
var OPTION_EXTERNAL_ATTRIBUTE = 'data-opt-in-is-external';

function OptIn(element) {
    var component = this;
    component.$element = $(element);
    component.isExternal = element.getAttribute(OPTION_EXTERNAL_ATTRIBUTE) === 'true';

    component.$optInLink = component.$element.find('a');
    component.$optOutForm = component.$element.find('form');

    component.$optInLink.on('click', function optIn() { component.optIn(); });
    component.$optOutForm.on('submit', function optOut(e) { component.optOut(e, this); });
}

OptIn.prototype.optIn = function() {
    var component = this;

    if (component.isExternal) {
        // We opened a URL in a new tab/window, so we need to close the opt-in in the old tab/window
        component.close();
    }
};

OptIn.prototype.optOut = function(e, form) {
    var component = this;

    var settings = {
        method: form.method,
        data: $(form).serialize(),
        success: function() {
            component.close();
            // Old website also tracks with an event: _gaq.push(['_trackEvent', 'Intercept', 'Sluiten', 'Tevredenheidsonderzoek']);
        },
        error: function(jqXHR, textStatus, errorThrown) {
            if (window.console) {
                console.log(jqXHR, textStatus, errorThrown);
            }
        }
    };
    $.ajax(form.action, settings);

    e.preventDefault();
};

OptIn.prototype.close = function() {
    var component = this;
    component.$element.addClass('is-removed');
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new OptIn(element);
});
import $ from 'jquery';
import Cookies from '../app-utils/app-utils-cookies';

export default UserMyHouseDatasource;

// component configuration
const COMPONENT_SELECTOR = '[data-my-house-datasource]';
const REQUEST_URL = 'data-datasource-request-url';
const JWT_KEY = 'data-datasource-jwt';
const PERIOD_DROPDOWN_SELECTOR = '[data-my-house-datasource-dropdown]';
const FORM_SELECTOR = '[data-my-house-datasource-form]';
const GLOBALID = 'data-datasource-globalid';
const SLASH = 'data-datasource-remove-slash';
const ERROR_MESSAGE_CLASS = '.graph-no-data';

const PREFERRED_PERIOD_COOKIE_NAME = 'mijn_PreferredStatsPeriod';

UserMyHouseDatasource.instance = null;

/**
 * Constructor method, links child elements to variables for internal use
 * @param {HTMLElement} element     The HTML element to bind to.
 */
function UserMyHouseDatasource(element) {
    const component = this;
    component.$element = $(element);

    //Dependencies
    component.Cookies = Cookies;

    component.$selectGraphDataForm = component.$element.find(FORM_SELECTOR);
    component.dataObservers = [];
    component.requestUrl = component.$element.attr(REQUEST_URL);
    component.issuedJwt = component.$element.attr(JWT_KEY);
    component.globalId = component.$element.attr(GLOBALID);
    component.trailingSlash = component.$element[0].hasAttribute(SLASH);
    component.selectedPeriod = null;

    let preferredPeriod = component.getValidPeriodCookie();
    if (preferredPeriod) {
        component.$element.find('select').val(preferredPeriod);
    }

    component.$selectGraphDataForm.on('change', () => {
        $(ERROR_MESSAGE_CLASS).remove();
        component.selectedInputChanged(component.$selectGraphDataForm);
    });

    $(document).ready(() => component.loadData());
}

/**
 * Check if all observers are loaded. If so, start loading data, if not, retry in 100ms.
 */
UserMyHouseDatasource.prototype.loadData = function () {
    const component = this;
    let allObserversLoaded = true;

    component.foreachObservers(observer => function () {
        if (!observer.isLoaded.apply(this)) {
            allObserversLoaded = false;
        }
    });

    if (!allObserversLoaded) {
        setTimeout(component.loadData.bind(component), 100);
    } else {
        // load the periods dropdown
        component.requestPeriodsForDropdown()
        // then trigger select of default option
            .then(() => component.selectedInputChanged(component.$selectGraphDataForm));
    }
};

/**
 * Get the selected input from the form.
 * @param {HTMLElement} form
 */
UserMyHouseDatasource.prototype.selectedInputChanged = function (form) {
    const component = this;
    component.setCookie(form);
    component.requestStatisticsData();
};

/**
 * sets cookie and return the cookie value
 * @returns {string}
 */
UserMyHouseDatasource.prototype.setCookie = function (form) {
    const component = this;
    const $form = $(form);
    component.selectedPeriod = $form.find(':selected');
    component.selectedType = $form.find(':checked').val();

    component.Cookies.setCookie(PREFERRED_PERIOD_COOKIE_NAME, component.selectedPeriod.val());
};

/**
 * Load dropdown periods data using a request with authorization.
 * @returns {*}     Select (dropdown) element.
 */
UserMyHouseDatasource.prototype.requestPeriodsForDropdown = function () {
    const component = this;
    const requestUrl = component.createDropdownRequestUrl(
        component.requestUrl,
        component.globalId,
        component.trailingSlash
    );

    component.foreachObservers(observer => observer.onDataLoading);

    return $.ajax({
        url: requestUrl,
        headers: {Authorization: 'Bearer ' + component.issuedJwt},
        xhrFields: {withCredentials: true},
        dataType: 'json',
        success: function (response) {
            if (UserMyHouseDatasource.isSuccesfulResponse(response)) {
                component.createOptionsForDropdown(response);
            } else {
                component.onFail(response);
            }
        },
        error: function (response) {
            component.onFail(response);
        },
        complete: function (response) {
            if (UserMyHouseDatasource.isTokenExpired(response)) {
                component.refreshToken(component.requestPeriodsForDropdown);
            }
        }
    });
};

UserMyHouseDatasource.prototype.onFail = function (response) {
    const component = this;
    component.foreachObservers(observer => observer.onDataLoadError, response);
};

/**
 * Create the options and insert them in the dropdown element.
 * @param {Object[]} response - Request response.
 */
UserMyHouseDatasource.prototype.createOptionsForDropdown = function (response) {
    let component = this;
    const $dropdown = $(PERIOD_DROPDOWN_SELECTOR);

    Object.keys(response).forEach(key => {
        let $element = $dropdown.find('[value=\'' + response[key].Label + '\']');

        if (!$element.length) {
            $element = $('<option></option>').text(response[key].Label);
            $dropdown.find('option').eq(key).before($element);
        }

        $element = $element.data('dateFrom', response[key].DateFrom)
            .data('dateTo', response[key].DateTo)
            .data('format', response[key].Format)
            .data('interval', response[key].Interval);

        if (!component.getValidPeriodCookie() && response[key].IsSelected) {
            $element.attr('selected', 1);
        }
    });

    //remove options that existed in funda.website, but where not provided by the statistics api
    $dropdown.find('option').each((key, value) => {
        if (!$(value).data('dateFrom')) {
            $(value).remove();
        }
    });
};

/**
 * Load statistics data using a request with authorization.
 * @returns {*}     Statistics request
 */
UserMyHouseDatasource.prototype.requestStatisticsData = function () {
    const component = this;
    const requestUrl = component.createStatisticsRequestUrl(
        component.requestUrl,
        component.selectedType,
        component.globalId,
        component.trailingSlash
    );

    component.foreachObservers(observer => observer.onDataLoading);

    return $.ajax({
        url: requestUrl,
        dataType: 'json',
        headers: {Authorization: 'Bearer ' + component.issuedJwt},
        xhrFields: {withCredentials: true},
        success: function (response) {
            if (UserMyHouseDatasource.isSuccesfulResponse(response)) {
                component.foreachObservers(observer => observer.onDataLoaded, response, component.selectedPeriod, component.selectedType);
            } else {
                component.onFail(response);
            }
            component.isLoading = false;
        },
        error: function (response) {
            component.onFail(response);
        },
        complete: function (response) {
            if (UserMyHouseDatasource.isTokenExpired(response)) {
                component.refreshToken(component.requestStatisticsData);
            }
        }
    });
};

/**
 * Create the request URL based on the selected period.
 * @returns {string}    Request URL
 */
UserMyHouseDatasource.prototype.createStatisticsRequestUrl = function (requestUrl, selectedType, globalId, trailingSlash) {
    const component = this;
    const fromDate = UserMyHouseDatasource.formatDate(component.selectedPeriod.data('dateFrom'));
    const toDate = UserMyHouseDatasource.formatDate(component.selectedPeriod.data('dateTo'));
    const partialUrl = [
        requestUrl,
        selectedType,
        globalId,
        fromDate,
        toDate
    ].join('/');

    return [
        partialUrl,
        (trailingSlash === false) ? '/' : ''
    ].join('');
};

/**
 * Create the URL to request the correct data.
 * @param {string} requestUrl
 * @param {number} globalId
 * @param {boolean} trailingSlash
 * @returns {string}
 */
UserMyHouseDatasource.prototype.createDropdownRequestUrl = function (requestUrl, globalId, trailingSlash) {
    return [
        requestUrl,
        '/getgraphperiods/',
        globalId,
        (trailingSlash === false) ? '/' : ''
    ].join('');
};

/**
 * Loop through each observer and call the {@link action} parameter with any provided {@link arguments}
 * @param {function} action - the action to call on the observer
 * @param {*} [arguments] - arguments passed to the {@link action} function
 */
UserMyHouseDatasource.prototype.foreachObservers = function (action) {
    const component = this;
    const parameters = [].slice.call(arguments).splice(1);

    Object.keys(this.dataObservers).forEach(observer => {
        action(component.dataObservers[observer]).apply(component.dataObservers[observer].context, parameters);
    });
};

/**
 * Register an observer for the dataChanges events
 * @param {Object} observer - Object with observed function objects.
 */
UserMyHouseDatasource.prototype.registerDataChanged = function (observer) {
    this.dataObservers.push(observer);
};

/**
 * Refresh the users' access-token when expired.
 * @param callback      requestStatisticsData function
 * @returns {*}         Refresh token request
 */
UserMyHouseDatasource.prototype.refreshToken = function (callback) {
    const component = this;

    return $.ajax(component.refreshJwtUrl)
        .done(function (response) {
            if ('Token' in response && response.Token !== '') {
                component.issuedJwt = response.Token;
                callback();
            }
        });
};

/**
 * Gets preferred period from cookie and performs a validation.
 * @returns The
 */
UserMyHouseDatasource.prototype.getValidPeriodCookie = function () {
    const component = this;
    var cookieValue = component.Cookies.getCookie(PREFERRED_PERIOD_COOKIE_NAME);

    //Check if the cookie value is a valid option.
    const optionElements = component.$element.find('option');
    for (let i = 0; i < optionElements.length; i++) {
        if (optionElements[i].value === cookieValue) {
            return cookieValue;
        }
    }
};

/**
 * Format a date to the correct format for the request URL.
 * @param date
 * @returns {string}
 */
UserMyHouseDatasource.formatDate = function (date) {
    const rawDate = new Date(date);
    const format = function zeroFormat(number) {
        return (number < 10) ? ('0' + number) : number;
    };

    return [
        rawDate.getFullYear(),
        format(rawDate.getMonth() + 1),
        format(rawDate.getDate())
    ].join('');
};

/**
 * Check if token is expired
 * @param {object} xhr - The request.
 * @returns {boolean}   Is token expired boolean
 */
UserMyHouseDatasource.isTokenExpired = function (xhr) {
    return xhr.status === 498;
};

/**
 * Check if response is successful.
 * @param {object[]} response - Request response.
 * @returns {boolean}   Is request successful boolean
 */
UserMyHouseDatasource.isSuccesfulResponse = function (response) {
    return response.Result !== 'undefined';
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function (index, element) {
    if (UserMyHouseDatasource.instance) {
        throw 'UserMyHouseDatasource.instance has already been set.';
    }
    UserMyHouseDatasource.instance = new UserMyHouseDatasource(element);
});

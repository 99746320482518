// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default UserLogin;

// component configuration
var COMPONENT_SELECTOR = '[data-user-login]';
var SUMBIT_FORM_SELECTOR = '[data-login-submit-form]';
var SUBMIT_BUTTON_SELECTOR = '[data-login-submit-button]';

function UserLogin(element) {

    var component = this;
    component.$element = $(element);

    //disable the submit button so doubleclicking if won't generate a anti-forgery exception
    $(SUMBIT_FORM_SELECTOR).submit(component.disableAfterAction);
}

UserLogin.prototype.disableAfterAction = function() {
    $(this).find(SUBMIT_BUTTON_SELECTOR).prop('disabled', true);
};

// turn all elements with the default selector into components
// note that this will only work for the login page and not the login dialog
// because the login dialog is loaded into the dom at a later point
// this is the intended behaviour
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new UserLogin(element);
});
import $ from 'jquery';
import reduxStore from '../store/store';
import {changeDrawer, enableCategory, setMediaViewerHash} from '../store/actions';
import {POI_OVERVIEW_DRAWER} from '../store/constants/drawer-names';
import {DRAWER_MAXIMISED} from '../store/constants/drawer-states';
import {default as gaLog, EVENT_TYPE} from '../media-viewer-map/media-viewer-gtm-events';

export default ObjectBuurt;

const COMPONENT_ATTR = 'data-object-buurt';
const COMPONENT_SELECTOR = `[${COMPONENT_ATTR}]`;
const CATEGORY_SELECT_ATTR = 'data-object-buurt-category-select';
const CATEGORY_SELECT_SELECTOR = `[${CATEGORY_SELECT_ATTR}]`;
const EXPLORE_LOCATIONS_SELECTOR = '[data-object-buurt-explore-locations]';

const EVENT_MEDIA_VIEWER_OPENED = 'media-viewer-opened-event';

class ObjectBuurt {

    constructor(element, store) {
        this.store = store || reduxStore;
        this.$element = $(element);

        this.bindEvents();
    }

    bindEvents() {
        this.$element
            .on('click', CATEGORY_SELECT_SELECTOR, event => {
                this.selectCategoryHandler(event);
            })
            .on('click', EXPLORE_LOCATIONS_SELECTOR, event => {
                this.exploreLocationsHandler(event);
            });
    }

    selectCategoryHandler(event) {
        event.preventDefault();
        const categoryId = event.currentTarget.getAttribute(CATEGORY_SELECT_ATTR);

        this.store.dispatch(setMediaViewerHash('kaart'));
        this.store.dispatch(enableCategory(categoryId));
        gaLog.services(EVENT_TYPE.ENTRY_POINT_SERVICES, categoryId);
    }

    exploreLocationsHandler(event) {
        event.preventDefault();
        this.store.dispatch(setMediaViewerHash('kaart'));

        $(window).one(EVENT_MEDIA_VIEWER_OPENED, () => {
            window.setTimeout(() => {
                this.store.dispatch(changeDrawer(POI_OVERVIEW_DRAWER, DRAWER_MAXIMISED));
            }, 0); // To end of the queue so we avoid clashing with regular drawer setup
        });
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new ObjectBuurt(element));

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import debounce from 'lodash/debounce';
import MapEvents from '../map/map-events';
import MapTileCache from '../map/map-tile-cache';
import MapLocalStorage from '../map-localstorage/map-localstorage';

// what does this module expose?
export default SearchMapMarkerManager;

// component configuration
const MARKER_ATTR = 'data-marker';
const MARKER_CLASS = 'search-map-marker';
const MARKER_DEFAULT_CLASS = ' search-map-marker__default';
const MARKER_MULTIPLE_OBJECTS_CLASS = ' search-map-marker__multiple-objects';
const IS_ACTIVE = 'is-active';
const IS_CAPPED = 'capped';
const IS_VIEWED = 'viewed';
const SHOW_OBJECT_INFO_EVENT = 'show_object_info';
const HIDE_OBJECT_INFO_EVENT = 'hide_object_info';
// pending on the size of the viewport the marker will be displayed at certain size. Small viewport -> large icon
const MARKER_SIZE = { small: 14, medium: 13 }; // px
const MINIMUM_INTERVAL_BETWEEN_RESIZE_VIEWPORT = 250; // ms
const MEDIUM_VIEWPORT_SIZE = 750; //$bp-medium

function SearchMapMarkerManager($map, config) {
    const component = this;
    const $window = $(window);

    component.$map = $map;
    component.config = config;
    component.selectedMarker = MapEvents.getValueFromHash('id') || null;
    component.pixelDistance = $window.width() >= MEDIUM_VIEWPORT_SIZE ? MARKER_SIZE.medium : MARKER_SIZE.small;
    component.isInitialized = false;
    // caching
    component.mapTileCache = MapTileCache;

    component.bindEvents();
}

SearchMapMarkerManager.prototype.bindEvents = function() {
    const component = this;
    const $window = $(window);
    component.$map.on(SHOW_OBJECT_INFO_EVENT, (event, eventArgs) => {
        if (eventArgs.objectId !== undefined && eventArgs.objectId !== null) {
            component.selectMarker(eventArgs.objectId);
        }
    });
    component.$map.on(HIDE_OBJECT_INFO_EVENT, () => component.unselectMarker());

    function debouncedResizeViewport() {
        return debounce(() => {
            if ($window.width() >= MEDIUM_VIEWPORT_SIZE) {
                component.pixelDistance = MARKER_SIZE.medium;
            } else {
                component.pixelDistance = MARKER_SIZE.small;
            }
        }, MINIMUM_INTERVAL_BETWEEN_RESIZE_VIEWPORT, { leading: false, trailing: true });
    }
    $window.on('resize', debouncedResizeViewport());
};

/**
 * Create a html marker and returns html
 * @param {Object} object {id: *, lat: *, lng: *, sub: []}
 * @returns {Element}
 */
SearchMapMarkerManager.prototype.getElement = function(object) {
    const component = this;
    const markerId = object.id;

    let isViewed = MapLocalStorage.isViewed(object.id, object.capped);
    let marker = document.createElement('div');
    marker.setAttribute(MARKER_ATTR, markerId);

    if (object.capped) {
        marker.setAttribute('class', component.createClassName(markerId, MARKER_MULTIPLE_OBJECTS_CLASS, object.capped, isViewed));
        marker.innerHTML = '•';
    } else if (object.count > 1) {
        marker.setAttribute('class', component.createClassName(markerId, MARKER_MULTIPLE_OBJECTS_CLASS, object.capped, isViewed));
        marker.innerHTML = object.count;
    } else {
        marker.setAttribute('class', component.createClassName(markerId, MARKER_DEFAULT_CLASS, object.capped, isViewed));
    }
    // trigger event only once (!initialized)
    if (!component.isInitialized && component.isActive(markerId)) {
        component.$map.trigger(SHOW_OBJECT_INFO_EVENT, {objectId: component.selectedMarker, initial: true});
        component.isInitialized = true;
    }

    marker.style.left = object.x + 'px';
    marker.style.top = object.y + 'px';
    // return marker (in this case to component map-marker-overlay)
    return marker;
};

/**
 * Set selected marker to active state
 * @param {string} objectId
 */
SearchMapMarkerManager.prototype.selectMarker = function(objectId) {
    const component = this;

    component.unselectMarker();

    component.selectedMarker = objectId;
    $('[' + MARKER_ATTR + '*="' + objectId + '"]').addClass(IS_ACTIVE).addClass(IS_ACTIVE);

};

/**
 * Unselect the marker and remove the active state
 */
SearchMapMarkerManager.prototype.unselectMarker = function() {
    const component = this;
    const $selectedMarker = $('[' + MARKER_ATTR + '*="' + component.selectedMarker + '"]');

    if (component.selectedMarker && !$selectedMarker.hasClass('capped')) {
        if (MapLocalStorage.isViewed(component.selectedMarker)) {
            $selectedMarker.addClass(IS_VIEWED);
        }
    }

    component.selectedMarker = null;
    $selectedMarker.removeClass(IS_ACTIVE);
};

/**
 * Unselect the marker and remove the active state
 */
SearchMapMarkerManager.prototype.unselectAllMarkers = function() {
    const component = this;
    const $selectedMarker = $('[' + MARKER_ATTR + ']');

    $selectedMarker.removeClass(IS_ACTIVE);
    component.selectedMarker = null;
};

/**
 * Create class name for marker
 * @param markerId
 * @param typeMarker
 * @returns {string}
 */
SearchMapMarkerManager.prototype.createClassName = function(markerId, typeMarker, capped, isViewed) {
    const component = this;

    let domainSpecificType = typeMarker + ' ' + component.config.markerDomainClass;
    let isActive = component.isActive(markerId);
    let classes = [MARKER_CLASS, domainSpecificType];

    if (isActive) {
        classes.push(IS_ACTIVE);
    }
    if (capped) {
        classes.push(IS_CAPPED);
    }
    if (isViewed) {
        classes.push(IS_VIEWED);
    }

    return classes.join(' ');
};

/**
 * Determine if marker is selected
 * @param {string} markerId
 * @returns {boolean}
 */
SearchMapMarkerManager.prototype.isActive = function(markerId) {
    const component = this;
    return (markerId.indexOf(component.selectedMarker) > -1 ? true: false);
};
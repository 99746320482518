import $ from 'jquery';

import {EVENT_TYPE} from '../media-viewer-map/media-viewer-gtm-events';

// what does this module expose?
export default SocialLogin;

// component configuration
const COMPONENT_SELECTOR = '[data-social-login]';
const PROVIDER_ATTR = 'data-social-login-provider';
const ROOT_URL_ATTR = 'data-social-login-root-url';
const RETURN_URL_ATTR = 'data-social-login-return-url';
const LOGIN_DIALOG_SELECTOR = '[data-dialog="user-form"]';
const BUTTON_TEXT_SELECTOR = '.facebook-button-text';
const EVENT_USER_AUTHENTICATE = 'user-authenticated-event';

const KEY_RETURN_URL = 'returnUrl';
const KEY_GA_TAG = 'facebookGaTag';

function SocialLogin(element) {
    const component = this;

    element.instance = component;

    component.$element = $(element);
    component.provider = element.getAttribute(PROVIDER_ATTR);
    component.dialogElement = document.querySelector(LOGIN_DIALOG_SELECTOR);

    const btnTetxt = $(element).find(BUTTON_TEXT_SELECTOR).text();
    component.eventType = btnTetxt === 'Log on with Facebook' || btnTetxt === 'Inloggen met Facebook'
        ? EVENT_TYPE.LOGIN_FACEBOOK : EVENT_TYPE.REGISTER_FACEBOOK;

    component.state = {
        reauthenticate: false,
        rootUrl: element.getAttribute(ROOT_URL_ATTR)
    };

    component.state.returnUrl = component.getReturnUrl() || component.state.rootUrl;

    component.$element.on('click', () => component.doAuth());

    component.initLocalStorageFallback();

    window.socialCall = (x, y, z) => component.socialCall(x, y, z);
}

SocialLogin.prototype.initLocalStorageFallback = function () {
    const component = this;

    localStorage.removeItem(KEY_RETURN_URL);
    localStorage.removeItem(KEY_GA_TAG);
    window.addEventListener('storage', (e) => component.storageEventListener(e));
};

SocialLogin.prototype.storageEventListener = function (e) {
    const component = this;

    if (e.key === KEY_RETURN_URL) {
        const url = e.newValue;
        localStorage.removeItem(KEY_RETURN_URL);
        component.redirect(url);
    } else if (e.key === KEY_GA_TAG) {
        const tag = e.newValue;
        localStorage.removeItem(KEY_GA_TAG);
        component.addGtmTag(tag);
    }
};

SocialLogin.prototype.doAuth = function () {
    const component = this;

    const chrome = 100;
    const width = 500;
    const height = 500;
    const left = (screen.width - width) / 2;
    const top = (screen.height - height - chrome) / 2;
    const options = 'status=0,toolbar=0,location=1,resizable=1,scrollbars=1,'
        + `left=${left},top=${top},width=${width},height=${height}`;


    const url = `${component.state.rootUrl.slice(0, -1)}/mijn/sociallogin/externallogin/?`
        + `provider=${component.provider}&returnUrl=${component.state.returnUrl}`
        + `&currentUrl=${document.location.pathname}&rerequest=${component.state.reauthenticate ? '1' : '0'}`;


    component.addGtmTag('Facebook-clicked');
    component.displayDialog(url, options);
};

SocialLogin.prototype.displayDialog = function (url, options) {
    const component = this;

    component.popup = window.open(url, '', options);
};

SocialLogin.prototype.redirect = function (returnUrl) {
    if (returnUrl) {
        window.location.href = returnUrl;
    }
};

SocialLogin.prototype.addGtmTag = function (tag, timeout = 2000) {
    let promiseHandler;
    if (window.ga) {
        promiseHandler = (resolve) => {
            const timeoutFunc = setTimeout(() => resolve('Timeout failure'), timeout);
            const callback = (message) => {
                resolve(message);
                clearTimeout(timeoutFunc);
            };
            window.ga('send', 'event', {
                'eventCategory': 'login',
                'eventAction': 'socialLogin',
                'eventLabel': tag,
                'eventValue': 1,
                'hitCallback': () => callback('Success'),
                'hitCallbackFail': () => callback('Failure'),
            });
        };
    }
    else {
        promiseHandler = resolve => resolve('Ga tag is not available');
    }

    return new Promise(promiseHandler);
};

SocialLogin.prototype.socialCall = function (success, returnUrl, gtmTag) {
    const component = this;

    if (component.popup && component.popup.close) {
        component.popup.close();
    }

    return component
        .addGtmTag(gtmTag)
        .then(() => {
            if (success) {
                $(document).trigger(EVENT_USER_AUTHENTICATE, {loginType: component.eventType});
                if (component.dialogElement !== null) {
                    component.dialogElement.dialog.onFormSuccess();
                } else {
                    component.redirect(returnUrl);
                }
            }
        });
};

SocialLogin.prototype.getReturnUrl = function () {
    const component = this;
    let returnUrl = component.$element[0].getAttribute(RETURN_URL_ATTR) || null;

    if (returnUrl != null)
        return returnUrl;

    const qs = window.location.search.substr(1).split('&');
    for (let i = 0; i < qs.length; ++i) {
        const p = qs[i].split('=');
        if (p.length !== 2 || p[0] !== 'ReturnUrl') {
            continue;
        }
        returnUrl = decodeURIComponent(p[1].replace(/\+/g, ' '));
    }
    return returnUrl;
};

$(COMPONENT_SELECTOR).each((index, element) => new SocialLogin(element));

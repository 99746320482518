import {
    activeLocationIdSelector,
    modalityAndTravelToggleSelector,
    selectedFacilityCategoriesSelector
} from './selectors';

export const hasActiveLocation = state => activeLocationIdSelector(state) !== null;
export const activeLocationChanged = (state, prevState) => activeLocationIdSelector(state) !== activeLocationIdSelector(prevState);
export const modalityAndTravelToggleChanged = (state, prevState) => modalityAndTravelToggleSelector(state) !== modalityAndTravelToggleSelector(prevState);
export const hasFacilityCategoriesSelected = state => selectedFacilityCategoriesSelector(state).length !== 0;
export const selectedFacilityCategoriesChanged = (state, prevState) => selectedFacilityCategoriesSelector(state) !== selectedFacilityCategoriesSelector(prevState);

/* global gtmDataLayer */

import $ from 'jquery';

const COMPONENT_SELECTOR = '[data-search-map-gps-controls]';
const BUTTON_SELECTOR = '[data-search-map-gps-controls-center]';
const CENTERED_CLASS = 'is-centered';
const DISABLED_CLASS = 'is-disabled';
const GPS_STATE = {
    DEFAULT: 1,
    CENTERED: 2,
    DISABLED: 3,
};

const GA_CATEGORY = 'interactions-kaart';
const TRACK_CENTER_MAP_EVENT = 'track_center_map';
const MAP_UNCENTERED_EVENT = 'map_uncentered';
const SHOW_GPS_ERROR_EVENT = 'gps-error-event';
const GPS_GENERAL_ERROR_TYPE = 'gps-general-error';
const GPS_ACCESS_DENIED_ERROR_TYPE = 'gps-access-denied-error';
const $window = $(window);


class ObjectMapGpsControls
{
    constructor(element)
    {
        this.$element = $(element);
        this.$button = $(element).find(BUTTON_SELECTOR);

        this.isGeoLocationSupported = 'geolocation' in navigator;
        this.gpsLocation = null;

        this.state = GPS_STATE.DEFAULT;

        this.bindEvents();
    }

    bindEvents() {
        this.$button.on('click', event => {
            this.handleClick(event);
            this.registerGAEvents(event);
        });
        $window.on(MAP_UNCENTERED_EVENT, () => {
            if (this.state === GPS_STATE.CENTERED) {
                this.state = GPS_STATE.DEFAULT;
                this.updateState();
            }
        });
    }

    registerGAEvents(event) {
        if ($(event.target).hasClass(DISABLED_CLASS)) {
            const GA_ACTION = 'click-current-location-disabled-button';
            this.gaLogEvent(GA_ACTION);
        } else {
            const GA_ACTION = 'click-current-location';
            this.gaLogEvent(GA_ACTION);
        }
    }

    updateState() {
        if (!this.isGeoLocationSupported) return;

        this.$button
            .removeClass(DISABLED_CLASS)
            .removeClass(CENTERED_CLASS);

        if (this.state === GPS_STATE.DISABLED) {
            this.$button.addClass(DISABLED_CLASS);
        } else if (this.state === GPS_STATE.CENTERED) {
            this.$button.addClass(CENTERED_CLASS);
            $window.trigger(TRACK_CENTER_MAP_EVENT, this.gpsLocation);
        }
    }

    handleClick() {
        if (!this.isGeoLocationSupported) return;

        navigator.geolocation.getCurrentPosition(this.geoSuccessHandler.bind(this), this.geoErrorHandler.bind(this));
    }

    geoSuccessHandler(position) {
        this.gpsLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.state = GPS_STATE.CENTERED;

        this.updateState();
        const GA_ACTION = 'click-current-location-success';
        this.gaLogEvent(GA_ACTION);
    }

    geoErrorHandler(error) {
        if (error.code === 1) {
            this.state = GPS_STATE.DISABLED;
            $(document).trigger(SHOW_GPS_ERROR_EVENT, {type: GPS_ACCESS_DENIED_ERROR_TYPE});
        } else {
            this.state = GPS_STATE.DEFAULT;
            $(document).trigger(SHOW_GPS_ERROR_EVENT, {type: GPS_GENERAL_ERROR_TYPE});
        }
        this.updateState();
        const GA_ACTION = 'click-current-location-error';
        this.gaLogEvent(GA_ACTION);
    }

    gaLogEvent (action) {
        if (window.gtmDataLayer !== undefined) {
            gtmDataLayer.push({
                event: 'GAEvent',
                eventCategory: GA_CATEGORY,
                eventAction: action
            });
        }
    }
}

$(COMPONENT_SELECTOR).each((_, element) => new ObjectMapGpsControls(element));

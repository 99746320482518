/**
 * user-my-house view
 * View for the users to view their house on funda, and how it performs.
 */

import './../_base-view/base-view';

import '../../components/dialog-simple/dialog-simple';
import '../../components/dialog/dialog';
import '../../components/graph/graph';
import '../../components/async-text-validate/async-text-validate';
import '../../components/user-my-house-datasource/user-my-house-datasource';
import '../../components/user-my-house-graph/user-my-house-graph';
import '../../components/user-my-house-header/user-my-house-header';
import '../../components/user-my-house-options/user-my-house-options';
import '../../components/user-my-house-statistics/user-my-house-statistics';

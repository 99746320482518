// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import AppliedFilters from '../applied-filters/applied-filters';
import RangeFilterSelector from '../range-filter-selector/range-filter-selector';
import Separator from '../thousand-separator/thousand-separator';

// what does this module expose?
export default RangeFilter;

// component configuration
const COMPONENT_ATTR = 'range-filter';
const COMPONENT_SELECTOR = '[data-' + COMPONENT_ATTR + ']';
const RANGE_FILTER_SELECTOR_COMPONENT = '[data-range-filter-selector]';
const RANGE_FILTER_MIN_SELECTOR = '[data-range-filter-min] ' + RANGE_FILTER_SELECTOR_COMPONENT;
const RANGE_FILTER_MAX_SELECTOR = '[data-range-filter-max] ' + RANGE_FILTER_SELECTOR_COMPONENT;
const RANGE_FILTER_NAME = 'data-filter-name';
const THOUSAND_SEPARATOR = 'data-thousand-separator';

const DISPLAY_STRING = 'data-display-string';
const TOKEN = '{value}';

const EXTERNAL_TRIGGER = 'change.external';
const RANGE_CHANGED_TRIGGER = 'rangechanged';
const VALUE_CHANGED_TRIGGER = 'numberchanged';
const IGNORE_FILTER = 'ignore_filter';

function RangeFilter(element) {
    const component = this;
    component.$element = $(element);
    component.$form = $('form');
    component.$minContainer = component.$element.find(RANGE_FILTER_MIN_SELECTOR);
    component.$maxContainer = component.$element.find(RANGE_FILTER_MAX_SELECTOR);
    component.min = new RangeFilterSelector(component.$minContainer, component.$element);
    component.max = new RangeFilterSelector(component.$maxContainer, component.$element);
    component.filterGroupId = component.$element.attr(RANGE_FILTER_NAME);
    component.filterGroupName = component.$element.attr(RANGE_FILTER_NAME);
    component.separator = component.$element.attr(THOUSAND_SEPARATOR);
    component.displayString = component.$element.attr(DISPLAY_STRING) || '&euro; ' + TOKEN;
    component.token = TOKEN;
    component.currentRange = null;
    component.isInitiated = false;

    component.bindEvents();

    component.adjustFilter();
}

RangeFilter.prototype.bindEvents = function() {
    const component = this;

    component.$element.on('resetfilter', () => {
        component.reset();
    });

    component.$element.on(VALUE_CHANGED_TRIGGER, () => {
        component.adjustFilter();
    });

    component.isInitiated = true;
};

/**
 * add a token to the given number
 * @param {String} number
 * @param {Bool} numberIsFormatted
 * @returns {string}
 */
RangeFilter.prototype.addToken = function(number, numberIsFormatted) {
    const component = this;

    if (numberIsFormatted) {
        return component.displayString.replace(component.token, number);
    } else {
        const formattedValue = Separator.format(number, component.separator);
        return component.displayString.replace(component.token, formattedValue);
    }
};

/**
 * add the range to the applied filters
 * @param {amount} range
 */
RangeFilter.prototype.addFilter = function(range) {
    const component = this;
    const rangeAttributes = {
        filterGroupId: component.filterGroupId,
        filterGroupName: component.filterGroupName,
        filterName: range,
        labelText: range
    };

    AppliedFilters.add(rangeAttributes, function() {
        component.reset(component);
    }, true);
};

/**
 * Adjust the filter depending if value is default or not
 */
RangeFilter.prototype.adjustFilter = function() {
    const component = this;

    if (!component.isValidRange()) {
        return;
    }
    let range = 0;
    const minimumNumber = component.min.getValue();
    const maximumNumber = component.max.getValue();
    const formattedMinValue = Separator.format(minimumNumber || '', component.separator);

    if (maximumNumber === IGNORE_FILTER && minimumNumber > 0) {
        // only minimum number is set
        range = component.addToken(formattedMinValue + '+', true);
    } else if (maximumNumber !== IGNORE_FILTER && maximumNumber > 0) {
        // maximum number is set
        const formattedMaxValue = Separator.format(maximumNumber, component.separator);
        range = component.addToken(formattedMinValue, true) + ' - ' + component.addToken(formattedMaxValue, true);
    }

    if (component.isInitiated && component.currentRange !== range) {
        // make sure there is a change
        component.$element.trigger(RANGE_CHANGED_TRIGGER);
    }

    // only apply filter when range is larger then 0
    if (range !== 0 && range !== '0') {
        component.addFilter(range);
    } else {
        AppliedFilters.remove(component.filterGroupId);
    }
    component.currentRange = range;
};

/**
 * set a range difference between minimum and maximum number
 */
RangeFilter.prototype.setRangeDifference = function() {
    const component = this;
    const minimumNumber = component.min.getValue();
    component.max.setValueDifference(minimumNumber);
};

/**
 * validate current range and change value if needed or show validation
 */
RangeFilter.prototype.isValidRange = function() {
    const component = this;
    const minimumNumber = component.min.getValue();
    const maximumNumber = component.max.getValue();
    if (minimumNumber > maximumNumber) {
        component.max.setInvalid();
        component.bindFormChangeEvent();
        return false;
    }
    component.max.removeValidation();
    component.unbindFormEvent();
    return true;
};

/**
 * reset complete component to default
 * @param {component} RangeFilterComponent; the range-filter component it self
 */
RangeFilter.prototype.reset = function(RangeFilterComponent) {
    const component = RangeFilterComponent || this;
    component.min.setDefault();
    component.max.setDefault();
};

RangeFilter.prototype.unbindFormEvent = function() {
    const component = this;
    component.$form.off(EXTERNAL_TRIGGER);
};

/**
 * only trigger this request when change triggered outside the custom value
 */
RangeFilter.prototype.bindFormChangeEvent = function() {
    const component = this;

    component.$form.on(EXTERNAL_TRIGGER, (event) => {
        if ($(event.target).closest(COMPONENT_SELECTOR).length === 1) {
            return;
        }
        component.formChanged();
    });
};

/**
 * there is a 'change' within the form
 */
RangeFilter.prototype.formChanged = function() {
    const component = this;
    const minimumNumber = component.min.getValue();
    const maximumNumber = component.max.getValue();

    if (minimumNumber > maximumNumber && component.max.isInvalid()) {
        component.setRangeDifference();
    }
};

/**
 * determine if current element is visible
 * @returns {boolean}
 */
RangeFilter.prototype.isVisible = function() {
    const component = this;
    return component.$element.is(':visible');
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => {
    return new RangeFilter(element);
});
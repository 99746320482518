// dependencies (alphabetically)
import $ from 'jquery';

export default Pagination;

// component configuration
const COMPONENT_SELECTOR = '[data-pagination]';
const PAGELINK_ATTR = 'data-pagination-page';
const PAGELINK_SELECTOR = '['+ PAGELINK_ATTR + ']';
const INPUT_SELECTOR = '[data-pagination-input]';
const PAGE_ADJUSTED_EVENT = 'pageadjusted';

function Pagination(element) {
    this.$element = $(element);
    this.$pageNumber = $();

    this.$element.on('click', PAGELINK_SELECTOR, (event) => {
        event.preventDefault();

        var clickedPage = $(event.currentTarget).attr(PAGELINK_ATTR);
        this.setPage(clickedPage);

    }).bind(this);
}

/**
 * Scroll to top and trigger instant-search update with new page number
 * @param {number} new page number
 */
Pagination.prototype.setPage = function (number) {
    window.scrollTo(0, 0);
    this.$pageNumber = this.$element.find(INPUT_SELECTOR);
    this.$pageNumber.val(number);
    this.$element.trigger(PAGE_ADJUSTED_EVENT);
};

Pagination.initialize = function() {
    // turn all elements with the default selector into components
    $(COMPONENT_SELECTOR).each((index, element) => new Pagination(element));
};

Pagination.initialize();

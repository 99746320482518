// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default UserMyLoggedInAs;

// component configuration
var COMPONENT_SELECTOR = '[data-user-my-logged-in-as]';
var BUTTON_SELECTOR = 'button';
var HAS_WRITE_ACCESS_CLASS = 'has-write-access';
var HAS_READONLY_ACCESS_CLASS = 'has-readonly-access';
var NOTIFY_CLASS = '.notify';
var REQUEST_WRITE_CLASS = 'request-write';
var DISABLE_WRITE_URL = '/mijn/fundamanagerlogin/declinewrite/';
var ENABLE_WRITE_URL = '/mijn/fundamanagerlogin/requestwrite/';

function UserMyLoggedInAs(element) {
    var component = this;
    var $element = $(element);
    component.$element = $element;
    component.button = $element.find(BUTTON_SELECTOR);
    component.hasWriteAccess = $element.hasClass(HAS_WRITE_ACCESS_CLASS);

    component.$element.on('click', BUTTON_SELECTOR, function() {
        var requestWrite = $(this).parents(NOTIFY_CLASS).hasClass(REQUEST_WRITE_CLASS);
        component.grantUsersWishes(requestWrite);
    });

    // annoy logged in user
    if (component.hasWriteAccess) {
        var result = confirm('Wil je de schrijfrechten behouden voor dit account?\n\nActies en veranderingen aan dit account worden wel opgeslagen.');
        if (!result) {
            component.doRequest(DISABLE_WRITE_URL, function() {
                component.switchWriteAccess(false);
            });
        }
    }
}

UserMyLoggedInAs.prototype.grantUsersWishes = function(isRequestForWriteAccess) {
    var component = this;

    if (isRequestForWriteAccess) {
        component.doRequest(ENABLE_WRITE_URL, function() {
            component.switchWriteAccess(true);
        }, component.getLapText(true));
    } else {
        component.doRequest(DISABLE_WRITE_URL, function() {
            component.switchWriteAccess(false);
        }, component.getLapText(false));
    }
};

UserMyLoggedInAs.prototype.switchWriteAccess = function(hasWriteAccess) {
    var component = this;

    component.hasWriteAccess = hasWriteAccess;
    if (hasWriteAccess) {
        component.$element.removeClass(HAS_READONLY_ACCESS_CLASS).addClass(HAS_WRITE_ACCESS_CLASS);
    } else {
        component.$element.removeClass(HAS_WRITE_ACCESS_CLASS).addClass(HAS_READONLY_ACCESS_CLASS);
    }
};

UserMyLoggedInAs.prototype.doRequest = function (url, callback, requestText) {
    if (requestText !== undefined && requestText !== null) {
        var result = confirm(requestText);
        if (!result) {
            return false;
        }
    }
    $.post(url, function() {
        if (callback !== undefined) {
            callback();
        }
    });
};

UserMyLoggedInAs.prototype.getLapText = function (isForSure) {
    var returnValue = '';
    if (isForSure) {
        returnValue = 'Weet je zeker dat je de schrijfrechten wilt inschakelen voor dit account?\n\nLet op! ';
        returnValue += 'Acties en veranderingen (zoals bekeken woningen) worden echt opgeslagen en zijn dus zichtbaar voor de mijn funda gebruiker! ';
        returnValue += 'Deze functionaliteit is alleen toegestaan in opdracht van de eigenaar van het “Mijn funda” account. ';
        returnValue += 'In het geval een verzoek tot wijziging niet afkomstig is van de eigenaar zelf (maar bijv. van de makelaar) ';
        returnValue += 'dan altijd checken of hij/zij hiervoor toestemming heeft van de eigenaar.\n\nBij twijfel altijd eerst overleggen met je manager!';
    } else {
        returnValue = 'Wil je de schrijfrechten uitschakelen voor dit account?\n\nActies en veranderingen worden dan niet meer opgeslagen.';
    }
    return returnValue;
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new UserMyLoggedInAs(element);
});
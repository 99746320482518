/* global google */

import reduxStore from '../store/store';
import Connect from '../store/connect';
import { hashToList } from '../store/utils/data-structures';
import { setMediaViewerHash, addVoorzieningenMarkers } from '../store/actions';
import { default as gaLog, EVENT_TYPE } from '../media-viewer-map/media-viewer-gtm-events';

const MIN_ZOOM_LEVEL_FEATURES = 13;

export default class FacilityMarkers {
    constructor(googleMaps, map, config, store) {
        this.googleMaps = googleMaps;
        this.map = map;
        this.config = config;
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.locations',
            'ui.selectedCategories'
        ]);

        this.voorzieningClickCount = 0;

        this.markerAnchorPoint = new google.maps.Point(30, 54);
        this.markerScaledSize = new google.maps.Size(60, 62);

        this.bindEvents();

        const state = this.store.getState();
        const locations = hashToList(state.entities.locations);
        this.updateLocationMarkers(locations);
    }

    bindEvents() {
        this
            .connect
            .subscribe(() => {
                const state = this.store.getState();
                const locations = hashToList(state.entities.locations);
                this.updateLocationMarkers(locations);
            });

        google.maps.event.addListener(this.map, 'zoom_changed', () => {
            const state = this.store.getState();
            const locations = hashToList(state.entities.locations);
            this.updateLocationMarkers(locations);
        });

        google.maps.event.addListener(this.map, 'maptypeid_changed', () => {
            const state = this.store.getState();
            const locations = hashToList(state.entities.locations);
            this.updateLocationMarkers(locations);
        });
    }

    getMapType() {
        return this.map.getMapTypeId();
    }

    updateLocationMarkers(locations) {
        const state = this.store.getState();
        const activeLocationId = state.ui.activeLocationId;
        const voorzieningen = state.entities.voorzieningen;
        const selectedCategories = state.ui.selectedCategories;
        const mapTypeRoadmap = this.getMapType() === 'roadmap';

        if (locations.length < 1) return;

        let markers = [];

        locations
            .filter(location => this.isFacility(voorzieningen, location))
            .forEach(location => {
                const isActiveLocation = activeLocationId === location.id;
                const voorzieningDetails = voorzieningen[location.id];
                const firstCategory = voorzieningDetails.categories[0];
                const hasAnyCategorySelected = selectedCategories.length > 0;
                const markerHasCategoryThatIsSelected = selectedCategories.some(el => voorzieningDetails.categories.indexOf(el) > -1);
                const visibleAtZoomLevel = this.map.getZoom() > MIN_ZOOM_LEVEL_FEATURES;
                const shouldBeVisible = visibleAtZoomLevel && hasAnyCategorySelected && markerHasCategoryThatIsSelected;
                const hasMarkerInStore = voorzieningDetails.marker !== null;

                if (hasMarkerInStore === false) {
                    const marker = this.createMarker(location, isActiveLocation, shouldBeVisible, firstCategory);

                    marker.id = location.id;

                    marker.addListener('click', () => {
                        gaLog.services(EVENT_TYPE.MARKER, location.label);

                        this.voorzieningClickCount++;
                        const newZIndex = google.maps.Marker.MAX_ZINDEX + this.voorzieningClickCount;
                        marker.setZIndex(newZIndex);

                        this.store.dispatch(setMediaViewerHash(`kaart/locatie/${marker.id}`));
                    });

                    markers.push(marker);
                } else {
                    voorzieningDetails.marker.setIcon(this.getMarkerIcon(firstCategory, isActiveLocation));

                    if (mapTypeRoadmap && (shouldBeVisible || isActiveLocation)) {
                        const map = voorzieningDetails.marker.getMap();

                        if (typeof map === 'undefined' || map === null) {
                            voorzieningDetails.marker.setMap(this.map);
                        }
                    } else {
                        voorzieningDetails.marker.setMap(null);
                    }
                }
            });

        this.store.dispatch(addVoorzieningenMarkers(markers));
    }

    isFacility(voorzieningen, location) {
        const voorzieningDetails = voorzieningen[location.id];
        return typeof voorzieningDetails !== 'undefined';
    }

    createMarkerConfigForGoogleMapsMarker(location, isActive, shouldBeVisible, firstCategory) {
        return {
            title: location.label,
            icon: this.getMarkerIcon(firstCategory, isActive),
            position: {
                lat: location.lat,
                lng: location.lng
            },
            shape: {
                coords: [8, 8, 52, 52],
                type: 'rect'
            },
            map: shouldBeVisible ? this.map : null
        };
    }

    createMarker(location, isActive, shouldBeVisible, firstCategory) {
        const config = this.createMarkerConfigForGoogleMapsMarker(location, isActive, shouldBeVisible, firstCategory);
        return new this.googleMaps.Marker(config);
    }

    getMarkerIcon(category, isActive) {
        return {
            url: this.getMarkerIconUrl(category, isActive),
            anchor: this.markerAnchorPoint,
            scaledSize: this.markerScaledSize
        };
    }

    getMarkerIconUrl(category, isActive) {
        let marker = 'marker-default';

        switch (category) {
            case 'education-1':
            case 'education-2':
            case 'education-3':
            case 'education-4':
            case 'education-5':
            case 'education-6':
            case 'education-7':
            case 'education-8':
            case 'education-9':
            case 'education-10':
            case 'education-11':
                marker = 'marker-education';
                break;
            case 'childcare-1':
            case 'childcare-2':
            case 'childcare-3':
                marker = 'marker-childcare';
                break;
            case 'shopping-1':
                marker = 'marker-shopping';
                break;
            case 'transport-1':
                marker = 'marker-transport-train';
                break;
            case 'transport-2':
                marker = 'marker-transport-bus';
                break;
            case 'transport-3':
                marker = 'marker-transport-tram';
                break;
            case 'transport-4':
                marker = 'marker-transport-metro';
                break;
            case 'transport-8':
                marker = 'marker-transport-ferry';
                break;
        }

        if (isActive) {
            return `${this.config.baseUrlAssets}/assets/components/markers/${marker}-active.png`;
        } else {
            return `${this.config.baseUrlAssets}/assets/components/markers/${marker}.png`;
        }
    }
}

// require this module where needed, either in a specific page or component or generically in src/funda.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// component configuration
var COMPONENT_ATTR = 'data-image-error-fallback';
var COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';

// what does this module expose?
export default ImageErrorFallback;

function ImageErrorFallback(element, event) {
    var component = this;
    component.$element = $(element);
    component.fallbackUrl = element.getAttribute(COMPONENT_ATTR);

    if (component.$element.is('IMG')) {
        if (event.type === 'load') {
            component.checkImage(element);
        } else {
            element.onerror = function() {
                element.onerror = null;
                component.applyFallback(element);
            };
        }
    } else {
        component.$element.find('img').each(function(index, image) {
            component.checkImage(image);

            image.onerror = function() {
                image.onerror = null;
                component.applyFallback(image);
            };
        });
    }
}

/**
 * Check image and apply fallback if not OK
 * @param image
 */
ImageErrorFallback.prototype.checkImage = function(image) {
    if (!this.imageIsOk(image)) {
        this.applyFallback(image);
    }
};

/**
 * Set image attributes to fallback
 * @param image
 */
ImageErrorFallback.prototype.applyFallback = function(image) {
    var parent = $(image).parent();

    image.srcset = '';
    image.src = this.fallbackUrl;

    if (parent.hasClass('is-backgroundcover')) {
        parent.css('background-image', 'url(' + this.fallbackUrl + ')');
    }
};

/**
 * Fuzzy fluffy image check for images.
 * http://stackoverflow.com/questions/1977871/check-if-an-image-is-loaded-no-errors-in-javascript
 * https://stereochro.me/ideas/detecting-broken-images-js
 *
 * @param image {element} - image html element
 * @return {boolean}
 */
ImageErrorFallback.prototype.imageIsOk = function(image) {
    // this indicates that a srcset operation is currently deciding what image to load, which can lead
    // to a false positive for the error fallback. Therefore in this case we will assume the image is OK.
    if (image.currentSrc === '' && image.hasAttribute('srcset')) {
        return true;
    }
    // During the onload event, IE correctly identifies any images that
    // weren’t downloaded as not complete. Others should too. Gecko-based
    // browsers act like NS4 in that they report this incorrectly.
    if (image.complete === false) {
        return false;
    }
    // However, they do have two very useful properties: naturalWidth and
    // naturalHeight. These give the true size of the image. If it failed
    // to load, either of these should be zero.
    if (image.naturalWidth === 0) {
        return false;
    }
    // No other way of checking: assume it’s ok.
    return true;
};

$(window).on('load resultsUpdated', function(event) {
    $(COMPONENT_SELECTOR).each(function(index, element) {
        return new ImageErrorFallback(element, event);
    });
});
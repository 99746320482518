// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default SearchElementInViewport;

const COMPONENT_POSITION_TOP = 0.4; // 40% height of viewport

function SearchElementInViewport(element, defaultTopPosition) {
    const component = this;
    component.element = element;
    component.$element = $(element);
    component.defaultTop = defaultTopPosition;
}

/**
 * Update vertical position to make component visible in the viewport
 */
SearchElementInViewport.prototype.updateVerticalPosition = function() {
    const component = this;
    component.$element.removeAttr('style');

    if (isScrollPositionAtTopOfPage()) {
        // place at top
        component.$element.css({'margin-top': component.defaultTop});
    } else {
        // center in viewport
        // offsets are relative to the current viewport
        const currentOffsetTop = component.element.getBoundingClientRect().top;
        const newOffsetTop = window.innerHeight * COMPONENT_POSITION_TOP;
        const marginTop = newOffsetTop - currentOffsetTop;

        component.$element.css({'margin-top': marginTop + 'px'});
    }
};

/**
 * Check if scroll position of document is at the top
 */
function isScrollPositionAtTopOfPage() {
    return document.documentElement.getBoundingClientRect().top === 0;
}
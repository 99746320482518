// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

import Expandible from '../expandible/expandible';

// what does this module expose?
export default makelaarsFeatures;

// component configuration
var COMPONENT_SELECTOR = '[data-makelaars-features]';
var MIN_FEATURES = 6; // 2 * groups of dt and dd

function makelaarsFeatures(element) {
    var component = {};
    var $element = $(element);
    var numberOfChildren = $element.children().length;
    if (numberOfChildren > MIN_FEATURES) {
        $element.attr('data-expandible', 'makelaars-features');
        component.expandible = new Expandible(element);
    }
    return component;
}

makelaarsFeatures($(COMPONENT_SELECTOR)[0]);
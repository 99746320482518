// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import ThousandSeparator from '../thousand-separator/thousand-separator';

// what does this module expose?
export default ConditionalRangeFilter;

// component configuration
const DATA_ATTRIBUTE_SELECTOR_DEFAULT_VALUE = 'data-range-filter-selector-default-value';
const DATA_ATTRIBUTE_PREFIX_DATA_VAN = 'data-van';
const DATA_ATTRIBUTE_PREFIX_DATA_TOT = 'data-tot';

const PLACEHOLDER_VALUE_IGNORE_FILTER = 'ignore_filter';
const PLACEHOLDER_VALUE_CUSTOM_VALUE_FILTER = 'other';

const DISPLAY_TEMPLATE_TOKEN = '{token}';
const COMPONENT_SELECTOR = '[data-conditional-range-filter]';
const THOUSAND_SEPARATOR = '.';
const DISPLAY_TEMPLATE = '€ {token}';
const SPLIT_SYMBOL = ':';

function ConditionalRangeFilter(element) {
    const component = this;
    component.$element = $(element);
    component.vanRangeSelectorConfiguration = component.createRangeSelectorConditionalConfiguration(component.createElementSelector(element.id, 'min'), DATA_ATTRIBUTE_PREFIX_DATA_VAN);
    component.totRangeSelectorConfiguration = component.createRangeSelectorConditionalConfiguration(component.createElementSelector(element.id, 'max'), DATA_ATTRIBUTE_PREFIX_DATA_TOT);
    component.bindEvents();
}

ConditionalRangeFilter.prototype.bindEvents = function() {
    const component = this;
    component.$element.on('change', event => component.updateTargetedRangeSelectors(event.target || event.srcElement, component));
};

ConditionalRangeFilter.prototype.addOptions = (selector, newOptions) => newOptions.forEach(optionToKeep => selector.add(optionToKeep));
ConditionalRangeFilter.prototype.isValueToKeep = (option, valuesToKeep) => valuesToKeep.filter(valueToKeep => option.value === valueToKeep).length > 0;
ConditionalRangeFilter.prototype.clearAllOptions = (selector) => [].slice.call(selector.options).forEach(() => selector.remove(0));
ConditionalRangeFilter.prototype.createFormattedValue = (displayTemplate, thousandSeparator, token, value) => displayTemplate.replace(token, ThousandSeparator.format(value, thousandSeparator));
ConditionalRangeFilter.prototype.selectMatchingOption = (optionsToKeep, filter) => optionsToKeep.filter(optionToKeep => filter(optionToKeep.value)).shift();
ConditionalRangeFilter.prototype.createElementSelector = (id, postfix) => '[data-conditie-filter-target="' + id + '"][data-range-filter-' + postfix + '] [data-range-filter-selector-preset-select]';
ConditionalRangeFilter.prototype.getIndexForMatchingOption = (options, filter) => options.reduce((carry, option, index) => filter(option) ? index : carry, undefined);
ConditionalRangeFilter.prototype.addAsLastOptionIfNonEmpty = (ignoreFilterOption, newVanRangeSelectorOptions) => ignoreFilterOption && newVanRangeSelectorOptions.push(ignoreFilterOption);
ConditionalRangeFilter.prototype.splitToListOfTrimmedValues = (datastring, splitSymbol) => datastring.split(splitSymbol).map(value => value.trim());
ConditionalRangeFilter.prototype.isPlaceholderValueForCustomValue = (value) => value === PLACEHOLDER_VALUE_CUSTOM_VALUE_FILTER;
ConditionalRangeFilter.prototype.isPlaceholderValueForNoMaximumValue = (value) => value === PLACEHOLDER_VALUE_IGNORE_FILTER;
ConditionalRangeFilter.prototype.hasPlaceholderValueForDefaultRangeSelectorValue = (option) => option.getAttribute(DATA_ATTRIBUTE_SELECTOR_DEFAULT_VALUE) === '';
ConditionalRangeFilter.prototype.addRangeFilterSelectorDefaultValueAttributeToOption = (option) => option.setAttribute(DATA_ATTRIBUTE_SELECTOR_DEFAULT_VALUE, '');

ConditionalRangeFilter.prototype.createRangeSelectorConditionalConfiguration = (selectorTargetRangeSelector, dataAttributePrefix) => {
    return {
        attributeNameDefaultValue: dataAttributePrefix + '-default-value',
        attributeNameNewRangeValue: dataAttributePrefix + '-range',
        selectorTargetRangeSelector: selectorTargetRangeSelector,
        attributeNameOptionValuesToKeep: dataAttributePrefix + '-options-to-keep',
        thousandSeparator: THOUSAND_SEPARATOR,
        displayTemplate: DISPLAY_TEMPLATE,
        splitSymbol: SPLIT_SYMBOL,
        token: DISPLAY_TEMPLATE_TOKEN
    };
};

ConditionalRangeFilter.prototype.updateTargetedRangeSelectors = function(conditionalSelector, component) {
    const selectedCondition = conditionalSelector.options[conditionalSelector.selectedIndex];

    component.updateTargetedRangeSelectorForConfigurationAndCondition(component.vanRangeSelectorConfiguration, selectedCondition);
    component.updateTargetedRangeSelectorForConfigurationAndCondition(component.totRangeSelectorConfiguration, selectedCondition);

    $(component.vanRangeSelectorConfiguration.selectorTargetRangeSelector).trigger('reset');
    $(component.totRangeSelectorConfiguration.selectorTargetRangeSelector).trigger('reset');
    $(component.totRangeSelectorConfiguration.selectorTargetRangeSelector).trigger('change');
};

ConditionalRangeFilter.prototype.updateTargetedRangeSelectorForConfigurationAndCondition = function(configuration, selectedCondition) {
    const component = this;
    const targetRangeSelector = document.querySelector(configuration.selectorTargetRangeSelector);
    const defaultSelectedValue = selectedCondition.getAttribute(configuration.attributeNameDefaultValue);

    const newVanRangeSelectorOptions = component.createOptionsForRangeSelector(
        component.splitToListOfTrimmedValues(selectedCondition.getAttribute(configuration.attributeNameNewRangeValue), configuration.splitSymbol),
        component.splitToListOfTrimmedValues(selectedCondition.getAttribute(configuration.attributeNameOptionValuesToKeep), configuration.splitSymbol),
        targetRangeSelector,
        defaultSelectedValue,
        value => component.createFormattedValue(configuration.displayTemplate, configuration.thousandSeparator, configuration.token, value));

    component.updateRangeSelectorWithOptions(
        targetRangeSelector,
        newVanRangeSelectorOptions);
};

ConditionalRangeFilter.prototype.createOptionsForRangeSelector = function(newRangeSelectorValues, rangeSelectorValuesToKeep, targetRangeSelector, newRangeSelectorDefaultSelectedValue, formatValue) {
    const component = this;
    const optionsToKeep = [].slice.call(targetRangeSelector.options).filter(option => component.isValueToKeep(option, rangeSelectorValuesToKeep));
    const otherOption = optionsToKeep.filter(optionToKeep => component.isPlaceholderValueForCustomValue(optionToKeep.value)).shift();
    const newVanRangeSelectorOptions = newRangeSelectorValues.map(newOptionValue => new Option(formatValue(newOptionValue), newOptionValue));
    newVanRangeSelectorOptions.unshift(otherOption);

    const ignoreFilterOption = component.selectMatchingOption(optionsToKeep, component.isPlaceholderValueForNoMaximumValue);
    component.addAsLastOptionIfNonEmpty(ignoreFilterOption, newVanRangeSelectorOptions);

    const selectedOption = newVanRangeSelectorOptions.filter(newOption => newOption.value === newRangeSelectorDefaultSelectedValue).shift();
    component.addRangeFilterSelectorDefaultValueAttributeToOption(selectedOption);

    return newVanRangeSelectorOptions;
};

ConditionalRangeFilter.prototype.syncSelectedIndexWithFlaggedDefaultOption = function(selector) {
    const component = this;
    selector.selectedIndex = component.getIndexForMatchingOption([].slice.call(selector.options), component.hasPlaceholderValueForDefaultRangeSelectorValue);
};

ConditionalRangeFilter.prototype.updateRangeSelectorWithOptions = function(targetRangeSelector, newRangeSelectorOptions) {
    const component = this;

    component.clearAllOptions(targetRangeSelector);
    component.addOptions(targetRangeSelector, newRangeSelectorOptions);
    component.syncSelectedIndexWithFlaggedDefaultOption(targetRangeSelector);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new ConditionalRangeFilter(element));
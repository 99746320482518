// dependencies (alphabetically)
import $ from 'jquery';
import Observable from '../class-observable/class-observable';
import controllerService from '../service-controller/service-controller';

export default Expandible;

// component configuration
const COMPONENT_ATTR = 'data-expandible';
const COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';
const HANDLE_ATTR = 'data-expandible-handle';
const HANDLE_SELECTOR = '[' + HANDLE_ATTR + ']';
const ENHANCED_CLASS = 'is-expandible';
const EXPANDED_CLASS = 'is-expanded';
const ON_BLUR_ATTR = 'data-expandible-on-blur';
const $body = $('body');
const OPENED_EVENT = 'expandibleopened';
const CLOSED_EVENT = 'expandibleclosed';
const TOGGLE_EVENT = 'expandibletoggle';
const PREVENT_DEFAULT_ATTR = 'data-prevent-default';

function Expandible(element) {
    // define relevant dom elements and initial value
    this.$element = $(element);
    this.expandibleId = this.$element.attr(COMPONENT_ATTR) || false;
    this.expandibleOnBlur = this.$element.attr(ON_BLUR_ATTR) || false;
    this.isExpanded = this.$element.hasClass(EXPANDED_CLASS);
    this.$handlesOutside = $();
    this.hasNestedExpandible = this.$element.find(COMPONENT_SELECTOR).length > 0;
    this.hasHandleOutside = !!this.expandibleId;
    this.observers = new Observable();

    //To be called by the observers (creator functions usually)
    this.onChange = (observer) => {
        this.observers.listen(observer);
    };

    // if component has an ID, look for handles outside the component with that ID
    if (this.expandibleId) {
        this.$handlesOutside = $body.find('[' + HANDLE_ATTR + '="' + this.expandibleId + '"]');
    }

    if (this.expandibleOnBlur) {
        $body.on('click', (event) => {
            const targetIsHandle = this.$handlesOutside.is(event.target);
            const targetIsExpandible = this.$element.is(event.target);
            const targetIsInsideExpandible = $(event.target).closest(this.$element).length !== 0;

            if (this.isExpanded && !targetIsHandle && !targetIsExpandible && !targetIsInsideExpandible) {
                this.toggleExpand();
            }
        });
    }

    // enhance, set initial state, and toggle when handles are triggered
    this.$element.addClass(ENHANCED_CLASS);
    this.toggleExpand(this.isExpanded);

    // only toggle expand on click element if it doesn't have nested expandible components
    if (!this.hasNestedExpandible && !this.hasHandleOutside) {
        this.$element.on('click', HANDLE_SELECTOR, (event) => {
            const shouldPreventDefaultAction = $(event.target).attr(PREVENT_DEFAULT_ATTR) !== undefined;

            if (shouldPreventDefaultAction) {
                event.preventDefault();
            }

            this.toggleExpand();
        });
    }
    if (this.expandibleId) {
        $body.on('click', '[' + HANDLE_ATTR + '="' + this.expandibleId + '"]', (event) => {
            const shouldPreventDefaultAction = $(event.target).attr(PREVENT_DEFAULT_ATTR) !== undefined;

            if (shouldPreventDefaultAction) {
                event.preventDefault();
            }
            this.toggleExpand();
        });
    }

    this.$element.on(TOGGLE_EVENT, (e, isExpanded) => this.toggleExpand(isExpanded));
}

/**
 * Toggle (expand / collapse) the expand state by adding / removing the expanded class.
 * @param {Boolean} [isExpanded]    Set true to force component to expand (optional).
 * @returns {Boolean}               True if component is expanded.
 */
Expandible.prototype.toggleExpand = function (isExpanded) {
    this.isExpanded = (isExpanded !== undefined) ? isExpanded : !this.isExpanded;
    this.$element.toggleClass(EXPANDED_CLASS, this.isExpanded);
    this.$handlesOutside.toggleClass(EXPANDED_CLASS, this.isExpanded);
    this.$element.attr('aria-expanded', this.isExpanded);
    this.$handlesOutside.attr('aria-expanded', this.isExpanded);
    this.$element.trigger(this.isExpanded ? OPENED_EVENT : CLOSED_EVENT);
    this.observers.notify(this.isExpanded);
    return this.isExpanded;
};

Expandible.getSelector = () => COMPONENT_SELECTOR;
Expandible.initialize = () => controllerService.getAllInstances(Expandible);
Expandible.initialize();

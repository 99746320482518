import $ from 'jquery';

export default ObjectRatingForm;

var COMPONENT_SELECTOR = '[data-object-rating-form]';
var RESET_HANDLE_SELECTOR = '[data-object-rating-form-reset-handle]';
var RESET_ACTION_ATTR = 'data-object-rating-form-reset-action';
var OVERALL_RATING_SELECTOR = '[data-overall-object-rating]';
var RATING_SELECTOR = '[data-rating]';
var TEXTAREA_SELECTOR = '[data-object-rating-textarea]';
var RATED_CLASS = 'is-rated';

function ObjectRatingForm(element) {
    var component = this;
    component.element = element;
    component.$element = $(element);
    component.$overallRating = component.$element.find(OVERALL_RATING_SELECTOR);
    component.$overallRatingInputs = component.$overallRating.find('input');
    component.$resetHandle = component.$element.find(RESET_HANDLE_SELECTOR);
    component.$ratings = component.$element.find(RATING_SELECTOR);
    component.$inputs = component.$ratings.find('input');
    component.$textarea = component.$element.find(TEXTAREA_SELECTOR);
    //Watchers
    component.$resetHandle.on('click', () => component.resetRating());
}

ObjectRatingForm.prototype.resetRating = function () {
    var component = this;
    component.$inputs.removeClass(RATED_CLASS);
    var resetUrl = component.$element.attr(RESET_ACTION_ATTR);
    return $.ajax({
        method: 'POST',
        url: resetUrl
    });
};

$(COMPONENT_SELECTOR).each(function(index, element) {
    return new ObjectRatingForm(element);
});

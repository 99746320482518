/**
 * Funda Trending view
 */

import './../_base-view/base-view';

import './../../components/object-share-menu/object-share-menu';
import $ from 'jquery';

//refresh page component
const TRENDING_REFRESH_SELECTOR = '[data-content-trending-refresh-handle]';

$(TRENDING_REFRESH_SELECTOR).each((index, element) => {
    element.addEventListener('click', () => {
        window.location.reload();
    });
});

// minutes spent on page component
const TRENDING_TIMER_SELECTOR = '[data-content-trending-timer]';
const TRENDING_TIMER_MINUTES_SELECTOR = '[data-content-trending-timer-minutes]';

class TrendingTimer {
    constructor(element) {
        this.minutesElement = element.querySelector(TRENDING_TIMER_MINUTES_SELECTOR);

        this.minutesSpent = 0;

        this.setMinuteCount(this.minutesSpent);

        window.setInterval(() => {
            this.setMinuteCount(++this.minutesSpent);
        }, 60000); // 1 minute
    }

    setMinuteCount(count) {
        this.minutesElement.innerHTML = `${count}`;
    }
}

$(TRENDING_TIMER_SELECTOR).each((index, element) => new TrendingTimer(element));

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default MakelaarAds;

// component configuration
const COMPONENT_SELECTOR = '[data-makelaar-ads]';
const ITEM_SELECTOR = '[data-makelaar-ads-item]';
const ITEM_IMAGE_SELECTOR = '[data-makelaar-ads-logo]';
const ITEM_TITLE_SELECTOR = '[data-makelaar-ads-title]';
const ITEM_BODY_LINE1_SELECTOR = '[data-makelaar-ads-line1]';
const ITEM_BODY_LINE2_SELECTOR = '[data-makelaar-ads-line2]';
const ITEM_WEBSITE_SELECTOR = '[data-makelaar-ads-website]';
const MAKELAARADS_FEED_URL = 'data-makelaars-ads-feed-url';
const MAKELAARADS_DEFAULT_IMAGE_URL = 'data-makelaars-ads-default-image-url';
const MAKELAARADS_CLICK_URL = 'data-makelaars-ads-click-url';
const ITEM_HEADER_SELECTOR = '[makelaar-ads-header]';
const MAKELAARADS_ASYNC_URL_SELECTOR = '[data-makelaarads-feed-async]';
const EVENT_NAMESPACE_MAKELAARAD = '.makelaar-ads-item';

function MakelaarAds(element, baseUrl, defaultImageUrl) {
    const component = this;
    component.urlClick = element.getAttribute(MAKELAARADS_CLICK_URL);
    component.$element = $(element);
    component.$items = component.$element.find(ITEM_SELECTOR);
    component.ads = []; // init we have 0 ads
    component.defaultImageUrl = defaultImageUrl;

    component.resetAds();
    component.loadAds(component.visibleItems(), baseUrl);
    component.handleClickEvent();
}

MakelaarAds.prototype.resetAds = function() {

    const component = this;
    const items = component.$items;

    for (let index = 0; index < items.length; index++) {
        let item = items[index];
        $(item).find(ITEM_TITLE_SELECTOR).empty();
        $(item).find(ITEM_IMAGE_SELECTOR).attr('src', '');
        $(item).find(ITEM_BODY_LINE1_SELECTOR).empty();
        $(item).find(ITEM_BODY_LINE2_SELECTOR).empty();
        $(item).find(ITEM_WEBSITE_SELECTOR).html('');
        $(item).attr('href', null);
        $(item).attr('class', 'makelaar-ads-item');
        $(item).attr('data-globalId', '');
        $(item).find(ITEM_IMAGE_SELECTOR).remove();
    }

    component.$element.addClass('reset-ads');
};

MakelaarAds.prototype.visibleItems = function() {
    const component = this;
    return component.$items.filter(':visible');
};

MakelaarAds.prototype.loadAds = function(visibleAds, baseUrl) {
    const component = this;
    const adsToRequest = visibleAds.length;
    const feedUrl = baseUrl + '&count=' + adsToRequest;
    component.loadAdsAjax(feedUrl);
};

MakelaarAds.prototype.loadAdsAjax = function(feedUrl) {
    const component = this;
    $.get({
        url: feedUrl,
        crossDomain: true,
        dataType: 'json',
        success: function(data) {
            component.setAds(data.MakelaarAds);
            component.togglePromoAdForMakelaarIfSpaceAvailable();
        }
    });
};

MakelaarAds.prototype.updateObjects = function(data) {
    return data;
};

MakelaarAds.prototype.setAds = function(ads) {
    const component = this;

    for (let index = 0; index < ads.length; index++) {
        let slot = component.getAvailableSlot();
        let mad = ads[index];

        //If the ad is not beeing displayed yet, render it
        if (component.isAdAlreadyBeingDisplayed(mad) === false) {
            this.renderAd(slot, mad);
        }

    }

    if (ads.length > 0) {
        component.$element.removeClass('reset-ads');
        component.$element.addClass('injected');
    }

    $.each(ads, function() {
        component.ads.push(this);
    }, this);
};

MakelaarAds.prototype.togglePromoAdForMakelaarIfSpaceAvailable = function() {
    const component = this;
    //If we have any ads set header and add border to each ad
    if (component.ads.length > 0) {
        component.$element.find(ITEM_HEADER_SELECTOR).css('max-height', 'inherit');
        component.$element.find(ITEM_HEADER_SELECTOR).show();
        // If there's only 1 ad but we have 2 or 3 avaiable slots, we show the ad and on the 2nd slot we show the ad promotion for makelaars
        if (component.ads.length === 1 && component.visibleItems().length > 1) {
            const promoAd = {
                GlobalId: 0,
                Titel: 'Bent u ook een makelaar?',
                TekstRegels: ['Adverteer hier met uw kantoor in', 'uw werkgebied'],
                LinkText: 'Klik voor meer informatie',
                ImageUrl: component.defaultImageUrl,
                Href: '/content/default.aspx?pagina=/nl/algemene-teksten-funda-sites/fundanl/verkoop/makelaarads'
            };
            const slot = component.getAvailableSlot();
            component.renderAd(slot, promoAd);
        }
    }
};

MakelaarAds.prototype.isAdAlreadyBeingDisplayed = function(ad) {
    const component = this;
    const displayedAds = component.visibleItems();

    for (let index = 0; index < displayedAds.length; index++) {
        let slot = displayedAds[index];
        if ($(slot).attr('data-globalId') === ad.GlobalId) {
            return true;
        }

    }
    return false;
};

MakelaarAds.prototype.getAvailableSlot = function() {
    const component = this;
    const slots = component.visibleItems();

    for (let index = 0; index < slots.length; index++) {
        let slot = slots[index];
        if (slot.getAttribute('data-globalId') === '') {
            return slot;
        }
    }
};

MakelaarAds.prototype.renderAd = function(slot, ad) {
    const component = this;
    const imageUrl = ad.ImageUrl || component.defaultImageUrl;

    $(slot).find('.makelaar-ads-image-wrap').prepend($('<img data-makelaar-ads-logo class="makelaar-ads-image" src="' + imageUrl + '">'));
    $(slot).attr('data-globalId', ad.GlobalId);
    $(slot).find(ITEM_TITLE_SELECTOR).text(ad.Titel);
    $(slot).find(ITEM_BODY_LINE1_SELECTOR).text(ad.TekstRegels[0]);
    $(slot).find(ITEM_BODY_LINE2_SELECTOR).text(ad.TekstRegels[1]);
    $(slot).find(ITEM_WEBSITE_SELECTOR).text(ad.LinkText);
    $(slot).addClass('loaded');
    $(slot).attr('href', ad.Href);
    $(slot).css('max-height', 'inherit');
    $(slot)
        .attr('data-index', ad.Index)
        .attr('data-geogebiednaam', ad.GeoGebiedNaam)
        .attr('data-geogebiedniveau', ad.GeoGebiedNiveau)
        .attr('data-makelaarid', ad.MakelaarId);
};

MakelaarAds.prototype.handleClickEvent = function() {
    const component = this;
    component.unregisterClickEvent('click', EVENT_NAMESPACE_MAKELAARAD);
    component.handleElementOnClick();
};

MakelaarAds.prototype.handleElementOnClick = function() {
    const component = this;
    component.$items.on('click', function() {
        const clickedAd = $(this);
        component.handleClick(clickedAd);
    });
};

MakelaarAds.prototype.handleClick = function(clickedAd) {
    const component = this;
    component.registerClick(clickedAd);
};

MakelaarAds.prototype.unregisterClickEvent = function(eventName) {
    const component = this;
    component.$items.off(eventName);
};

MakelaarAds.prototype.registerClick = function(clickedAd) {
    const component = this;
    const paramArray = {
        makelaarAdId: clickedAd.attr('data-globalId'),
        makelaarAdPosition: clickedAd.attr('data-index')-1, //This is retarded for impressions the ad position has 0 index based and for recording impressions is 1 index based.
        geoGebiedNaam: clickedAd.attr('data-geogebiednaam'),
        geoGebiedNiveau: clickedAd.attr('data-geogebiedniveau'),
        makelaarId: clickedAd.attr('data-makelaarid')
    };
    console.log('idd', paramArray.makelaarAdId);
    $.ajax({
        url: component.urlClick,
        type: 'POST',
        data: paramArray
    });
};

MakelaarAds.prototype.setCurrentUrl = function(value) {
    return $(COMPONENT_SELECTOR).attr(MAKELAARADS_FEED_URL, value);
};

MakelaarAds.prototype.getCurrentUrl = function() {
    return $(COMPONENT_SELECTOR).attr(MAKELAARADS_FEED_URL);
};

MakelaarAds.prototype.getAsyncUrl = function() {
    return $(MAKELAARADS_ASYNC_URL_SELECTOR).attr('data-makelaarads-feed-async-url');
};

MakelaarAds.prototype.handleMakelaarAdsOnResultsUpdated = function() {
    let makelaarAdsAsyncUrl = MakelaarAds.prototype.getAsyncUrl();
    let makelaarAdsUrl = MakelaarAds.prototype.getCurrentUrl();

    // check if the url is updated
    if (makelaarAdsAsyncUrl != makelaarAdsUrl) {
        MakelaarAds.prototype.createMakelaarAds(makelaarAdsAsyncUrl);
    }

    MakelaarAds.prototype.setCurrentUrl(makelaarAdsAsyncUrl);
};

MakelaarAds.prototype.createMakelaarAds = function(MakelaarAdsAsyncUrl) {
    $(COMPONENT_SELECTOR).each((index, element) => new MakelaarAds(element, MakelaarAdsAsyncUrl, element.getAttribute(MAKELAARADS_DEFAULT_IMAGE_URL)));
};

MakelaarAds.initialize = function() {
    // automatically turn all elements with the default selector into components
    $(COMPONENT_SELECTOR).each((index, element) => new MakelaarAds(element, element.getAttribute(MAKELAARADS_FEED_URL), element.getAttribute(MAKELAARADS_DEFAULT_IMAGE_URL)));

    $(document).on('resultsUpdated', function() {
        MakelaarAds.prototype.handleMakelaarAdsOnResultsUpdated();
    });
};

MakelaarAds.initialize();
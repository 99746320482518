import $ from 'jquery';
import URLparser from 'url-parse';
import objectAssign from 'object-assign';

const COMPONENT_ATTRIBUTE = 'data-object-url-tracking';
const COMPONENT_SELECTOR = `[${COMPONENT_ATTRIBUTE}]`;


export default function ObjectUrlTracking(element) {
    const component = this;
    component.$element = $(element);
    if (component.$element.attr('href')) {
        component.url = new URLparser(component.$element.attr('href'), true);
        component.url.set('query', objectAssign({}, component.url.query, {
            'navigateSource': component.$element.attr(COMPONENT_ATTRIBUTE)
        }));
        component.$element.attr('href', component.url.href);
    }
}


$(document).on('resultsUpdated', function() {
    ObjectUrlTracking.initialize();
});

ObjectUrlTracking.initialize = function () {
    $(COMPONENT_SELECTOR).each((index, element) => new ObjectUrlTracking(element));
};

ObjectUrlTracking.initialize();



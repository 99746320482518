// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default SoortNotificationCookie;

// component configuration
const COMPONENT_SELECTOR = '[data-soort-notification-cookie]';
const HANDLE_SELECTOR = '[data-soort-notification-cookie-close]';
const VERSION_ATTR = 'data-soort-notification-cookie-version';
const SOORTAANBOD_ATTR = 'data-soort-notification-cookie-soortaanbod';
const COOKIE_PREFIX = 'notificationAcknowledged_sa';
const ACKNOWLEDGED_CLASS = 'is-acknowledged';

function SoortNotificationCookie(element) {
    this.$element = $(element);
    this.version = element.getAttribute(VERSION_ATTR);
    this.soortAanbod = element.getAttribute(SOORTAANBOD_ATTR);
    this.cookieName = COOKIE_PREFIX + '_' + this.version;
    this.$closeButton = this.$element.find(HANDLE_SELECTOR);
    this.bindEventListeners();
}

SoortNotificationCookie.prototype.bindEventListeners = function () {
    this.$closeButton.on('click', e => this.clickHandler(e));
};

SoortNotificationCookie.prototype.clickHandler = function (e) {
    e.preventDefault();
    this.acknowledgeNotification();
    $(document).find(COMPONENT_SELECTOR).toArray().forEach(c => $(c).addClass(ACKNOWLEDGED_CLASS));
};

SoortNotificationCookie.prototype.acknowledgeNotification = function () {
    this.setCookie();
};
/**
 * sets cookie and return the cookie value
 * @returns {string}
 */
SoortNotificationCookie.prototype.setCookie = function () {
    var component = this;
    var date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    var cookie = component.cookieName + '=' + component.version + '; expires=' + date.toGMTString() + '; path= /';
    document.cookie = cookie;
    return cookie;
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function (index, element) {
    return new SoortNotificationCookie(element);
});

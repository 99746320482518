export const SET_MEDIA_VIEWER_HASH = 'set_media_viewer_hash';
export const SET_MEDIA_VIEWER_ROUTE = 'set_media_viewer_route';

export const SET_MODALITY = 'set_modality';
export const CHANGE_DRAWER = 'change_drawer';
export const SET_LOGIN_STATE = 'set_login_state';

export const GET_PERSONAL_PLACES = 'get_personal_places';
export const GET_PERSONAL_PLACES_SUCCESS = 'get_personal_places_success';
export const GET_PERSONAL_PLACES_FAIL = 'get_personal_places_fail';

export const ADD_LOCATION = 'add_location';
export const DELETE_LOCATION = 'delete_location';
export const SET_ACTIVE_LOCATION = 'set_active_location';

export const ADD_PERSONAL_PLACE = 'add_personal_place';
export const ADD_PERSONAL_PLACE_SUCCESS = 'add_personal_place_success';
export const ADD_PERSONAL_PLACE_FAIL = 'add_personal_place_fail';

export const DELETE_PERSONAL_PLACE = 'delete_personal_place';
export const DELETE_PERSONAL_PLACE_SUCCESS = 'delete_personal_place_success';
export const DELETE_PERSONAL_PLACE_FAIL = 'delete_personal_place_fail';

export const GET_TRAVEL_TIMES = 'get_travel_times';
export const GET_TRAVEL_TIMES_SUCCESS = 'get_travel_times_success';
export const GET_TRAVEL_TIMES_FAIL = 'get_travel_times_fail';

export const GET_CATEGORIES = 'get_categories';
export const ENABLE_CATEGORY = 'enable_category';
export const DISABLE_CATEGORY = 'disable_category';
export const SET_CATEGORIES = 'set_categories';

export const GET_VOORZIENINGEN = 'get_voorzieningen';
export const GET_VOORZIENINGEN_SUCCESS = 'get_voorzieningen_success';
export const GET_VOORZIENINGEN_FAIL = 'get_voorzieningen_fail';

export const ADD_LOADED_TILE = 'add_loaded_tile';

export const ADD_VOORZIENINGEN_MARKERS = 'add_voorzieningen_markers';

export const EXPAND_ACCORDION_PANEL = 'expand_accordion_panel';
export const COLLAPSE_ACCORDION_PANEL = 'collapse_accordion_panel';


export const SET_GLOBAL_OBJECT_ID = 'set_global_object_id';

export const SET_POI_TRAVEL_DISTANCE = 'set_poi_travel_distance';

export const GET_HEADER_AS_PARTIAL_VIEW = 'get_header_as_partial_view';

export const SET_TRAVEL_TIME_POLYGON_DATA = 'set_travel_time_polygon_data';

export const UPDATE_POLYGON_MODALITY_AND_TRAVEL_TIME = 'update_polygon_modality_and_travel_time';

export const SHOW_LOADING_SPINNER = 'show_loading_spinner';

import '../../components/address-autocomplete/address-autocomplete';
import '../../components/accordion/accordion';

import $ from 'jquery';
export default VerkoopleadsAddress;

const COMPONENT_SELECTOR = '[data-nieuwe-huis-toevoegen]';
const SUBMIT_BUTTON = '[data-nieuwe-huis-toevoegen-button]';
const CUSTOM_VALIDATE_EVENT = 'addressvalidated';
const CUSTOM_VALIDATE_EVENT_AFTER = '-after';
const CUSTOM_VALIDATE_EVENT_BEFORE = '-before';

function VerkoopleadsAddress(element) {
    const component = this;
    component.$element = $(element);
    component.$submitButton = $(SUBMIT_BUTTON, component.$element);
    component.bindEvents();
}

VerkoopleadsAddress.prototype.bindEvents = function() {
    const component = this;

    $(window).on(CUSTOM_VALIDATE_EVENT + CUSTOM_VALIDATE_EVENT_AFTER, (event, isValid) => {
        if (isValid)
            component.enableSubmitButton();
        else
            component.disableSubmitButton();
    });

    $(window).on(CUSTOM_VALIDATE_EVENT + CUSTOM_VALIDATE_EVENT_BEFORE, () => {
        component.disableSubmitButton();
    });
};

VerkoopleadsAddress.prototype.enableSubmitButton = function() {
    const component = this;
    component.$submitButton.removeAttr('disabled');
};

VerkoopleadsAddress.prototype.disableSubmitButton = function() {
    const component = this;
    component.$submitButton.attr('disabled', 'disabled');
};

$(COMPONENT_SELECTOR).each((index, element) => new VerkoopleadsAddress(element));

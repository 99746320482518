import {
    ADD_LOCATION,
    DELETE_LOCATION,
    DELETE_PERSONAL_PLACE,
    GET_PERSONAL_PLACES_SUCCESS,
    GET_VOORZIENINGEN_SUCCESS
} from '../../constants/action-types';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import { listToHash } from '../../utils/data-structures';

const initialState = {};

export default (state = initialState, action = {}) => {

    switch (action.type) {
        case GET_PERSONAL_PLACES_SUCCESS:
            const locations = listToHash(action.result.personalplaces.map(personalPlace => ({
                id: personalPlace.id,
                lat: personalPlace.lat,
                lng: personalPlace.lng,
                label: personalPlace.label,
                location: personalPlace.location,
                personalPlace: personalPlace.id
            })), 'id');

            return merge({}, state, locations);

        case GET_VOORZIENINGEN_SUCCESS:
            const voorzieningen = listToHash(action.result.map(location => ({
                id: location.Type.toLowerCase() + '-' + location.Id,
                lat: location.Latitude,
                lng: location.Longitude,
                label: location.Name,
                location: location.Address,
                personalPlace: null
            })), 'id');

            return Object.assign({}, state, {
                ...state,
                ...voorzieningen
            });

        case ADD_LOCATION:
            return Object.assign({}, state, {
                [action.location.id]: {
                    id: action.location.id,
                    lat: action.location.lat,
                    lng: action.location.lng,
                    label: action.location.label,
                    location: action.location.location
                }
            });

        case DELETE_LOCATION:
            return omit(state, action.id);

        case DELETE_PERSONAL_PLACE:
            if (!action.id) {
                return state;
            }

            const locationForPersonalPlace =
                Object
                    .keys(state)
                    .map(id => state[id])
                    .reduce((accumulator, currentValue) =>
                        currentValue.personalPlace === action.id ?
                            currentValue : accumulator, null);

            const newLocation = Object.assign({}, locationForPersonalPlace, {
                personalPlace: null
            });

            return Object.assign({}, state, {
                [newLocation.id]: newLocation
            });

        default:
            return state;
    }
};

export const getByIds = (state, ids) =>
    ids
        .map(id => state[id])
        .filter(object => !!object);

// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

export default LogRequest;

// component configuration
var COMPONENT_ATTR = 'data-log-request';
var COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';

function LogRequest(element) {
    var component = this;
    component.$element = $(element);
    component.url = component.$element.attr(COMPONENT_ATTR);

    if (!component.url || component.url.length === 0) {
        return;
    }

    component.$element.on('click', function(event) {
        var clickedElement = $(event.target);
        if (!clickedElement.data('logged')) {
            $.ajax(component.url);
            clickedElement.data('logged', true);
        }
    });
}

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new LogRequest(element);
});
import $ from 'jquery';
import Carousel from '../carousel/carousel';
import UrlTracking from '../object-url-tracking/object-url-tracking';

export default RelatedObjects;

const COMPONENT_SELECTOR = '[data-related-objects]';
const SLIDE_SET_ATTRIBUTE = 'data-related-objects-set';
const URL_JSON_ATTRIBUTE = 'data-related-objects-json';
const CAROUSEL_SELECTOR = '[data-related-objects-carousel]';
const CAROUSEL_LIST_SELECTOR = '[data-carousel-list]';
const TOGGLE_SELECTOR = '[data-related-objects-toggle]';
const TOGGLE_BUTTON_ATTRIBUTE = 'data-related-objects-toggle-button';
const TOGGLE_BUTTON_SELECTOR = `[${TOGGLE_BUTTON_ATTRIBUTE}]`;
const TOGGLE_BUTTON_TEMPLATE_SELECTOR = '[data-related-objects-button-element]';
const COMPONENT_ENHANCED_CLASS = 'is-enhanced';
const COMPONENT_ENHANCING_CLASS = 'is-enhancing';

function RelatedObjects(element) {
    this.element = element;
    this.$element = $(element);
    this.$toggleContainer = this.$element.find(TOGGLE_SELECTOR);
    this.$slides = this.$element.find(CAROUSEL_LIST_SELECTOR);

    this.$element.addClass(COMPONENT_ENHANCING_CLASS);
    this.tryAsyncLoad();
}

/**
 * See if the component attribute has a value that indicates a set of slides
 * should be loaded (if not this component does nothing further)
 */
RelatedObjects.prototype.tryAsyncLoad = function() {
    const slidesToLoad = this.element.getAttribute(SLIDE_SET_ATTRIBUTE);

    if (slidesToLoad !== '') {
        this.getSlides(slidesToLoad)
            .then((data) => {
                this.slideData = data;
                this.initSlides(slidesToLoad);
            });
    }
};

/**
 * Get the slides
 * @returns {promise.<data[]>} the slide sets in an array
 */
RelatedObjects.prototype.getSlides = function() {
    const jsonUrl = this.element.getAttribute(URL_JSON_ATTRIBUTE);

    return $.get(jsonUrl).promise();
};

RelatedObjects.prototype.initSlides = function(slidesToLoad) {
    const slideSetIndex = parseInt(slidesToLoad, 10) - 1;
    const slides = this.slideData[slideSetIndex];

    if (typeof slides === 'object') {
        this.$element.toggleClass(`${COMPONENT_ENHANCING_CLASS} ${COMPONENT_ENHANCED_CLASS}`);
        this.setSlideHtml(slides.html);
        this.carousel = new Carousel(this.$element.find(CAROUSEL_SELECTOR)[0]);
    }

    if (this.slideData.length >= 2) {
        this.initButtons();
        this.updateToggleButtons(slideSetIndex);
        this.bindButtonEvent();
    }
};

RelatedObjects.prototype.initButtons = function() {
    const buttonTemplate = this.$element.find(TOGGLE_BUTTON_TEMPLATE_SELECTOR).html();

    this.slideData.forEach((element, index) => {
        $(buttonTemplate)
            .attr(TOGGLE_BUTTON_ATTRIBUTE, index + 1)
            .html(element.label)
            .appendTo(this.$toggleContainer);
    });

    this.$toggleButtons = this.$element.find(TOGGLE_BUTTON_SELECTOR);
};

/**
 * Set the toggle buttons' state to match the currently active data set
 */
RelatedObjects.prototype.updateToggleButtons = function(slideSetIndex) {
    this.$toggleButtons.each((index, buttonElement) => {
        if (slideSetIndex === index) {
            buttonElement.setAttribute('disabled', '');
        } else {
            buttonElement.removeAttribute('disabled');
        }
    });
};

RelatedObjects.prototype.bindButtonEvent = function() {
    this.$toggleButtons.on('click', (event) => {
        const newSlideSetIndex = (event.currentTarget.attributes.getNamedItem(TOGGLE_BUTTON_ATTRIBUTE).value) - 1;

        this.cycleSlideSet(newSlideSetIndex);
        this.updateToggleButtons(newSlideSetIndex);
    });
};

/**
 * Load a new slide set into the carousel
 * @param {number} slideSetIndex - 0-based index
 */
RelatedObjects.prototype.cycleSlideSet = function(slideSetIndex) {
    this.carousel.unslick();
    this.setSlideHtml(this.slideData[slideSetIndex].html);
    this.carousel.initSlick();
};

/**
 * Replaces the slides HTML with supplied HTML string
 * @param {string} data - HTML string
 */
RelatedObjects.prototype.setSlideHtml = function(data) {
    this.$slides.html(data);
    UrlTracking.initialize();
};

$(COMPONENT_SELECTOR).each((index, element) => new RelatedObjects(element));

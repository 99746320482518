import LocalStorageService from '../local-storage-service/local-storage-service';

const LOCAL_STORAGE_TEALIUM_KEY = 'tealium_va_funda_funda';

const localStorageKey = prefix => `${prefix}-traffic-driver-bar`;
const ACCEPTED = 'accepted';
const DECLINED = 'declined';
const DISMISSED = 'dismissed';

export default class TrafficDriverBarVisibilityService {
    constructor(options = {}) {
        this._localStorageService = options.localStorageService || new LocalStorageService();
        this._isLocalStorageSupported = this._localStorageService.isSupported();
        this.options = options;
    }

    isEnabled(type) {
        if (!this._isLocalStorageSupported) {
            return false;
        }

        const tealiumContents = this._localStorageService.getAsParsedJson(LOCAL_STORAGE_TEALIUM_KEY) || {};
        const currentUserAudiences = tealiumContents.audiences || {};
        const currentUserBadges = tealiumContents.badges || {};

        const audienceNames = Object
            .keys(currentUserAudiences)
            .map(key => currentUserAudiences[key]);

        const badgesIds = Object
            .keys(currentUserBadges);

        return {
            audiences: audienceNames.indexOf(this.options.audiences) > -1,
            badges: badgesIds.indexOf(this.options.badges) > -1,
        } [type];
    }

    _saveValue(value) {
        this._localStorageService.saveAsStringified(localStorageKey(this.options.id), value);
    }

    _getValue() {
        return this._localStorageService.getAsParsedJson(localStorageKey(this.options.id));
    }

    accept() {
        this._saveValue(ACCEPTED);
    }

    decline() {
        this._saveValue(DECLINED);
    }

    dismiss() {
        this._saveValue(DISMISSED);
    }

    isAccepted() {
        return this._getValue() === ACCEPTED;
    }

    isDeclined() {
        return this._getValue() === DECLINED;
    }

    isDismissed() {
        return this._getValue() === DISMISSED;
    }
}

/* globals fpViewer */
export default PlattegrondView;

import $ from 'jquery';
import load from 'load-script';
import Menu from '../media-viewer-menu/media-viewer-menu';
import AppSpinner from '../app-spinner/app-spinner';

const COMPONENT_SELECTOR = '[data-media-viewer-plattegrond]';
const PLATTEGROND_CONTAINER_CLASS = 'media-viewer-plattegrond-container';
const PLATTEGROND_CONTAINER_SELECTOR = '.' + PLATTEGROND_CONTAINER_CLASS;
const PLATTEGROND_CONTAINER_SRC_ATTR = 'data-plattegrond-src';
const PLATTEGROND_VIEW_SELECT_RADIO_SELECTOR = '[data-view-select]';
const PLATTEGROND_2D_VIEW_SELECT_RADIO_SELECTOR = '[data-view-select][value="2d"]';
const PLATTEGROND_2D_VIEW_SELECTOR = '[data-plattegrond-2d-view]';
const PLATTEGROND_3D_VIEW_SELECTOR = '[data-plattegrond-3d-view]';
const PLATTEGROND_SPINNER_VIEW_SELECTOR = '[data-plattegrond-spinner-view]';
const PLATTEGROND_SPINNER_SELECTOR = '[data-plattegrond-spinner]';
const PLATTEGROND_MENU_HEADER_SELECTOR = '[data-plattegrond-menu-header]';
const PLATTEGROND_MENU_FOOTER_SELECTOR = '[data-plattegrond-menu-footer]';
const PLATTEGROND_MENU_ITEM_TEMPLATE_SELECTOR = '[data-plattegrond-menu-item-template]';
const PLATTEGROND_ZOOM_IN_SELECTOR = '[data-plattegrond-zoom-in]';
const PLATTEGROND_ZOOM_OUT_SELECTOR = '[data-plattegrond-zoom-out]';
const BASE_URL_ATTR = 'data-base-url';
const MENU_ITEMS_SELECTOR = '[data-menu-items]';
const FLOORPLANNER_ACTIVE_CLASS = 'active';
const SHOW_CLASS = 'show';

function PlattegrondView(floorplanNumber) {
    const view = this;
    view.floorplanNumber = floorplanNumber;

    view.$container = $(PLATTEGROND_CONTAINER_SELECTOR);
    view.$2dView = view.$container.find(PLATTEGROND_2D_VIEW_SELECTOR);
    view.$3dView = view.$container.find(PLATTEGROND_3D_VIEW_SELECTOR);

    view.src = view.$container.attr(PLATTEGROND_CONTAINER_SRC_ATTR);
    view.baseUrl = view.$container.attr(BASE_URL_ATTR);

    view.$zoomIn = $(PLATTEGROND_ZOOM_IN_SELECTOR);
    view.$zoomOut = $(PLATTEGROND_ZOOM_OUT_SELECTOR);
    view.$viewTypeSelect = $(PLATTEGROND_VIEW_SELECT_RADIO_SELECTOR);

    view.menuItemTemplate = $(PLATTEGROND_MENU_ITEM_TEMPLATE_SELECTOR).html();

    view.viewType = '2d';
    // make sure 2d is the initial state of the radio select
    $(PLATTEGROND_2D_VIEW_SELECT_RADIO_SELECTOR).prop('checked', true);

    view.startSpinner();
    view.loadFloorplanner();

    view.bindEvents();
}

PlattegrondView.prototype.bindEvents = function() {
    const view = this;

    view.$zoomIn.on('click', () => fpViewer.zoomIn());
    view.$zoomOut.on('click', () => fpViewer.zoomOut());

    view.$viewTypeSelect.on('change', function() {
        view.viewType = (this.value === '2d') ? '2d' : '3d';
        view.display();
    });

    $(window).on('mediaviewermenuitemselected', function(e, data) {
        if (data.type && data.type === 'plattegrond') {
            view.designId = data.menuParam;
            view.display();
        }
    });
};

PlattegrondView.prototype.loadFloorplanner = function() {
    const view = this;

    const theme = {
        ui: {
            initView: '2d', // or '3d'
            rotate3d: true // automatically rotate the 3d view
        },
        engine: {
            wallSectionHeight: null,
            wallTopColor: 0x7F7F7F, // top colour of walls in 3D
            showGrid: false,
            showDims: true, // visibility of dimension lines (both auto and manual)
            engineAutoDims: false, // generate and show automatic dimensions
            showObjects: true,
            showLabels: true, // visibility for all labels, including room names and dims
            useMetric: true// false means Imperial system, affects dimensions
        }
    };

    load(view.baseUrl + '/assets/vendor-floorplanner.js', function(err) {
        if (err === null) {
            fpViewer.init({
                projectId: null,
                box2d: view.$2dView.get(0),
                box3d: view.$3dView.get(0),
                theme: theme,
                fmlUrl: view.src,
                callback: (project) => {
                    view.initView(project);
                },
                jquery: $
            });
        }
    });
};

PlattegrondView.prototype.initView = function(fpProject) {
    const view = this;

    view.floors = fpProject.floors;
    view.floorsOrdered = view.floors.slice().sort((a, b) => a.name.localeCompare(b.name)); // create an array with the same objects ordered by name

    if (typeof view.floorplanNumber !== 'undefined') {
        view.designId = view.floorsOrdered[view.floorplanNumber - 1].id;
    } else {
        const defaultFloor = view.floors[0];
        view.designId = defaultFloor.id;
    }

    view.setupMenu();
    view.display();
    view.endSpinner();
};

PlattegrondView.prototype.setupMenu = function() {
    const view = this;

    const $headerFloorSelect = $(PLATTEGROND_MENU_HEADER_SELECTOR);
    const $footerFloorSelect = $(PLATTEGROND_MENU_FOOTER_SELECTOR);

    const headerMenu = $headerFloorSelect.find(MENU_ITEMS_SELECTOR);
    const footerMenu = $footerFloorSelect.find(MENU_ITEMS_SELECTOR);

    for (let i = 0; i < view.floors.length; i++) {
        let currentFloor = view.floors[i];
        let menuItem = view.renderMenuItem({id: currentFloor.id, name: currentFloor.name});

        headerMenu.append(menuItem);
        footerMenu.append(menuItem);
    }

    view.menu = new Menu($headerFloorSelect, $footerFloorSelect);

    if (Object.keys(view.floors).length > 1) {
        $headerFloorSelect.addClass(SHOW_CLASS);
        $footerFloorSelect.addClass(SHOW_CLASS);
    }

    view.menu.transformHeader();
    view.menu.setActive(view.designId);
};

PlattegrondView.prototype.display = function() {
    const view = this;

    view.$2dView.removeClass(FLOORPLANNER_ACTIVE_CLASS);
    view.$3dView.removeClass(FLOORPLANNER_ACTIVE_CLASS);
    const floorToShow = view.floors.filter((floor) => floor.id === view.designId)[0];
    const designToShow = floorToShow.designs[0];

    if (view.viewType === '2d') {
        view.$2dView.addClass(FLOORPLANNER_ACTIVE_CLASS);
        fpViewer.display2D(designToShow);
    } else {
        view.$3dView.addClass(FLOORPLANNER_ACTIVE_CLASS);
        fpViewer.display3D(designToShow);
    }

    view.replaceStateForFloor(floorToShow);
};

PlattegrondView.prototype.replaceStateForFloor = function(floor) {
    const view = this;
    const defaultFloorUrlNumber = view.floorsOrdered.indexOf(floor) + 1;
    window.history.replaceState({}, '', '#plattegrond-' + defaultFloorUrlNumber);
};

PlattegrondView.prototype.renderMenuItem = function(item) {
    let html = this.menuItemTemplate;
    // replace placeholders in template by values from option data
    for (let prop in item) {
        if (item.hasOwnProperty(prop)) {
            let pattern = new RegExp('{' + prop + '}', 'g');
            html = html.replace(pattern, item[prop]);
        }
    }
    return html;
};

PlattegrondView.prototype.dispose = function() {
    const view = this;
    fpViewer.cleanup();

    const $headerFloorSelect = $(PLATTEGROND_MENU_HEADER_SELECTOR);
    const $footerFloorSelect = $(PLATTEGROND_MENU_FOOTER_SELECTOR);

    const headerMenu = $headerFloorSelect.find(MENU_ITEMS_SELECTOR);
    const footerMenu = $footerFloorSelect.find(MENU_ITEMS_SELECTOR);
    headerMenu.empty();
    footerMenu.empty();
    view.unBindEvents();

    if (view.menu) {
        view.menu.dispose();
    }
};

PlattegrondView.prototype.unBindEvents = function() {
    this.$zoomIn.off('click');
    this.$zoomOut.off('click');
    this.$viewTypeSelect.off('change');
    $(window).off('mediaviewermenuitemselected');
};

PlattegrondView.prototype.startSpinner = function() {
    this.$spinnerView = $(PLATTEGROND_SPINNER_VIEW_SELECTOR);
    this.spinner = new AppSpinner($(PLATTEGROND_SPINNER_SELECTOR).get(0));
    this.spinner.show();
};

PlattegrondView.prototype.endSpinner = function() {
    this.$spinnerView.removeClass(FLOORPLANNER_ACTIVE_CLASS);
    this.spinner.hide();
};

$(COMPONENT_SELECTOR).each(function(index, element) {
    const autoInitialize = element.getAttribute('data-media-viewer-auto-initialize');

    if (autoInitialize === 'true') {
        return new PlattegrondView(element);
    }
});

import $ from 'jquery';
import 'funda-slick-carousel';

export default Carousel;

const COMPONENT_SELECTOR = '[data-carousel]';
const ITEM_LIST_SELECTOR = '[data-carousel-list]';
const NEXT_SELECTOR = '[data-carousel-next]';
const ENHANCED_CLASS = 'is-enhanced';
const BP_MEDIUM = 750; // $bp-medium
const BP_LARGE = 1020; // $bp-large

function Carousel(element) {
    const component = this;

    component.$element = $(element);
    component.$items = component.$element.find(ITEM_LIST_SELECTOR);
    component.$nextButton = component.$element.find(NEXT_SELECTOR);

    component.slickOptions = {
        mobileFirst: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: component.$nextButton,
        prevArrow: false,
        responsive: [
            {
                breakpoint: BP_MEDIUM,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: BP_LARGE,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };

    component.initSlick();
    component.$items.addClass(ENHANCED_CLASS);
}

Carousel.prototype.initSlick = function() {
    this.$items.slick(this.slickOptions);
};

Carousel.prototype.unslick = function() {
    this.$items.slick('unslick');
};

$(COMPONENT_SELECTOR).each((index, element) => new Carousel(element));

import $ from 'jquery';

// constant definitions
const COMPONENT_SELECTOR = '[data-sidebar-info]';
const COMPONENT_TRIGGER_ATTR = 'sidebar-info-trigger';
const CONTENT_SELECTOR = '.info-message';
const FLOATING_CONTENT_CLASS = 'info-message-floating';
const IS_HIDDEN_CLASS = 'is-hidden';


export default class SidebarInfo {
    constructor(element) {
        const component = this;
        component.$element = $(element);
        component.content = component.$element.find(CONTENT_SELECTOR).html();
        component.$triggerElement = $(component.$element.attr(COMPONENT_TRIGGER_ATTR));
        component.$floater = component.makeFloatingInfo();

        component.isVisible = false;

        component.bindEvents();
    }

    makeFloatingInfo() {
        const component = this;

        if (component.$triggerElement.length === 0) return;

        const $floaterElement = $(`<div class="${FLOATING_CONTENT_CLASS} ${IS_HIDDEN_CLASS}">${component.content}</div>`);
        component.$triggerElement.append($floaterElement);
        // set parent to display: relative because floater is positioned absolutely
        component.$triggerElement.css({position: 'relative'});
        return $floaterElement;
    }

    bindEvents() {
        const component = this;

        $(window).on('click', () => {
            component.toggleInfo(false);
        });

        component.$triggerElement.on('click', event => {
            event.preventDefault();
            event.stopPropagation();
            component.toggleInfo();
        });
    }

    toggleInfo(state) {
        const component = this;
        const wantedState = state === undefined ? !component.isVisible: state;
        if (wantedState) {
            component.$element.removeClass(IS_HIDDEN_CLASS);
            component.$floater.removeClass(IS_HIDDEN_CLASS);
            component.isVisible = true;
        } else {
            component.$element.addClass(IS_HIDDEN_CLASS);
            component.$floater.addClass(IS_HIDDEN_CLASS);
            component.isVisible = false;
        }
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new SidebarInfo(element));

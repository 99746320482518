import $ from 'jquery';
import template from 'lodash/template';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import {
    deletePersonalPlace,
    setMediaViewerHash
} from '../store/actions';
import { hashToList } from '../store/utils/data-structures';
import {default as gaLog, EVENT_TYPE} from '../media-viewer-map/media-viewer-gtm-events';

const ITEM_TEMPLATE_SELECTOR = '[data-object-my-locations-template]';
const ITEM_TEMPLATE_ERROR_SELECTOR = '[data-object-my-locations-template-error]';

const COMPONENT_SELECTOR = '[data-object-my-locations]';
const ITEMS_SELECTOR = '[data-object-my-locations-items]';
const LIST_SELECTOR = '[data-object-my-locations-list]';
const PLACES_LOADED_CLASS = 'places-loaded';
const HAS_PLACES_CLASS = 'has-places';
const ACTION_DELETE_ATTR = 'data-object-my-locations-action-delete';
const ACTION_DELETE_SELECTOR = `[${ACTION_DELETE_ATTR}]`;
const ACTION_SELECT_ATTR = 'data-object-my-locations-action-select';
const ACTION_SELECT_SELECTOR = `[${ACTION_SELECT_ATTR}]`;

export default class ObjectMyLocations
{
    constructor(element, store)
    {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.personalPlaces',
            'entities.travelTimes',
            'getTravelTimes',
            'ui.userLoggedIn'
        ]);
        this.$element = $(element);
        this.$itemElement = this.$element.find(ITEMS_SELECTOR);
        this.$listElement = this.$element.find(LIST_SELECTOR);

        this.compiledTemplate = template(this.$element.find(ITEM_TEMPLATE_SELECTOR).html());

        this.subscribeToStore();
        this.bindEvents();
    }

    subscribeToStore() {
        this.connect.subscribe((state) => {
            this.layout(state.ui.globalObjectId,
                state.ui.modality,
                state.entities.personalPlaces,
                state.entities.travelTimes
            );
        });
    }

    bindEvents() {
        this.$listElement
            .on('click', ACTION_SELECT_SELECTOR, event => {
                this.selectPersonalPlaceHandler(event);
            })
            .on('click', ACTION_DELETE_SELECTOR, event => {
                this.deletePersonalPlaceHandler(event);
            });
    }

    layout(globalObjectId, modality, personalPlaces, travelTimes) {
        const canceledRequest = this.store.getState().getTravelTimes.error === 'abort';

        if (canceledRequest) {
            return;
        }

        const placesCount = Object.keys(personalPlaces).length;
        const isLoading = this.store.getState().getTravelTimes.loading;
        const failedToLoadAllRoutes = this.store.getState().getTravelTimes.error !== null;

        if (failedToLoadAllRoutes) {
            this.$listElement.empty();
            this.compiledErrorTemplate = template(this.$element.find(ITEM_TEMPLATE_ERROR_SELECTOR).html());
            this.$listElement.append(this.compiledErrorTemplate());
        } else {
            this.$element.addClass(PLACES_LOADED_CLASS);
            this.$itemElement.toggleClass(HAS_PLACES_CLASS, placesCount > 0);
            this.$listElement.empty();

            const travelTimeInfoToString = travelTimeInfo =>
                travelTimeInfo && !travelTimeInfo.error ? `${travelTimeInfo.duration} • ${travelTimeInfo.distance}` : null;

            hashToList(personalPlaces).forEach(location => {
                const rowLabel = location.label || location.location;
                const travelTimeInfo = travelTimeInfoToString(travelTimes[`${location.id}-${globalObjectId}-${modality}`]);

                this.$listElement.append(this.compiledTemplate({
                    locationId: location.id,
                    isLoading: isLoading,
                    label: rowLabel,
                    distance: travelTimeInfo,
                    error: travelTimeInfo === null && !isLoading
                }));
            });
        }
    }

    selectPersonalPlaceHandler(event) {
        const locationId = event.currentTarget.getAttribute(ACTION_SELECT_ATTR);
        const navLocation = this.store.getState().entities.personalPlaces[locationId].location;

        this.store.dispatch(setMediaViewerHash(`kaart/locatie/${locationId}`));
        gaLog.travelTime(EVENT_TYPE.ENTRY_POINT_GO_TO, navLocation);
    }

    deletePersonalPlaceHandler(event) {
        event.stopPropagation();
        const locationId = event.currentTarget.getAttribute(ACTION_DELETE_ATTR);
        const deletedLocation = this.store.getState().entities.personalPlaces[locationId].location;

        this.store.dispatch(deletePersonalPlace(locationId));
        gaLog.travelTime(EVENT_TYPE.DELETE_POI, deletedLocation);
    }
}

$(COMPONENT_SELECTOR).each((_, element) => new ObjectMyLocations(element));
import $ from 'jquery';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import { setMediaViewerHash } from '../store/actions';

import FotosView from '../media-viewer-fotos/media-viewer-fotos';
import VideoView from '../media-viewer-video/media-viewer-video';
import Foto360View from '../media-viewer-foto360/media-viewer-foto360';
import PlattegrondView from '../media-viewer-plattegrond/media-viewer-plattegrond';
import MediaViewerOverview from '../media-viewer-overview/media-viewer-overview';
import MediaViewerMap from '../media-viewer-map/media-viewer-map';
import MediaViewerMapNWN from '../media-viewer-map-nwn/media-viewer-map-nwn';
import browserSupportsAnimations from '../app-utils/app-utils-browser-supports-animations';

// monkey-patch 2 functions that are used by Slick but deprecated in jQuery 3
$.prototype.load = (callback) => $.fn.on.apply(this, ['load', callback]);
$.prototype.error = (callback) => $.fn.on.apply(this, ['error', callback]);

const COMPONENT_SELECTOR = '[data-media-viewer]';
const COMPONENT_BACKGROUND_SELECTOR = '[data-media-viewer-background]';
const FOTO_VIEWER_SELECTOR = '[data-media-viewer-fotos]';
const FOTO_HASH = 'foto-';
const VIDEO_VIEWER_SELECTOR = '[data-media-viewer-video]';
const VIDEO_HASH = 'video';
const FOTO360_VIEWER_SELECTOR = '[data-media-viewer-foto360]';
const FOTO360_HASH = '360-foto-';
const PLATTEGROND_VIEWER_SELECTOR = '[data-media-viewer-plattegrond]';
const PLATTEGROND_HASH = 'plattegrond';
const OVERVIEW_VIEWER_SELECTOR = '[data-media-viewer-overview]';
const OVERVIEW_HASH = 'overzicht';
const MAP_VIEWER_SELECTOR = '[data-media-viewer-map]';
const MAP_HASH = 'kaart';
const MAP_NWN_VIEWER_SELECTOR = '[data-media-viewer-map-nwn]';
const MAP_NWN_HASH = 'nwn-kaart';
const MEDIA_VIEWER_HASHES = [FOTO_HASH, FOTO360_HASH, VIDEO_HASH, PLATTEGROND_HASH, OVERVIEW_HASH, MAP_HASH, MAP_NWN_HASH];
const HIDDEN_CLASS = 'hidden';
const EVENT_NAMESPACE = '.media-viewer';
const BODY_SELECTOR = 'body';
const BODY_OPEN_CLASS = 'media-viewer-open';
const OPEN_CLASS = 'is-open';
const OPENING_CLASS = 'is-opening';
const CLOSING_CLASS = 'is-closing';
const CLOSE_SELECTOR = '[data-media-viewer-close]';
const FULLSCREEN_CLASS = 'is-fullscreen';
const FULLSCREEN_AVAILABLE_CLASS = 'fullscreen-available';
const FULLSCREEN_SELECTOR = '[data-media-viewer-fullscreen]';
const OBJECT_MEDIA_OVERLAY_LINK = 'data-media-viewer-overlay';
const OBJECT_MEDIA_OVERLAY_LINK_SELECTOR = '[' + OBJECT_MEDIA_OVERLAY_LINK + ']';

const OBJECT_MEDIA_OVERLAY_SWITCH_LINK = 'data-media-viewer-overlay-switch';
const OBJECT_MEDIA_OVERLAY_SWITCH_SELECTOR = '[data-media-viewer-overlay-switch]';

const ANIMATION_END_EVENTS = 'webkitAnimationEnd oAnimationEnd msAnimationEnd animationend';
const FULLSCREEN_CHANGED_EVENTS = 'fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange';

const EVENT_MEDIA_VIEWER_OPENED = 'media-viewer-opened-event';
const EVENT_MEDIA_VIEWER_CLOSED = 'media-viewer-closed-event';
const EVENT_REFRESH_PAGE_AFTER_CLOSE = 'refresh-page-after-close';

const KEY_CODE = {
    ESCAPE: 27
};

const $window = $(window);
const $body = $(BODY_SELECTOR);


export default class MediaViewer {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, ['ui.mediaViewerRoute']);

        this.animationsSupported = browserSupportsAnimations();

        this.$element = $(element);
        this.$closeButton = this.$element.find(CLOSE_SELECTOR);
        this.$fullscreenButton = this.$element.find(FULLSCREEN_SELECTOR);
        this.$elementBackground = $(BODY_SELECTOR).find(COMPONENT_BACKGROUND_SELECTOR);

        this.refreshOnClose = false;
        this.isMediaViewerAccessedDirectly = false;
        this.savedScrollPosition = null;

        const state = this.store.getState();
        if (this.hashMatchesKnownMedia(state.ui.mediaViewerHash)) {
            this.isMediaViewerAccessedDirectly = true;
        }

        this.clearViews();
        this.bindEvents();
        this.initFullscreen();
    }

    bindEvents() {
        const component = this;

        component.$closeButton.on('click', () => {
            this.handleMediaViewerClose();
        });

        // Close key (escape)
        $(document).on('keydown' + EVENT_NAMESPACE, (event) => {
            if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) {
                return;
            }
            if (event.keyCode === KEY_CODE.ESCAPE) {
                this.handleMediaViewerClose();
            }
        });

        component.$fullscreenButton.on('click', function closeHandler() {
            if (!component.isFullscreen) {
                component.enterFullscreen();
            } else {
                component.exitFullscreen();
            }
        });

        // Link to media clicked, media viewer should be opened
        $(OBJECT_MEDIA_OVERLAY_LINK_SELECTOR).on('click', function (e) {
            e.preventDefault(); // prevent hashchange

            component.store.dispatch(setMediaViewerHash(this.getAttribute(OBJECT_MEDIA_OVERLAY_LINK)));
        });

        // Switch to different view while media viewer is open
        $(OBJECT_MEDIA_OVERLAY_SWITCH_SELECTOR).on('click', function (e) {
            e.preventDefault(); // prevent hashchange
            component.clearViews();
            component.store.dispatch(setMediaViewerHash(this.getAttribute(OBJECT_MEDIA_OVERLAY_SWITCH_LINK)));
        });

        /* Register the fact that when media viewer closes we need to do a reload.
         * This is done to avoid 'weird' transitions when a user logs in while the
         * media-viewer is open/active.
         *
         * TODO: add partial page updates when user logs in/out
         */
        $(document).on(EVENT_REFRESH_PAGE_AFTER_CLOSE, () => {
            this.refreshOnClose = true;
        });

        this.connect.subscribe((state, prevState) => {
            if (state.ui.mediaViewerRoute !== prevState.ui.mediaViewerRoute) {
                if (this.hashMatchesKnownMedia(state.ui.mediaViewerRoute)) {
                    this.open();
                    this.refreshView(state.ui.mediaViewerRoute);
                } else {
                    this.close();
                }
            }
        });
    }

    handleMediaViewerClose() {
        if (this.isMediaViewerAccessedDirectly) {
            this.store.dispatch(setMediaViewerHash(''));
        } else {
            window.history.go(-1);
        }
    }

    /*
     * Returns true if the current location url contains a valid media viewer hash
     */
    hashMatchesKnownMedia(hash) {
        return MEDIA_VIEWER_HASHES.some(knownHash => hash.indexOf(knownHash) !== -1);
    }

    /*
     * Opens the media viewer and shows the view
     */
    open() {
        const component = this;

        if (this.isOpen()) {
            return;
        }

        if (component.animationsSupported) {
            component.$element.one(ANIMATION_END_EVENTS, () => this.openAnimationEndHandler());
        } else {
            this.openAnimationEndHandler();
        }

        this.disableBodyScrolling();

        component.$element.addClass(OPENING_CLASS);
        component.$elementBackground.addClass(OPENING_CLASS);
    }

    /*
     * Closes the media viewer and hides any view that may be visible
     */
    close() {
        const component = this;

        if (component.refreshOnClose) {
            window.location.reload();
        }

        if (component.isFullscreen) {
            component.exitFullscreen();
        }

        if (!component.isOpen()) {
            return;
        }

        component.$element.removeClass(OPEN_CLASS).addClass(CLOSING_CLASS);
        component.$elementBackground.removeClass(OPEN_CLASS).addClass(CLOSING_CLASS);

        component.enableBodyScrolling();

        if (component.animationsSupported) {
            component.$element.one(ANIMATION_END_EVENTS, () => this.closeAnimationEndHandler());
        } else {
            this.closeAnimationEndHandler();
        }
    }

    initFullscreen() {
        const component = this;

        component.isFullscreen = false;

        if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled) {
            component.$element.addClass(FULLSCREEN_AVAILABLE_CLASS);
        }

        $(document).on(FULLSCREEN_CHANGED_EVENTS, function () {
            let fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;

            if (fullscreenElement) {
                component.$element.addClass(FULLSCREEN_CLASS);
                component.isFullscreen = true;
            } else {
                component.$element.removeClass(FULLSCREEN_CLASS);
                component.isFullscreen = false;
            }
        });
    }

    enterFullscreen() {
        const component = this;

        component.isFullscreen = true;

        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
        }
    }

    exitFullscreen() {
        const component = this;

        component.isFullscreen = false;

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    refreshView(hash) {
        const component = this;
        let $elements;

        if (hash.indexOf(FOTO_HASH) === 0) {
            const fotoIndex = hash.split(FOTO_HASH)[1];
            $elements = $(FOTO_VIEWER_SELECTOR);
            $elements.removeClass(HIDDEN_CLASS);
            component.fotosView = new FotosView($elements, fotoIndex);

        } else if (hash.indexOf(VIDEO_HASH) === 0) {
            $elements = $(VIDEO_VIEWER_SELECTOR); // for video a single element
            $elements.removeClass(HIDDEN_CLASS);
            component.videoView = new VideoView($elements[0]);

        } else if (hash.indexOf(FOTO360_HASH) === 0) {
            const foto360Id = hash.split(FOTO360_HASH)[1];
            $elements = $(FOTO360_VIEWER_SELECTOR); // for 360 also a single element
            $elements.removeClass(HIDDEN_CLASS);
            component.foto360View = new Foto360View($elements[0], foto360Id);

        } else if (hash.indexOf(PLATTEGROND_HASH) === 0) {
            const plattegrondId = hash.split('-')[1];
            $elements = $(PLATTEGROND_VIEWER_SELECTOR);
            $elements.removeClass(HIDDEN_CLASS);
            component.plattegrondView = new PlattegrondView(plattegrondId);

        } else if (hash.indexOf(OVERVIEW_HASH) === 0) {
            $elements = $(OVERVIEW_VIEWER_SELECTOR);
            $elements.removeClass(HIDDEN_CLASS);
            if (typeof component.OverView !== 'object') {
                component.OverView = new MediaViewerOverview($elements);
            }
        } else if (hash.indexOf(MAP_HASH) === 0) {
            $elements = $(MAP_VIEWER_SELECTOR);
            $elements.removeClass(HIDDEN_CLASS);
            if (typeof component.mapView !== 'object') {
                component.mapView = new MediaViewerMap($elements);
            }
        } else if (hash.indexOf(MAP_NWN_HASH) === 0) {
            $elements = $(MAP_NWN_VIEWER_SELECTOR);
            $elements.removeClass(HIDDEN_CLASS);
            if (typeof component.mapNWNView !== 'object') {
                component.mapNWNView = new MediaViewerMapNWN($elements);
            }
        }
    }

    clearViews() {
        const component = this;
        const $allViewElements = $(`
            ${FOTO_VIEWER_SELECTOR},
            ${VIDEO_VIEWER_SELECTOR},
            ${FOTO360_VIEWER_SELECTOR},
            ${PLATTEGROND_VIEWER_SELECTOR},
            ${OVERVIEW_VIEWER_SELECTOR},
            ${MAP_VIEWER_SELECTOR},
            ${MAP_NWN_VIEWER_SELECTOR}`
        );

        $allViewElements.addClass(HIDDEN_CLASS);

        if (typeof component.fotosView === 'object') {
            component.fotosView.dispose();
        }
        if (typeof component.videoView === 'object') {
            component.videoView.dispose();
        }
        if (typeof component.foto360View === 'object') {
            component.foto360View.dispose();
        }
        if (typeof component.plattegrondView === 'object') {
            component.plattegrondView.dispose();
        }
        if (typeof component.mapNWNView === 'object') {
            component.mapNWNView.dispose();
        }
    }

    isOpen() {
        return this.$element.hasClass(OPEN_CLASS);
    }

    disableBodyScrolling() {
        let scrollPosition = $window.scrollTop();

        $body.css('top', -1 * scrollPosition);
        $body.addClass(BODY_OPEN_CLASS);
        this.savedScrollPosition = scrollPosition;
    }

    enableBodyScrolling() {
        $body.css('top', '');
        $body.removeClass(BODY_OPEN_CLASS);
        $window.scrollTop(this.savedScrollPosition);
    }

    openAnimationEndHandler() {
        const component = this;

        component.$element.removeClass(OPENING_CLASS).addClass(OPEN_CLASS);
        component.$elementBackground.removeClass(OPENING_CLASS).addClass(OPEN_CLASS);
        component.$element.attr('aria-hidden', 'false');
        component.$element.off(ANIMATION_END_EVENTS);

        $window.trigger(EVENT_MEDIA_VIEWER_OPENED);
    }

    closeAnimationEndHandler() {
        const component = this;

        component.clearViews();
        component.$element.removeClass(CLOSING_CLASS);
        component.$elementBackground.removeClass(CLOSING_CLASS);
        component.$element.attr('aria-hidden', 'true');
        component.$element.off(ANIMATION_END_EVENTS);

        $window.trigger(EVENT_MEDIA_VIEWER_CLOSED);
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new MediaViewer(element));

import $ from 'jquery';
import template from 'lodash/template';
import listItem from './personal-places-list-item';
import reduxStore from '../store/store';
import Connect from '../store/connect';
import { hashToList } from '../store/utils/data-structures';

const LOCATION_ID_ATTR = 'data-location-id';
const LOCATION_ID_SELECTOR = `[${LOCATION_ID_ATTR}]`;
const SELECT_ROUTE_SELECTOR = '[data-calculate-route-button]';
const REMOVE_BUTTON_SELECTOR = '[data-remove-button]';

export const SELECTOR = '[data-personal-places-list]';

const locationsListSelector = state => hashToList(state.entities.locations);
const locationsWithPersonalPlaceSelector = state =>
    locationsListSelector(state)
        .filter(location => !!location.personalPlace);

const noop = () => {};

export default class PersonalPlacesList {
    constructor(element, store, onPersonalPlaceSelectedHandler, onRemovePersonalPlace) {
        this.onPersonalPlaceSelectedHandler = onPersonalPlaceSelectedHandler || noop;
        this.onRemovePersonalPlace = onRemovePersonalPlace;

        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.personalPlaces'
        ]);

        this.$element = $(element);
        this.compiledItemTemplate = template(listItem);

        this.layout();
        this.bindEvents();
    }

    bindEvents() {
        this
            .connect
            .subscribe((state, prevState) => {
                if (state.entities.personalPlaces !== prevState.entities.personalPlaces) {
                    const locations = locationsWithPersonalPlaceSelector(state);
                    this.layout(locations);
                }
            });

        this.$element.on('click', REMOVE_BUTTON_SELECTOR, (event) => {
            event.preventDefault();
            const locationId = this.findLocationIdFromLocationItemChildElement(event.currentTarget);
            this.removePersonalPlace(locationId);
        });

        this.$element.on('click', SELECT_ROUTE_SELECTOR, event => {
            event.preventDefault();
            const locationId = this.findLocationIdFromLocationItemChildElement(event.currentTarget);
            this.selectPersonalPlace(locationId);
        });
    }

    findLocationIdFromLocationItemChildElement(element) {
        return $(element).parents(LOCATION_ID_SELECTOR).attr(LOCATION_ID_ATTR);
    }

    removePersonalPlace(locationId) {
        this.onRemovePersonalPlace(locationId);
    }

    selectPersonalPlace(locationId) {
        this.onPersonalPlaceSelectedHandler(locationId);
    }

    layout(list) {
        list = list || [];

        const listItemElements = list
            .map(location =>
                this.compiledItemTemplate({
                    locationId: location.id,
                    label: location.label || location.location
                })
            );

        this.$element.html(listItemElements);
    }
}

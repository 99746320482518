// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default FilterCount;

// component configuration
const COMPONENT_SELECTOR = '[data-filter-count]';
const COUNT_SELECTOR = '[data-filter-count-number]';
const LABEL_SELECTOR = '[data-filter-count-label]';
const LABEL_ONE_ATTR = 'data-filter-count-label-one';
const LABEL_MULTIPLE_ATTR = 'data-filter-count-label-multiple';

function FilterCount(element) {
    const component = this;
    component.$element = $(element);
    component.$count = component.$element.find(COUNT_SELECTOR);
    component.initialCount = component.getInitialCount();
    component.$label = component.$element.find(LABEL_SELECTOR);
    component.labelCountOne = component.$label.attr(LABEL_ONE_ATTR);
    component.labelCountMultiple = component.$label.attr(LABEL_MULTIPLE_ATTR);
    component.$form = component.$element.closest('form');

    $(document).on('setfiltercount', (event, eventArgs) => component.setCount(eventArgs.amount));
}

FilterCount.prototype.getLabel = function(count) {
    const component = this;
    return count === 1 ? component.labelCountOne : component.labelCountMultiple;
};

FilterCount.prototype.setCount = function(int) {
    const component = this;

    if (component.initialCount > 0) {
        // When total number of filters has been rendered, reset initial count so on subsequent renderings it will be updated correctly.
        if (component.initialCount === int) {
            component.initialCount = 0;
        }
    }
    else {
        component.count = int || 0;
        component.$count.text(component.count);
        component.$label.text(component.getLabel(component.count));
    }
};

FilterCount.prototype.getInitialCount = function() {
    const component = this;
    return component.$count.length > 0 ? parseInt(component.$count[0].innerText, 10) : 0;
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new FilterCount(element));
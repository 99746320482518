import {
    GET_PERSONAL_PLACES_SUCCESS,
    DELETE_PERSONAL_PLACE
} from '../../constants/action-types';
import omit from 'lodash/omit';
import { listToHash } from '../../utils/data-structures';

const initialState = {};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_PERSONAL_PLACES_SUCCESS:
            return listToHash(action.result.personalplaces, 'id');
        case DELETE_PERSONAL_PLACE:
            return omit(state, action.id);
        default:
            return state;
    }
};

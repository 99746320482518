import { combineReducers } from 'redux';
import ui from './ui';
import addPersonalPlace from './add-personal-place';
import deletePersonalPlace from './delete-personal-place';
import getPersonalPlaces from './get-personal-places';
import getTravelTimes from './get-travel-times';
import getVoorzieningen from './get-voorzieningen';
import entities, * as fromEntities from './entities';

export default combineReducers({
    ui,
    addPersonalPlace,
    deletePersonalPlace,
    getPersonalPlaces,
    getTravelTimes,
    getVoorzieningen,
    entities
});

export const getLocationById = (state, id) =>
    fromEntities.getLocationsByIds(state.entities, [id])[0];

import {
    GET_TRAVEL_TIMES_SUCCESS
} from '../../constants/action-types';

const responseToHash = (globalObjectId, modality, state, input = []) => {
    const travelTimes = input.reduce((hash, item) => {
        hash[`${item.id}-${globalObjectId}-${modality}`] = item;
        return hash;
    }, {});
    return {
        ...state,
        ...travelTimes
    };
};


const initialState = {};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_TRAVEL_TIMES_SUCCESS:
            return responseToHash(
                action.result.param.globalobjectid,
                action.result.param.travelmode,
                state,
                action.result.traveltimes
            );
        default:
            return state;
    }
};

/* globals Highcharts */

import $ from 'jquery';
import isFunction from 'lodash/isFunction';
import debounce from 'lodash/debounce';
import load from 'load-script';
import thousandSeperator from '../thousand-separator/thousand-separator';
import AppSpinner from '../app-spinner/app-spinner';

export default Graph;

// component configuration
const COMPONENT_SELECTOR = '[data-graph]';
const ASSET_BASE_SELECTOR = 'data-graph-asset-base';
const ID_SELECTOR = 'graph-container';
const LIBRARY_URL = '/assets/vendor-highcharts.js';
const TYPE = 'area';
const TOOLTIP_SELECTOR = 'div.highcharts-tooltip'; // Specific element created by Highcharts.
const TOOLTIP_FORMAT_SELECTOR = '[data-my-house-tooltip-template]';
const TOOLTIP_PRODUCT_FORMAT_SELECTOR = '[data-my-house-tooltip-product-template]';
const TOOLTIP_PRODUCT_HANDLE = '[data-my-house-tooltip-products]';
const SPINNER_TEMPLATE_HANDLE = '[data-my-house-graph-spinner-template]';
const SPINNER_HANDLE = '[data-graph-spinner]';
const TRANSLATION_MONTHS = 'data-date-translations-months';
const TRANSLATION_SHORTMONTHS = 'data-date-translations-shortmonths';
const TRANSLATION_WEEKDAYS = 'data-date-translations-weekdays';
const TRANSLATION_FORMAT_MONTH = 'data-date-translations-month-format';
const TRANSLATION_FORMAT_DATE = 'data-date-translations-date-format';
const TRANSLATION_DAY = 'data-translations-day-label';
const THOUSAND_SEPARATOR = 'data-thousand-separator';
const YOUR_OBJECT_ID = 'YourObject';

/**
 * Constructor method, links child elements to variables for internal use
 * @param {HTMLElement} element     The HTML element to bind to.
 * @param {object} options          Received component options.
 */
function Graph(element, options) {
    const component = this;
    this.$element = $(element);
    const tooltipTemplate = component.$element.find(TOOLTIP_FORMAT_SELECTOR).html().trim();
    const productTemplate = component.$element.find(TOOLTIP_PRODUCT_FORMAT_SELECTOR).html().trim();
    const initialViewportWidth = $(window).width();
    const monthFormat = component.$element.attr(TRANSLATION_FORMAT_MONTH);
    component.componentLoaded = false;
    component.assetBaseUrl = component.$element.attr(ASSET_BASE_SELECTOR);
    component.setSpecificOptions = null;
    component.spinner = new AppSpinner(SPINNER_HANDLE);
    component.seperator = component.$element.attr(THOUSAND_SEPARATOR);
    const chartOptions = {
        chart: {
            renderTo: ID_SELECTOR,
            spacing: [10, 0, 10, 0],
            type: TYPE,
            animation: {
                duration: 300
            },
            height: 330,
            backgroundColor: 'transparent',
            style: {
                fontFamily: '"Proxima Nova",sans-serif'
            }
        },
        credits: {
            enabled: false
        },
        lang: {
            thousandsSep: this.seperator,
            decimalPoint: ','
        },
        legend: {
            enabled: false
        },
        loading: {
            hideDuration: 500,
            showDuration: 500
        },
        plotOptions: {
            area: {
                animation: false,
                fillColor: 'transparent',
                lineWidth: 2.5,
                marker: {
                    fillColor: '#FFFFFF',
                    lineColor: '#3888C5',
                    lineWidth: 2,
                    radius: 3,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                trackByArea: true
            },
            allowPointSelect: false,
            series: {
                turboThreshold: 5000,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }
        },
        series: [
            {
                data: [],
                id: YOUR_OBJECT_ID,
                lineWidth: 3,
                color: '#3888C5',
                marker: {
                    lineWidth: 2,
                    radius: 3
                },
                name: YOUR_OBJECT_ID,
                zIndex: 3
            }, {
                data: [],
                id: 'flags',
                linkedTo: ':previous',
                lineWidth: 0,
                marker: {
                    enabled: false
                },
                name: null,
                onSeries: 'line'
            }, {
                data: [],
                id: 'Products',
                linkedTo: ':previous',
                lineWidth: 0,
                marker: {
                    enabled: false
                },
                color: '#3888C5',
                name: 'Products',
                onSeries: 'line'
            }
        ],
        title: {
            text: ''
        },
        tooltip: {
            shadow: false,
            shared: true,
            useHTML: true,
            crosshairs: {
                color: '#A4DBFF',
                dashStyle: 'solid'
            },
            followPointer: true,
            formatter: function() {
                if (!component.isLoading) {
                    return component.renderTooltip({
                        tooltip: tooltipTemplate,
                        product: productTemplate
                    }, this);
                }
            },
            hideDelay: 0,
            positioner: function(labelWidth, labelHeight, point) {
                let tooltipXaxis;
                let tooltipYaxis;
                const tooltip = $(TOOLTIP_SELECTOR);
                const tooltipWidth = tooltip.width() / 2;
                const tooltipHeight = tooltip.height();

                // Set the tooltip horizontal position.
                if (point.plotX + tooltipWidth > component.chart.plotWidth) { // If tooltip start touching the edge on the right.
                    tooltipXaxis = point.plotX + component.chart.plotLeft - 130;
                    tooltip.addClass('align-right');
                } else if (point.plotX < tooltipWidth) { // If tooltip start touching the edge on the left.
                    tooltipXaxis = point.plotX + component.chart.plotLeft - 20;
                    tooltip.addClass('align-left');
                } else {
                    tooltipXaxis = point.plotX + component.chart.plotLeft - 75;
                    tooltip.removeClass('align-right');
                    tooltip.removeClass('align-left');
                }

                // Set the tooltip vertical position.
                tooltipYaxis = point.plotY + component.chart.plotTop - (tooltipHeight + 20);

                return {
                    x: tooltipXaxis,
                    y: tooltipYaxis
                };
            },
            style: {
                padding: 0
            }
        },
        xAxis: {
            dateTimeLabelFormats: {
                day: monthFormat,
                week: monthFormat,
                month: '%B'
            },
            name: 'Datum',
            labels: {
                autoRotation: [-45],
                autoRotationLimit: 120,
                align: 'center',
                style: {
                    color: '#999'
                }
            },
            lineColor: '#D7E2E5',
            lineWidth: 1,
            minPadding: 0,
            maxPadding: 0,
            tickColor: 'transparent'
        },
        yAxis: {
            gridLineColor: '#D7E2E5',
            gridLineWidth: 1,
            labels: {
                formatter: function() {
                    return Highcharts.numberFormat(this.value, 0, '', component.seperator);
                },
                style: {
                    color: '#999'
                }
            },
            lineColor: '#D7E2E5',
            lineWidth: 1,
            min: 0,
            title: {
                style: {
                    color: '#999'
                },
                x: -6
            }
        }
    };

    component.overrideComponentOptions(options);

    // When main Highcharts library is loaded, init a new Highcharts chart.
    load(component.getLibraryUrl(), function(err) {
        if (typeof err !== 'undefined') {

            component.translateDates();

            Highcharts.setOptions({
                global: {
                    useUTC: false
                }
            });

            component.chart = new Highcharts.Chart(chartOptions);
            Graph.redrawBasedOnViewport(component.chart, initialViewportWidth);
            component.componentLoaded = true;
        }
    });

    $(window).on('resize', debounce(function() {
        const resizedViewportWidth = $(window).width();
        Graph.redrawBasedOnViewport(component.chart, resizedViewportWidth);
    }, 100));
}

/**
 * Get options from component that is extending this component.
 * @param {string} options
 */
Graph.prototype.overrideComponentOptions = function(options) {
    const component = this;

    component.assetBaseUrl = options.assetBaseUrl || component.assetBaseUrl;
    component.setSpecificOptions = options.setSpecificOptions || component.setSpecificOptions;
};

/**
 * Set the translations to Highchart properties so the correct translations show up in the graph.
 */
Graph.prototype.translateDates = function() {
    const component = this;
    const lang = {};

    const setTranslation = function(object) {
        const attribute = component.$element.attr(object.selector);
        if (attribute) {
            Graph[object.property] = lang[object.property] = attribute.split(',');
        }
    };

    setTranslation({ property: 'months', selector: TRANSLATION_MONTHS });
    setTranslation({ property: 'shortMonths', selector: TRANSLATION_SHORTMONTHS });
    setTranslation({ property: 'weekdays', selector: TRANSLATION_WEEKDAYS });

    Graph.dateFormat = component.$element.attr(TRANSLATION_FORMAT_DATE);

    // Set language options with correct translations.
    Highcharts.setOptions({lang});
};

/**
 * Returns url based on where Highcharts is loaded from.
 * @returns {string} library url
 */
Graph.prototype.getLibraryUrl = function() {
    const component = this;

    if (component.assetBaseUrl !== undefined && component.assetBaseUrl !== '') {
        return component.assetBaseUrl + LIBRARY_URL;
    }

    return LIBRARY_URL;
};

/**
 * Transforms the received data to the format needed for the chart.
 */
Graph.prototype.processData = function() {
    const component = this;

    component.setLoading(true);

    if (component.setSpecificOptions !== null && isFunction(component.setSpecificOptions)) {
        component.setSpecificOptions(component.data, component.chart); // Set specific options
    }

    Graph.highlightWeekends(component.chart, component.data.weekends);
    Graph.populateChart(component.chart, component.data);
    Graph.plotFlagLine(component.chart);

    component.setLoading(false);
};

/**
 * Show or hide the spinner, depanding on boolean returned by all connected components.
 * @param {boolean} value       If we're still loading data.
 */
Graph.prototype.setLoading = function(value) {
    const component = this;
    const spinnerTemplate = component.$element.find(SPINNER_TEMPLATE_HANDLE).html().trim();

    // If they're equal, return.
    if (component.isLoading == value) {
        return;
    }

    component.isLoading = value;

    if (component.isLoading) {
        component.chart.showLoading(spinnerTemplate);
        component.spinner.show();
    } else {
        component.chart.hideLoading();
        component.spinner.hide();
    }
};

/**
 * Redraw axis based on viewport size.
 * @param {Object[]} chart
 * @param {number} width
 */
Graph.redrawBasedOnViewport = function (chart, width) {
    if (width < 500) {
        chart.xAxis[0].update({
            labels: {
                y: 30
            }}, false);
        chart.yAxis[0].update({
            showLastLabel: false,
            labels: {
                align: 'left',
                x: 5,
                y: -10
            }}, false);
    } else {
        chart.xAxis[0].update({
            labels: {
                y: 20
            }}, false);
        chart.yAxis[0].update({
            showLastLabel: true,
            labels: {
                align: 'right',
                x: -8,
                y: 3
            }}, false);
    }
    chart.redraw();
    chart.reflow();
};

/**
 * Filter series to a single flag.
 * @param {Object[]} chart
 */
Graph.plotFlagLine = function (chart) {
    chart.xAxis[0].removePlotLine('flags'); // Remove all existing flag lines
    chart.series
        .filter(serie => (serie.options.name !== 'products')) // exclude products
        .filter(serie => (serie.options.id === 'flags')) // find all flags
        .reduce((out, serie) => serie.data, []) // reduce to data of a single flag
        .forEach(point => Graph.plotFlagPoint(point, chart));
};

/**
 * Adds plot lines to the graph.
 * @param {Object[]} point
 * @param {Object[]} chart
 */
Graph.plotFlagPoint = function (point, chart) {
    chart.xAxis[0].addPlotLine({
        value: point.x,
        width: 1,
        color: '#00AAE6',
        id: 'flags',
        zIndex: 1
    });
};

/**
 * Adds a plotBand for each weekend day into the graph.
 * @param {Object[]} chart
 * @param {array} weekends
 */
Graph.highlightWeekends = function(chart, weekends) {
    weekends.forEach(function(weekend) {
        const date = weekend.Date;
        const twelveHours = 60 * 60 * 24 * 1000 / 2;

        chart.xAxis[0].addPlotBand({
            from: date - twelveHours,
            to: date + twelveHours,
            color: 'rgba(245, 245, 245, 0.7)',
            zIndex: 1
        });
    });
};

/**
 * Creates a custom tooltip from the available data.
 * @param {{tooltip, product}} templates
 * @param {Object[]} data
 */
Graph.prototype.renderTooltip = function(templates, data) {
    const component = this;
    const titlePattern = new RegExp('{title}', 'g');
    const serieNameLabel = YOUR_OBJECT_ID;
    const dayLabel = $(COMPONENT_SELECTOR).attr(TRANSLATION_DAY);
    const title = data.x.toString().length > 2 ? Graph.formatDate(new Date(data.x)) : dayLabel.replace('{value}', data.x);
    const value = data.points.filter(point => (point.series.name === serieNameLabel))[0];
    const product = data.points.filter(point => (point.series.name === 'Products'))[0];

    const tooltipHtml = templates.tooltip
        .replace(titlePattern, title)
        .replace('{seriesColor}', value.color)
        .replace('{value}', thousandSeperator.format(value.y, component.seperator));

    const $tooltip = $(tooltipHtml);

    if (product) {
        const titlesHtml = Graph.renderProductTitles(templates.product, product.point.title);
        const productsHandle = $tooltip.find(TOOLTIP_PRODUCT_HANDLE);
        productsHandle.append(titlesHtml);
    }

    $(TOOLTIP_SELECTOR).empty().append($tooltip);
};

/**
 * Lookup flag data from the flagMap and return the array of flag objects.
 * @param {string} template
 * @param {string[]} titles
 * @returns {string} rendered product-title HTML
 */
Graph.renderProductTitles = function(template, titles) {
    const pattern = new RegExp('{flagName}', 'g');

    return titles.map(title => template.replace(pattern, title)).join('');
};

/**
 * Iterate over the chart data object and push the data to the chart options
 * in the order that the series array is defined in the options.
 * @param {string} chart
 * @param {string[]} data
 */
Graph.populateChart = function(chart, data) {
    delete data.weekends;

    Object.keys(data)
        .forEach((key, index) => chart.series[index].setData(data[key], true, true, false));
};

/**
 * Create formatted, readable, date from received date string.
 * @param {Date} date
 * @returns {string} date as "Ma 10 januari 2000"
 */
Graph.formatDate = function(date) {
    return Highcharts.dateFormat(Graph.dateFormat, date);
};

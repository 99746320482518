import $ from 'jquery';
import UserMyHouseDatasource from '../user-my-house-datasource/user-my-house-datasource';
import thousandSeperator from '../thousand-separator/thousand-separator';

export default UserMyHouseStatistics;

// component configuration
const COMPONENT_SELECTOR = '[data-my-house-statistics]';
const CELL_SELECTOR = 'data-my-house-cell-data';
const CELL_SELECTOR_ATTR = '[' + CELL_SELECTOR + ']';
const CELL_TOTAAL_SELECTOR = '#user-my-house-table-header-total';
const THOUSAND_SEPARATOR = 'data-thousand-separator';
const NO_DATA_CLASS = 'no-data';

function UserMyHouseStatistics(element, userMyHouseDatasource = UserMyHouseDatasource.instance) {
    const component = this;
    component.$element = $(element);
    component.loaded = false;
    component.$cells = component.$element.find(CELL_SELECTOR_ATTR);
    component.$cellTotal = component.$element.find(CELL_TOTAAL_SELECTOR);
    component.thousandSeperator = component.$element.attr(THOUSAND_SEPARATOR);

    // Observe the data loading events
    userMyHouseDatasource.registerDataChanged({
        onDataLoading: component.loadingTableData,
        onDataLoaded: component.populateTable,
        onDataLoadError: component.loadError,
        isLoaded: component.isLoaded,
        context: component
    });

    $(document).ready(() => component.onReady());
}

/**
 * Sets the value of loaded to true
 */
UserMyHouseStatistics.prototype.onReady = function() {
    const component = this;
    component.loaded = true;
};

/**
 * return if this component is loaded (used by user-my-house-datasource)
 * @returns {boolean}       If component is loaded.
 */
UserMyHouseStatistics.prototype.isLoaded = function() {
    const component = this;
    return component.loaded;
};

/**
 * Populate the table with the received data.
 * @param {object} rawData          Raw data received from the API.
 * @param {object} selectedPeriod   Selected option from the period dropdown.
 */
UserMyHouseStatistics.prototype.populateTable = function(rawData, selectedPeriod) {
    const receivedData = rawData.TableData;
    const component = this;

    // Remove NO_DATA_CLASS if it exists.
    if (component.$element.hasClass(NO_DATA_CLASS)) {
        component.$element.removeClass(NO_DATA_CLASS);
    }

    component.$cellTotal.html($(selectedPeriod).html());

    if (receivedData.hasOwnProperty) {
        component.$cells.each(function(index, element) {
            let data = element.getAttribute(CELL_SELECTOR).split('.');
            if (data[1] == 'Conversion') {
                element.innerHTML = receivedData[data[0]][data[1]] + '%';
            } else {
                element.innerHTML = thousandSeperator.format(receivedData[data[0]][data[1]], component.thousandSeperator);
            }
        });
    }
};

/**
 * When loading data resulted in error
 */
UserMyHouseStatistics.prototype.loadError = function() {
    // Hide table when error occurred
    const component = this;
    component.$element.addClass(NO_DATA_CLASS);
};

/**
 * Tell all related components data is being loaded.
 */
UserMyHouseStatistics.prototype.loadingTableData = function() {
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new UserMyHouseStatistics(element));

import $ from 'jquery';
import TrafficDriverBarVisibilityService from './../traffic-driver-bar/traffic-driver-bar-visibility-service';

const COMPONENT_SELECTOR = '[data-traffic-driver-waardecheck]';
const DISMISS_BUTTON_SELECTOR = '[data-dismiss-button]';
const IS_HIDDEN_CLASS = 'is-hidden';

export default class TrafficDriverWaardecheck {
    constructor(element) {
        this.$element = $(element);
        const config = {
            id: 'waardecheck'
        };
        this.visibilityRepository = new TrafficDriverBarVisibilityService(config);

        const isDismissed = this.visibilityRepository.isDismissed();
        this.$element.toggleClass(IS_HIDDEN_CLASS, isDismissed);

        this.bindEvents();
    }

    bindEvents() {
        this.$element.on('click', DISMISS_BUTTON_SELECTOR, event => {
            event.preventDefault();
            this.visibilityRepository.dismiss();
            this.$element.addClass(IS_HIDDEN_CLASS);
        });
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new TrafficDriverWaardecheck(element));
import {
    GET_TRAVEL_TIMES,
    GET_TRAVEL_TIMES_SUCCESS,
    GET_TRAVEL_TIMES_FAIL,
} from '../constants/action-types';

const initialState = {
    loaded: false,
    loading: false,
    error: null
};

export default function getTravelTimes(state = initialState, action = {}) {
    switch (action.type) {
        case GET_TRAVEL_TIMES:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: null
            };
        case GET_TRAVEL_TIMES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null
            };
        case GET_TRAVEL_TIMES_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        default:
            return state;
    }
}

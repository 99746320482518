// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

import ObjectRatingForm from '../object-rating-form/object-rating-form';
import Rating from '../rating/rating';
import LoginDialog from '../login-dialog/login-dialog';

import Expandible from '../expandible/expandible';

export default AsyncObjectRating;

// component configuration
const COMPONENT_ATTR = 'data-async-object-rating';
const COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';
const HANDLE_SELECTOR = '[data-async-object-rating-handle]';
const HANDLE_TEXT_ATTR = 'data-async-object-rating-handle-text';
const HANDLE_TEXT_SELECTOR = '[' + HANDLE_TEXT_ATTR + ']';
const DEFAULT_INPUT_SELECTOR = '[data-default-rating-input]';
const RESET_HANDLE_SELECTOR = '[data-object-rating-form-reset-handle]';
const CONTENT_SELECTOR = '[data-async-object-rating-content]';
const FORM_SELECTOR = '[data-object-rating-form]';
const RATING_SELECTOR = '[data-rating]';
const TEXTAREA_SELECTOR = '[data-object-rating-textarea]';
const SUBMIT_BUTTON_SELECTOR = '[data-object-rating-submit-button]';
const ERROR_MESSAGE_SELECTOR = '[data-overall-object-rating-error-message]';
const OVERALL_RATING_SELECTOR = '[data-overall-object-rating-form]';
const LOGIN_DIALOG_SELECTOR = '[data-dialog="user-form"]';
const RATED_CLASS = 'is-rated';
const LOADED_CLASS = 'is-loaded';
const EXPANDED_CLASS = 'is-expanded';
const VISIBLE_CLASS = 'is-visible';
const IS_HIDDEN_CLASS = 'is-hidden';
const DOING_REQUEST_CLASS = 'is-doing-request';
const SCROLL_ATTR = 'data-expandible-scroll';
const CLOSE_HANDLE_SELECTOR = '[data-expandible-handle-scroll]';

var instances = {};

function AsyncObjectRating(element) {
    var component = this;
    component.$element = $(element);
    component.$handle = component.$element.find(HANDLE_SELECTOR);
    component.$closeHandle = component.$element.find(CLOSE_HANDLE_SELECTOR);
    component.$overallRating = component.$element.find(OVERALL_RATING_SELECTOR);
    component.$overallRatingInput = component.$overallRating.find('input');
    component.doPageRefresh = false;
    component.isExpanded = component.$element.hasClass(EXPANDED_CLASS);
    instances[component.$element.attr(COMPONENT_ATTR)] = component;
    component.scrollPosition = 0;
    component.dialogElement = document.querySelector(LOGIN_DIALOG_SELECTOR);
    component.expandible = new Expandible(component.$element[0]);
    component.objectRatingForm = new ObjectRatingForm(component.$element.find(RATING_SELECTOR)[0]);

    component.$handle.on('click', function(event) {
        event.preventDefault();
        var objectRatingFormExists = component.$element.find(FORM_SELECTOR).length;
        component.isExpanded = component.$element.hasClass(EXPANDED_CLASS);

        if (!component.isExpanded && !objectRatingFormExists) {
            component.isUserLoggedIn(function onSuccessfulLogin() {
                component.getForm(component.$handle);
            });
        }

        if (component.$element[0].hasAttribute(SCROLL_ATTR)) {
            component.scrollPosition = $(window).scrollTop();
        }
    });

    component.$element.on('click', SUBMIT_BUTTON_SELECTOR, function(event) {
        var checkedOverallInputValue = component.$overallRating.find('input:checked').val();
        event.preventDefault();
        component.toggleRatedState();

        if (component.scrollPosition > 0 && checkedOverallInputValue !== '0') {
            component.scrollToObjectPosition();
        }
    });

    component.$element.on('click', RESET_HANDLE_SELECTOR, function(event) {
        event.preventDefault();
        component.resetObjectRating();

        if (component.scrollPosition > 0) {
            component.scrollToObjectPosition();
        }
    });

    component.$closeHandle.on('click', function(event) {
        event.preventDefault();

        if (component.scrollPosition > 0) {
            component.scrollToObjectPosition();
        }
    });

    component.$overallRatingInput.on('change', function() {
        component.submitOverallRating();
    });

    $(window).on('objectsaved', function(event, isSaved) {
        var objectRatingFormExists = component.$element.find(FORM_SELECTOR).length;
        var $handleText = component.$element.find(HANDLE_TEXT_SELECTOR);

        component.$element.toggleClass(IS_HIDDEN_CLASS);

        if (objectRatingFormExists) {
            if (isSaved === true) {
                component.getForm(component.$handle);
            } else {
                component.$element.find(TEXTAREA_SELECTOR).val('').html('');
                component.expandible.toggleExpand(false);
                $handleText.text($handleText.attr(HANDLE_TEXT_ATTR));
                component.resetRatingStyling();
            }
        }
    });
}

AsyncObjectRating.prototype.isUserLoggedIn = function(onLoggedIn) {
    var component = this;
    var url = component.dialogElement.dialog.isUserLoggedInUrl;

    component.expandible = new Expandible(component.$element[0]);

    return $.ajax({
        url: url,
        success: function(response) {
            if (response.LoggedIn === true) {
                onLoggedIn();
            } else {
                component.userLoginStatus = new LoginDialog(response.LoginUrl, function onSuccessfulLogin() {
                    window.location.reload();
                });
                component.expandible.toggleExpand(false);
            }
        },
        error: function(response) {
            console.error('Error calling', url, response);
        }
    });
};

/**
 * Does an asynchronous request to get the form content and appends
 * it to the DOM if the request is successful
 */
AsyncObjectRating.prototype.getForm = function($element) {
    var component = this;
    var url = $element.attr('href');
    component.$element.removeClass(LOADED_CLASS);

    return $.ajax({
        url: url,
        success: function(response) {
            if (AsyncObjectRating.isSuccesfulResponse(response)) {
                var $content = component.$element.find(CONTENT_SELECTOR);
                $content.html('');
                $content.append(response.Html);
                component.createInstances();
            }
        },
        error: onError,
        complete: function() {
            component.$element.addClass(LOADED_CLASS);
        }
    });

    function onError(response) {
        console.error('Error trying to rate object using', url, response);
    }
};

AsyncObjectRating.isSuccesfulResponse = function(response) {
    return response.Result === 'OK';
};

/**
 * Creates a new instance of all components if they're not instantiated yet
 */
AsyncObjectRating.prototype.createInstances = function() {
    var component = this;

    component.objectRatingForm = new ObjectRatingForm(FORM_SELECTOR);
    component.$element.find(RATING_SELECTOR).each(function(index, element) {
        return new Rating(element);
    });
    component.expandible = new Expandible(component.$element[0]);
};

/**
 * Updates the text inside the handle with the value of the textarea
 */
AsyncObjectRating.prototype.updateHandleText = function() {
    var component = this;
    var note = component.$element.find(TEXTAREA_SELECTOR).val();
    var maxCharacters = 150;
    var $handleText = component.$element.find(HANDLE_TEXT_SELECTOR);

    if (note) {
        $handleText.text(note.substring(0, maxCharacters));
    } else {
        $handleText.text($handleText.attr(HANDLE_TEXT_ATTR));
    }
};

/**
 * Does an ajax request and adds the 'is-rated' class if the response is
 * successful
 */
AsyncObjectRating.prototype.toggleRatedState = function() {
    var component = this;
    component.$form = component.$element.find(FORM_SELECTOR);
    var url = component.$form.attr('action');
    var data = component.$form.find(':input').serialize();
    component.$element.addClass(DOING_REQUEST_CLASS);

    return $.ajax({
        method: 'POST',
        url: url,
        data: data,
        success: function onSuccess(response) {
            if (AsyncObjectRating.isSuccesfulResponse(response)) {
                component.updateHandleText();
                component.$handle.addClass(RATED_CLASS);
                component.$element.addClass(RATED_CLASS);
                component.expandible.toggleExpand(!component.expandible.isExpanded);
                component.getForm(component.$handle);
            } else if (response.Result === 'ERROR') {
                var $element = component.$element.find(CONTENT_SELECTOR);
                $element.html('');
                $element.append(response.Html);
                component.createInstances();
            }
        },
        error: function onError(error) {
            console.error('Error trying to submit form', error);
            window.location.href = url;
        },
        complete: function() {
            if (component.doPageRefresh === true) {
                window.location.reload();
            }
            component.$element.removeClass(DOING_REQUEST_CLASS);
        }
    });
};

/**
 * Does an asynchronous request to post the overall object rating
 */
AsyncObjectRating.prototype.submitOverallRating = function() {
    var component = this;
    var url = component.$overallRating.attr('action');
    var data = component.$overallRating.serialize();

    return $.ajax({
        method: 'POST',
        url: url,
        data: data,
        error: function onError(error) {
            console.error('Error trying to submit overall rating form', error);
        }
    });
};

/**
 * Resets, clears and closes the async object rating form, calls
 * resetRatingStyling() for resetting the rating styling
 */
AsyncObjectRating.prototype.resetObjectRating = function() {
    var component = this;
    var $defaultInputs = component.$element.find(DEFAULT_INPUT_SELECTOR);

    // set all ratings to the default input (which is the hidden input
    // with value=0)
    $defaultInputs.each(function(index, element) {
        $(element).prop('checked', true);
    });

    component.resetRatingStyling();
    component.$element.find(TEXTAREA_SELECTOR).val('');
    component.updateHandleText();
    $(ERROR_MESSAGE_SELECTOR).removeClass(VISIBLE_CLASS);
    // clear the form
    component.$element.find(CONTENT_SELECTOR).html('');
    component.expandible.toggleExpand(!component.expandible.isExpanded);
};

/**
 * Resets all ratings to the default value and removes the 'is-rated' class
 * from all ratings
 */
AsyncObjectRating.prototype.resetRatingStyling = function() {
    var component = this;

    component.$overallRatingInput.removeClass(RATED_CLASS);
    component.$handle.removeClass(RATED_CLASS);
    component.$element.removeClass(RATED_CLASS);
    component.objectRatingForm.$inputs.removeClass(RATED_CLASS);
};

AsyncObjectRating.getInstances = function() {
    return instances;
};

AsyncObjectRating.prototype.scrollToObjectPosition = function() {
    var component = this;

    $(window).scrollTop(component.scrollPosition);
};

AsyncObjectRating.initialize = function() {
    // turn all elements with the default selector into components
    $(COMPONENT_SELECTOR).each(function(index, element) {
        return new AsyncObjectRating(element);
    });
};

AsyncObjectRating.initialize();
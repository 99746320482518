// require this module where needed, either in a specific view or component or generically in src/index.js
import assign from 'lodash/assign';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';

// what does this module expose?
export default MapEvents;

const HISTORY_PUSH_STATE = 'push_state_set_by_funda';
const UPDATE_HASH_DELAY = 750;


function MapEvents() {
    MapEvents.lastMapEvent = {
        center: null,
        zoom: null
    };
    MapEvents.eventHandle = null;
}

/**
 * Update the hash with new data, overwrite the old data when available
 * @param {object} mapData
 */
MapEvents.updateHash = function(currentState, newState) {
    let diffKeys = reduce(currentState, function(result, value, key) {
        return isEqual(value, newState[key]) ? result : result.concat(key);
    }, []);
    if (diffKeys.length == 0) return;

    // handle changes in id directly
    if (diffKeys.indexOf('id') >= 0) {
        MapEvents.setNewHash(newState);
    }

    if (diffKeys.indexOf('center') >= 0 || diffKeys.indexOf('zoom') >= 0) {
        // always register new location/zoom
        MapEvents.registerMapEvent(newState);
        // only trigger update of hash once per UPDATE_HASH_DELAY
        if (MapEvents.eventHandle == null) {
            MapEvents.eventHandle = window.setTimeout(function() {
                MapEvents.setNewHash(MapEvents.lastMapEvent);
                MapEvents.eventHandle = null;
            }, UPDATE_HASH_DELAY);
        }

    }
};

MapEvents.setNewHash = function(newState) {
    const newHash = MapEvents.createHash(newState);
    // set new generated hash
    if (newHash !== '') {
        const url = window.location.pathname;
        window.history.pushState(HISTORY_PUSH_STATE, window.title, url + newHash);
    }
};

MapEvents.registerMapEvent = function(newState) {
    MapEvents.lastMapEvent = {
        center: newState.center,
        zoom: newState.zoom,
    };
};

/**
 * Get the hash and returns as object
 * @returns {object} hash
 */
MapEvents.getHash = function() {
    let hash = window.location.search || null;
    let hashObj = {};
    if (hash !== null) {
        hash = hash.replace('?', '');
        let hashArr = hash.split('&');

        for (let hashOption in hashArr) {
            let option = hashArr[hashOption].split('=');
            hashObj[option[0]] = option[1];
        }
    }
    return hashObj;
};

/**
 * Returns a value from the hash
 * @param {string} key
 * @returns {data}
 */
MapEvents.getValueFromHash = function(key) {
    const hashData = MapEvents.getHash();
    return (hashData[key] !== undefined ? hashData[key] : null);
};

/**
 * Get current search query fom url
 */
MapEvents.getCurrentSearchFromUrl = function() {
    return window.location.pathname.replace('/kaart', '');
};

/**
 * Create a new hash from new and old map data
 * @param {object} mapData
 * @returns {string} hash
 */
MapEvents.createHash = function(newState) {
    let hashData = MapEvents.getHash();

    // update center to usable format
    if (Object.keys(newState).indexOf('center') >= 0) {
        // don't modify center objct from newState
        let hashValues = assign({}, hashData);
        hashValues.zoom = newState.zoom;
        hashValues.id = newState.id;
        hashValues.center = newState.center.lat() + ',' + newState.center.lng();
        return MapEvents.objectToQueryString(hashValues);
    }

    let hashValues = assign({}, hashData, newState);
    return MapEvents.objectToQueryString(hashValues);
};

MapEvents.objectToQueryString = function(data) {
    // create query string (skipping invalid values)
    let newHash = '';
    for (let key in data) {
        if (data[key] !== undefined && data[key] !== null) {
            let divider = newHash === '' ? '?' : '&';
            newHash += divider + key + '=' + data[key];
        }
    }
    return newHash;
};
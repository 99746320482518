import $ from 'jquery';

import LoginDialog from '../login-dialog/login-dialog';
const LOGIN_DIALOG_SELECTOR = '[data-dialog="user-form"]';

export default class Authentication {
    constructor() {
        this.isUserLoggedInUrl = '/mijn/account/isuserloggedin/';
        this.loginDialogUrl = '/mijn/login/dialog/';

        this.$loginDialogElement = $(document).find(LOGIN_DIALOG_SELECTOR);
    }

    getUserAuthenticationInfo() {
        const url = this.isUserLoggedInUrl;

        return $
            .ajax({
                url: url,
                contentType: 'json'
            })
            .then(response => ({
                isLoggedIn: response.LoggedIn,
                loginUrl: response.LoginUrl
            }))
            .catch(response => {
                console.error(`Error while getting user authentication info. URL: ${url}. Response: ${response}`);
            });
    }

    openLoginDialog() {
        return new Promise(resolve => {
            // eslint-disable-next-line no-new
            new LoginDialog(this.loginDialogUrl, function () {
                resolve(true);
            });
        });
    }

    closeLoginDialog() {
        if (this.$loginDialogElement && this.$loginDialogElement.length) {
            this.$loginDialogElement[0].dialog.close();
        }
    }
}

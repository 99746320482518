import $ from 'jquery';
import template from 'lodash/template';
import includes from 'lodash/includes';
import { default as gaLog, EVENT_TYPE } from '../media-viewer-map/media-viewer-gtm-events';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import {
    getCategories,
    enableCategory,
    disableCategory,
    expandAccordionPanel,
    collapseAccordionPanel
} from '../store/actions';
import { hashToList } from '../store/utils/data-structures';

const CATEGORIES_DATA_SELECTOR = '[data-object-map-drawer-categories]';
const ACCORDION_ITEM_TEMPLATE = '[data-accordion-item-template]';

const ACCORDION_ITEM = '[data-accordion-item]';
const ACCORDION_ITEM_HEADER_ATTR = 'data-accordion-item-header';
const ACCORDION_ITEM_HEADER = '[' + ACCORDION_ITEM_HEADER_ATTR +']';

const CATEGORY_ITEM_TEMPLATE_CHECKBOX = '[data-category-item-checkbox]';

const ACCORDION_CONTAINER = '[data-object-map-drawer-overview-accordion-container]';

const EXPANDED_CLASS = 'is-expanded';

export default class ObjectMapDrawerOverviewAccordion {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.categories',
            'ui.selectedCategories',
            'ui.expandedAccordionPanels'
        ]);

        this.$element = $(element);
        this.$accordionContainer = this.$element.find(ACCORDION_CONTAINER);
        this.categoryData = this.$element.find(CATEGORIES_DATA_SELECTOR).text();

        // include _.includes() for IE11 support
        this.$accordionItemTemplate = this.$element.find(ACCORDION_ITEM_TEMPLATE);
        this.compiledTemplate = template(this.$accordionItemTemplate.html(), { imports: { $, includes } });

        if (this.categoryData !== '') {
            let parsedCategoriesData = JSON.parse(this.categoryData);
            this.store.dispatch(getCategories(parsedCategoriesData));
        }

        this.subscribeToStore();
        this.bindEvents();
        this.layout();
    }

    subscribeToStore() {
        this
            .connect
            .subscribe((state, prevState) => {
                if (state.ui.selectedCategories !== prevState.ui.selectedCategories ||
                    state.ui.expandedAccordionPanels !== prevState.ui.expandedAccordionPanels) {
                    this.layout();
                }
            });
    }

    bindEvents() {
        this.$element.on('click', ACCORDION_ITEM_HEADER, event => {
            this.handleAccordionPanelAction(event);
        });

        this.$element.on('change', CATEGORY_ITEM_TEMPLATE_CHECKBOX, event => {
            this.handleCategoryAction(event);
        });
    }

    layout() {
        const state = this.store.getState();
        const categories = hashToList(state.entities.categories);
        const selectedCategories = state.ui.selectedCategories;
        const expandedAccordionPanels = state.ui.expandedAccordionPanels;

        if (!categories === {}) {
            return;
        }

        const groupedCategories = this.groupCategories(categories);

        const html = groupedCategories.map(type =>
            this.compiledTemplate({
                ...type,
                selectedCategories,
                expandedAccordionPanels
            })
        );

        this.$accordionContainer.html(html);
    }

    groupCategories(categories) {
        return categories.reduce((r, item) => {
            let current = r.hash[item.type];

            if (!current) {
                current = r.hash[item.type] = {
                    name: item.typeName,
                    type: item.type,
                    icon: item.icon,
                    categories: []
                };

                r.arr.push(current);
            }

            current.categories.push({
                id: item.id,
                name: item.name
            });

            return r;
        }, { hash: {}, arr: [] }).arr;
    }

    handleAccordionPanelAction(event) {
        const currentTarget = $(event.currentTarget);
        const currentTargetType = currentTarget.attr(ACCORDION_ITEM_HEADER_ATTR);

        if (!currentTarget.parents(ACCORDION_ITEM).hasClass(EXPANDED_CLASS)) {
            this.store.dispatch(expandAccordionPanel(currentTargetType));
        } else {
            this.store.dispatch(collapseAccordionPanel(currentTargetType));
        }
    }

    handleCategoryAction(event) {
        const id = event.target.value;
        const checked = event.target.checked;

        if (checked) {
            this.store.dispatch(enableCategory(id));

            gaLog.services(EVENT_TYPE.CATEGORY, id);
        } else {
            this.store.dispatch(disableCategory(id));
        }
    }
}

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import SearchElementInViewport from '../search-element-in-viewport/search-element-in-viewport';

// what does this module expose?
export default SearchNoResults;

// component configuration
const COMPONENT_SELECTOR = '[data-search-no-results]';
const COMPONENT_POSITION_TOP = 0.4; // 40% height of viewport
const WARNING_CLASS = 'search-no-results-warning';
const RESULTS_UPDATED_EVENT = 'resultsUpdated';

function SearchNoResults(element) {
    const component = this;
    component.element = element;
    component.$element = $(component.element);
    component.noResults = new SearchElementInViewport(component.element, COMPONENT_POSITION_TOP);

    component.updateState();
}

/**
 * Center the component inside the viewport to show the user there are 0 results
 */
SearchNoResults.prototype.updateState = function () {
    const component = this;
    component.noResults.updateVerticalPosition();

    // class may have been added already by clicking another filter, so remove it first
    component.$element.removeClass(WARNING_CLASS).addClass(WARNING_CLASS);
};

SearchNoResults.enhance = function() {
    const element = $(COMPONENT_SELECTOR)[0];

    if (element !== undefined) {
        return new SearchNoResults(element);
    }
};

$(document).on(RESULTS_UPDATED_EVENT, SearchNoResults.enhance);
SearchNoResults.enhance();
import $ from 'jquery';

export default ToggleAllInputs;

var ALL_INPUTS_HANDLER_SELECTOR = '[data-toggle-all-inputs-handle]';

function ToggleAllInputs(element) {
    var component = this;
    component.$element = $(element);

    component.$inputs = component.$element.find('input').not(ALL_INPUTS_HANDLER_SELECTOR);
    component.$handle = component.$element.find(ALL_INPUTS_HANDLER_SELECTOR);
    component.totalAmountInputs = component.$inputs.length;

    component.$inputs.on('change', function() {
        var amountCheckedInputs = component.$element.find('input:checked').not(ALL_INPUTS_HANDLER_SELECTOR).length;
        var isChecked = amountCheckedInputs === component.totalAmountInputs;
        component.$handle.prop('checked', isChecked);
    });

    component.$handle.on('change', function() {
        var isChecked = component.$handle.is(':checked');
        component.changeCheckedState(isChecked);
    });

    component.initializeHandler();
}

ToggleAllInputs.prototype.initializeHandler = function () {
    var component = this;
    var isChecked = component.$handle.is(':checked');
    if (isChecked) {
        component.changeCheckedState(isChecked);
    }
};

ToggleAllInputs.prototype.changeCheckedState = function(isChecked) {
    var component = this;

    component.$inputs.each(function() {
        var $input = $(this);

        if (isChecked === true && !$input.is(':checked')) {
            $input.prop('checked', isChecked);
            $input.trigger('change');
        } else if (isChecked === false && $input.is(':checked')) {
            $input.prop('checked', isChecked);
            $input.trigger('change');
        }
    });
};


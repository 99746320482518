import $ from 'jquery';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import {
    getPersonalPlaces,
    getTravelTimes,
    setMediaViewerHash,
    setCategories
} from '../store/actions';
import {default as gaLog, EVENT_TYPE} from '../media-viewer-map/media-viewer-gtm-events';
import Cookies from '../app-utils/app-utils-cookies';

const COMPONENT_SELECTOR = '[data-object-locations]';
const ENDPOINT_GET_ROUTE_ATTR='data-object-locations-route-endpoint';
const ENTRY_POINT_SELECTOR = '[data-entry-point]';
const EVENT_USER_AUTHENTICATE = 'user-authenticated-event';
const CATEGORIES_COOKIE_NAME = 'selected-categories';

class ObjectLocations {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.personalPlaces',
            'ui.modality',
            'ui.userLoggedIn',
            'getPersonalPlaces.loaded',
            'getPersonalPlaces.error',
            'addPersonalPlace.loaded',
            'addPersonalPlace.error',
            'ui.selectedCategories'
        ]);
        this.routeEndpoint = element.getAttribute(ENDPOINT_GET_ROUTE_ATTR);
        this.Cookies = Cookies;

        if (!this.routeEndpoint) {
            console.error('object-locations configuration error');
        }

        this.connectToStore();
        this.bindEvents();

        this.restoreCategories();
    }

    connectToStore() {
        const calledBackendWithoutError = (prop, state, prevState) => {
            return state[prop].loaded &&
                !state[prop].error &&
                state[prop].loaded !== prevState[prop].loaded;
        };

        this.connect.subscribe((state, prevState) => {
            if (state.ui.selectedCategories !== prevState.ui.selectedCategories) {
                this.rememberCategories(state.ui.selectedCategories);
            }
            const userWasLoggedOut = prevState.ui.userLoggedIn === false || prevState.ui.userLoggedIn === null;
            const loggedInSuccessfully = state.ui.userLoggedIn && userWasLoggedOut;

            if (calledBackendWithoutError('addPersonalPlace', state, prevState)
                || loggedInSuccessfully) {
                this.store.dispatch(getPersonalPlaces());
            }

            if (calledBackendWithoutError('getPersonalPlaces', state, prevState)) {
                // personal places loaded AFTER a place has been added
                if (state.addPersonalPlace.loaded) {
                    const lastPersonalPlace = Object.values(state.entities.personalPlaces).filter((pp) => {
                        return pp.isLatest;
                    });
                    if (lastPersonalPlace.length === 1) {
                        this.store.dispatch(setMediaViewerHash(`kaart/locatie/${lastPersonalPlace[0].id}`));
                    }
                }
                // personal places loaded and getTravelTimes not loaded
                if (!$.isEmptyObject(state.entities.personalPlaces)) {
                    const cacheKey = Object.keys(state.entities.locations).join(',');
                    this.store.dispatch(getTravelTimes(state.ui.globalObjectId, state.ui.modality, cacheKey, this.routeEndpoint));
                }
            }
        });
    }

    bindEvents() {
        $(document)
            .on('click', ENTRY_POINT_SELECTOR, (event) => {
                const entryPoint = $(event.target).data('entry-point');
                if (entryPoint === 'open-map') { //unused somehow
                    gaLog.travelTime(EVENT_TYPE.ENTRY_POINT_MAP, '');
                }
                else if (entryPoint === 'add-location') {
                    gaLog.travelTime(EVENT_TYPE.ENTRY_POINT_ADD, '');
                }
            })
            .on(EVENT_USER_AUTHENTICATE, (_, data) => {
                gaLog.travelTime(data.loginType, 'poi');
            });
    }

    rememberCategories(categories) {
        this.Cookies.setCookie(CATEGORIES_COOKIE_NAME, categories.join('|'));
    }

    restoreCategories() {
        const cookieCategories = this.Cookies.getCookie(CATEGORIES_COOKIE_NAME, '');
        if (cookieCategories === '') {
            return;
        }

        const categories = cookieCategories.split('|');
        this.store.dispatch(setCategories(categories));
    }
}

//depend on component being present on the page, but initialize once and only once
if ($(COMPONENT_SELECTOR).length > 0) {
    $(COMPONENT_SELECTOR).first().each((_, element) => new ObjectLocations(element));
}

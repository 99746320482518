import $ from 'jquery';

export default FormAlertOnLeave;

const COMPONENT_DATA_ATTR = 'form-alert-on-leave';
const COMPONENT_SELECTOR = `[data-${COMPONENT_DATA_ATTR}]`;

function FormAlertOnLeave(element) {
    const component = this;
    component.$element = $(element);
    component.message = component.$element.data(COMPONENT_DATA_ATTR);
    component.isDirty = false;

    component.bindEvents();
}

FormAlertOnLeave.prototype.bindEvents = function () {
    const component = this;

    component.$element.find('input,textarea,select').on('change', () => component.setDirty(true));
    component.$element.on('submit', () => component.setDirty(false));
    $(window).on('beforeunload', () => component.pageLeaveHandler());
};

FormAlertOnLeave.prototype.setDirty = function (isDirty) {
    const component = this;

    component.isDirty = isDirty;
};

FormAlertOnLeave.prototype.pageLeaveHandler = function () {
    const component = this;

    if (component.isDirty) {
        return confirm(component.message);
    }
};

$(COMPONENT_SELECTOR).each((index, element) => new FormAlertOnLeave(element));

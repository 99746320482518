import $ from 'jquery';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import {addPersonalPlace, setMediaViewerHash, changeDrawer, setActiveLocation} from '../store/actions';
import SaveLocationForm from '../save-location-form/save-location-form';
import { POI_DETAIL_DRAWER } from '../store/constants/drawer-names';
import { DRAWER_HIDDEN } from '../store/constants/drawer-states';
import {default as gaLog, EVENT_TYPE} from '../media-viewer-map/media-viewer-gtm-events';

import { SELECTOR as SAVE_LOCATION_FORM_ELEMENT_SELECTOR } from '../save-location-form/save-location-form';
import AuthenticationRedux from '../authentication/authentication-redux';

const BACKGROUND_WHITE_SELECTOR = '.white-bg';

const EVENT_REFRESH_PAGE_AFTER_CLOSE = 'refresh-page-after-close';

const IS_HIDDEN_CLASS = 'is-hidden';

const isUserLoggedIn = state => state.ui.userLoggedIn;

export default class DetailDrawerSaveLocationFormContainer {
    constructor(element, store) {
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'addPersonalPlace.loaded',
            'addPersonalPlace.error',
            'getPersonalPlaces.loaded',
            'getPersonalPlaces.error',
            'ui.mediaViewerHash',
        ]);

        this.handleSaveLocationSubmit = this.handleSaveLocationSubmit.bind(this);
        this.handleCancelSaveLocation = this.handleCancelSaveLocation.bind(this);
        this.onSuggestingItems = this.onSuggestingItems.bind(this);

        this.$element = $(element);
        this.$whiteBackground = this.$element.find(BACKGROUND_WHITE_SELECTOR);
        this.$saveLocationForm = this.$element.find(SAVE_LOCATION_FORM_ELEMENT_SELECTOR);
        this.saveLocationForm = new SaveLocationForm(this.$saveLocationForm, this.handleSaveLocationSubmit, this.handleCancelSaveLocation, this.onSuggestingItems);
        this.authenticationRedux = new AuthenticationRedux();
    }

    onSuggestingItems(show) {
        this.$whiteBackground.toggleClass(IS_HIDDEN_CLASS, !show);
    }

    handleSaveLocationSubmit(formData) {
        const state = this.store.getState();

        return Promise
            .resolve(isUserLoggedIn(state))
            .then(isLoggedIn => {
                if (isLoggedIn) {
                    return this.saveLocation(formData);
                } else {
                    return this
                        .authenticationRedux
                        .openLoginDialog()
                        .then(() => {
                            $(document).trigger(EVENT_REFRESH_PAGE_AFTER_CLOSE);
                            return this.saveLocation(formData);
                        });
                }
            });
    }

    openLoginDialog() {
        return this.authenticationRedux.openLoginDialog();
    }

    isFacilityMarker(locationId) {
        const state = this.store.getState();
        return state.entities.locations[locationId] !== undefined
            && state.entities.voorzieningen[locationId] !== undefined;
    }

    saveLocation(locationInfo) {
        const location = locationInfo.location;
        const label = locationInfo.label;
        const lat = locationInfo.coordinates.lat;
        const lng = locationInfo.coordinates.lng;

        this.authenticationRedux.closeLoginDialog();

        this.store.dispatch(addPersonalPlace({ location, label, lat, lng }));

        gaLog.travelTime(EVENT_TYPE.SAVE_POI, location);
    }

    handleCancelSaveLocation() {
        const state = this.store.getState();
        const activeLocationId = state.ui.activeLocationId;

        if (activeLocationId) {
            this.store.dispatch(setMediaViewerHash(`kaart/locatie/${activeLocationId}`));
            this.store.dispatch(setActiveLocation(activeLocationId));
        } else {
            this.store.dispatch(changeDrawer(POI_DETAIL_DRAWER, DRAWER_HIDDEN));
            this.store.dispatch(setMediaViewerHash('kaart'));
        }
    }


    setLocation(location) {
        if (!location) {
            return;
        }
        // When is a facility, it automatically fills the autosuggest with the facility label and disable it
        if (this.isFacilityMarker(location.id)) {
            this.saveLocationForm.setLocation(
                location.id,
                location.label,
                location.lat,
                location.lng
            );
            this.saveLocationForm.disableLocationInput();
        } else {
            this.saveLocationForm.setLocation(
                location.id,
                location.location,
                location.lat,
                location.lng
            );
        }
    }
}

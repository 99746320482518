// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

export default CookiePreferences;

// constant definitions
const COMPONENT_SELECTOR = '[data-cookie-preferences]';
const TAB_TRIGGER_SELECTOR = '[data-tabs-trigger]';
const TARGET_TYPE_ATTR = 'data-tabs-target-type';
const VISIBLE_CLASS = 'is-visible';

function CookiePreferences(element) {
    const component = this;
    component.$element = $(element);
    component.$trigger = component.$element.find(TAB_TRIGGER_SELECTOR);

    // show the tabs toggle only when js is enabled
    component.$trigger.addClass(VISIBLE_CLASS);

    // show standaard tabs only when js is enabled
    const $standaardTab = component.$element.find('['+TARGET_TYPE_ATTR+'="standaard"]');
    $standaardTab.addClass(VISIBLE_CLASS);
}

CookiePreferences.initialize = function () {
    $(COMPONENT_SELECTOR).each((index, element) => new CookiePreferences(element));
};

CookiePreferences.initialize();
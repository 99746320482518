import $ from 'jquery';
import 'jquery-validation';
import debounce from 'lodash/debounce';

export default AddressAutocomplete;

const COMPONENT_SELECTOR = '[data-address-autocomplete]';
const INPUT_SELECTOR = '[data-address-autocomplete-input]';
const RESPONSE_SELECTOR = '[data-address-autocomplete-response]';
const RESPONSE_SUCCESS_SELECTOR = '[data-address-autocomplete-response-success]';
const RESPONSE_ERROR_SELECTOR = '[data-address-autocomplete-response-error]';
const RESPONSE_SUCCESS_MESSAGE_SELECTOR = '[data-success-message]';
const RESPONSE_ERROR_MESSAGE_SELECTOR = '[data-error-message]';
const POSTCODE_INPUT_SELECTOR = '[data-address-postcode]';
const HUISNUMMER_INPUT_SELECTOR = '[data-address-huisnummer]';
const TOEVOEGING_INPUT_SELECTOR = '[data-address-toevoeging]';
const ACTIVE_CLASS = 'is-active';
const RESPONSE_ACTIVE_CLASS = 'has-response';
const DEBOUNCE_DELAY = 300;
const CUSTOM_VALIDATE_EVENT = 'addressvalidated';
const CUSTOM_VALIDATE_EVENT_AFTER = '-after';
const CUSTOM_VALIDATE_EVENT_BEFORE = '-before';
const STORING_ERROR_MSG = 'Door een storing kunnen we geen postcodes opzoeken. Probeer het later opnieuw.';

function AddressAutocomplete(element) {
    const component = this;
    component.$element = $(element);
    component.$input = component.$element.find(INPUT_SELECTOR);
    component.$response = component.$element.find(RESPONSE_SELECTOR);
    component.$responseSuccess = component.$element.find(RESPONSE_SUCCESS_SELECTOR);
    component.$responseError = component.$element.find(RESPONSE_ERROR_SELECTOR);

    component.$postcodeElement = component.$element.find(POSTCODE_INPUT_SELECTOR);
    component.$postcodeRegex = new RegExp(component.$postcodeElement.data('val-regex-pattern'));

    component.$huisnummerElement = component.$element.find(HUISNUMMER_INPUT_SELECTOR);
    component.$toevoegingElement = component.$element.find(TOEVOEGING_INPUT_SELECTOR);

    component.lastAdresInputValue = [];

    component.bindEvents();

    component.checkValidation();

    // change default error message for huisnummer field
    $.extend($.validator.messages, {
        number: 'Dit huisnummer is niet geldig.'
    });
}

AddressAutocomplete.prototype.bindEvents = function() {
    const component = this;

    component.$input.on('blur', function(event) {
        // prevent the blur event to be executed twice
        event.stopImmediatePropagation();

        component.isValueChanged($(this));
        component.checkValidation();
    });

    // put this on separate input event because huisnummer and toevoeging fields need to detect the idle before it actually validate the value
    component.$element.on('input', HUISNUMMER_INPUT_SELECTOR + ',' + TOEVOEGING_INPUT_SELECTOR,
        debounce(function(event) {
            if (component.isValueChanged($(event.target)))
                component.checkValidation();
        }, DEBOUNCE_DELAY)
    );

    component.$huisnummerElement.on('keyup', function() {
        if (!component.$huisnummerElement.valid())
            component.resetResponseMessages();
    });

    component.$postcodeElement.on('keyup', function(event) {
        const $element = $(event.target);

        if (!component.isValueChanged($element)) return;

        component.resetResponseMessages();
        component.changeInputFocus($element);
    });
};

AddressAutocomplete.prototype.isValueChanged = function($element) {
    const component = this;
    const elementName = $element.data('address-field-name');
    let elementValue = $element.val();

    if (elementName == 'postcode' && elementValue != '') {
        elementValue = component.reformatPostcode(elementValue);
        $element.val(elementValue);
    }

    // if the value does not change, no need to revalidate the address
    if (
        component.lastAdresInputValue[elementName] !== undefined &&
        component.lastAdresInputValue[elementName] === elementValue
    )
        return false;

    component.lastAdresInputValue[elementName] = elementValue;
    return true;
};

AddressAutocomplete.prototype.changeInputFocus = function($element) {
    const component = this;
    const elementValue = $element.val();

    if (!elementValue.match(component.$postcodeRegex)) return false;

    const currentElementIndex = component.$input.index($element);
    const $nextElement = component.$input.eq(currentElementIndex + 1);

    $nextElement.focus();
    $nextElement.select();

    return true;
};

AddressAutocomplete.prototype.reformatPostcode = function(postcodeValue) {
    const component = this;

    if (!postcodeValue) return;

    let postcodeParts = postcodeValue.match(component.$postcodeRegex);

    if (postcodeParts)
        postcodeValue = (postcodeValue.substr(0, 4) + ' ' + postcodeValue.substr(-2)).toUpperCase();

    return postcodeValue;
};

AddressAutocomplete.prototype.checkValidation = function() {
    const component = this;
    if (component.$postcodeElement.val() && component.$huisnummerElement.val()
        && !component.$postcodeElement.hasClass('input-validation-error') && !component.$huisnummerElement.hasClass('input-validation-error'))
        component.validateOnBackend();
};

AddressAutocomplete.prototype.resetResponseMessages = function() {
    const component = this;

    component.$element.trigger(CUSTOM_VALIDATE_EVENT + CUSTOM_VALIDATE_EVENT_BEFORE);
    component.$responseSuccess.removeClass(ACTIVE_CLASS);
    component.$responseError.removeClass(ACTIVE_CLASS);
    component.$element.removeClass(RESPONSE_ACTIVE_CLASS);
};

AddressAutocomplete.prototype.validateOnBackend = function() {
    const component = this;
    const actionUrl = component.$element.attr('data-url');
    const complementUrl = component.$element.attr('data-complement-url');
    const postcode = component.$postcodeElement.val();
    const huisnummer = component.$huisnummerElement.val();
    const toevoeging = component.$toevoegingElement.val();

    $.ajax({
        url: complementUrl,
        method: 'GET',
        data: {
            postcode: postcode,
            huisnummer: huisnummer,
        },
        success: function(result) {
            component.populateToevoegingDropdown(toevoeging, result);
        },
        error: function() {
            component.resetResponseMessages();
            component.showErrorResponse(STORING_ERROR_MSG);
        },
    });

    $.ajax({
        url: actionUrl,
        method: 'GET',
        data: {
            postcode: postcode,
            huisnummer: huisnummer,
            huisnummertoevoeging: toevoeging,
        },
        success: function(result) {
            component.resetResponseMessages();

            if (result.success == false)
                component.showErrorResponse('Dit adres kennen wij niet.');
            else {
                component.showSuccessResponse();
                component.handleSuccessResponse(result);
            }

            component.handleSuccessResponse(result);
        },
        error: function() {
            component.resetResponseMessages();
            component.showErrorResponse(STORING_ERROR_MSG);
        },
    });
};

AddressAutocomplete.prototype.populateToevoegingDropdown = function (toevoeging, result) {
    if (!(result instanceof Array) || result.length == 0) return;

    const component = this;
    component.$toevoegingElement.empty();

    result.forEach(function(value) {
        let selected = toevoeging && toevoeging !== '' && value === toevoeging;
        let optionText = value === '' ? '-' : value;
        let option = new Option(optionText, value, false, selected);
        component.$toevoegingElement.append(option);
    });
};

AddressAutocomplete.prototype.handleSuccessResponse = function(result) {
    const component = this;
    let huisnummertoevoeging = '';

    if (result.huisnummertoevoeging != null) {
        huisnummertoevoeging = '-' + result.huisnummertoevoeging;
    }
    component.$responseSuccess.find(RESPONSE_SUCCESS_MESSAGE_SELECTOR).html(result.straat + ' ' + result.huisnummer + huisnummertoevoeging + ', ' + result.postcode + ' ' + result.woonplaats);
};

AddressAutocomplete.prototype.showSuccessResponse = function() {
    const component = this;
    component.$responseSuccess.addClass(ACTIVE_CLASS);
    component.$element.addClass(RESPONSE_ACTIVE_CLASS);
    component.$element.trigger(CUSTOM_VALIDATE_EVENT + CUSTOM_VALIDATE_EVENT_AFTER, true);
};

AddressAutocomplete.prototype.showErrorResponse = function(customError) {
    const component = this;
    component.$responseSuccess.removeClass(ACTIVE_CLASS);
    component.$responseError.addClass(ACTIVE_CLASS);
    component.$responseError.find(RESPONSE_ERROR_MESSAGE_SELECTOR).html(customError);
    component.$element.addClass(RESPONSE_ACTIVE_CLASS);
    component.$element.trigger(CUSTOM_VALIDATE_EVENT + CUSTOM_VALIDATE_EVENT_AFTER, false);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new AddressAutocomplete(element);
});

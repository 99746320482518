export function apiMiddleware() {
    return next => action => {
        const { promise, types, ...rest } = action;

        if (!promise) {
            return next(action);
        }

        const [REQUEST, SUCCESS, FAILURE] = types;
        next({ ...rest, type: REQUEST });
        return promise
            .then(result => {
                return next({
                    ...rest,
                    result: result,
                    type: SUCCESS
                });
            })
            .catch(error => {
                return next({ ...rest, error: error.message, type: FAILURE });
            });
    };
}

import $ from 'jquery';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';

export default TopPosition;

var COMPONENT_ATTR = 'data-top-position';
var COMPONENT_SELECTOR = '[' + COMPONENT_ATTR + ']';
var ITEM_ATTR = 'data-top-position-item';
var ITEM_SELECTOR = '[' + ITEM_ATTR + ']';

function TopPosition(element) {
    var component = this;

    $(document).on('resultsUpdated', function() {
        $(COMPONENT_SELECTOR).each(function(index, elementOnUpdate) {
            component.init(elementOnUpdate);
        });
    });

    component.init(element);
}

TopPosition.prototype.init = function(element) {
    var component = this;
    component.element = element;
    component.$element = $(element);
    component.loggedEndpoints = [];
    component.endpoints = TopPosition.getEndPoints(element);
    component.logTracking(component.endpoints);

    component.bindEvents();
};

TopPosition.prototype.logTracking = function(endpoints) {
    var component = this;
    return endpoints.map(function(url) {
        component.loggedEndpoints.push(url);
        return $.ajax(url);
    });
};

TopPosition.prototype.bindEvents = function() {
    var component = this;

    $(window).on('resize', debounce(function() {

        var endpoints = TopPosition.getEndPoints(component.element);
        var notLogged = difference(endpoints, component.loggedEndpoints);

        if (notLogged.length > 0) {
            component.logTracking(notLogged);
        }
    }, 200));
};

TopPosition.getValidElements = function(element) {
    return element.find(ITEM_SELECTOR + ':visible');
};

TopPosition.getEndPoints = function(element) {
    var $items = TopPosition.getValidElements($(element));
    var endpoints = [];

    $items.each(function(index, topPositionElement) {
        endpoints.push($(topPositionElement).attr(ITEM_ATTR));
    });
    return endpoints;
};

$(COMPONENT_SELECTOR).each(function(index, element) {
    return new TopPosition(element);
});

import $ from 'jquery';

export default CookiePolicy;

// component configuration
const COMPONENT_SELECTOR = '[data-cookie-policy]';
const COOKIE_NAME_ATTR = 'data-cookie-policy-name';
const ACCEPTED_CLASS = 'is-accepted';
const AUTO_ACCEPTING_INTERACTIONS_SELECTOR = 'a:not([data-cookie-policy-ignore]), :input:not([data-cookie-policy-ignore]), .zoek-op-kaart';
const COOKIES_ACCEPTED_EVENT = 'cookiesaccepted';
const EVENT_NAMESPACE = '.cookie-policy';
const COOKIE_DEFAULT_VAL_ATTR = 'data-cookie-default-value';
const ENHANCED_CLASS = 'is-acceptable';

const COOKIE_TOGGLE = '[data-cookie-toggle]';
const COOKIE_TOGGLE_TARGET = '[data-cookie-target]';
const COOKIE_TOGGLE_TRIGGER = '[data-cookie-trigger]';

const $body = $('body');

function CookiePolicy(element) {
    const component = this;
    component.$element = $(element);
    component.cookieName = component.$element.attr(COOKIE_NAME_ATTR);
    component.cookieDefaultValue = component.$element.attr(COOKIE_DEFAULT_VAL_ATTR);

    component.$element.addClass(ENHANCED_CLASS);
    component.toggleExpand(element);

    // Listen to all clickable elements and if any of them is clicked, we assume the user is accepting the cookies.
    $body.on('click' + EVENT_NAMESPACE, AUTO_ACCEPTING_INTERACTIONS_SELECTOR, function() {
        component.acceptPolicy();
    });
}

CookiePolicy.prototype.toggleExpand = function(element) {
    $(element).find(COOKIE_TOGGLE_TARGET).addClass('hidden');
    $(element).find(COOKIE_TOGGLE_TRIGGER).on('click', function(event) {
        event.preventDefault();
        $(this)
            .hide()
            .parents(COOKIE_TOGGLE).find(COOKIE_TOGGLE_TARGET).removeClass('hidden');
    });
};

CookiePolicy.prototype.acceptPolicy = function() {
    this.setCookie();
    this.$element.addClass(ACCEPTED_CLASS);
    $body.off('click' + EVENT_NAMESPACE);
    $(window).trigger(COOKIES_ACCEPTED_EVENT);
    //check if consent framework exists and opt-in
    if (window.AS_OIL) {
        window.AS_OIL.triggerOptIn();
    }
};

/**
 * sets cookie and return the cookie value
 * @returns {string}
 */
CookiePolicy.prototype.setCookie = function() {
    const component = this;
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    const cookie = component.cookieName + '='+ component.cookieDefaultValue+'; expires=' + date.toGMTString() + '; path= /';

    document.cookie = cookie;
    return cookie;
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => {
    return new CookiePolicy(element);
});

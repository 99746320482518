// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default FilterReset;

// component configuration
var COMPONENT_SELECTOR = '[data-filter-reset]';
var RESETTABLE_ELEMENTS_SELECTOR = '[data-resettable]';
var IS_ENHANCED_CLASS = 'is-enhanced';

function FilterReset(element) {
    var component = this;
    component.$element = $(element);
    component.$element.addClass(IS_ENHANCED_CLASS);
    component.$form = component.$element.closest('form');
    component.$resetElements = component.$form.find(RESETTABLE_ELEMENTS_SELECTOR);

    component.$element.on('click', function(event) {
        event.preventDefault();
        component.$resetElements.trigger('resetfilter');
    });

    $(document).on('setfiltercount', function(event, eventArgs) {
        var isDisabled = true;
        if (eventArgs.amount > 0) {
            isDisabled = false;
        }
        component.$element.prop('disabled', isDisabled);
    });
}

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new FilterReset(element);
});
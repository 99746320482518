// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

export default AanbodMobileSearchbox;

// constant definitions
const COMPONENT_SELECTOR = '[data-aanbod-mobile-searchbox]';
const SEARCH_BOX_FOCUS_EVENT = 'focusonsearchbox';

function AanbodMobileSearchbox(element) {
    const component = this;
    component.$element = $(element);
    // To open pop-up search page for mobile
    component.$element.on('click', () => {
        $(window).trigger(SEARCH_BOX_FOCUS_EVENT);
    });
}

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new AanbodMobileSearchbox(element));
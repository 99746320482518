// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default ClearInput;

// component configuration
const COMPONENT_SELECTOR = '[data-clear-input]';
const INPUT_SELECTOR = '[data-dirty-input]';
const DIRTY_CLASS = 'is-dirty';
const TRIGGER_SELECTOR = '[data-clear-input-handle]';

function ClearInput(element) {
    const component = this;
    component.$element = $(element);
    component.$trigger = component.$element.find(TRIGGER_SELECTOR);
    component.$input = component.$element.find(INPUT_SELECTOR);
    component.bindEvents();
    if (component.$input.val() !== '') {
        component.$trigger.addClass(DIRTY_CLASS);
    }
}

ClearInput.prototype.clear = function() {
    const component = this;
    component.$input.val('');
    component.$trigger.removeClass(DIRTY_CLASS);
};

ClearInput.prototype.bindEvents = function() {
    const component = this;

    component.$input.on('keyup', function() {
        component.toggleInput();
    });

    component.$trigger.on('click', function() {
        component.clear();
    });
};

ClearInput.prototype.toggleInput = function() {
    const component = this;

    if (component.$input.val() === '') {
        component.handleEmptyInput();
    } else {
        component.handleInput();
    }
};

ClearInput.prototype.handleEmptyInput = function() {
    const component = this;
    component.$trigger.removeClass(DIRTY_CLASS);
};

ClearInput.prototype.handleInput = function() {
    const component = this;
    component.$trigger.addClass(DIRTY_CLASS);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new ClearInput(element));
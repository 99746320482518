import $ from 'jquery';

export default AutoDropdown;

const COMPONENT_SELECTOR = '[data-auto-dropdown]';
const SELECTED_SELECTOR = ':selected';

function AutoDropdown(element) {
    const component = this;
    component.$element = $(element);

    component.bindEvents();
}

AutoDropdown.prototype.bindEvents = function () {
    const component = this;

    component.$element.on('change', () => component.changeHandler());
};

AutoDropdown.prototype.changeHandler = function () {
    const component = this;

    component.loadUrl(component.$element.find(SELECTED_SELECTOR).val());
};

AutoDropdown.prototype.loadUrl = function (url) {
    window.location =url;
};

$(COMPONENT_SELECTOR).each((index, element) => new AutoDropdown(element));

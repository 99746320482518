import { geometry } from '@targomo/core';

export default class GeometryUtils {
    static webMercatorToLatLng(point, elevation) {
        const raw = geometry.webMercatorToLatLng(point, elevation);
        return {
            lat: raw.lat, lng: raw.lng
        };
    }
}

import api from './helpers/api';

import {
    ADD_LOADED_TILE,
    ADD_LOCATION,
    ADD_PERSONAL_PLACE_FAIL,
    ADD_PERSONAL_PLACE_SUCCESS,
    ADD_PERSONAL_PLACE,
    ADD_VOORZIENINGEN_MARKERS,
    CHANGE_DRAWER,
    COLLAPSE_ACCORDION_PANEL,
    DELETE_LOCATION,
    DELETE_PERSONAL_PLACE_FAIL,
    DELETE_PERSONAL_PLACE_SUCCESS,
    DELETE_PERSONAL_PLACE,
    DISABLE_CATEGORY,
    ENABLE_CATEGORY,
    EXPAND_ACCORDION_PANEL,
    GET_CATEGORIES,
    SET_CATEGORIES,
    GET_HEADER_AS_PARTIAL_VIEW,
    GET_PERSONAL_PLACES_FAIL,
    GET_PERSONAL_PLACES_SUCCESS,
    GET_PERSONAL_PLACES,
    GET_TRAVEL_TIMES_FAIL,
    GET_TRAVEL_TIMES_SUCCESS,
    GET_TRAVEL_TIMES,
    GET_VOORZIENINGEN_FAIL,
    GET_VOORZIENINGEN_SUCCESS,
    GET_VOORZIENINGEN,
    SET_ACTIVE_LOCATION,
    SET_LOGIN_STATE,
    SET_MEDIA_VIEWER_HASH,
    SET_MEDIA_VIEWER_ROUTE,
    SET_MODALITY,
    SET_GLOBAL_OBJECT_ID,
    SET_POI_TRAVEL_DISTANCE,
    SET_TRAVEL_TIME_POLYGON_DATA,
    UPDATE_POLYGON_MODALITY_AND_TRAVEL_TIME,
    SHOW_LOADING_SPINNER
} from './constants/action-types';

function convertFloatToStringWithCommaSeparator(value) {
    return `${value}`.replace('.', ',');
}

export function setMediaViewerHash(mediaViewerHash) {
    return { type: SET_MEDIA_VIEWER_HASH, mediaViewerHash };
}

export function setMediaViewerRoute(mediaViewerRoute) {
    return { type: SET_MEDIA_VIEWER_ROUTE, mediaViewerRoute };
}

export function setGlobalObjectID(globalObjectId) {
    return { type: SET_GLOBAL_OBJECT_ID, globalObjectId };
}

export function updatePolygonModalityAndTravelTime(selectedToggle) {
    return { type: UPDATE_POLYGON_MODALITY_AND_TRAVEL_TIME, selectedToggle };
}

export function setModality(label) {
    return { type: SET_MODALITY, label };
}

export function changeDrawer(drawerName, drawerPosition) {
    return { type: CHANGE_DRAWER, drawerName, drawerPosition };
}

export function setLoginState(loggedIn, loginUrl) {
    return { type: SET_LOGIN_STATE, loggedIn, loginUrl };
}

export function updateHeader(appHeader) {
    return { type: GET_HEADER_AS_PARTIAL_VIEW, appHeader };
}

export function showLoadingSpinner(show) {
    return { type: SHOW_LOADING_SPINNER, show };
}

export function updateAppHeader() {
    return dispatch =>
        api
            .get('/navigation/topforasyncheader/')
            .then((responseHeader)=> {
                dispatch(updateHeader(responseHeader));
            });
}

export function getUserLoginState(url) {
    return dispatch =>
        api
            .get(url)
            .then((response) => {
                if (response.LoggedIn === true) {
                    dispatch(setLoginState(response.LoggedIn, response.LoginUrl));
                } else {
                    dispatch(setLoginState(response.LoggedIn, response.LoginUrl));
                }
            });
}

export function setActiveLocation(id) {
    return { type: SET_ACTIVE_LOCATION, id };
}

export function addLocation(location) {
    return { type: ADD_LOCATION, location };
}

export function deleteLocation(id) {
    return { type: DELETE_LOCATION, id };
}

export function getPersonalPlaces() {
    return {
        types: [GET_PERSONAL_PLACES, GET_PERSONAL_PLACES_SUCCESS, GET_PERSONAL_PLACES_FAIL],
        promise: api.get('/search/personalplaces/list/')
    };
}

export function getTravelTimes(globalObjectId, modality, cacheKey, endpoint) {
    return {
        types: [GET_TRAVEL_TIMES, GET_TRAVEL_TIMES_SUCCESS, GET_TRAVEL_TIMES_FAIL],
        promise: api.get(`${endpoint}?globalObjectId=${globalObjectId}&travelMode=${modality}&cacheKey=${cacheKey}`)
    };
}

export function addPersonalPlace(data) {
    const convertedLat = convertFloatToStringWithCommaSeparator(data.lat);
    const convertedLng = convertFloatToStringWithCommaSeparator(data.lng);

    return {
        types: [ADD_PERSONAL_PLACE, ADD_PERSONAL_PLACE_SUCCESS, ADD_PERSONAL_PLACE_FAIL],
        promise: api.post('/search/personalplaces/save/', `lat=${convertedLat}&lng=${convertedLng}&location=${data.location}&label=${data.label}`)
    };
}

export function deletePersonalPlace(id) {
    return {
        id: id,
        types: [DELETE_PERSONAL_PLACE, DELETE_PERSONAL_PLACE_SUCCESS, DELETE_PERSONAL_PLACE_FAIL],
        promise: api.post('/search/personalplaces/remove/', `id=${id}`)
    };
}

export function getCategories(data) {
    return { type: GET_CATEGORIES, data };
}

export function enableCategory(id) {
    return { type: ENABLE_CATEGORY, id };
}

export function disableCategory(id) {
    return { type: DISABLE_CATEGORY, id };
}

export function setCategories(ids) {
    return { type: SET_CATEGORIES, ids };
}

export function getVoorzieningen(url, data) {
    return {
        types: [GET_VOORZIENINGEN, GET_VOORZIENINGEN_SUCCESS, GET_VOORZIENINGEN_FAIL],
        promise: api.get(url, data)
    };
}

export function addLoadedTile(tileId) {
    return { type: ADD_LOADED_TILE, tileId };
}

export function addVoorzieningenMarkers(markers) {
    return { type: ADD_VOORZIENINGEN_MARKERS, markers };
}

export function expandAccordionPanel(id) {
    return { type: EXPAND_ACCORDION_PANEL, id };
}

export function collapseAccordionPanel(id) {
    return { type: COLLAPSE_ACCORDION_PANEL, id };
}

export function setPoiTravelDistance(data) {
    return { type: SET_POI_TRAVEL_DISTANCE, data };
}

export function getTravelTimePolygonData(location, travelModeAndTime) {
    const url = `/search/traveltimepolygons/getpolygons/?objectId=${location.id}&lat=${location.lat}&lon=${location.lng}&travelMode=${travelModeAndTime.modality}&timeInMinutes=${travelModeAndTime.travelTime}`;

    return dispatch => api
        .get(url)
        .then((response) => {
            dispatch(showLoadingSpinner(false));
            dispatch(setTravelTimePolygonData(response));
        }).catch((response) => {
            if (response.message != 'abort') {
                dispatch(showLoadingSpinner(false));
            }
        });
}

export function setTravelTimePolygonData(data) {
    return { type: SET_TRAVEL_TIME_POLYGON_DATA, data };
}

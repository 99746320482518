import reduxStore from '../store/store';
import Authentication from './authentication';
import {
    getUserLoginState,
    setLoginState
} from '../store/actions';

export default class AuthenticationRedux {
    constructor() {
        this.store = reduxStore;
        this.authentication = new Authentication();
    }

    fetchUserAuthenticationInfo() {
        const { isUserLoggedInUrl } = this.authentication;
        this.store.dispatch(getUserLoginState(isUserLoggedInUrl));
    }

    openLoginDialog() {
        this.store.dispatch(setLoginState(false));

        return this
            .authentication
            .openLoginDialog()
            .then(() => {
                this.store.dispatch(setLoginState(true));
            });
    }

    closeLoginDialog() {
        this.authentication.closeLoginDialog();
    }
}

import { combineReducers } from 'redux';
import categories from './categories';
import locations, * as fromLocations from './locations';
import personalPlaces from './personal-places';
import travelTimes from './travel-times';
import voorzieningen from './voorzieningen';

export default combineReducers({
    categories,
    locations,
    personalPlaces,
    travelTimes,
    voorzieningen
});

export const getLocationsByIds = (state, ids) =>
    fromLocations.getByIds(state.locations, ids);

import $ from 'jquery';
import ArrayExtensions from '../app-utils/app-utils-array-extensions';

export default ToggleVisibility;

// constant definitions
const COMPONENT_SELECTOR = '[data-toggle-visibility]';
const CONTAINER_DATA = 'toggle-visibility-container';
const CONTAINERS_SELECTOR = `[data-${CONTAINER_DATA}]`;
const TRIGGER_DATA = 'toggle-visibility-trigger';
const TRIGGER_SELECTOR = `[data-${TRIGGER_DATA}]`;
const IS_HIDDEN_CLASS = 'is-hidden';

function ToggleVisibility(element) {
    const component = this;
    component.$element = $(element);

    component.$$containers = new ArrayExtensions(component.$element.find(CONTAINERS_SELECTOR));
    component.iActiveContainer = component.$$containers.getIndexBy(c => !$(c).hasClass(IS_HIDDEN_CLASS));
    component.$triggers = component.$element.find(TRIGGER_SELECTOR);

    component.bindEvents();
}

ToggleVisibility.prototype.bindEvents = function () {
    const component = this;

    component.$triggers.on('click', (event) => component.toggle(event));
};

ToggleVisibility.prototype.toggle = function (event) {
    const component = this;
    const $trigger = $(event.target);
    const $triggerThisContainer = $trigger.data(TRIGGER_DATA);

    const index = $triggerThisContainer ? //Do I have to trigger a specific container?
    //YES! Find the container with that name
        component.$$containers.getIndexBy(c => $(c).data(CONTAINER_DATA) === $triggerThisContainer) :
        //NOPE! Give me the next element (circular, so after last comes first again)
        component.$$containers.getNextIndexCircular(component.iActiveContainer);

    component.showByIndex(index);
};

ToggleVisibility.prototype.showByIndex = function (index) {
    const component = this;
    if (index >= 0 && index < component.$$containers.length) {
        component.$$containers.addClass(IS_HIDDEN_CLASS);
        $(component.$$containers[index]).removeClass(IS_HIDDEN_CLASS);
        component.iActiveContainer = index;
    }
};

$(COMPONENT_SELECTOR).each((index, element) => new ToggleVisibility(element));

import $ from 'jquery';
import ClearableTextbox from '../clearable-textbox/clearable-textbox';
import ListResult, { SELECTOR as LIST_RESULT_SELECTOR } from '../list-result/list-result';

const AUTO_SUGGEST_INPUT_ELEMENT_SELECTOR = '[data-travel-time-input-container] .clearable-textbox';
const LIST_ITEM_SELECTOR = '.suggest-item';
const ICON_CLASS = 'icon-search-grey';
const MINIMUM_CHARACTER_COUNT_FOR_RESULTS = 1;

const KEY_CODE = {
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    ENTER: 13
};

const IS_ACTIVE_CLASS = 'is-active';
const noop = () => {};

export default class AutoSuggest {
    constructor(element, onQueryChangedCallback, onItemSelected, onSuggestionClear, onRemoveFormErrorMessage, onSuggestingChangedHandler) {
        this.onItemSelected = onItemSelected || noop;
        this.onQueryChangedCallback = onQueryChangedCallback || noop;
        this.onSuggestionClear = onSuggestionClear || noop;
        this.onRemoveFormErrorMessage = onRemoveFormErrorMessage || noop;
        this.onSuggestingChangedHandler = onSuggestingChangedHandler || noop;

        this.clearSuggestion = this.clearSuggestion.bind(this);

        const textBoxMapEventHandlers = {
            input: event => this.addressSuggestInputChange(event),
            focus: event => this.onRemoveFormErrorMessage(event),
            blur: event => this.onTextBoxBlur(event)
        };

        this.$element = $(element);
        this.$clearableTextbox = this.$element.find(AUTO_SUGGEST_INPUT_ELEMENT_SELECTOR);
        this.$autoSuggestResults = this.$element.find(LIST_RESULT_SELECTOR);

        this.clearableTextbox = new ClearableTextbox(this.$clearableTextbox, textBoxMapEventHandlers, ICON_CLASS, this.clearSuggestion);
        this.autoSuggestList = new ListResult(this.$autoSuggestResults, this.onItemSelected);
        this.autoSuggestList.hideList();

        this.previousClearableTextboxValue = undefined;

        this.bindEvents();
    }

    bindEvents() {
        this.clearableTextbox.$input.on('keydown', event => {
            const stroke = event.which || event.keyCode;

            const currentSelectedItemIndex = this.getSelectedItemIndex();

            switch (stroke) {
                case KEY_CODE.ARROW_UP:
                    this.selectPreviousItem(currentSelectedItemIndex);
                    break;

                case KEY_CODE.ARROW_DOWN:
                    this.selectNextItem(currentSelectedItemIndex);
                    break;

                case KEY_CODE.ENTER:
                    if (currentSelectedItemIndex > -1) {
                        this.hideSuggestions();

                        const itemProperties = this.autoSuggestList.getItemPropertiesByIndex(currentSelectedItemIndex);
                        this.onItemSelected(itemProperties);
                    }
                    break;
            }
        });
    }

    onTextBoxBlur(event) {
        const newItemThatGetsFocus = event.relatedTarget;

        // this check is to prevent that - when clicking an item from the list,
        // the list is being hidden before we could actually handle the click event
        // for the list item.
        if (newItemThatGetsFocus !== this.autoSuggestList.$list[0]) {
            this.hideSuggestions();
        }
    }

    getSelectedItemIndex() {
        return this
            .$autoSuggestResults
            .find(LIST_ITEM_SELECTOR)
            .filter(`.${IS_ACTIVE_CLASS}`)
            .first()
            .index();
    }

    selectPreviousItem(currentItemIndex) {
        const previousItemIndex = currentItemIndex > 0 ? currentItemIndex - 1 : 0;
        this.setSelectedItem(previousItemIndex);
    }

    selectNextItem(currentItemIndex) {
        const nextItemIndex = currentItemIndex !== -1 ? currentItemIndex + 1 : 0;
        this.setSelectedItem(nextItemIndex);
    }

    setSelectedItem(index) {
        const $listItemElement = this.$autoSuggestResults.find(LIST_ITEM_SELECTOR).eq(index);

        if ($listItemElement.length < 1) {
            return;
        }

        this.$autoSuggestResults.find(LIST_ITEM_SELECTOR).removeClass(IS_ACTIVE_CLASS);
        $listItemElement.addClass(IS_ACTIVE_CLASS);

        this.scrollListItemIntoView($listItemElement[0]);
    }

    scrollListItemIntoView(element) {
        const containerElement = this.$autoSuggestResults[0];

        const visibleArea = {
            top: containerElement.scrollTop,
            bottom: containerElement.scrollTop + containerElement.clientHeight
        };

        const activeItemArea = {
            top: element.offsetTop,
            bottom: element.offsetTop + element.clientHeight
        };

        const activeItemVisible = activeItemArea.top >= visibleArea.top && activeItemArea.bottom <= visibleArea.bottom;

        if (!activeItemVisible) {
            containerElement.scrollTop = element.offsetTop;
        }
    }

    clearSuggestion() {
        this.onSuggestionClear();
        this.hideSuggestions();
        this.previousClearableTextboxValue = undefined;
    }

    addressSuggestInputChange(event) {
        const inputValue = event.target.value;
        const valueHasChanged = inputValue !== this.previousClearableTextboxValue;
        const valueIsLongEnough = inputValue.length >= MINIMUM_CHARACTER_COUNT_FOR_RESULTS;
        const valueIsEmpty = inputValue.length === 0;

        if (valueHasChanged && (valueIsLongEnough || valueIsEmpty)) {
            this.onQueryChangedCallback(inputValue);
        }

        this.previousClearableTextboxValue = inputValue;
    }

    setValue(value) {
        this.clearableTextbox.setValue(value);
        this.hideSuggestions();
    }

    updateList(listData) {
        this.autoSuggestList.layout(listData);
        this.onSuggestingChangedHandler(true);
    }

    hideSuggestions() {
        this.autoSuggestList.hideList();
        this.onSuggestingChangedHandler(false);
    }

    focus() {
        this.clearableTextbox.focus();
    }

    disable() {
        this.clearableTextbox.disable();
    }

    enable() {
        this.clearableTextbox.enable();
    }
}

// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import Separator from '../thousand-separator/thousand-separator';

// what does this module expose?
export default InputNumber;

// component configuration
const COMPONENT_SELECTOR = '[data-input-number]';
const INPUT_SELECTOR = '[data-input-number-field]';
const MASK_SELECTOR = '[data-input-number-mask]';
const THOUSAND_SEPARATOR = 'data-thousand-separator';
const DISPLAY_STRING = 'data-display-string';
const TOKEN = '{value}';

const KEY_CODE = {
    ENTER: 13
};

function InputNumber(element) {
    const component = this;
    component.$element = $(element);
    component.$input = component.$element.find(INPUT_SELECTOR);
    component.$mask = component.$element.find(MASK_SELECTOR);
    component.separator = component.$element.attr(THOUSAND_SEPARATOR);
    component.displayString = component.$element.attr(DISPLAY_STRING) || TOKEN;
    component.token = TOKEN;
    component.bindEvents();
}

InputNumber.prototype.bindEvents = function() {
    const component = this;

    component.$input.on('keypress', function(event) {
        if (event.keyCode === KEY_CODE.ENTER) {
            event.preventDefault();
            const $input = $(event.currentTarget);
            $input.blur();
        }
    });

    component.$input.on('blur', () => {
        component.updateMask();
    });
};

/**
 * update the mask with the data from the input field and add the thousand separator if needed
 */
InputNumber.prototype.updateMask = function() {
    const component = this;
    const number = component.$input.val();
    if (number > 0) {
        const formattedValue = Separator.format(parseInt(number, 10), component.separator);
        const displayString = component.displayString.replace(component.token, formattedValue);

        component.$mask.text(displayString);
    } else {
        component.$mask.text('');
    }
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new InputNumber(element);
});
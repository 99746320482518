import $ from 'jquery';
import find from 'lodash/find';
import reduxStore from '../store/store';
import Connect from '../store/connect';
import {
    setMediaViewerHash,
    deletePersonalPlace,
    setActiveLocation,
    deleteLocation
} from '../store/actions';
import { hashToList } from '../store/utils/data-structures';
import PersonalPlacesList from '../personal-places-list/personal-places-list';
import AuthenticationRedux from '../authentication/authentication-redux';

const COMPONENT_SELECTOR = '[data-my-personal-places]';

const LOGIN_BUTTON_SELECTOR = '[data-login-button]';
const SAVED_PLACES_LIST_SELECTOR = '[data-personal-places-list]';
const EMPTY_STATE_SELECTOR = '[data-empty-state]';
const NOT_LOGGEDIN_STATE_SELECTOR = '[data-not-loggedin-state]';
const IS_HIDDEN_CLASS = 'is-hidden';

const personalPlaces = state => state.entities.personalPlaces;
const locationsListSelector = state => hashToList(state.entities.locations);
const locationsWithPersonalPlaceSelector = state =>
    locationsListSelector(state)
        .filter(location => !!location.personalPlace);

const activeLocationIdSelector = state => state.ui.activeLocationId;

export default class MyPersonalPlaces {
    constructor(element, store) {
        this.onPersonalPlacesListItemSelected = this.onPersonalPlacesListItemSelected.bind(this);
        this.onRemovePersonalPlace = this.onRemovePersonalPlace.bind(this);

        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'entities.personalPlaces',
            'ui.activeLocationId'
        ]);
        this.$element = $(element);
        this.$personalPlacesList = $(SAVED_PLACES_LIST_SELECTOR, this.$element);
        this.$emptyState = $(EMPTY_STATE_SELECTOR, this.$element);
        this.$loggedInState = $(NOT_LOGGEDIN_STATE_SELECTOR, this.$element);

        this.personalPlacesList = new PersonalPlacesList(
            this.$personalPlacesList,
            this.store,
            this.onPersonalPlacesListItemSelected,
            this.onRemovePersonalPlace
        );

        this.authenticationRedux = new AuthenticationRedux();
    }

    onPersonalPlacesListItemSelected(locationId) {
        this.updateUrlToContainActiveLocation(locationId);
    }

    updateUrlToContainActiveLocation(locationId) {
        const hash = `kaart/locatie/${locationId}`;
        this.store.dispatch(setMediaViewerHash(hash));
    }

    init() {
        const state = this.store.getState();
        const locations = locationsWithPersonalPlaceSelector(state);
        this.authenticationRedux.fetchUserAuthenticationInfo();
        this.layout(locations);
        this.bindEvents();
    }

    bindEvents() {
        this
            .connect
            .subscribe((state, prevState) => {
                if (personalPlaces(state) !== personalPlaces(prevState)) {
                    const locations = locationsWithPersonalPlaceSelector(state);
                    this.layout(locations);
                }
            });

        this.$element.on('click', LOGIN_BUTTON_SELECTOR, event => {
            event.preventDefault();

            this
                .authenticationRedux
                .openLoginDialog()
                .then(() => {
                    this.authenticationRedux.closeLoginDialog();
                });
        });
    }

    onRemovePersonalPlace(personalPlaceId) {
        const state = this.store.getState();

        if (personalPlaceId === activeLocationIdSelector(state)) {
            this.store.dispatch(setActiveLocation(null));
        }

        const matchedLocation = find(state.entities.locations, location => location.personalPlace == personalPlaceId);
        if (matchedLocation) {
            this.store.dispatch(deletePersonalPlace(personalPlaceId));
            this.store.dispatch(deleteLocation(matchedLocation.id));
        }
    }

    layout(locations) {
        const state = this.store.getState();
        this.$personalPlacesList.toggleClass(IS_HIDDEN_CLASS, locations.length < 1);
        this.$emptyState.toggleClass(IS_HIDDEN_CLASS, locations.length > 0);
        this.$loggedInState.toggleClass(IS_HIDDEN_CLASS, state.ui.userLoggedIn);
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new MyPersonalPlaces(element).init());

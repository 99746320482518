// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import '../../components/conditional-range-filter/conditional-range-filter';

// what does this module expose?
export default SearchPriceToggle;

// component configuration
const COMPONENT_SELECTOR = '[data-search-price-toggle]';
const PRICE_TYPE_INPUT_SELECTOR = '[data-search-price-toggle-input]';
const PRICE_TYPE_TARGET_ATTR = 'data-search-price-toggle-target';
const PRICE_TYPE_TARGET_SELECTOR = '[' + PRICE_TYPE_TARGET_ATTR + ']';
const PRICE_RANGE_SELECTOR = '[data-range-filter]';
const MAP_CONDITION_SELECTOR = '[data-display-range-filter]';
const MAP_PRICE_PAIR_ITEM_ATTR = 'data-display-range-value';
const MAP_PRICE_PAIR_ITEM_SELECTOR = '[' + MAP_PRICE_PAIR_ITEM_ATTR + ']';
const ACTIVE_CLASS = 'is-active';

function SearchPriceToggle(element) {
    const component = this; // Not necessary in ES2015, keep using it for consistency
    component.$element = $(element);

    component.$targets = component.$element.find(PRICE_TYPE_TARGET_SELECTOR);
    component.$currentActiveTarget = component.$element.find(PRICE_TYPE_TARGET_SELECTOR + '.' + ACTIVE_CLASS);

    component.$targetsDisplay = component.$element.find(MAP_PRICE_PAIR_ITEM_SELECTOR);
    component.$currentActiveTargetDisplay = component.$element.find(MAP_PRICE_PAIR_ITEM_SELECTOR + '.' + ACTIVE_CLASS);

    component.bindEvents();
}

SearchPriceToggle.prototype.bindEvents = function() {
    const component = this;

    component.$element.on('change', PRICE_TYPE_INPUT_SELECTOR, (event) => {
        const $input = $(event.currentTarget);
        const targetValue = $input.val();
        component.showTarget(targetValue);
    });
    component.$element.on('change', MAP_CONDITION_SELECTOR, (event) => {
        const $input = $(event.currentTarget);
        const targetValue = $input.val();
        component.showTargetDisplay(targetValue);
    });
};

SearchPriceToggle.prototype.showTarget = function(targetValue) {
    const component = this;

    component.$currentActiveTarget.find(PRICE_RANGE_SELECTOR).trigger('resetfilter');
    component.$targets.removeClass(ACTIVE_CLASS);

    component.$currentActiveTarget = component.$element.find('[' + PRICE_TYPE_TARGET_ATTR + '="' + targetValue + '"]');
    component.$currentActiveTarget.addClass(ACTIVE_CLASS);
};

SearchPriceToggle.prototype.showTargetDisplay = function(targetValue) {
    const component = this;

    component.$currentActiveTargetDisplay.trigger('resetfilter');
    component.$targetsDisplay.removeClass(ACTIVE_CLASS);

    component.$currentActiveTargetDisplay = component.$element.find('[' + MAP_PRICE_PAIR_ITEM_ATTR + '="' + targetValue + '"]');
    component.$currentActiveTargetDisplay.addClass(ACTIVE_CLASS);
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new SearchPriceToggle(element));
import {
    GET_VOORZIENINGEN_SUCCESS,
    ADD_VOORZIENINGEN_MARKERS
} from '../../constants/action-types';
import { listToHash } from '../../utils/data-structures';

const initialState = {};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_VOORZIENINGEN_SUCCESS:
            const voorzieningen = listToHash(action.result.map(location => ({
                id: location.Type.toLowerCase() + '-' + location.Id,
                type: location.Type,
                lat: location.Latitude,
                lng: location.Longitude,
                name: location.Name,
                address: location.Address,
                categories: location.Categories.map(el => location.Type.toLowerCase() + '-' + el),
                phone: location.Phone,
                website: location.Website,
                openingHoursToday: location.OpeningHoursToday,
                routeLineNumber: location.RouteLineNumber,
                routeLongName: location.RouteLongName,
                provider: location.Provider,
                source: location.Source,
                marker: null
            })), 'id');

            return Object.assign({}, state, {
                ...state,
                ...voorzieningen
            });
        case ADD_VOORZIENINGEN_MARKERS:
            const newState = { ...state };
            action.markers.forEach(element => {
                newState[element.id].marker = element;
            });

            return newState;
        default:
            return state;

    }
};

import $ from 'jquery';

export default FormWithNotifications;

const COMPONENT_SELECTOR = '[data-form-with-notifications]';
const CANCEL_SELECTOR = '[data-form-with-notifications-cancel]';
const SUCCESS_NOTIFICATION_SELECTOR = '[data-notification="successNotification"]';
const ERROR_NOTIFICATION_SELECTOR = '[data-notification="errorNotification"]';
const READ_FIELD_DATA_ATTRIBUTE = 'data-read';
const READ_FIELD_SELECTOR = '[' + READ_FIELD_DATA_ATTRIBUTE + ']';

function FormWithNotifications(element) {
    const component = this;

    component.$element = $(element);
    component.$form = component.$element.find('form');
    component.$cancel = component.$element.find(CANCEL_SELECTOR);
    component.$successNotification = component.$element.find(SUCCESS_NOTIFICATION_SELECTOR);
    component.$errorNotification = component.$element.find(ERROR_NOTIFICATION_SELECTOR);
    component.$readFields = component.$element.find(READ_FIELD_SELECTOR);
    component.lastValidData = component.getInitialFormValues();

    component.bindEvents();
}

FormWithNotifications.prototype.bindEvents = function () {
    const component = this;

    component.$form.unbind('submit').bind('submit', event => component.submitAsync(event));
    component.$cancel.click(() => component.cancel());
};

FormWithNotifications.prototype.submitAsync = function (event) {
    const component = this;

    const url = component.$form.attr('action');
    const data = component.$form.serialize();

    if (typeof event !== 'undefined' && typeof event.preventDefault === 'function') {
        event.preventDefault();
    }

    $.post(url, data)
        .done(message => component.submitSuccessHandler(message))
        .fail(message => component.submitErrorHandler(message));
};

FormWithNotifications.prototype.submitErrorHandler = function (message) {
    const component = this;

    component.$errorNotification.trigger('show', message);
    component.$element.trigger('submit-error', message);
};

FormWithNotifications.prototype.submitSuccessHandler = function (message) {
    const component = this;

    if (typeof message !== 'undefined' && message.success === true) {
        component.lastValidData = message.data;
        component.restoreEditValues();
        component.updateReadContent(message.data);
        component.$successNotification.trigger('show', message.NotificationMessage);
        component.$errorNotification.trigger('hide');
        component.$element.trigger('submit-success', message.data);
    } else {
        const errorMessage = typeof message === 'object' ? message.NotificationMessage : message;
        component.$successNotification.trigger('hide');
        component.$errorNotification.trigger('show', errorMessage);
        component.$element.trigger('submit-error', errorMessage);
    }
};

FormWithNotifications.prototype.updateReadContent = function (data) {
    const component = this;

    component.$readFields.each((i, inputField) => {
        const key = inputField.getAttribute(READ_FIELD_DATA_ATTRIBUTE);
        if (typeof data !== 'undefined' && data.hasOwnProperty(key)) {
            inputField.innerHTML = data[key];
        }
    });
};

FormWithNotifications.prototype.cancel = function () {
    const component = this;

    component.restoreEditValues();
    component.$successNotification.trigger('hide');
    component.$errorNotification.trigger('hide');
};

FormWithNotifications.prototype.getInitialFormValues = function () {
    const component = this;

    const queryData = component.$form.serialize();
    return JSON.parse('{"' +
        decodeURI(queryData)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
        + '"}');
};

FormWithNotifications.prototype.restoreEditValues = function () {
    const component = this;

    for (let key in component.lastValidData) if (component.lastValidData.hasOwnProperty(key)) {
        const initialValue = component.lastValidData[key];
        const $inputField = component.$form.find('[name=' + key + ']');

        if ($inputField.length > 0) {
            if ($inputField.attr('type') === 'radio') {
                $inputField.filter('[value="' + initialValue + '"]').prop('checked', true);
            } else {
                $inputField.val(initialValue);
            }
        }
    }
};

$(COMPONENT_SELECTOR).each((i, element) => new FormWithNotifications(element));

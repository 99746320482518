/**
 * object-detail view
 * [description]
 */

import './../_base-view/base-view';

import '../../components/async-object-rating/async-object-rating';
import '../../components/object-rating-form/object-rating-form';
import '../../components/user-saved-objects-sorting/user-saved-objects-sorting';
import '../../components/user-my-logged-in-as/user-my-logged-in-as';

import $ from 'jquery';

export default DialogSimple;

const COMPONENT_SELECTOR = '[data-dialog-simple]';
const OPEN_SELECTOR = '[data-dialog-open]';
const CLOSE_SELECTOR = '[data-dialog-close]';
const VISIBLE_CLASS = 'show';
const CLICK_EVENT = 'click';

function DialogSimple(element) {
    const component = this;
    component.$element = $(element);
    component.$openTrigger = component.$element.find(OPEN_SELECTOR);
    component.$closeTrigger = component.$element.find(CLOSE_SELECTOR);

    component.$openTrigger.on(CLICK_EVENT, (e) => component.open(e));
    component.$closeTrigger.on(CLICK_EVENT, (e) => component.close(e));
}

DialogSimple.prototype.open = function (event) {
    const component = this;

    if (typeof event === 'object' && typeof event.preventDefault === 'function') {
        event.preventDefault();
    }

    component.$element.addClass(VISIBLE_CLASS);
};

DialogSimple.prototype.close = function (event) {
    const component = this;

    if (typeof event === 'object' && typeof event.preventDefault === 'function') {
        event.preventDefault();
    }

    component.$element.removeClass(VISIBLE_CLASS);
};

$(COMPONENT_SELECTOR).each((index, element) => new DialogSimple(element));

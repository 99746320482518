import { applyMiddleware, createStore, compose } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';

import { apiMiddleware } from './middleware/api';

const middleWareToCompose = [applyMiddleware(apiMiddleware), applyMiddleware(thunk)];

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    middleWareToCompose.push(devTools);
}

export const createStoreInstance = () =>
    createStore(
        reducers,
        compose.apply(null, middleWareToCompose)
    );

const store = createStoreInstance();
export default store;

// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';
import reduxStore from '../store/store';
import Connect from '../store/connect';
import { updateAppHeader } from '../store/actions';
import AppHeaderMenu from './app-header-menu';


const COMPONENT_SELECTOR = '[data-app-header]';
const MENU_SELECTOR = '[data-app-header-menu]';
const $body = $('body');

const isUserLoggedIn = state => state.ui.userLoggedIn;
const hasUserLoggedInCheckBeenExecuted = state => state.ui.userLoggedIn !== null;

export default class AppHeader {
    constructor(element, store) {
        const component = this;
        component.store = store || reduxStore;
        component.connect = new Connect(this.store, [
            'ui.userLoggedIn',
            'ui.appHeader',
        ]);

        component.$element = $(element);
        this.subscribeToStore();
        this.initMenu();
    }

    subscribeToStore() {
        this.connect.subscribe((state, prevState) => {
            const userLoggedInCheckHasBeenPerformedBefore = hasUserLoggedInCheckBeenExecuted(state) && hasUserLoggedInCheckBeenExecuted(prevState);
            const userSwitchedBetweenLoggedInOrOut = isUserLoggedIn(state) !== isUserLoggedIn(prevState) && userLoggedInCheckHasBeenPerformedBefore;

            if (userSwitchedBetweenLoggedInOrOut) {
                this.fetchNewHeaderPartial();
            }

            if (state.ui.appHeader !== prevState.ui.appHeader) {
                this.updateHeader(state.ui.appHeader);
            }
        });
    }

    fetchNewHeaderPartial() {
        return this.store.dispatch(updateAppHeader());
    }

    updateHeader(header) {
        this.$element.replaceWith(header);
        this.$element = $body.find(COMPONENT_SELECTOR);
        this.initMenu();
    }

    initMenu() {
        $(MENU_SELECTOR).each((index, element) => new AppHeaderMenu(element));
    }
}

$(COMPONENT_SELECTOR).each((index, element) => new AppHeader(element));

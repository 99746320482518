// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';
import '../input-number/input-number';

// what does this module expose?
export default RangeFilterSelector;

// component configuration
const RANGE_FILTER_SELECTOR_PRESET = '[data-range-filter-selector-preset]';
const RANGE_FILTER_SELECTOR_CUSTOM = '[data-range-filter-selector-custom]';
const CLEAR_CUSTOM_RANGE_FILTER_SELECTOR = '[data-range-filter-selector-clear-custom-value]';

const RANGE_FILTER_SELECTOR_SELECT = '[data-range-filter-selector-preset-select]';
const PRESET_DEFAULT_NUMBER_SELECTOR = '[data-range-filter-selector-default-value]';

const RANGE_FILTER_SELECTOR_INPUT = '[data-input-number-field]';

const VALUE_CHANGED_TRIGGER = 'numberchanged';
const CUSTOM_VALUE = 'other';
const INACTIVE_CLASS = 'is-inactive';
const INPUT_FOCUS_DELAY = 125;
const INVALID_CLASS = 'is-invalid';

function RangeFilterSelector(element, $parent) {
    const component = this;
    component.$element = $(element);
    component.$parent = $parent;
    component.$preset = component.$element.find(RANGE_FILTER_SELECTOR_PRESET);
    component.$custom = component.$element.find(RANGE_FILTER_SELECTOR_CUSTOM);
    component.$select = component.$element.find(RANGE_FILTER_SELECTOR_SELECT);
    component.$input = component.$element.find(RANGE_FILTER_SELECTOR_INPUT);
    component.$clearCustomValue = component.$element.find(CLEAR_CUSTOM_RANGE_FILTER_SELECTOR);
    component.defaultValue = component.$select.find(PRESET_DEFAULT_NUMBER_SELECTOR).val();
    component.defaultIndex = component.$select.find(PRESET_DEFAULT_NUMBER_SELECTOR).index();
    component.selectedIndex = component.$select[0].selectedIndex;
    component.valueIsDefault = component.isDefault();
    component.time = null;

    component.bindEvents();
}

RangeFilterSelector.prototype.bindEvents = function() {
    const component = this;

    component.$input.on('blur', () => component.updateCustomValueInput());
    component.$select.on('reset', () => component.setDefault());
    component.$select.on('change', (event) => component.changedPresetValue(event));
    component.$clearCustomValue.on('click', () => component.setDefault());
};

/**
 * determine if component is set to default
 * @returns {boolean}
 */
RangeFilterSelector.prototype.isDefault = function() {
    const component = this;
    return component.defaultIndex === component.selectedIndex;
};

/**
 * set component to it's default; remove changed value and classes
 */
RangeFilterSelector.prototype.setDefault = function () {
    const component = this;
    component.removeValidation(); // default value can't be invalid
    component.setPresetSelect(component.defaultValue);
    component.$input.val('');
    component.$custom.addClass(INACTIVE_CLASS);
    component.$preset.removeClass(INACTIVE_CLASS);
    component.$parent.trigger(VALUE_CHANGED_TRIGGER);
};

/**
 * when the select is changed; we also changed the selectedIndex
 * @param {String} number
 */
RangeFilterSelector.prototype.setPresetSelect = function(number) {
    const component = this;
    component.$select.val(number);
    component.selectedIndex = component.$select[0].selectedIndex;
};

/**
 * get the number
 */
RangeFilterSelector.prototype.getValue = function() {
    const component = this;
    const number = component.$select.val() === CUSTOM_VALUE ? component.$input.val() : component.$select.val();

    if (number > 0) {
        return parseInt(number, 10);
    }
    return number;
};


/**
 * set a new value
 * @param {Integer} number
 */
RangeFilterSelector.prototype.setValue = function (number) {
    const component = this;
    component.setPresetSelect(CUSTOM_VALUE);
    component.$input.val(number);
    component.$input.blur(); // blur to set new mask
    component.showCustomValue();
    component.$parent.trigger(VALUE_CHANGED_TRIGGER);
};

/**
 * set the difference between numbers
 * @param {Integer} number
 */
RangeFilterSelector.prototype.setValueDifference = function(number) {
    const component = this;
    const $filtered = component.$select.find('option').filter(function() {
        return parseInt($(this).val(), 10) > number;
    });

    if ($filtered.length > 0) {
        component.setPresetSelect($filtered.first().val());
    } else {
        component.setPresetSelect(component.$select.find('option:last').val());
    }
    component.$parent.trigger(VALUE_CHANGED_TRIGGER);
};

/**
 * preset select is changed
 * @param {Object} event
 */
RangeFilterSelector.prototype.changedPresetValue = function(event) {
    const component = this;
    const $select = $(event.currentTarget);
    component.selectedIndex = $select[0].selectedIndex;
    if ($select.val() === CUSTOM_VALUE) {
        event.stopPropagation();
        // prob. Chrome fix
        $select.find('option[value="' + CUSTOM_VALUE + '"]').prop('selected', true);
        component.showCustomValue();
        return; // stop trigger
    } else {
        component.$parent.trigger(VALUE_CHANGED_TRIGGER);
    }
};

/**
 * hides the preset value and shows the custom value
 */
RangeFilterSelector.prototype.showCustomValue = function() {
    const component = this;
    component.$preset.addClass(INACTIVE_CLASS);
    component.$custom.removeClass(INACTIVE_CLASS);
    component.time = setTimeout(() => {
        // set focus on input field after a small delay
        component.$input.focus();
    }, INPUT_FOCUS_DELAY);
};

/**
 * input field is changed, let the form know
 */
RangeFilterSelector.prototype.updateCustomValueInput = function() {
    const component = this;
    if (component.$input.val() === '') {
        component.setDefault();
    } else {
        component.$parent.trigger(VALUE_CHANGED_TRIGGER);
    }
};

/**
 * the value is invalid
 */
RangeFilterSelector.prototype.setInvalid = function() {
    const component = this;
    component.$element.addClass(INVALID_CLASS);
};

/**
 * determine if the value is invalid
 */
RangeFilterSelector.prototype.isInvalid = function() {
    const component = this;
    return component.$element.hasClass(INVALID_CLASS);
};

/**
 * the value is not invalid
 */
RangeFilterSelector.prototype.removeValidation = function() {
    const component = this;
    if (component.isInvalid()) {
        component.$element.removeClass(INVALID_CLASS);
    }
};
import $ from 'jquery';

window.triggerHotspotClickEvent = function(name, referer) {
    $(window).trigger('krpanohotspotclicked',
        {
            name: name,
            referer: referer
        }
    );
};

window.triggerWebVRAvailable = function() {
    $(window).trigger('webvravailable');
};

window.triggerWebVREnabled = function() {
    $(window).trigger('webvrenabled');
};

window.triggerWebVRDisabled = function() {
    $(window).trigger('webvrdisabled');
};

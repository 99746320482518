// require this module where needed, either in a specific view or component or generically in src/index.js
// explicitly inject dependencies (alphabetically), only those needed
import $ from 'jquery';

// what does this module expose?
export default FilterVisibility;

// component configuration
const COMPONENT_SELECTOR = '[data-filter-visibility]';
const INVALID_FILTERS_ATTR = 'data-filter-visibility-invalid-filters';
const INVALID_FILTERS_SELECTOR = '[' + INVALID_FILTERS_ATTR + ']';
const TARGET_ATTR = 'data-filter-visibility-target';
const TARGET_SELECTOR = '[' + TARGET_ATTR + ']';
const DISABLED_CLASS = 'is-disabled';

/**
 * @param {HTMLElement} element containing all options (handles)
 * @constructor
 */
function FilterVisibility(element) {
    const component = this;
    component.$element = $(element);
    component.$targets = $(TARGET_SELECTOR);

    component.$element.on('change', INVALID_FILTERS_SELECTOR, (event) => {
        // enable filters for current filter
        component.$element.find(INVALID_FILTERS_SELECTOR).each(function(index, option) {
            const filters = $(option).attr(INVALID_FILTERS_ATTR).split(' ');
            component.enableFilters(filters);
        });

        // disable filters for current option
        const $input = $(event.currentTarget);
        if ($input.is(':checked')) {
            const invalidFilters = $input.attr(INVALID_FILTERS_ATTR).split(' ');
            component.disableFilters(invalidFilters);
        }
    });
}

/**
 * @param {String[]} disabledFilters
 */
FilterVisibility.prototype.disableFilters = function(invalidFilters) {
    const component = this;
    component.$targets.each(function(index, element) {
        const name = element.getAttribute(TARGET_ATTR);
        const shouldBeDisabled = (invalidFilters.indexOf(name) > -1);

        if (shouldBeDisabled) {
            let $element = $(element);
            $element.trigger('resetfilter');
            $element.addClass(DISABLED_CLASS).trigger('changed');
        }
    });
};

FilterVisibility.prototype.enableFilters = function(filters) {
    const component = this;
    component.$targets.each(function(index, element) {
        const name = element.getAttribute(TARGET_ATTR);
        if (filters.indexOf(name) > -1) {
            $(element).removeClass(DISABLED_CLASS);
        }
    });
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new FilterVisibility(element));
import $ from 'jquery';
import isFunction from 'lodash/isFunction';
import AsyncForm from '../async-form/async-form';
import SocialLogin from '../social-login/social-login';
import AppSpinner from '../app-spinner/app-spinner';
import DialogPolyfill from 'dialog-polyfill';

const $body = $('body');

export default Dialog;

const COMPONENT_SELECTOR = '[data-dialog]';
const CLOSE_SELECTOR = '[data-dialog-close], a.cancel-button';
const BODY_SELECTOR = '[data-dialog-body]';
const HANDLE_SELECTOR = '[data-dialog-handle]';
const OPEN_DIALOG_CLASS = 'has-open-dialog';
const OPEN_DIALOG_SELECTOR = 'dialog[open]';
const AJAX_SUBMIT_SELECTOR = '[dialog-ajax-submit]';
const SOCIAL_LOGIN_SELECTOR = '[data-social-login]';
const URL_ISUSERLOGGEDIN = 'data-isuserloggedinurl';
const SPINNER_SELECTOR = '[data-dialog-spinner]';
const AUTO_OPEN_SELECTOR = '[data-dialog-auto-open]';
const CLOSE_DISABLED_DATA = 'disable-close';

const KEY_CODE_ESCAPE = 27;
const KEY_CODES_PREVENT_SCROLL = [
    32, // spacebar
    33, // pageup
    34, // pagedown
    35, // end
    36, // home
    37, // left
    38, // up
    39, // right
    40 //  down
];

function Dialog(element) {
    const component = this;

    element.dialog = this; //Register instance in dom

    //Dependencies
    component.AsyncForm = AsyncForm;
    component.SocialLogin = SocialLogin;
    component.AppSpinner = AppSpinner;

    //Members
    component.element = element;
    component.$element = $(element);

    component.$body = component.$element.find(BODY_SELECTOR);
    component.$spinner = component.$element.find(SPINNER_SELECTOR);
    component.isUserLoggedInUrl = element.getAttribute(URL_ISUSERLOGGEDIN);

    component.onFormSuccess = undefined;
    component.previousDialog = undefined;

    if (!(typeof document.createElement('dialog').show === 'function')) { //If browser does not support dialog
        DialogPolyfill.registerDialog(element);
    }

    component.loadOnStart();

    component.bindEvents();
}

Dialog.prototype.bindEvents = function () {
    const component = this;
    const $window = $(window);
    const $document = $(document);

    $body.on('click', HANDLE_SELECTOR, (e) => component.loadContent(e));
    $window.on('popstate', (e) => component.close(e));
    $document.on('keydown', (e) => component.keyDownHandler(e));
    component.$element.on('click', CLOSE_SELECTOR, (e) => component.close(e));
};

Dialog.prototype.keyDownHandler = function (event) {
    const component = this;

    if (event.keyCode === KEY_CODE_ESCAPE) { // Close key (escape)
        component.close();
    }
};

Dialog.prototype.loadContent = function (event) {
    const component = this;
    event.preventDefault();

    if (event.target.href) {
        component.$body.html('');
        component.getContent(event.target.href);

        if (!component.element.open) {
            component.open();
        }
    }
};

Dialog.prototype.getContent = function (url) {
    const component = this;

    const ajaxConfig = {
        url: url,
        cache: false,
        data: {
            currentUrl: window.location.pathname
        },
        xhrFields: {
            withCredentials: true
        },
        dataType: 'json'
    };

    component.spinner = new component.AppSpinner(component.$spinner[0]);
    component.spinner.show();

    return $
        .ajax(ajaxConfig)
        .then((response) => component.contentResponseHandler(response))
        .catch((error) => component.errorHandler(error))
        .always(() => component.spinner.hide());
};


Dialog.prototype.contentResponseHandler = function (response) {
    const component = this;

    if (response.Result === 'OK') {
        component.updateContent(response.View || response.Html);
    }
    else {
        component.errorHandler(response.errorMessage);
    }
};

Dialog.prototype.errorHandler = function (error) {
    console.log(error);
};

Dialog.prototype.enableSocialLogin = function (body) {
    const component = this;

    const socialLogin = body.find(SOCIAL_LOGIN_SELECTOR);
    if (socialLogin.length === 1) {
        return new component.SocialLogin(socialLogin[0]);
    }
    return null;
};

Dialog.prototype.updateContent = function (content) {
    const component = this;

    component.$body.html(content);

    if (component.$body.find(AJAX_SUBMIT_SELECTOR).length > 0) {
        component.asyncForm();
    }

    return component.enableSocialLogin(component.$body);
};

Dialog.prototype.asyncForm = function () {
    const component = this;

    return new component.AsyncForm(component.$body, {
        body: component.$body,
        success: function (response) {
            if (component.onFormSuccess) {
                component.onFormSuccess(response);
            } else {
                window.location.reload();
            }
            component.close();
        },
        submitCallback: function (body) {
            component.enableSocialLogin(body);
        }
    });
};

Dialog.prototype.hideVisibleDialogs = function () {
    const component = this;

    const $openDialog = $(OPEN_DIALOG_SELECTOR);
    if ($openDialog.length) {
        component.previousDialog = $openDialog[0];
        component.previousDialog.close();
    }
};

Dialog.prototype.restorePreviousDialogs = function () {
    const component = this;

    if (component.previousDialog != null) {
        component.previousDialog.showModal();
        component.previousDialog = null;
    }
};

Dialog.prototype.open = function (disableClose = false) {
    const component = this;
    const $closeButton = component.$element.find(CLOSE_SELECTOR);

    if (disableClose) {
        $closeButton.hide();
    } else {
        $closeButton.show();
    }

    component.hideVisibleDialogs();
    component.element.showModal();
    // prevent scroll bar when the content of the window body  is larger than the dialog
    $body.addClass(OPEN_DIALOG_CLASS);
    component.disableScroll();
};

Dialog.prototype.close = function (event) {
    const component = this;

    if (typeof event === 'object' && typeof event.preventDefault === 'function') {
        event.preventDefault();
    }

    if (component.element.open && isFunction(component.element.close)) {
        component.element.close();
        component.enableScroll();
    }
    $body.removeClass(OPEN_DIALOG_CLASS);
    component.enableScroll();
    component.restorePreviousDialogs();
};

Dialog.prototype.disableScroll = function () {
    if (window.addEventListener) { // older FF
        window.addEventListener('DOMMouseScroll', preventDefaultBehavior, false);
    }

    window.onwheel = preventDefaultBehavior; // modern standard
    window.onmousewheel = document.onmousewheel = preventDefaultBehavior; // older browsers, IE
    window.ontouchmove = preventDefaultBehavior; // mobile
    document.onkeydown = preventScrollBehavior;
};

/**
 * Enables scroll-events, including touch devices.
 */
Dialog.prototype.enableScroll = function () {
    if (window.removeEventListener) { // older FF
        window.removeEventListener('DOMMouseScroll', preventDefaultBehavior, false);
    }

    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
};


Dialog.prototype.loadOnStart = function () {
    const component = this;

    const $autoOpen = $body.find(AUTO_OPEN_SELECTOR);
    if ($autoOpen.length === 1) {
        const disableClose = $autoOpen.data(CLOSE_DISABLED_DATA) !== undefined;

        component.$body.html($autoOpen.html());
        component.open(disableClose);
    }
};

function preventDefaultBehavior(event) {
    event = event || window.event;
    if (event.preventDefault) {
        event.preventDefault();
    }
    event.returnValue = false;
}

function preventScrollBehavior(event) {
    if (KEY_CODES_PREVENT_SCROLL.indexOf(event.keyCode) !== -1) {
        event.preventDefault();
    }
}

$(COMPONENT_SELECTOR).each((i, element) => new Dialog(element));

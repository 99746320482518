export default VideoView;

import $ from 'jquery';
import quadia from 'funda-quadia';

const COMPONENT_SELECTOR = '[data-media-viewer-video]';
const COOKIES_ACCEPTED_EVENT = 'cookiesaccepted';
const COOKIES_ACCEPTED_ATTR = 'data-cookies-accepted';
const VIDEO_CONTAINER_ID = 'media-viewer-quadia-container';
const VIDEO_CONTAINER_SELECTOR = '#' + VIDEO_CONTAINER_ID;
const VIDEO_CONTAINER_SRC_ATTR = 'data-media-viewer-video-src';

function VideoView(element) {
    const view = this;
    view.$videoContainer = $(VIDEO_CONTAINER_SELECTOR);
    view.src = this.$videoContainer.attr(VIDEO_CONTAINER_SRC_ATTR);

    let cookiesAccepted = element.getAttribute(COOKIES_ACCEPTED_ATTR);

    if (cookiesAccepted === 'false') {
        // There must be a cookie notification somewhere on the page, so wait
        // until accepted.
        $(window).on(COOKIES_ACCEPTED_EVENT, function cookiesAcceptedHandlerWithViewLoaded() {
            $(window).off(COOKIES_ACCEPTED_EVENT);
            view.init();
        });
    } else {
        view.init();
    }
}

/**
 * Initializes the Quadia video player, adds markup to the container, including
 * an iframe that hosts the video player
 */
VideoView.prototype.init = function() {
    const view = this;
    quadia.iframe(view.src, {
        container: '' + VIDEO_CONTAINER_ID + '',
        methods: ['fullscreen', 'fullbrowser'],
        style: 'bottom_right(4px,16px,44px,44px)'
    });
};

VideoView.prototype.dispose = function() {
    this.$videoContainer.html('');
};

// turn all elements with the default selector into components
// Auto-initialize means the component should instantiate itself and handle the cookie policy in the constructor
// Otherwise this view's constructor will likely be called from the media viewer
// todo: move init logic to constructor. Differentiate between player "init" and "play".
$(COMPONENT_SELECTOR).each(function(index, element) {
    // use jquery to get data attribute because of IE 9/10
    var autoInitialize = $(element).data('mediaViewerAutoInitialize');

    if (autoInitialize === true) {
        return new VideoView(element);
    } else {
        // Listen for accepted cookies and update the attribute so that video can
        // start normally when the component is constructed.
        $(window).on(COOKIES_ACCEPTED_EVENT, function cookiesAcceptedHandlerWithViewNotLoaded() {
            $(window).off(COOKIES_ACCEPTED_EVENT);
            element.setAttribute(COOKIES_ACCEPTED_ATTR, true);
        });
    }
});

import $ from 'jquery';

const INPUT_FIELD_SELECTOR = '[data-clearable-textbox-input]';
const INPUT_FIELD_CLEAR_SELECTOR = '[data-clearable-textbox-clear]';
const MAIN_ICON_SELECTOR = '[data-clearable-textbox-main-icon]';

const IS_HIDDEN = 'is-hidden';
const ERROR_STATE_CLASS = 'clearable-textbox--has-error';

const noop = () => {};

export default class ClearableTextbox {
    constructor(element, inputFieldEventHandlers, iconClass, onClearInputFieldHandler) {
        this.onClearInputFieldHandler = onClearInputFieldHandler || noop;

        this.$element = $(element);
        this.$input = this.$element.find(INPUT_FIELD_SELECTOR);
        this.$buttonClear = this.$element.find(INPUT_FIELD_CLEAR_SELECTOR);
        this.$mainIcon = this.$element.find(MAIN_ICON_SELECTOR);

        this.inputFieldEventHandlers = { ...inputFieldEventHandlers };

        this.bindEvents();

        if (iconClass) {
            this.addIconClass(iconClass);
        }

        this.showOrHideClearButtonBasedOnValue(this.$input.val());
    }

    bindEvents() {
        this.$input.on('input', event => {
            this.showOrHideClearButtonBasedOnValue(event.target.value);
        });

        this.$input.on('focus', () => this.removeErrorState());

        this.$buttonClear.on('click', event => {
            event.preventDefault();
            this.clearInput();
        });

        for (let e in this.inputFieldEventHandlers) {
            const triggeredEventHandler = this.inputFieldEventHandlers[e];
            this.$input.on(e, event => triggeredEventHandler(event));
        }
    }

    clearInput() {
        this.setValue('');
        this.onClearInputFieldHandler();
    }

    showOrHideClearButtonBasedOnValue(value) {
        if (value && value.length) {
            this.showClearButton();
        } else {
            this.removeClearButton();
        }
    }

    addIconClass(iconClass) {
        this.$mainIcon.removeClass();
        this.$mainIcon.addClass(`icon main-icon ${iconClass}`);
    }

    showClearButton() {
        this.$buttonClear.removeClass(IS_HIDDEN);
        this.$mainIcon.addClass(IS_HIDDEN);
    }

    removeClearButton() {
        this.$buttonClear.addClass(IS_HIDDEN);
        this.$mainIcon.removeClass(IS_HIDDEN);
    }

    setValue(value) {
        this.$input.val(value);
        this.$input.trigger('input');
    }

    setErrorState() {
        this.$element.addClass(ERROR_STATE_CLASS);
    }

    removeErrorState() {
        this.$element.removeClass(ERROR_STATE_CLASS);
    }

    focus() {
        this.$input.focus();
    }

    disable() {
        this.$input.prop('disabled', true);
        this.removeClearButton();
    }

    enable() {
        this.$input.prop('disabled', false);
    }
}

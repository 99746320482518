'use strict';

import $ from 'jquery';

const COMPONENT_SELECTOR = '[data-accordion]';
const TAB_SELECTOR = '[data-accordion-tab]';
const PANEL_SELECTOR = '[data-accordion-panel]';

const TAB_ID_PREFIX = 'accordion__tab';
const PANEL_ID_PREFIX = 'accordion__panel';

export default class Accordion {
    constructor(element) {
        const component = this;
        component.$element = $(element);
        component.$tabs = component.$element.find(TAB_SELECTOR);
        component.$panels = component.$element.find(PANEL_SELECTOR);

        component.setupTabs();
        component.setupPanels();
        component.bindEvents();
    }

    bindEvents() {
        const component = this;

        component.$tabs.click((e) => component.togglePanel(e.currentTarget));
    }

    setupTabs() {
        const component = this;

        component.$tabs.each((index, tab) => {
            tab.setAttribute('id', `${TAB_ID_PREFIX}-${index+1}`);
            tab.setAttribute('aria-expanded', 'false');
            tab.setAttribute('aria-controls', `${PANEL_ID_PREFIX}-${index+1}`);
        });
    }

    setupPanels() {
        const component = this;

        component.$panels.each((index, panel) => {
            panel.setAttribute('id', `${PANEL_ID_PREFIX}-${index+1}`);
            panel.setAttribute('aria-labelledby', `${TAB_ID_PREFIX}-${index+1}`);
            panel.setAttribute('aria-hidden', 'true');
        });
    }

    togglePanel(tab) {
        const component = this;
        const $tab = $(tab);
        const $panel = component.$element.find(`#${$tab.attr('aria-controls')}`);
        const isExpanded = $tab.attr('aria-expanded') == 'true';

        if (isExpanded) {
            component.collapsePanel($tab, $panel);
        } else {
            component.expandPanel($tab, $panel);
        }
    }

    collapsePanel($tab, $panel) {
        $tab.attr('aria-expanded', 'false');
        $panel.attr('aria-hidden', 'true');
    }

    expandPanel($tab, $panel) {
        $tab.attr('aria-expanded', 'true');
        $panel.attr('aria-hidden', 'false');
    }
}

$(COMPONENT_SELECTOR).each((i, element) => new Accordion(element));

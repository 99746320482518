import $ from 'jquery';

export default RadiusFilter;

const COMPONENT_SELECTOR = '[data-input-radius]';
const AUTOCOMPLETE_SELECTOR = '[data-autocomplete]';
const IS_DISABLED_CLASS = 'is-disabled';
const SEARCH_QUERY_SET_EVENT = 'searchQuerySet';
const RESET_FILTER_EVENT = 'reset';
const CHANGE_BUURT_EVENT = 'changeBuurt';
const CHANGE_RADIUS_EVENT = 'changeRadius';

function RadiusFilter(element) {
    const component = this;
    component.$element = $(element);
    component.$select = component.$element.find('select');
    component.$form = component.$element.closest('form');
    component.$autocomplete = component.$form.find(AUTOCOMPLETE_SELECTOR);

    component.$autocomplete.on(RESET_FILTER_EVENT, () => {
        component.$select[0].selectedIndex = 0;
    });

    component.$select.on('change', () => {
        let radiusValue = +component.$select.val();
        $(document).trigger(CHANGE_RADIUS_EVENT, {selectedRadius: radiusValue});
    });

    $(document).on(CHANGE_BUURT_EVENT, (event, data) => {
        component.setDisabled(data.selectedFilterCount > 1);
    });

    component.$autocomplete.on(SEARCH_QUERY_SET_EVENT, (event, data) => {
        component.setDisabled(data.multiple);
    });
}

RadiusFilter.prototype.setDisabled = function(newState) {
    const component = this;

    if (newState) {
        component.$select.attr('disabled', true);
        component.$element.addClass(IS_DISABLED_CLASS);
        component.$select[0].selectedIndex = 0;
    } else {
        component.$select.removeAttr('disabled');
        component.$element.removeClass(IS_DISABLED_CLASS);
    }
};

$(COMPONENT_SELECTOR).each((index, element) => new RadiusFilter(element));

/**
 * nieuwbouwproject-detail view
 */
import './../_base-view/base-view';

import './../../components/nieuwbouwproject-downloads/nieuwbouwproject-downloads';
import './../../components/nieuwbouwproject-news/nieuwbouwproject-news';
import './../../components/nieuwbouwproject-woningtypen/nieuwbouwproject-woningtypen';
import './../../components/object-description/object-description';
import './../../components/object-buurt/object-buurt';
import './../../components/related-objects/related-objects';

/**
 * Transforms an array of Objects into an Object with the specified key as its key
 * @param {Array} input - an Array of Objects, e.g. [{foo: 'bar', ..}, {foo: 'baz', ..}]
 * @param {String} key - the key to use in the resulting object, e.g. 'foo'
 * @returns {Object} e.g. {bar: {foo: 'bar', ..}, ..}
 */
export const listToHash = (input = [], key) =>
    input
        .reduce((hash, item) => {
            hash[item[key]] = item;
            return hash;
        }, {});

/**
 * Transforms an Object to an Array of each of its values
 * @param {Object} hash
 * @returns {Array}
 */
export const hashToList = hash =>
    Object
        .keys(hash)
        .map(key => hash[key]);

import get from 'lodash/get';

export default class Connect {
    constructor(store, propertiesToWatch) {
        this.store = store;
        this.state = store.getState();
        this.subscribers = [];
        this.propertiesToWatch = propertiesToWatch;

        this.store.subscribe(() => {
            this.compare(this.store.getState(), this.state);
        });
    }

    compare(state, prevState) {
        let hasChanged = false;

        this
            .propertiesToWatch
            .forEach(property => {
                if (get(state, property) !== get(prevState, property)) {
                    hasChanged = true;
                }
            });

        this.state = this.store.getState();

        if (hasChanged) {
            this.subscribers.map(callback => callback(state, prevState));
        }

    }

    subscribe(callback) {
        this.subscribers.push(callback);
    }
}

import $ from 'jquery';
import Map from '../map/map';
import MapEventProxy from '../map/map-event-proxy';
import MapEvents from '../map/map-events';
import MapMarkerOverlay from '../map/map-marker-overlay';
import SearchMapMarkerManager from '../search-map-marker-manager/search-map-marker-manager';
import SearchMapInfoWindow from '../../components/search-map-infowindow/search-map-infowindow';
import TravelTimeMapPolygon from './travel-time-map-polygon';
import '../object-map-gps-controls/object-map-gps-controls';
import '../search-map-content/search-map-content';
import PersonalPlaceMarkers from '../markers/personal-place-markers';

import reduxStore from '../store/store';
import Connect from '../store/connect';
import {setActiveLocation, getTravelTimePolygonData, showLoadingSpinner} from '../store/actions';
import {getLocationById} from '../store/reducers';
import {activeLocationIdSelector, modalityAndTravelToggleSelector} from '../store/utils/selectors';
import {hasActiveLocation, activeLocationChanged, modalityAndTravelToggleChanged} from '../store/utils/conditions';
import AppSpinner from '../app-spinner/app-spinner';

const COMPONENT_SELECTOR = '[data-search-map]';
const INFO_WINDOW_COMPONENT_SELECTOR = '[data-search-map-infowindow]';
const GPS_GENERAL_ERROR_SELECTOR = '[data-general-error]';
const GPS_ACCESS_DENIED_ERROR_SELECTOR = '[data-access-denied-error]';
const NOTIFICATION_SELECTOR = '[data-notification]';
const SHOW_OBJECT_INFO_EVENT = 'show_object_info';
const HIDE_OBJECT_INFO_EVENT = 'hide_object_info';
const NO_OBJECT_EVENT_ARG = 'no_object';
const FORCE_REFRESH_MAPTILES_EVENT = 'force_maptile_refresh';
const SEARCH_QUERY_UPDATED_EVENT = 'zo_updated';
const TRACK_CENTER_MAP_EVENT = 'track_center_map';
const MAP_UNCENTERED_EVENT = 'map_uncentered';
const SHOW_GPS_ERROR_EVENT = 'gps-error-event';
const GPS_GENERAL_ERROR_TYPE = 'gps-general-error';
const GPS_ACCESS_DENIED_ERROR_TYPE = 'gps-access-denied-error';
const IS_HIDDEN_CLASS = 'is-hidden';
const SPINNER_SELECTOR = '[data-travel-time-spinner]';
const SPINNER_OVERLAY_SELECTOR = '[data-travel-time-polygon-loading-overlay]';

const PUSH_STATE_MARKER = 'push_state_set_by_funda';
const ASYNC_SIDEBAR_URL = '/kaartsidebar';
const MIN_ZOOM = 8;
const MAX_ZOOM = 19;
const $window = $(window);

class SearchMap extends Map {
    constructor(element, store) {
        const mapLoadedHandler = function mapLoadedHandler() {
            // The Google Map API idle event will create the overlay
            component.createOverlay();

            component.mapEventProxy.removeListener(component.zoomLimitHandlerId);
            component.mapEventProxy.addListener('zoom_changed', () => {
                component.verifyMapZoomLimit(component.map);
            });
            component.mapEventProxy.addListener('idle', () => {
                component.recordMapState();
            });
            component.mapEventProxy.addListener('dragstart', () => {
                if (component.trackedCenter) {
                    component.trackedCenter = null;
                    $window.trigger(MAP_UNCENTERED_EVENT);
                }
            });

            component.$element.on(FORCE_REFRESH_MAPTILES_EVENT, (event, eventArgs) => {
                component.doNotEnforceZoomLevel = !eventArgs.isfinshed;
            });
            window.addEventListener('popstate', component.onMapPopState.bind(component), false);
            $(INFO_WINDOW_COMPONENT_SELECTOR).each((index, ele) => {
                component.infoWindow = new SearchMapInfoWindow(ele, component);
            });
            component.$element.on(SEARCH_QUERY_UPDATED_EVENT, (event, eventArgs) => {
                component.currentSearch = eventArgs.zo;
            });
            $window.on(TRACK_CENTER_MAP_EVENT, (event, data) => {
                component.trackedCenter = data;
                const centerLatLng = new google.maps.LatLng(data.lat, data.lng);
                component.map.panTo(centerLatLng);
                component.updateGpsMarker(centerLatLng);
            });

            $(document).on(SHOW_GPS_ERROR_EVENT, (event, data) => {
                component.hideGpsErrors();
                if (data.type === GPS_GENERAL_ERROR_TYPE) {
                    component.$gpsGeneralError.find(NOTIFICATION_SELECTOR).removeClass(IS_HIDDEN_CLASS);
                }
                if (data.type === GPS_ACCESS_DENIED_ERROR_TYPE) {
                    component.$gpsAccessDeniedError.find(NOTIFICATION_SELECTOR).removeClass(IS_HIDDEN_CLASS);
                }
            });

            component.onPersonalPlaceMarkerClickedHandler = component.onPersonalPlaceMarkerClickedHandler.bind(component);

            const google = window.google;
            component.personalPlaceMarkers = new PersonalPlaceMarkers(google.maps, component.map, null, null, component.onPersonalPlaceMarkerClickedHandler);

            // The Targomo library wants the Google Maps global object to be present;
            // at this point we know Google Maps has been loaded.
            component.travelTimePolygon = new TravelTimeMapPolygon(component.map, store);
        };

        super(element, mapLoadedHandler);

        const component = this;
        component.doNotEnforceZoomLevel = false;
        component.trackedCenter = null;
        component.centerMarker1 = null;
        component.centerMarker2 = null;
        // event proxy
        component.mapEventProxy = MapEventProxy;
        component.mapEvents = MapEvents;
        component.currentSearch = component.mapEvents.getCurrentSearchFromUrl();
        this.gpsMarker = null;
        this.$gpsGeneralError = $(document).find(GPS_GENERAL_ERROR_SELECTOR);
        this.$gpsAccessDeniedError = $(document).find(GPS_ACCESS_DENIED_ERROR_SELECTOR);
        this.travelTimeSpinnerOverlay = $(document).find(SPINNER_OVERLAY_SELECTOR);
        this.hideGpsErrors();
        this.appSpinner = new AppSpinner(SPINNER_SELECTOR);
        this.store = store || reduxStore;
        this.connect = new Connect(this.store, [
            'ui.activeLocationId',
            'ui.modalityAndTravelToggle',
            'ui.showPolygonSpinner',
        ]);

        this.subscribeToStore();
    }

    onPersonalPlaceMarkerClickedHandler(locationId) {
        this.store.dispatch(setActiveLocation(locationId));
    }

    subscribeToStore() {
        this.connect.subscribe((state, prevState) => {
            const locationId = activeLocationIdSelector(state);

            if ((activeLocationChanged(state, prevState) && hasActiveLocation(state)) ||
                modalityAndTravelToggleChanged(state, prevState)) {
                this.store.dispatch(showLoadingSpinner(true));
                const location = getLocationById(state, locationId);
                this.store.dispatch(getTravelTimePolygonData(location, modalityAndTravelToggleSelector(state)));
            }
            if (state.ui.showPolygonSpinner !== prevState.ui.showPolygonSpinner) {
                this.showLoadingSpinner(state.ui.showPolygonSpinner);
            }
        });
    }

    showLoadingSpinner(show) {
        if (show) {
            this.travelTimePolygon.clearPolygon();
            this.travelTimeSpinnerOverlay.removeClass(IS_HIDDEN_CLASS);
            this.appSpinner.show();
        } else {
            this.travelTimeSpinnerOverlay.addClass(IS_HIDDEN_CLASS);
            this.appSpinner.hide();
        }
    }

    /**
     *  Create marker overlay with custom markers
     */
    createOverlay() {
        const component = this;
        component.mapMarkerManager = new SearchMapMarkerManager(component.$element, component.config);
        const mapData = {
            map: component.map,
            config: component.config,
            tileSize: component.tileSize,
            cluster: MapEvents.getValueFromHash('c') || 0
        };
        component.markerOverlay = new MapMarkerOverlay(mapData, component.mapMarkerManager);
        // Use this map type as overlay.
        component.map.overlayMapTypes.insertAt(0, component.markerOverlay);
        // map changed
        component.$element.on(SHOW_OBJECT_INFO_EVENT, (event, eventArgs) => {
            let id = eventArgs.objectId;
            // we could get an id consisting of multiple parts
            var idParts = id.split('-');
            if (idParts.length > 1) {
                id = idParts[0];
            }
            component.recordMapState({id: id});
        });
        component.$element.on(HIDE_OBJECT_INFO_EVENT, (event, eventArgs) => {
            if (eventArgs.type === NO_OBJECT_EVENT_ARG) {
                component.recordMapState({id: null});
            }
        });
    }

    /**
     *  implementation that checks zoomlevel limits that can be switched off (used when forcing google map to reload tiles)
     */
    verifyMapZoomLimit(map) {
        const component = this;
        if (component.doNotEnforceZoomLevel) return;

        const currentZoom = map.getZoom();
        if (currentZoom > MAX_ZOOM) {
            map.setZoom(MAX_ZOOM);
        }
        else if (currentZoom < MIN_ZOOM) {
            map.setZoom(MIN_ZOOM);
        }
    }

    /**
     * handles popstate for map
     */
    onMapPopState(event) {
        const component = this;
        const google = window.google;

        if (event && event.state === PUSH_STATE_MARKER) {
            // handle map stuff
            component.mapEventProxy.forwardProxiedEvents(false);
            let prevLocation = component.mapEvents.getValueFromHash('center');
            let prevZoom = component.mapEvents.getValueFromHash('zoom');
            if (prevLocation && prevZoom) {
                const latlng = component.mapEvents.getValueFromHash('center').split(',');
                const weirdPoint = new google.maps.LatLng(parseFloat(latlng[0]) + .5, parseFloat(latlng[1]) + .5);
                component.map.setCenter(weirdPoint);
                const point = new google.maps.LatLng(parseFloat(latlng[0]), parseFloat(latlng[1]));
                component.map.setCenter(point);
                component.map.setZoom(parseInt(component.mapEvents.getValueFromHash('zoom'), 10));
            }
            // do tiles need refreshing
            var newSearchFromUrl = component.mapEvents.getCurrentSearchFromUrl();
            if (newSearchFromUrl !== component.currentSearch) {
                component.markerOverlay.refreshTiles(newSearchFromUrl);
                component.currentSearch = newSearchFromUrl;
                component.refreshSidebar(newSearchFromUrl);
            }
            // marker / infowindow
            const rawId = component.mapEvents.getValueFromHash('id');
            if (rawId !== null && rawId !== '') {
                component.mapMarkerManager.selectMarker(rawId);
                component.infoWindow.processEvent({initial: true, objectId: rawId});
            } else {
                component.mapMarkerManager.unselectAllMarkers();
                component.infoWindow.hide();
            }
            component.mapEventProxy.forwardProxiedEvents(true);
        }
    }

    refreshSidebar(newSearch) {
        $.ajax({
            type: 'GET',
            url: ASYNC_SIDEBAR_URL + newSearch,
            dataType: 'text',
            success: function (data) {
                $('form').html($(data));
                $(window).trigger('sidebar-refresh');
            }
        });

    }

    hideGpsErrors() {
        this.$gpsGeneralError.find(NOTIFICATION_SELECTOR).addClass(IS_HIDDEN_CLASS);
        this.$gpsAccessDeniedError.find(NOTIFICATION_SELECTOR).addClass(IS_HIDDEN_CLASS);
    }

    updateGpsMarker(location) {
        const component = this;
        if (component.gpsMarker === null) {
            component.gpsMarker = {
                item1: new google.maps.Marker({
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillColor: '#1285ff',
                        fillOpacity: .2,
                        strokeColor: '#1285ff',
                        strokeOpacity: .6,
                        strokeWeight: 1,
                        scale: 40
                    },
                    map: component.map,
                    clickable: false
                }),
                item2: new google.maps.Marker({
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillColor: '#1285ff',
                        fillOpacity: 1,
                        strokeColor: '#ffffff',
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        scale: 8
                    },
                    map: component.map,
                    clickable: false
                })
            };
        }
        component.gpsMarker.item1.setPosition(location);
        component.gpsMarker.item2.setPosition(location);
    }
}

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new SearchMap(element));

import $ from 'jquery';

export default AsyncUrlRemoveParam;

const Component = AsyncUrlRemoveParam;
const COMPONENT_DATA = 'async-url-remove-param';
const COMPONENT_SELECTOR = `[data-${COMPONENT_DATA}]`;

function AsyncUrlRemoveParam(element) {
    const component = this;
    component.$element = $(element);
    component.parameter = component.$element.data(COMPONENT_DATA);

    component.bindEvents();
}

AsyncUrlRemoveParam.prototype.bindEvents = function () {
    const component = this;

    component.$element.on('click', (e) => Component.removeParam(component.parameter, e));
};

AsyncUrlRemoveParam.removeParam = function (param, event) {
    if (typeof event === 'object' && typeof event.preventDefault === 'function') {
        event.preventDefault();
    }
    const splitUrl = Component.getUrl().split('?');
    let newUrl = splitUrl[0]; //base url
    let newParams = splitUrl[1];

    if (typeof newParams === 'string') {
        newParams = newParams.replace(param, '').replace('&&', '&');

        if (newParams.length > 0 && newParams[0] == '&') {
            newParams = newParams.substr(1);
        }
        if (newParams.length > 0 && newParams[newParams.length - 1] == '&') {
            newParams = newParams.substr(0, newParams.length - 1);
        }
        if (newParams.length > 0) {
            newUrl += '?' + newParams;
        }
    }
    Component.setUrl(newUrl);
};

AsyncUrlRemoveParam.getUrl = function () {
    return window.location.href;
};

AsyncUrlRemoveParam.setUrl = function (url) {
    window.location.href = url;
};

AsyncUrlRemoveParam.initialize = function () {
    $(COMPONENT_SELECTOR).each((index, element) => new AsyncUrlRemoveParam(element));
};

AsyncUrlRemoveParam.initialize();

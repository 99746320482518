/* global google */

import {
    getVoorzieningen,
    addLoadedTile
} from '../store/actions';

const MIN_ZOOM_LEVEL = 14;

export default class VoorzieningenMapType {
    constructor(map, config, store) {
        // properties that Google Maps expects
        this.tileSize = new google.maps.Size(256, 256);
        this.maxZoom = 21;
        this.minZoom = MIN_ZOOM_LEVEL;

        this.map = map;
        this.config = config;
        this.store = store;

        this.tileSizeInPoints = this.tileSize.width / Math.pow(2, MIN_ZOOM_LEVEL);
        this.projection = this.map.getProjection();

        google.maps.event.addListener(this.map, 'projection_changed', () => {
            this.projection = this.map.getProjection();
        });
    }

    getTile(coord, zoom) {
        // this MapType should not work if you zoom out after MIN_ZOOM_LEVEL
        if (zoom < MIN_ZOOM_LEVEL) {
            return;
        }

        const diff = Math.abs(MIN_ZOOM_LEVEL - zoom);
        /* eslint-disable no-bitwise */
        const tileX = coord.x >> diff;
        const tileY = coord.y >> diff;
        /* eslint-enable no-bitwise */
        const tileId = `${tileX}-${tileY}`;

        const state = this.store.getState();
        if (state.ui.loadedTiles.includes(tileId)) {
            return;
        }

        let x = tileX * this.tileSizeInPoints;
        let y = (tileY * this.tileSizeInPoints) + this.tileSizeInPoints;
        const southWest = this.fromXyToLatLng(x, y);

        x = (tileX * this.tileSizeInPoints) + this.tileSizeInPoints;
        y = tileY * this.tileSizeInPoints;
        const northEast = this.fromXyToLatLng(x, y);

        const data = {
            NorthLat: northEast.lat(),
            EastLong: northEast.lng(),
            SouthLat: southWest.lat(),
            WestLong: southWest.lng()
        };

        this.store.dispatch(addLoadedTile(tileId));
        this.store.dispatch(getVoorzieningen(this.config.baseUrlVoorzieningen, data));
    }

    fromXyToLatLng(x, y) {
        return this.projection.fromPointToLatLng(new google.maps.Point(x, y));
    }
}

import {
    DELETE_PERSONAL_PLACE,
    DELETE_PERSONAL_PLACE_SUCCESS,
    DELETE_PERSONAL_PLACE_FAIL,
} from '../constants/action-types';

const initialState = {
    loaded: false,
    loading: false,
    error: null,
    html: null
};

export default function deletePersonalPlace(state = initialState, action = {}) {
    switch (action.type) {
        case DELETE_PERSONAL_PLACE:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: null,
                html: null
            };
        case DELETE_PERSONAL_PLACE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null,
                html: action.result.locationList
            };
        case DELETE_PERSONAL_PLACE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error,
                html: null
            };
        default:
            return state;
    }
}

/**
 * home koop / default view
 */

import './../_base-view/base-view';

import '../../components/autocomplete/autocomplete';
import '../../components/advertisements/advertisements';
import '../../components/soort-notification-cookie/soort-notification-cookie';
import '../../components/notification-status/notification-status';
import '../../components/range-filter/range-filter';
import '../../components/expandible/expandible';
import '../../components/search-block/search-block';
import '../../components/traffic-driver-waardecheck/traffic-driver-waardecheck';

/**
 * object-detail view
 */

import './../_base-view/base-view';

import './../../components/advertisements/advertisements';
import './../../components/image-error-fallback/image-error-fallback';
import './../../components/object-description/object-description';
import './../../components/object-kenmerken/object-kenmerken';
import './../../components/object-buurt/object-buurt';
import './../../components/object-map/object-map';
import './../../components/object-map-type-select/object-map-type-select';
import './../../components/object-map-drawer-overview-accordion/object-map-drawer-overview-accordion';
import './../../components/media-viewer/media-viewer';
import './../../components/object-media/object-media';
import './../../components/object-contact/object-contact';
import './../../components/log-request/log-request';
import './../../components/opt-in/opt-in';
import './../../components/tooltip/tooltip';
import './../../components/user-save-object/user-save-object';
import './../../components/object-detail-interaction/object-detail-interaction';
import './../../components/related-objects/related-objects';
import './../../components/media-viewer-map/media-viewer-map';
import './../../components/media-viewer-map-nwn/media-viewer-map-nwn';
import './../../components/object-map-situatiekaart-drawer/object-map-situatiekaart-drawer';
import './../../components/travel-time-modality-toggle/travel-time-modality-toggle';
import './../../components/object-map-drawers/object-map-drawers';
import './../../components/my-personal-places/my-personal-places';
import './../../components/media-viewer-map/media-viewer-spinner';
import './../../components/object-locations/object-locations';
import './../../components/object-my-locations/object-my-locations';
import './../../components/media-viewer/media-viewer-router';
import './../../components/kopers-services-traffic-driver/kopers-services-traffic-driver';

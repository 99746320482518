// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

export default AanbodtypeDropdown;

// constant definitions
const COMPONENT_SELECTOR = '[data-aanbodtype-dropdown]';
const SELECTED_OPTION = '[data-dropdown-selected-option]';
const OPENED_CLASS = 'is-open';
const OPTIONS_LIST = '[data-dropdown-list]';
const OPTION = '[data-dropdown-option]';
const SELECTED__DATA_VALUE = 'dropdown-value';
const DEST_FIELD_NAME = '[name=filter_ZoekType]';


function AanbodtypeDropdown(element) {
    const component = this;
    component.$element = $(element);
    component.$selectedOption = component.$element.find(SELECTED_OPTION);
    component.$optionsList = component.$element.find(OPTIONS_LIST);
    component.$option = component.$element.find(OPTION);
    component.$destValue = component.$element.find(DEST_FIELD_NAME);
    component.isOpen = false;
    component.bindEvents();
}

AanbodtypeDropdown.prototype.bindEvents = function () {
    const component = this;
    component.bindSelectboxEvent();
    component.bindClickOutsideEvent();
    component.bindOptionClickEvent();
};

AanbodtypeDropdown.prototype.bindOptionClickEvent = function () {
    const component = this;
    component.$option.on('click', (event) => {
        event.preventDefault();
        var aanbodType = $(event.currentTarget).find('.label-text').html();
        component.$selectedOption.html(aanbodType);
        // sync selected value to used select element
        var sourceValue = $(event.currentTarget).data(SELECTED__DATA_VALUE);
        component.$destValue.val(sourceValue).change();
    });
};

AanbodtypeDropdown.prototype.bindSelectboxEvent = function () {
    const component = this;
    component.$selectedOption.on('click', () => {
        if (component.isOpen) {
            component.closeOptions();
        } else {
            component.openOptions();
        }
    });
};

AanbodtypeDropdown.prototype.openOptions = function () {
    const component = this;
    component.isOpen = true;
    component.$element.addClass(OPENED_CLASS);
    component.$optionsList.addClass(OPENED_CLASS);
};

AanbodtypeDropdown.prototype.closeOptions = function () {
    const component = this;
    component.isOpen = false;
    component.$element.removeClass(OPENED_CLASS);
    component.$optionsList.removeClass(OPENED_CLASS);
};

AanbodtypeDropdown.prototype.bindClickOutsideEvent = function () {
    const component = this;
    $(document).on('click', function (event) {
        if ($(event.target).closest(COMPONENT_SELECTOR).length === 0) {
            component.closeOptions();
        }
    });
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each((index, element) => new AanbodtypeDropdown(element));
import $ from 'jquery';
import template from 'lodash/template';

const RESULT_LIST_ITEM_SELECTOR = '[data-list-result-item]';
const RESULT_LIST_SELECTOR = '[data-save-location-form-result-list-target]';
const LIST_EMPTY_TEMPLATE_SELECTOR = '[data-save-location-form-result-list-empty-template]';
const LIST_ITEM_TEMPLATE_SELECTOR = '[data-save-location-form-result-list-item-template]';
const IS_HIDDEN = 'is-hidden';

export const SELECTOR = '[data-save-location-form-result-list]';

const noop = () => {};

export default class ListResult {
    constructor(element, onListItemClicked) {
        this.$element = $(element);
        this.$list = this.$element.find(RESULT_LIST_SELECTOR);

        this.compiledTemplateListEmpty = template(this.$element.find(LIST_EMPTY_TEMPLATE_SELECTOR).html());
        this.compiledTemplateListItem = template(this.$element.find(LIST_ITEM_TEMPLATE_SELECTOR).html());

        this.onListItemClicked = onListItemClicked || noop;
    }

    bindEvents() {
        const listItems = this.$list.find(RESULT_LIST_ITEM_SELECTOR);
        listItems.on('click', event => this.onListItemClickedHandler(event));
    }

    onListItemClickedHandler(event) {
        event.preventDefault();

        if (event.currentTarget.tagName === 'LI') {
            const itemProperties = this.getItemPropertiesElement(event.currentTarget);
            this.onListItemClicked(itemProperties);
        }
    }

    getItemPropertiesElement(itemElement) {
        return ({
            value: itemElement.dataset.itemValue,
            key: itemElement.dataset.itemKey
        });
    }

    getItemPropertiesByIndex(itemIndex) {
        const itemElement = this.$list.find(RESULT_LIST_ITEM_SELECTOR).eq(itemIndex);
        return this.getItemPropertiesElement(itemElement[0]);
    }

    layout(data) {
        const results = this.buildList(data);
        this.showList();
        this.$list.html(results);
        this.bindEvents();
    }

    hideList() {
        this.$element.addClass(IS_HIDDEN);
    }

    showList() {
        this.$element.removeClass(IS_HIDDEN);
    }

    buildList(items) {
        if (!items.length) {
            return this.compiledTemplateListEmpty();
        } else {
            return items.map((item) => {
                return this.compiledTemplateListItem({
                    value: item.value,
                    key: item.key
                });
            });
        }
    }
}

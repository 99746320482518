/**
 * Base view
 * Foundation for all views, bootstraps app-wide components
 */

import '../../components/app-utils/app-utils-jquery-extensions';

import '../../components/error-bar/error-bar';
import '../../components/app-header/app-header';
import '../../components/app-header/app-header-menu';
import '../../components/popover/popover';
import '../../components/cookie-policy/cookie-policy';
import '../../components/cookie-preferences/cookie-preferences';

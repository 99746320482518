/* globals embedpano */

export default MediaFoto360;
import $ from 'jquery';

import load from 'load-script';

import '../krpano-loader/krpano-loader';

var COMPONENT_SELECTOR = '[data-media-foto360]';
var KRPANO_CONTAINER_ID = 'media-foto360-krpano';
var KRPANO_BASE_URL_ATTR = 'data-media-foto360-krpano-base-url';
var FOTO_360_SOURCE = 'data-media-foto360-krpano-source';
var FOTO_360_PREFIX = '360-foto-';

function MediaFoto360(element) {
    var component = this;

    component.$element = $(element);
    component.baseUrl = component.$element.attr(KRPANO_BASE_URL_ATTR) || '';
    component.src = component.$element.attr(FOTO_360_SOURCE);

    this.initKrpano();

    $(window).on('krpanohotspotclicked', function(e, data) {
        if (data.name) {
            // targets everything after the last '/' ignoring a final '/' with nothing trailing it
            //      ____          ___
            // /foo/bar/ and /foo/bar
            /* eslint-disable no-useless-escape */
            let urlFotoParameterRegex = new RegExp('([^\/]+\/$)|([^\/]+$)');
            let newLocation = (window.location.href).replace(urlFotoParameterRegex, FOTO_360_PREFIX + data.name);

            // See media-viewer-foto-360.js for KRPano navigation instead
            window.location.assign(newLocation);
        }
    });
}

MediaFoto360.prototype.initKrpano = function() {
    var component = this;
    var krpanoContainer = document.createElement('div');
    krpanoContainer.id = KRPANO_CONTAINER_ID;
    component.$element.append(krpanoContainer);

    var onReady = function onReady(krpano) {
        component.krpano = krpano;

        // Hack for the relative path of the stub pano's in the guide.
        // Krpano sets its basedir automatically to the path of the first loaded pano,
        // therefore the next pano's relative path becomes relative to the first one's path.
        krpano.set('basedir', '');
        // end hack
    };

    var debugging = window.location.search.indexOf('krpanodebug=true') !== -1;

    load(component.baseUrl + '/assets/vendor-krpano.js', function(error) {
        if (!error) {
            embedpano({
                html5: 'prefer',
                swf: component.baseUrl + '/assets/components/krpano-loader/krpano.swf',
                xml: component.src,
                target: KRPANO_CONTAINER_ID,
                consolelog: debugging,
                onready: onReady
            });
        }
    });
};

// turn all elements with the default selector into components
$(COMPONENT_SELECTOR).each(function(index, element) {
    return new MediaFoto360(element);
});

// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

const CLOSE_SELECTOR = '[data-app-header-menu-close]';
const HANDLE_ATTR = 'data-app-header-menu-handle';
const HANDLE_SELECTOR = `[${HANDLE_ATTR}]`;
const SUBMENU_SELECTOR = '[data-app-header-submenu]';

const BODY_OPEN_CLASS = 'app-header-open';
const OPEN_CLASS = 'is-open';
const OPENING_CLASS = 'is-opening';
const CLOSING_CLASS = 'is-closing';
const ANIMATION_END_EVENTS = 'webkitAnimationEnd oAnimationEnd msAnimationEnd animationend';

const $body = $('body');
const $window = $(window);

export default class AppHeaderMenu {
    constructor(element) {
        const component = this;

        component.$element = $(element);
        component.$closeButton = component.$element.find(CLOSE_SELECTOR);
        component.$handle = component.$element.find(HANDLE_SELECTOR);
        component.$submenu = component.$element.find(SUBMENU_SELECTOR);
        component.submenuOpened = false;
        component.savedScrollPosition = null;

        component.bindEvents();
    }

    bindEvents() {
        const component = this;
        component.$handle.click((e) => {
            e.preventDefault();
            component.toggleMenu();
        });
        component.$closeButton.click(() => component.closeMenu());

        $body.on('click', (e) => {
            const targetIsHandle = component.$handle.is(e.target);
            const targetIsMenu = component.$element.is(e.target);
            const targetIsInsideMenu = $(e.target).closest(component.$element).length !== 0;

            if (component.submenuOpened && !targetIsHandle && !targetIsMenu && !targetIsInsideMenu) {
                component.closeMenu();
            }
        });
    }

    toggleMenu() {
        const component = this;

        if (component.$submenu.hasClass(OPEN_CLASS)) {
            component.closeMenu();
        } else {
            component.openMenu();
        }
    }

    openMenu() {
        const component = this;

        component.$submenu.one(ANIMATION_END_EVENTS, () => component.openAnimationEndHandler());

        component.disableBodyScrolling();
        component.$submenu.addClass(OPENING_CLASS);
        component.$handle.addClass(OPEN_CLASS);
        component.$handle.attr('aria-expanded', 'true');
        component.submenuOpened = true;
    }

    closeMenu() {
        const component = this;

        component.$submenu.removeClass(OPEN_CLASS).addClass(CLOSING_CLASS);
        component.$handle.removeClass(OPEN_CLASS);
        component.$handle.attr('aria-expanded', 'false');
        component.enableBodyScrolling();

        component.$submenu.one(ANIMATION_END_EVENTS, () => component.closeAnimationEndHandler());
        component.submenuOpened = false;
    }

    openAnimationEndHandler() {
        const component = this;

        component.$submenu.removeClass(OPENING_CLASS).addClass(OPEN_CLASS);
        component.$submenu.attr('aria-hidden', 'false');
        component.$submenu.off(ANIMATION_END_EVENTS);
    }

    closeAnimationEndHandler() {
        const component = this;

        component.$submenu.removeClass(CLOSING_CLASS);
        component.$submenu.attr('aria-hidden', 'true');
        component.$submenu.off(ANIMATION_END_EVENTS);
    }

    disableBodyScrolling() {
        const component = this;
        component.savedScrollPosition = $window.scrollTop();

        $body.css('top', -1 * component.savedScrollPosition);
        $body.addClass(BODY_OPEN_CLASS);
    }

    enableBodyScrolling() {
        const component = this;

        $body.removeClass(BODY_OPEN_CLASS);
        $window.scrollTop(component.savedScrollPosition);
    }
}



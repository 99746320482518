// require this module where needed, either in a specific view or component or generically in src/index.js
import $ from 'jquery';

export default KopersServicesTrafficDriver;

// constant definitions
const COMPONENT_SELECTOR = '[data-kopers-services-traffic-driver]';
const COMPONENT_ADBLOCK_ONLY_DATA_ATTR = 'data-kopers-services-traffic-driver-adreplacement';
const ADSDETECT_ID = 'krnomhVATBFQ';

function KopersServicesTrafficDriver(element, adblockDetected) {
    const component = this;
    component.$element = $(element);
    component.isReplacementForAdBanner = component.$element.attr(COMPONENT_ADBLOCK_ONLY_DATA_ATTR) !== undefined ? true : false;

    if (adblockDetected && component.isReplacementForAdBanner)
        component.$element.removeAttr('hidden');
}

KopersServicesTrafficDriver.initialize = function () {
    let adblockDetected = !document.getElementById(ADSDETECT_ID);

    $(COMPONENT_SELECTOR).each((index, element) => new KopersServicesTrafficDriver(element, adblockDetected));
};

KopersServicesTrafficDriver.initialize();